import axios, { CancelTokenSource } from 'axios';

import { FullTaxes } from '@entities/Taxes';
import { http } from '@utils';

const basePath = '/api/taxes';
let axiosSource: CancelTokenSource | null = null;

export const getList = async (
  query?: Query['query'],
  isCancel = true,
): Promise<{
  data: FullTaxes[];
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<FullTaxes[]>>(
    `${basePath}${query || ''}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};

export const create = async (body: Omit<FullTaxes, 'id' | 'isActive'>) => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullTaxes>>(`${basePath}`, body);

  return data;
};

export const remove = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const getOne = async (id: FullTaxes['id']): Promise<FullTaxes> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullTaxes>>(`${basePath}/${id}`);

  return data;
};

export const update = async (
  id: number,
  body: Partial<FullTaxes>,
): Promise<FullTaxes> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullTaxes>>(`${basePath}/${id}`, body);

  return data;
};
