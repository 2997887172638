import { DiscountUnit } from '@utils';

import { FormValues } from './index.type';

export const INITIAL_VALUES: FormValues = {
  name: '',
  description: '',
  price: 0,
  gramsPerPack: 0,
  gramsInStock: 0,
  packsInStock: 0,
  isActive: true,
  isFastest: false,
  isMostPopular: false,
  isTodayDeals: false,
  isPromotion: false,
  stores: null,
  drivers: null,
  discountPrice: 0,
  totalPrice: 0,
  discount: {
    discountUnit: DiscountUnit.PERCENT,
    discountValue: 1,
    validFrom: new Date().toString(),
    validUntil: '',
  },
};
