import { ColumnsType } from 'antd/lib/table';

import { FullDriversList } from '@entities/User';
import { DriverUIActionQuantity } from '@features/Product/Detail/ui';
import { ProductUIActionList } from '@features/Product/ui';
import { AppDispatch } from '@store/index';
import { UICellFullName, UICellLink } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_NUMBER,
  LINK_BRAND,
  LINK_CATEGORY,
  LINK_PRODUCT,
  StringHelper,
} from '@utils';

export const columns = ({
  dispatch,
}: {
  dispatch: AppDispatch;
}): ColumnsType<FullDriversList & Id> => {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) =>
        UICellLink({ value, link: `/${LINK_PRODUCT}/${record?.product?.id}` }),
      width: 50,
    },
    {
      title: 'Product name',
      width: 170,
      render: (_, record) =>
        UICellFullName({
          firstName: record?.product?.name || 'not name',
          src: record?.product?.image?.path,
          link: `/${LINK_PRODUCT}/${record?.product?.id}`,
        }),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value, record) =>
        DriverUIActionQuantity({
          productId: record.productId,
          id: record.driverId,
          quantity: +value,
          name: record?.product?.name || 'not name',
          dispatch,
          text: value,
        }),
      width: COLUMN_WIDTH_NUMBER,
    },
    {
      title: 'Brand',
      ellipsis: true,
      width: COLUMN_WIDTH_ACTIONS,
      render: (_, record) =>
        UICellLink({
          value: record?.product?.brand?.name || '',
          link: `/${LINK_BRAND}/${record?.product?.brand?.id}`,
        }),
    },
    {
      title: 'Category',
      ellipsis: true,
      width: COLUMN_WIDTH_ACTIONS,
      render: (_, record) =>
        UICellLink({
          value: record?.product?.category?.name || '',
          link: `/${LINK_CATEGORY}/${record?.product?.category?.id}`,
        }),
    },
    {
      title: 'Price',
      ellipsis: {
        showTitle: false,
      },
      width: 100,
      render: (_, record) =>
        record?.product?.price
          ? StringHelper.toMoney(record?.product?.price, 2)
          : 'not price',
    },
    // {
    //   title: 'Description',
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   width: COLUMN_WIDTH_LONG_TEXT,
    //   render: (_, record) =>
    //     UICellTooltip({
    //       text: record?.product?.description || 'not description',
    //     }),
    // },
    // {
    //   title: 'Visible',
    //   width: 80,
    //   render: (_, record) =>
    //     UICellStatus({
    //       value: record?.product?.isActive || false,
    //       text: record?.product?.isActive ? 'Yes' : 'No',
    //     }),
    // },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (id) => ProductUIActionList({ id, dispatch }),
      align: 'right',
    },
  ];
};
