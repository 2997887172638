import { useAppDispatch } from '@store/index';
import { orderPaymentRequest } from '@store/Order';
import { UIButton } from '@ui';
import { Button } from '@utils';

const PaymentAeroPay = ({
  totalPrice,
  paymentToken,
}: {
  paymentToken: string;
  totalPrice: string | number | null;
}) => {
  const dispatch = useAppDispatch();
  const { AeroPay } = window as unknown as {
    AeroPay: {
      button: (prop: { [key: string]: any }) => void;
      init: ({ env }: { env: string }) => void;
      transaction: () => void;
    };
  } & Window;

  if (window && typeof window !== 'undefined' && AeroPay && AeroPay?.init) {
    AeroPay.init({
      env: process.env.REACT_APP_AEROPAY || 'staging',
    });
  }

  const onSuccess = (uuid: string) => {
    dispatch(
      orderPaymentRequest({
        paymentToken,
        paymentTransaction: uuid,
      }),
    );
  };

  const onEvent = (event: { [key: string]: any }) => {
    console.log(event);
  };

  type ASD = {
    launch: (prop: string | number) => void;
  };

  const apButton = AeroPay?.button({
    merchantId: '1380',
    type: 'checkout',
    onSuccess,
    onEvent,
  });

  return (
    <UIButton
      size="middle"
      type={Button.ANT_BUTTON}
      handler={() => (apButton as unknown as ASD).launch(totalPrice as number)}
    >
      Click to pay with
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416.1 86">
        <g id="Layer_2_00000015327336124958780600000015319321936240301452_"></g>
        <g>
          <g>
            <path
              d="M142.5,61.4v-2l1.3-1.6l0.2-0.7c-0.5,0.6-1.1,1.1-1.7,1.6c-3,2.2-6.8,3.3-11.4,3.3c-2.7,0-5.3-0.5-7.8-1.6
              s-4.7-2.5-6.6-4.4s-3.4-4.1-4.4-6.6s-1.5-5.1-1.5-7.8s0.5-5.4,1.5-7.9s2.6-4.8,4.5-6.7c1.8-1.9,4-3.5,6.5-4.5s5.1-1.6,7.7-1.5
              c4.7,0,8.6,1.2,11.6,3.5c0.5,0.4,1.1,0.9,1.5,1.3l-0.2-0.6l-1.3-1.6v-2.3h10.8v40h-10.7V61.4z M131.8,30.8c-1.4,0-2.8,0.2-4.1,0.8
              c-1.3,0.5-2.5,1.4-3.4,2.4c-2,2.1-3,4.8-3,7.6s1.1,5.6,3,7.6c1,1,2.1,1.8,3.4,2.4c1.3,0.5,2.7,0.8,4.1,0.8c1.4,0,2.9-0.2,4.2-0.8
              c1.3-0.5,2.6-1.3,3.6-2.4c1-1,1.8-2.2,2.3-3.5s0.8-2.7,0.8-4.1c0-1.4-0.3-2.8-0.8-4.1s-1.3-2.5-2.4-3.5c-1-1-2.2-1.8-3.5-2.4
              C134.6,31.1,133.2,30.8,131.8,30.8z"
            />
            <path
              d="M197.1,52.8c-4.2,6.3-10.5,9.5-19,9.5c-6.3,0-11.4-2-15.3-5.9C159,52.3,157,47,157,41.5s2.1-10.9,5.9-14.9c4-4,9-6,15.2-6
              s11.1,1.9,14.8,5.7s5.5,8.7,5.5,14.6c0,0.5,0,1,0,1.6s-0.1,1.1-0.1,1.5s0,0.7,0,0.7h-30.6c0.5,2.4,1.8,4.6,3.7,6.1
              c2,1.5,4.4,2.3,6.9,2.2c2.1,0.1,4.1-0.4,6-1.3c1.9-0.9,3.4-2.3,4.6-4L197.1,52.8z M178.1,29.5c-5.2,0-8.5,2.4-10,7.3h19.4
              c-0.5-2.2-1.7-4.1-3.4-5.4C182.4,30.1,180.3,29.4,178.1,29.5z"
            />
            <path
              d="M213.2,61.4h-10.9v-40h10.8v4.3l-0.6,1.9c0,0,0.6-0.9,0.7-0.9c1.3-1.9,3-3.4,5-4.4s4.3-1.6,6.5-1.5v10.9
              c-3.9,0-6.7,1-8.7,3c-1.9,2-2.9,4.8-2.9,8.4L213.2,61.4L213.2,61.4z"
            />
            <path
              d="M246.6,20.6c2.8-0.1,5.7,0.4,8.3,1.5c2.6,1,5,2.6,7.1,4.6c2,1.9,3.5,4.3,4.6,6.8s1.6,5.3,1.6,8c0,2.8-0.5,5.5-1.6,8
              s-2.6,4.9-4.6,6.8c-4.2,3.9-9.7,6-15.3,6c-5.7,0-11.2-2.1-15.4-6c-1.9-1.9-3.5-4.3-4.5-6.8c-1.1-2.5-1.6-5.3-1.6-8
              c0-2.8,0.5-5.5,1.6-8s2.6-4.9,4.6-6.8c2-2,4.4-3.6,7-4.6C241,21,243.8,20.5,246.6,20.6z M254.3,33.6c-2-2-4.8-3.2-7.7-3.2
              c-2.9,0-5.6,1.1-7.7,3.2c-1,1-1.8,2.3-2.3,3.6c-0.5,1.4-0.8,2.8-0.7,4.3c-0.1,1.5,0.2,2.9,0.7,4.3c0.5,1.4,1.3,2.6,2.3,3.7
              c1,1,2.1,1.8,3.4,2.4c1.3,0.5,2.7,0.8,4.1,0.8c1.5,0,2.9-0.2,4.2-0.8c1.3-0.6,2.6-1.4,3.6-2.4c2-2.1,3.2-5,3.2-7.9
              C257.4,38.6,256.3,35.8,254.3,33.6L254.3,33.6z"
            />
            <path
              d="M293.2,62c-2.6,0.1-5.2-0.4-7.7-1.4c-2.4-1-4.6-2.5-6.5-4.3l-0.7-1l0.7,1.9v17.7h-6.7v-53l6.9-0.7v5.2l-0.6,1.7l0.6-0.9
              c3.5-3.9,8.2-5.9,14.1-5.9c2.6,0,5.3,0.5,7.7,1.5c2.4,1,4.6,2.5,6.5,4.4c3.8,3.8,5.9,9,5.9,14.4s-2.1,10.6-5.9,14.4
              c-1.8,1.9-4,3.4-6.5,4.4C298.5,61.6,295.9,62.1,293.2,62z M292.9,27.8c-3.7,0-7.3,1.5-9.9,4.1s-4.1,6.2-4.1,9.9
              c0,3.7,1.5,7.3,4.1,9.9c2.6,2.6,6.2,4.1,9.9,4.1c1.8,0,3.6-0.3,5.3-1c1.7-0.7,3.2-1.7,4.5-3.1c2.5-2.7,3.9-6.2,3.9-9.9
              s-1.4-7.2-3.9-9.9c-1.3-1.3-2.8-2.4-4.5-3.1C296.5,28.1,294.7,27.7,292.9,27.8z"
            />
            <path
              d="M351.2,61.4v-5.3c-3.6,4-8.3,5.9-14.2,5.9c-2.6,0-5.3-0.5-7.7-1.5c-2.4-1-4.6-2.5-6.5-4.4c-3.8-3.8-5.9-9-5.9-14.4
              s2.1-10.6,5.9-14.4c1.8-1.9,4-3.4,6.5-4.4s5.1-1.5,7.7-1.5c2.6-0.1,5.3,0.4,7.7,1.4c2.4,1,4.7,2.5,6.5,4.4V22h6.8v39.5L351.2,61.4
              z M337.3,27.8c-1.8,0-3.6,0.3-5.3,1c-1.7,0.7-3.2,1.7-4.5,3.1c-2.5,2.7-3.9,6.2-3.9,9.9c0,3.7,1.4,7.2,3.9,9.9
              c1.3,1.3,2.8,2.4,4.5,3.1c1.7,0.7,3.5,1,5.3,1c3.7,0,7.3-1.5,9.9-4.1s4.1-6.2,4.1-9.9s-1.5-7.3-4.1-9.9
              C344.6,29.3,341.1,27.8,337.3,27.8L337.3,27.8z"
            />
            <path
              d="M398.5,21.9l-14,38.4c-1.9,5.2-4.2,8.9-6.9,11.2c-2.8,2.3-6.4,3.4-10.8,3.4H365v-6.2h1.8c4.8,0,8.4-2.7,10.7-8l-15.8-38.8
              h7.6l11.6,29.9l10.5-29.9C391.4,21.9,398.5,21.9,398.5,21.9z"
            />
          </g>
          <path
            className="st0"
            d="M36,46.6L36,46.6c2.4-1.3,42.6-23.9,43.6-24.4c0.3-0.3,0.8-0.4,1.2-0.6c0.4-0.2,0.9-0.1,1.2,0.1
            c0.3,0.3,0.4,0.7,0.3,1.1c-0.2,0.3-0.4,0.7-0.8,0.9c-0.5,0.3-42.7,26.7-44.5,27.8c-1.2,0.8-2.3,1.6-2.4,3.2c-0.1,0.8,0.3,1.4,0.8,2
            c0.5,0.4,1.3,0.7,2,0.7s1.4-0.1,2.1-0.1c2.1,0,4.3,0.8,6,2.1c2,1.7,3.7,3.7,4.9,6c0.8,1.3,2.1,3.7,3.9,3.7h0.1l0.6,0.1
            c1.5,0.1,2.8-1.3,3.7-2.6c4-5.2,38.1-50.7,39.5-53c0.5-0.8,0.4-2.3-1-2.5c-1-0.2-65.1,18.8-68,19.7l-3.3,1.1
            c-0.3,0.1-0.6,0.2-0.9,0.3l-0.9,0.3c-0.6,0.2-1.2,0.3-1.7,0.6c-1.3,0.4-2.6,0.9-3.7,1.4c-0.3,0.2-0.7,0.4-0.9,0.7
            c-0.3,0.3-0.3,0.8-0.3,1.2c0,0.9,0.5,1.6,1.4,2c1.2,0.5,2.5,1,3.7,1.4c3.5,1.3,6.6,3.4,8.8,6.3c0.4,0.6,0.8,1.1,1.6,1.2
            c0.6,0.1,1.2,0,1.8-0.2L36,46.6z"
          />
        </g>
      </svg>
    </UIButton>
  );
};

export default PaymentAeroPay;
