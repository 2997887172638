import { Button, Space } from 'antd';

import { useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';

const ReviewBrandUIButton = () => {
  const dispatch = useAppDispatch();

  const onCreateClientClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.EDIT_REVIEW_BRAND,
        modalProps: { type: 'create' },
      }),
    );
  };

  return (
    <Space>
      <Button type="primary" onClick={onCreateClientClick} size="large">
        Create review brand
      </Button>
    </Space>
  );
};

export default ReviewBrandUIButton;
