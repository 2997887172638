/* eslint-disable indent */
import { ModalType, showModal } from '@store/Modal';
import { UICellAction } from '@ui';
import { ProcessStatus } from '@utils';

import { Button, Props } from './index.type';

const OrderUIActions = ({ id, status, dispatch }: Props) => {
  const onEditClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.CREATE_ORDER,
        modalProps: { orderId: id },
      }),
    );
  };

  // const onCancelClickHandler = () => {
  //   dispatch(
  //     showModal({
  //       modalType: ModalType.CONFIRM,
  //       modalProps: {
  //         content: <p>Are you sure you want to cancel your order?</p>,
  //         action: () => {
  //           dispatch(
  //             updateOrderRequest({
  //               id,
  //               status: ProcessStatus.CANCELED,
  //             }),
  //           );
  //         },
  //       },
  //     }),
  //   );
  // };

  const renderButton = (): Button[] => {
    const button: Button[] =
      status !== ProcessStatus.CANCELED && status !== ProcessStatus.COMPLETED
        ? [
            {
              title: 'Edit',
              onClick: (e: React.MouseEvent<HTMLElement>) =>
                onEditClickHandler(e),
            },
          ]
        : [];

    // if (status === ProcessStatus.SUCCESS) {
    //   button.push({
    //     title: 'Cancel',
    //     onClick: onCancelClickHandler,
    //     danger: true,
    //   });
    // }

    return button;
  };

  return UICellAction({
    buttons: renderButton(),
  });
};

export default OrderUIActions;
