// import { LayoutMenu } from '@layouts';
import { ModalType, showModal } from '@store/Modal';
import { deleteReviewUserRequest } from '@store/Review';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const ReviewUserUIActions = ({ review, dispatch }: Props) => {
  const onEditClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.EDIT_REVIEW_USER,
        modalProps: { review },
      }),
    );
  };

  const onDeleteReviewHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: <p>Are you sure you want to delete the review?</p>,
          action: () => dispatch(deleteReviewUserRequest({ id: review.id })),
        },
      }),
    );
  };

  return UICellAction({
    // dropdownMenu: LayoutMenu({
    //   buttons: [{ title: 'Delete', onClick: onDeleteReviewHandler }],
    // }),
    buttons: [
      { title: 'Edit', onClick: onEditClickHandler },
      { title: 'Delete', danger: true, onClick: onDeleteReviewHandler },
    ],
  });
};

export default ReviewUserUIActions;
