/* eslint-disable indent */
import { CalendarOutlined, NotificationOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

import { ReactComponent as ChatIcon } from '@assets/svg/chat_icon.svg';
import {
  DateHelper,
  FORMAT_DATE_TIME_WITH_DOT,
  LINK_ORDER,
  LINK_PRODUCT,
  LINK_SUPPORT,
  Notification,
  NotificationType,
} from '@utils';

const { Item } = Menu;

const WidgetHeaderNotification = ({
  notifications,
  handleClick,
  clearAll,
}: {
  clearAll: () => void;
  handleClick: (prop: { id?: number; type: NotificationType }) => void;
  notifications: Notification[];
}) => {
  return (
    <Menu>
      {notifications
        ? notifications?.map((item, index) => (
            <Item
              key={index}
              // icon={<ChatIcon />}
              icon={(() => {
                switch (item.type) {
                  case NotificationType.SUPPORT: {
                    return <ChatIcon />;
                  }
                  case NotificationType.ORDER: {
                    return <CalendarOutlined />;
                  }
                  case NotificationType.NOTIFICATION: {
                    return <NotificationOutlined />;
                  }
                  default:
                    return null;
                }
              })()}
            >
              <Link
                onClick={() =>
                  handleClick({
                    type: item.type,
                    id: item.id,
                  })
                }
                to={`/${(() => {
                  switch (item.type) {
                    case NotificationType.SUPPORT: {
                      return `${LINK_SUPPORT}/${item.id}`;
                    }
                    case NotificationType.ORDER: {
                      return `${LINK_ORDER}/${item.id}`;
                    }
                    case NotificationType.NOTIFICATION: {
                      return LINK_PRODUCT;
                    }
                    default:
                      return null;
                  }
                })()}`}
              >
                {`${item.text.slice(0, 40)} / ${DateHelper.formateDateToString(
                  item?.createdAt,
                  FORMAT_DATE_TIME_WITH_DOT,
                )}`}
              </Link>
            </Item>
          ))
        : null}
      <Item onClick={clearAll}>Clear all</Item>
    </Menu>
  );
};

export default WidgetHeaderNotification;
