import { useEffect, useState } from 'react';

import { Form, Spin } from 'antd';
import { useSelector } from 'react-redux';
import './style.scss';

import { FullMedia } from '@entities/Media';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import {
  clearShop,
  createShopRequest,
  getOneShopRequest,
  updateShopRequest,
} from '@store/Shop';
import { UIButton, UIModal } from '@ui';
import { Button, FileName, UploadFile } from '@utils';

import WidgetModalShopForm from './Form';
import { INITIAL_VALUES } from './Form/constant';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';

const WidgetModalShop = ({ shopId, type }: Props) => {
  const { loading: loadingImage, file: fileStore } = useSelector(
    (state: RootState) => state.media,
  );
  const { shop, loading: loadingShop } = useSelector(
    (state: RootState) => state.shop,
  );
  const [imageFile, setImageFile] = useState<FullMedia | null>(null);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (shopId) {
      dispatch(clearShop());
      dispatch(getOneShopRequest({ id: +shopId }));
    }
  }, [dispatch, shopId]);

  const isCreate = type === 'create';

  const onFormSubmitHandler = (values: FormValues) => {
    if (isCreate) {
      dispatch(createShopRequest(values));
    } else {
      dispatch(updateShopRequest({ ...values, id: +shopId }));
    }
  };

  const onUploadImageHandler = (file: UploadFile['file']) => {
    dispatch(uploadMediaRequest({ file, fileName: FileName.IMAGE }));
  };

  const onRemoveImageHandler = () => {
    dispatch(clearStore());
    setImageFile(null);
  };

  useEffect(() => {
    if (fileStore) {
      setImageFile(fileStore);
    }
  }, [dispatch, fileStore]);

  useEffect(() => {
    if (shop?.image) {
      setImageFile(shop?.image);
    }
  }, [shop?.image]);

  useEffect(() => {
    return () => {
      dispatch(clearShop());
      dispatch(clearStore());
    };
  }, [dispatch]);

  const footer = [
    <UIButton
      key="Save changes"
      typeAntBtn="primary"
      handler={() => form.submit()}
      type={Button.ANT_BUTTON}
      size="large"
      className="edit-form__save-button"
    >
      {isCreate ? 'Add store' : 'Save'}
    </UIButton>,
  ];

  return (
    <UIModal
      className="edit-form"
      title={isCreate ? 'Add store' : 'Edit shop'}
      footer={footer}
      visible
      children={
        <Spin spinning={loadingShop || loadingImage}>
          <WidgetModalShopForm
            shop={shopId ? shop : INITIAL_VALUES}
            image={imageFile}
            form={form}
            onFormSubmit={onFormSubmitHandler}
            onUploadImage={onUploadImageHandler}
            onRemoveImage={onRemoveImageHandler}
          />
        </Spin>
      }
    />
  );
};

export default WidgetModalShop;
