import { createContext, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '@hooks';
import { alertError } from '@store/Alert';
import { useAppDispatch } from '@store/index';
import { getProfileRequest } from '@store/Profile';
import { LINK_LOGIN, LINK_PAYMENT } from '@utils';

import { Props } from './index.type';

export const AppContext = createContext<null>(null);

export const AppProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isAuth, user } = useAuth();

  useEffect(() => {
    if (isAuth) {
      dispatch(getProfileRequest());
    } else {
      if (user) {
        dispatch(alertError('Access is closed!'));
      }
      if (pathname !== `/${LINK_PAYMENT}`) {
        navigate(LINK_LOGIN);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, dispatch, user]);

  return <AppContext.Provider value={null}>{children}</AppContext.Provider>;
};
