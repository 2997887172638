import { useCallback, useEffect } from 'react';

import { Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@hooks';
import { RootState, useAppDispatch } from '@store/index';
import { hideModal } from '@store/Modal';
import { clearListOrder, getListOrderRequest } from '@store/Order';
import { clearUserListStore } from '@store/User';
import { UITable } from '@ui';
import { ActionStatus, LIMIT_DEFAULT, Role } from '@utils';

import './style.scss';
import { columns } from './constant';

const ProductToOrder = ({ id, role }: { id: number; role: Role }) => {
  const { query, updateQuery } = useQuery();
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  const { orderList, meta, loading, status } = useSelector(
    (state: RootState) => state.order,
  );
  const { modalType } = useSelector((state: RootState) => state.modal);

  useEffect(() => {
    dispatch(clearUserListStore());
  }, [dispatch, modalType]);

  useEffect(() => {
    return () => {
      dispatch(clearListOrder());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRequest = useCallback(() => {
    dispatch(clearListOrder());
    dispatch(
      getListOrderRequest(
        `${query}&filter[${
          role === Role.DRIVERS ? 'driverIds' : 'userIds'
        }][0]=${+id}`,
      ),
    );
  }, [dispatch, query, role, id]);

  useEffect(() => {
    if (status === ActionStatus.UPDATED) {
      getRequest();
      dispatch(hideModal());
    }
  }, [dispatch, getRequest, status]);

  useEffect(() => {
    if (search === '') {
      updateQuery({ page: 1, limit: LIMIT_DEFAULT });
    } else {
      getRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  return (
    <Spin size="large" spinning={false}>
      <div className="card-shops-info-shop">
        {orderList?.length ? (
          <div className="card-shops-info-shop-content">
            <Title level={5}>History of orders of this driver:</Title>
            <UITable
              dataSource={orderList}
              loading={loading}
              columns={columns({ dispatch })}
              onPaginationChange={onPaginationChange}
              meta={meta}
              isScrollDefault
            />
          </div>
        ) : (
          <div className="card-shops-info-shop-content">
            <Title level={5}>No order history for this driver.</Title>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default ProductToOrder;
