import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CLEAR_IMAGE_TO_SHOP,
  CLEAR_LIST_SHOP,
  CLEAR_SHOP,
  CREATE_SHOP_FAILURE,
  CREATE_SHOP_REQUEST,
  CREATE_SHOP_SUCCESS,
  DELETE_SHOP_FAILURE,
  DELETE_SHOP_REQUEST,
  DELETE_SHOP_SUCCESS,
  GET_LIST_SHOP_FAILURE,
  GET_LIST_SHOP_REQUEST,
  GET_LIST_SHOP_SUCCESS,
  GET_ONE_SHOP_REQUEST,
  GET_ONE_SHOP_SUCCESS,
  UPDATE_SHOP_FAILURE,
  UPDATE_SHOP_REQUEST,
  UPDATE_SHOP_SUCCESS,
} from './Shop.constant';
import { IShopState, ShopActionsTypes } from './Shop.type';

const initialState: IShopState = {
  status: ActionStatus.NONE,
  shopList: [],
  meta: META_DEFAULT,
  shop: null,
  loading: false,
  isChangeList: false,
};

export default function ShopReducer(
  state = initialState,
  action: ShopActionsTypes,
): IShopState {
  switch (action.type) {
    case GET_LIST_SHOP_REQUEST:
    case GET_ONE_SHOP_REQUEST:
      return {
        ...state,
        status: ActionStatus.NONE,
        loading: true,
      };

    case GET_LIST_SHOP_SUCCESS:
      return {
        ...state,
        shopList: [...state.shopList, ...action.payload.data],
        meta: action.payload.meta,
        loading: false,
      };
    case GET_LIST_SHOP_FAILURE:
    case UPDATE_SHOP_FAILURE:
    case DELETE_SHOP_FAILURE:
    case CREATE_SHOP_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_SHOP_REQUEST:
    case DELETE_SHOP_REQUEST:
    case UPDATE_SHOP_REQUEST:
      return {
        ...state,
        loading: true,
        isChangeList: false,
      };
    case CREATE_SHOP_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.CREATED,
        loading: false,
        isChangeList: true,
        shopList: [action.payload, ...state.shopList],
      };
    }
    case UPDATE_SHOP_SUCCESS:
      return {
        ...state,
        status: ActionStatus.UPDATED,
        loading: false,
        shop: action.payload,
        isChangeList: true,
        shopList: state.shopList.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      };
    case DELETE_SHOP_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.DELETED,
        loading: false,
        isChangeList: true,
        shopList: state.shopList.filter((item) => item.id !== action.payload),
      };
    }
    case GET_ONE_SHOP_SUCCESS: {
      return {
        ...state,
        shop: action.payload.data,
        loading: false,
        ...(action.payload.toList && {
          shopList: [action.payload.data, ...state.shopList],
        }),
      };
    }
    case CLEAR_SHOP: {
      return {
        ...state,
        status: ActionStatus.NONE,
        shop: null,
      };
    }
    case CLEAR_LIST_SHOP: {
      return {
        ...state,
        shopList: [],
        meta: META_DEFAULT,
      };
    }
    case CLEAR_IMAGE_TO_SHOP: {
      return {
        ...state,
        ...(state.shop && {
          shop: { ...state.shop, image: null },
        }),
      };
    }
    default:
      return state;
  }
}
