import { LayoutMenu } from '@layouts';
import { deleteCategoryRequest } from '@store/Category';
import { ModalType, showModal } from '@store/Modal';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const CategoryUIActionList = ({ id, dispatch }: Props) => {
  const onEditClickHandler = (
    e: React.MouseEvent<HTMLElement | HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.CREATE_CATEGORY,
        modalProps: { categoryId: id },
      }),
    );
  };

  const onDeleteCategoryHandler = (
    e: React.MouseEvent<HTMLElement | HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: <p>Are you sure you want to delete the Category</p>,
          action: () => dispatch(deleteCategoryRequest(id)),
        },
      }),
    );
  };

  return UICellAction({
    dropdownMenu: LayoutMenu({
      buttons: [
        { title: 'Delete', onClick: (e) => onDeleteCategoryHandler(e) },
      ],
    }),
    buttons: [{ title: 'Edit', onClick: (e) => onEditClickHandler(e) }],
  });
};

export default CategoryUIActionList;
