import { CaretDownOutlined, NotificationOutlined } from '@ant-design/icons';
import { Col, Dropdown, Layout, Row, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// import { useValidation } from '@hooks';
import { RootState } from '@store/index';
import { clearNotification, clearOneNotification } from '@store/Profile';
import { UIAvatar } from '@ui';

import { Props } from './index.type';
import WidgetHeaderMenu from './Menu';
import WidgetHeaderNotification from './Notification';

import './style.scss';

const WidgetHeader = ({ showProfile }: Props) => {
  const { notifications, profile } = useSelector(
    (state: RootState) => state.profile,
  );
  const dispatch = useDispatch();

  // const [name, setName] = useState('');

  // const { trimValue } = useValidation({ value: name });

  return (
    <Layout.Header className="site-header">
      <Row align="middle" justify="space-between">
        <Col className="logo" span={4}>
          <Link to="/">
            <img src="/weellogo.png" alt="logo" />
          </Link>
        </Col>
        {showProfile && (
          <>
            {/* <Col flex="auto" className="search">
              <Input.Search
                value={trimValue}
                onChange={(e) => setName(e.target.value)}
                allowClear
                placeholder="Search"
                className="search-input"
                size="large"
              />
            </Col> */}
            <Col className="user">
              {notifications.length ? (
                <Dropdown
                  placement="topRight"
                  overlay={
                    <WidgetHeaderNotification
                      handleClick={(prop) =>
                        dispatch(clearOneNotification(prop))
                      }
                      clearAll={() => dispatch(clearNotification())}
                      notifications={notifications}
                    />
                  }
                >
                  <div style={{ display: 'flex', marginRight: '10px' }}>
                    <NotificationOutlined
                      style={{ fontSize: '20px' }}
                      width={20}
                      height={20}
                    />
                    <p className="menu__notification">{notifications.length}</p>
                  </div>
                </Dropdown>
              ) : null}
              <Dropdown overlay={<WidgetHeaderMenu />}>
                <Space>
                  <UIAvatar path={profile?.avatar?.path} />
                  <>
                    {profile?.profile?.fullName || ''}
                    <CaretDownOutlined />
                  </>
                </Space>
              </Dropdown>
            </Col>
          </>
        )}
      </Row>
    </Layout.Header>
  );
};

export default WidgetHeader;
