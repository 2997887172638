import { useCallback, useEffect, useState } from 'react';

import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';

import { useSupportSocket } from '@context';
import { CreateMessageSupport } from '@entities/Support';
import { en } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import { createMessageRequest } from '@store/Support';
import { UIButton } from '@ui';
import { Button } from '@utils';

import { INITIAL_VALUES } from './constant';
import { FormValues, Props } from './index.type';

import './styles.scss';

const SupportDetailSend = ({ maxRows }: Props) => {
  const { supportSocket } = useSupportSocket();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  const { supportId, chat } = useSelector((state: RootState) => state.support);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handlerFinish = useCallback(
    (values: FormValues) => {
      if (values.message && chat && supportId && supportSocket) {
        const message: CreateMessageSupport = {
          text: values.message.trim(),
          supportId: chat.id,
          user: chat.user,
          createdById: chat.message.createdById,
        };

        dispatch(createMessageRequest({ message, socket: supportSocket }));
        window.scrollTo(0, scrollPosition);

        form.resetFields();
      }
    },
    [chat, supportId, supportSocket, dispatch, scrollPosition, form],
  );

  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        requiredMark={false}
        size="large"
        initialValues={INITIAL_VALUES}
        onFinish={handlerFinish}
        autoComplete="off"
        validateMessages={en.validation}
        className="chat-newMessage"
      >
        <Form.Item name="message" className="chat-newMessage__input">
          <TextArea rows={maxRows} />
        </Form.Item>
        <UIButton
          handler={() => form.submit()}
          type={Button.ANT_BUTTON}
          className="chat-newMessage__btn"
          size="large"
        >
          Send
        </UIButton>
      </Form>
    </>
  );
};

export default SupportDetailSend;
