import { Form, Spin } from 'antd';

import { useAppDispatch } from '@store/index';
import { updateOrderRequest } from '@store/Order';

import OrderDetailEditForm from './Form';
import { FormValues, FormValuesChange } from './Form/index.type';
import { Props } from './index.type';
import './style.scss';

const OrderDetailEdit = (props: Props) => {
  const { order, loading } = props;

  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  const onFormSubmitHandler = (values: FormValuesChange) => {
    dispatch(updateOrderRequest(values));
  };

  return (
    <div className="order__edit">
      <Spin spinning={loading}>
        <OrderDetailEditForm
          order={order}
          form={form}
          onFormSubmit={onFormSubmitHandler}
        />
      </Spin>
    </div>
  );
};

export default OrderDetailEdit;
