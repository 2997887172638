import { useEffect } from 'react';

import { Button, Form, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { hideModal } from '@store/Modal';
import { clearAvatarProfile, updateProfileRequest } from '@store/Profile';
import { FileName, UploadFile } from '@utils';

import WidgetModalEditProfileForm from './Form';
import { FormValues } from './Form/index.type';

const WidgetModalProfile = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  const { profile, loading } = useSelector((state: RootState) => state.profile);
  const { loading: loadingImage, file: fileStore } = useSelector(
    (state: RootState) => state.media,
  );

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, [dispatch]);

  const closeModal = () => {
    // form.resetFields();
    dispatch(hideModal());
  };

  const saveProfile = () => {
    form.submit();
  };

  const onFormSubmitHandler = (values: FormValues) => {
    dispatch(updateProfileRequest(values));
  };

  const onUploadImageHandler = (file: UploadFile['file']) => {
    dispatch(uploadMediaRequest({ file, fileName: FileName.IMAGE }));
  };

  const onRemoveImageHandler = () => {
    dispatch(clearAvatarProfile());
    dispatch(clearStore());
  };

  return (
    <Modal
      visible
      title="Edit profile"
      onOk={saveProfile}
      onCancel={closeModal}
      footer={[
        <Button
          key="Save changes"
          type="primary"
          onClick={saveProfile}
          size="large"
          className="edit-form__save-button"
        >
          Save changes
        </Button>,
      ]}
      className="edit-form"
    >
      <Spin spinning={loading || loadingImage}>
        {profile && (
          <WidgetModalEditProfileForm
            user={profile}
            form={form}
            avatar={profile ? fileStore || profile?.avatar || null : fileStore}
            onFormSubmit={onFormSubmitHandler}
            onUploadImage={onUploadImageHandler}
            onRemoveImage={onRemoveImageHandler}
          />
        )}
      </Spin>
    </Modal>
  );
};

export default WidgetModalProfile;
