import TextArea from 'antd/lib/input/TextArea';

import { Props } from './index.type';

const UITextArea = ({ onChangeHandler, value, rows }: Props) => {
  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    if (onChangeHandler) {
      onChangeHandler(e.currentTarget.value);
    }
  };

  return (
    <TextArea
      {...(value && { value })}
      {...(onChangeHandler && { onChange })}
      rows={rows}
    />
  );
};

export default UITextArea;
