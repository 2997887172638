/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Col, Form, Input, InputNumber, Row, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';

import { en } from '@i18n';
import { LocalStorage } from '@services/storage';
import {
  clearListBrands,
  getListBrandRequest,
  getOneBrandRequest,
} from '@store/Brand';
import {
  clearListCategory,
  getListCategoryRequest,
  getOneCategoryRequest,
} from '@store/Category';
import { clearListEffect, getListEffectRequest } from '@store/Effect';
import { RootState, useAppDispatch } from '@store/index';
import { UIButton, UICalendar, UIImageUpload, UISelectFormItem } from '@ui';
import { Button, DiscountUnit, OptionSelect } from '@utils';

import { INITIAL_VALUES } from './constant';
import { FormValues, Props } from './index.type';

const ProductEditForm = ({
  onFormSubmit,
  form,
  product,
  onUploadImage,
  image,
  promotionImage,
  onRemoveImage,
}: Props) => {
  const dispatch = useAppDispatch();
  // const [addresses, setAddresses] = useState<IAddressesCity[]>(
  //   product?.discount?.addresses || [],
  // );

  const {
    brandList,
    loading: loadingBrand,
    meta: metaBrand,
  } = useSelector((state: RootState) => state.brand);
  const {
    categoryList,
    loading: loadingCategory,
    meta: metaCategory,
  } = useSelector((state: RootState) => state.category);
  const {
    effectList,
    loading: loadingEffect,
    meta: metaEffect,
  } = useSelector((state: RootState) => state.effect);

  const price = Form.useWatch('price', form);
  const categoryId = Form.useWatch('categoryId', form);
  const brandId = Form.useWatch('brandId', form);
  const isPromotion = Form.useWatch('isPromotion', form);
  const productEffect: OptionSelect[] | null =
    LocalStorage.get('productEffect');

  const discountPrice = Form.useWatch('discountPrice', form);
  const totalPrice = Form.useWatch('totalPrice', form);
  const discountUnitValue =
    Form.useWatch(['discount', 'discountUnit'], form) || DiscountUnit.PERCENT;
  const isPercent = discountUnitValue === DiscountUnit.PERCENT;

  const [isDiscount, setIsDiscount] = useState(false);
  const [percent, setPercent] = useState(1);
  const [timeActive, setTimeActive] = useState<[string, string] | []>([]);
  const [effects, setEffects] = useState<OptionSelect[] | null | undefined>(
    productEffect,
  );

  const setValueAll = (val: OptionSelect[]) => {
    LocalStorage.set('productEffect', val);
    setEffects(val);
  };

  const setFormPercent = (price: string | number, percent: string | number) => {
    const newDiscountPrice = +(+price * (+(percent || 0) / 100)).toFixed(2);

    setPercent(+percent);
    form.setFieldValue('discountPrice', +newDiscountPrice.toFixed(2));
    form.setFieldValue('totalPrice', +(+price - newDiscountPrice).toFixed(2));
  };

  const setFormTotal = (price: string | number, total: string | number) => {
    form.setFieldValue('discountPrice', +(+total).toFixed(2));
    form.setFieldValue('totalPrice', +(+price - +total).toFixed(2));
    setPercent(+(+total / (+price / 100)).toFixed(2));
  };

  useEffect(() => {
    if (product) {
      form.setFieldsValue(product);
      setPercent(+(+product.discountPrice / (+product.price / 100)).toFixed(2));
      if (!product.discountPrice && product.discount?.discountValue) {
        const dp = product.discount?.discountValue;

        if (product.discount?.discountUnit === DiscountUnit.PERCENT) {
          setFormPercent(product.price, dp);
        } else {
          setFormTotal(product.price, dp);
        }
      } else {
        setPercent(
          +(+product.discountPrice / (+product.price / 100)).toFixed(2),
        );
      }
      setIsDiscount(!!product?.discount);
      if (product.discount?.validFrom && product.discount?.validUntil) {
        setTimeActive([
          product.discount.validFrom,
          product.discount.validUntil,
        ]);
      }
      setValueAll(
        product?.effects?.map((item) => ({
          label: item.name,
          value: item.id,
        })) || [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, form]);

  const handlerFinish = (values: FormValues) => {
    const {
      packsInStock,
      gramsInStock,
      gramsPerPack,
      discountPrice: valDiscountPrice,
      totalPrice: valTotalPrice,
      ...val
    } = values;

    onFormSubmit({
      ...val,
      image,
      ...((!product?.gramsPerPack ||
        (product?.gramsPerPack &&
          (gramsPerPack || gramsPerPack === 0) &&
          +gramsPerPack !== product?.gramsPerPack)) && { gramsPerPack }),
      ...((!product?.gramsInStock ||
        (product?.gramsInStock &&
          (gramsInStock || gramsInStock === 0) &&
          +gramsInStock !== product?.gramsInStock)) && { gramsInStock }),
      discount: isDiscount
        ? {
            discountUnit: discountUnitValue || DiscountUnit.PERCENT,
            discountValue: isPercent ? percent : valDiscountPrice,
            ...(timeActive.length && {
              validFrom: timeActive[0],
              validUntil: timeActive[1],
            }),
          }
        : null,
      promotionImage: isPromotion ? promotionImage : null,
      effects: effects?.map((effect) => ({
        id: +effect.value,
        name: effect.label,
      })),
    });
  };

  // const handleAddCity = (obj: google.maps.places.PlaceResult) => {
  //   setAddresses((prev) => [
  //     ...prev,
  //     {
  //       ...getInfoCity(obj?.address_components as IAddressComponents[]),
  //       formattedAddress: obj.formatted_address || '',
  //       location: {
  //         lat: obj?.geometry?.location?.lat(),
  //         lng: obj?.geometry?.location?.lng(),
  //       },
  //     },
  //   ]);
  //   form.setFieldValue('addresses', '');
  // };

  // const handleDeleteCity = (location: string) => {
  //   setAddresses(
  //     addresses?.filter((item) => JSON.stringify(item.location) !== location),
  //   );
  // };

  const radioWrap = {
    labelCol: { xxl: 14, span: 16 },
    wrapperCol: { xxl: 10, span: 8 },
  };

  const backgroundWrap = {
    labelCol: { xxl: 12, span: 13 },
    wrapperCol: { xxl: 12, span: 10 },
  };

  const discountedWrap = {
    labelCol: { span: 6 },
    wrapperCol: { span: 15 },
  };

  const imageWrap = {
    labelCol: { span: 0, xxl: 7 },
    wrapperCol: { span: 24, xxl: 17 },
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 9, xxl: 7 }}
      wrapperCol={{ span: 15, xxl: 17 }}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      validateMessages={en.validation}
    >
      <Row justify="center">
        <Col span={12}>
          <Form.Item {...imageWrap} name="image" label="Image">
            <UIImageUpload
              view="image"
              name="image"
              label="Image"
              path={image?.path || 'error'}
              onUploadImage={onUploadImage}
              onRemoveImage={onRemoveImage}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          {isPromotion && (
            <Form.Item
              {...imageWrap}
              name="promotionImage"
              label="Background promotion"
            >
              <UIImageUpload
                view="image"
                name="promotionImage"
                label="Background promotion"
                path={promotionImage?.path || 'error'}
                onUploadImage={onUploadImage}
                onRemoveImage={onRemoveImage}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row justify="center">
        <Col span={10}>
          <Form.Item
            name="name"
            label="Product name"
            required
            rules={[{ required: true, whitespace: true }]}
          >
            <Input placeholder="Enter the product" />
          </Form.Item>
          <UISelectFormItem
            label="Brand"
            name="brandId"
            rules={[{ required: true }]}
            required={!!brandId}
            options={brandList || []}
            getOption={(id) =>
              id &&
              dispatch(
                getOneBrandRequest({
                  id: +id,
                  toList: true,
                }),
              )
            }
            valueId={brandId}
            getOptions={(query) =>
              dispatch(getListBrandRequest(`${query}&filter[isActive]=true`))
            }
            clearOptions={() => dispatch(clearListBrands())}
            meta={metaBrand}
            loading={loadingBrand}
          />
          <UISelectFormItem
            label="Effect"
            value={effects as OptionSelect[]}
            onChange={(ids, values) => {
              const newValue = (ids as number[])?.map((id) =>
                [
                  ...(values as OptionSelect[]),
                  ...((effects || []).map((i) => i) || []),
                ].find((e) => +e.value === +id),
              );

              setValueAll(newValue as OptionSelect[]);
            }}
            options={effectList || []}
            getOptions={(query) =>
              dispatch(getListEffectRequest(`${query}&filter[isActive]=true`))
            }
            clearOptions={() => dispatch(clearListEffect())}
            meta={metaEffect}
            loading={loadingEffect}
            mode="multiple"
          />
        </Col>
        <Col span={14}>
          <Row justify="center">
            <Col span={12}>
              <Form.Item
                label="Price"
                name="price"
                {...backgroundWrap}
                required
                rules={[{ required: true, min: 0.01, type: 'number' }]}
              >
                <InputNumber
                  min={0}
                  type="number"
                  precision={2}
                  {...(+price < 0.01 && { status: 'error' })}
                  placeholder="Price"
                />
              </Form.Item>
              <Form.Item
                {...backgroundWrap}
                label="Total grams"
                name="gramsInStock"
              >
                <InputNumber
                  // disabled={true}
                  min={0}
                  type="number"
                  placeholder="Total grams"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...backgroundWrap}
                label="Grams per pack"
                name="gramsPerPack"
              >
                <InputNumber
                  min={0}
                  type="number"
                  placeholder="Grams per pack"
                />
              </Form.Item>
              <Form.Item
                {...backgroundWrap}
                label="Units in stock"
                name="packsInStock"
              >
                <InputNumber
                  min={0}
                  disabled={true}
                  type="number"
                  placeholder="Units in stock"
                />
              </Form.Item>
            </Col>
          </Row>
          <UISelectFormItem
            label="Category"
            name="categoryId"
            options={categoryList || []}
            getOption={(id) =>
              id &&
              dispatch(
                getOneCategoryRequest({
                  id: +id,
                  toList: true,
                }),
              )
            }
            valueId={categoryId}
            getOptions={(query) =>
              dispatch(getListCategoryRequest(`${query}&filter[isActive]=true`))
            }
            clearOptions={() => dispatch(clearListCategory())}
            meta={metaCategory}
            loading={loadingCategory}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col xxl={4} span={4}>
          <Form.Item {...radioWrap} label="Visible" name="isActive">
            <Switch defaultChecked={product?.isActive} />
          </Form.Item>
        </Col>
        <Col xxl={4} span={4}>
          <Form.Item {...radioWrap} label="Is fastest" name="isFastest">
            <Switch defaultChecked={product?.isFastest} />
          </Form.Item>
        </Col>
        <Col xxl={4} span={5}>
          <Form.Item {...radioWrap} label="Is promotion" name="isPromotion">
            <Switch defaultChecked={product?.isPromotion} />
          </Form.Item>
        </Col>
        <Col xxl={4} span={6}>
          <Form.Item
            {...radioWrap}
            label="Is most popular"
            name="isMostPopular"
          >
            <Switch defaultChecked={product?.isMostPopular} />
          </Form.Item>
        </Col>
        <Col xxl={4} span={5}>
          <Form.Item {...radioWrap} label="Is today deals" name="isTodayDeals">
            <Switch defaultChecked={product?.isTodayDeals} />
          </Form.Item>
        </Col>
        <Col xxl={4} span={5}>
          <Form.Item {...radioWrap} label="Is discount">
            <Switch
              checked={isDiscount}
              onChange={(item) => setIsDiscount(item)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item {...discountedWrap} label="Description" name="description">
        <TextArea showCount rows={4} placeholder="Enter the description" />
      </Form.Item>
      {isDiscount && (
        <>
          <Row justify="center">
            <Col xxl={8} span={8}>
              <Form.Item
                label="Percentage"
                {...backgroundWrap}
                required
                rules={[{ required: true, min: 1, max: 100, type: 'number' }]}
              >
                <InputNumber
                  disabled={!isPercent}
                  type="number"
                  value={percent}
                  onChange={(item) => {
                    if (item) {
                      setFormPercent(price, item);
                    }
                  }}
                  {...(percent &&
                    (+percent < 1 || +percent > 100) && { status: 'error' })}
                  min={1}
                  step={1}
                  max={100}
                  placeholder="Percentage"
                />
              </Form.Item>
            </Col>
            <Col xxl={9} span={9}>
              <Form.Item
                {...backgroundWrap}
                label="Amount"
                name="discountPrice"
                required
                rules={[
                  { required: true, min: 0.01, max: +price, type: 'number' },
                ]}
              >
                <InputNumber
                  disabled={isPercent}
                  {...(discountPrice &&
                    (+discountPrice < 0.01 || +discountPrice > +price) && {
                      status: 'error',
                    })}
                  type="number"
                  max={+price}
                  min={0.01}
                  value={discountPrice || 0}
                  onChange={(item) => {
                    if (item) {
                      setFormTotal(price, item);
                    }
                  }}
                  placeholder="Amount"
                />
              </Form.Item>
            </Col>
            <Col xxl={5} span={5}>
              {product ? (
                <Form.Item
                  {...radioWrap}
                  label="Is percent"
                  name={['discount', 'discountUnit']}
                >
                  <Switch
                    onChange={(item) => {
                      form.setFieldValue(
                        ['discount', 'discountUnit'],
                        item ? DiscountUnit.PERCENT : DiscountUnit.CURRENCY,
                      );
                    }}
                    checked={isPercent}
                  />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Row justify="center">
            <Col span={15}>
              <Form.Item
                required
                rules={[{ required: true }]}
                {...discountedWrap}
                label="Discount life time"
              >
                <UICalendar
                  value={timeActive}
                  {...(timeActive[1] &&
                    new Date(timeActive[1]) < new Date() && {
                      status: 'error',
                    })}
                  onChange={(_, i) => setTimeActive(i)}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Total price"
                name="totalPrice"
                {...backgroundWrap}
              >
                <InputNumber
                  disabled={true}
                  type="number"
                  value={totalPrice}
                  placeholder="Total price"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Form.Item
            {...discountedWrap}
            name="addresses"
            label="Address discounted"
          >
            <PlacesAutocomplete
              className="ant-input"
              onPlaceSelected={(places) => handleAddCity(places)}
              apiKey="AIzaSyCaSLe663mSesOXEO5HXgF_tNE_osZNKl4"
              options={{ types: ['(cities)'] }}
            />
          </Form.Item>
          {addresses?.length ? (
            <Form.Item {...discountedWrap} label="Discount addresses">
              {addresses?.map((item, index) => (
                <Tag
                  closable
                  key={index}
                  onClose={(e) => {
                    e.preventDefault();
                    handleDeleteCity(JSON.stringify(item?.location));
                  }}
                >
                  {item.formattedAddress}
                </Tag>
              ))}
            </Form.Item>
          ) : null} */}
        </>
      )}
      <div className="user-edit__save-button">
        <UIButton
          key="Save changes"
          typeAntBtn="primary"
          handler={() => form.submit()}
          type={Button.ANT_BUTTON}
          size="large"
          className="edit-form__save-button"
        >
          Save changes
        </UIButton>
      </div>
    </Form>
  );
};

export default ProductEditForm;
