import cn from 'classnames';

import { UIAvatar } from '@ui';
import { DateHelper, FORMAT_TIME, Role } from '@utils';

import { Props } from './index.type';

const ChatDetailMessageItem = (props: Props) => {
  const { message, user, role } = props;

  return (
    <div
      className={cn('chat-block', { 'chat-block-expert': role === Role.USER })}
    >
      <div className="chat-message">
        <div className="chat-message-text">{message?.text}</div>
        <div className="chat-message-info">
          <div className="chat-message-info-time">
            {DateHelper.formateDateToString(message.createdAt, FORMAT_TIME)}
          </div>
          <div className="chat-message-info-name">
            {user?.profile?.fullName || ''}
          </div>
        </div>
      </div>
      <div className="chat-user">
        <UIAvatar path={user?.avatar?.path} />
      </div>
    </div>
  );
};

export default ChatDetailMessageItem;
