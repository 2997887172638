import { Col, Row } from 'antd';
import cn from 'classnames';

import { DateHelper, FORMAT_DATE_TIME_WITH_DOT } from '@utils';

import UIImage from '../Image';
import UIUserCard from '../UserCard';
import { Props } from './index.type';
import './style.scss';
import UIOverviewInfo from './Info';

const UIOverview = (props: Props) => {
  const {
    date,
    users,
    infoList,
    button,
    imagePath,
    imageTitle = 'Image',
    imageClass,
    title = 'Overview',
  } = props;

  const renderListHTML = () =>
    users?.map((user, i) => (
      <Col key={`user_${i}`} xxl={12} xl={24} lg={24}>
        <UIUserCard {...user} className="card" />
      </Col>
    ));

  const renderInfoListHTML = () =>
    infoList?.map((item, i) => (
      <UIOverviewInfo {...item} key={`user_info${i}`} />
    ));

  return (
    <div className="overview">
      {(title || button) && (
        <Row className="overview-title" justify="space-between" align="middle">
          <div className="overview-text">{title}</div>
          {button}
        </Row>
      )}
      {users && (
        <Row className="overview-box" gutter={16} justify="space-between">
          {renderListHTML()}
        </Row>
      )}
      {imagePath && (
        <Row
          className={cn(imageClass, 'overview-data')}
          justify="space-between"
        >
          <Col>{imageTitle}</Col>
          <Col className="overview-data-info">
            <UIImage path={imagePath || 'error'} />
          </Col>
        </Row>
      )}
      {date && (
        <UIOverviewInfo
          name="Date"
          value={DateHelper.formateDateToString(
            date,
            FORMAT_DATE_TIME_WITH_DOT,
          )}
        />
      )}
      {renderInfoListHTML()}
    </div>
  );
};

export default UIOverview;
