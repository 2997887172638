import { Button, Radio, RadioChangeEvent, Space } from 'antd';

import { Props } from './index.type';

const { Group } = Radio;

const WidgetModalFilterRadioRange = (props: Props) => {
  const { filterValue, options, onFilterUpdate } = props;

  const getCurrentValue =
    filterValue &&
    filterValue.max &&
    options?.findIndex(
      ({ value }) =>
        value.max === filterValue.max && value.min === filterValue.min,
    );

  const changeHandler = (e: RadioChangeEvent) => {
    if (options) {
      onFilterUpdate(options[+e.target.value].value);
    }
  };

  const clearHandler = () => {
    onFilterUpdate(null);
  };

  return (
    <Space direction="vertical">
      <Group onChange={changeHandler} value={getCurrentValue}>
        <Space direction="vertical">
          {options?.map((option, id) => (
            <Radio value={id} key={`radio_range_${id}`}>
              {option.label}
            </Radio>
          ))}
        </Space>
      </Group>
      <Button
        type="link"
        className="modal-filter__button-clear"
        onClick={clearHandler}
      >
        Clear
      </Button>
    </Space>
  );
};

export default WidgetModalFilterRadioRange;
