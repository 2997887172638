import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CLEAR_LIST_PROMO_CODE,
  CLEAR_PROMO_CODE,
  CREATE_PROMO_CODE_FAILURE,
  CREATE_PROMO_CODE_REQUEST,
  CREATE_PROMO_CODE_SUCCESS,
  DELETE_PROMO_CODE_SUCCESS,
  GET_LIST_PROMO_CODE_FAILURE,
  GET_LIST_PROMO_CODE_REQUEST,
  GET_LIST_PROMO_CODE_SUCCESS,
  GET_ONE_PROMO_CODE_REQUEST,
  GET_ONE_PROMO_CODE_SUCCESS,
  UPDATE_PROMO_CODE_FAILURE,
  UPDATE_PROMO_CODE_REQUEST,
  UPDATE_PROMO_CODE_SUCCESS,
} from './PromoCode.constant';
import { IPromoCodeState, PromoCodeActionsTypes } from './PromoCode.type';

const initialState: IPromoCodeState = {
  status: ActionStatus.NONE,
  promoCodeList: null,
  meta: META_DEFAULT,
  promoCode: null,
  loading: false,
};

export default function PromoCodeReducer(
  state = initialState,
  action: PromoCodeActionsTypes,
): IPromoCodeState {
  switch (action.type) {
    case GET_LIST_PROMO_CODE_REQUEST:
    case GET_ONE_PROMO_CODE_REQUEST:
      return {
        ...state,
        status: ActionStatus.NONE,
        loading: true,
      };

    case GET_LIST_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCodeList: [...(state.promoCodeList || []), ...action.payload.data],
        meta: action.payload.meta,
        loading: false,
      };
    case GET_LIST_PROMO_CODE_FAILURE:
    case UPDATE_PROMO_CODE_FAILURE:
    case CREATE_PROMO_CODE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_PROMO_CODE_REQUEST:
    case UPDATE_PROMO_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.CREATED,
        loading: false,
        promoCodeList: [action.payload, ...(state.promoCodeList || [])],
      };
    }
    case UPDATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        status: ActionStatus.UPDATED,
        loading: false,
        promoCode: action.payload,
        promoCodeList: (state.promoCodeList || []).map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      };
    case DELETE_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.DELETED,
        loading: false,
        promoCodeList: (state.promoCodeList || []).filter(
          (item) => item.id !== action.payload,
        ),
      };
    }
    case GET_ONE_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        promoCode: action.payload.data,
        loading: false,
        ...(action.payload.toList && {
          promoCodeList: [action.payload.data, ...(state.promoCodeList || [])],
        }),
      };
    }
    case CLEAR_PROMO_CODE: {
      return {
        ...state,
        status: ActionStatus.NONE,
        promoCode: null,
      };
    }
    case CLEAR_LIST_PROMO_CODE: {
      return {
        ...state,
        promoCodeList: null,
        meta: META_DEFAULT,
      };
    }
    default:
      return state;
  }
}
