import { ProcessStatus } from '@utils';

import { FormValues, FormValuesChange } from './index.type';

export const INITIAL_VALUES: FormValues | FormValuesChange = {
  products: [],
  addressId: null,
  userId: null,
  driverId: null,
  tips: 0,
  // paymentMethod: 'cash',
  shippingMethod: 'delivery',
  status: ProcessStatus.VERIFICATION_REQUIRED,
};
