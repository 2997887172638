import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import type { FullUser } from '@entities/index';
import { ProfileHttp } from '@services/http';

import { alertError, alertSuccess } from '../Alert';
import {
  getProfileFailure,
  getProfileSuccess,
  updateProfileFailure,
  updateProfileSuccess,
} from './Profile.action';
import {
  GET_PROFILE_REQUEST,
  UPDATE_PROFILE_REQUEST,
} from './Profile.constant';
import { IUpdateProfileRequest } from './Profile.type';

function* workerGetProfile() {
  try {
    const user: FullUser = yield call(ProfileHttp.getProfile);

    yield put(getProfileSuccess(user));
  } catch (error) {
    yield put(getProfileFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateProfile({ payload }: IUpdateProfileRequest) {
  try {
    const { body } = payload;
    const { user } = yield call(ProfileHttp.updateProfile, body);

    yield put(updateProfileSuccess(user as FullUser));
    yield put(alertSuccess('Profile updated successfully!'));
  } catch (error) {
    yield put(updateProfileFailure());
    yield put(alertError(error));
  }
}

function* watchGetProfile() {
  yield takeLatest(GET_PROFILE_REQUEST, workerGetProfile);
}

function* watchUpdateProfile() {
  yield takeLatest(UPDATE_PROFILE_REQUEST, workerUpdateProfile);
}

export const profileWatchers: ForkEffect[] = [
  fork(watchGetProfile),
  fork(watchUpdateProfile),
];
