import { FormPromoCode, FullPromoCode } from '@entities/PromoCode';

import {
  CLEAR_LIST_PROMO_CODE,
  CLEAR_PROMO_CODE,
  CREATE_PROMO_CODE_FAILURE,
  CREATE_PROMO_CODE_REQUEST,
  CREATE_PROMO_CODE_SUCCESS,
  DELETE_PROMO_CODE_FAILURE,
  DELETE_PROMO_CODE_REQUEST,
  DELETE_PROMO_CODE_SUCCESS,
  GET_LIST_PROMO_CODE_FAILURE,
  GET_LIST_PROMO_CODE_REQUEST,
  GET_LIST_PROMO_CODE_SUCCESS,
  GET_ONE_PROMO_CODE_FAILURE,
  GET_ONE_PROMO_CODE_REQUEST,
  GET_ONE_PROMO_CODE_SUCCESS,
  UPDATE_PROMO_CODE_FAILURE,
  UPDATE_PROMO_CODE_REQUEST,
  UPDATE_PROMO_CODE_SUCCESS,
} from './PromoCode.constant';
import { PromoCodeActionsTypes } from './PromoCode.type';

export function getListPromoCodeRequest(
  query?: Query['query'],
): PromoCodeActionsTypes {
  return {
    type: GET_LIST_PROMO_CODE_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListPromoCodeSuccess(
  payload: DataResponse<FullPromoCode[]>,
): PromoCodeActionsTypes {
  return {
    type: GET_LIST_PROMO_CODE_SUCCESS,
    payload,
  };
}

export function getListPromoCodeFailure(): PromoCodeActionsTypes {
  return {
    type: GET_LIST_PROMO_CODE_FAILURE,
  };
}

export function createPromoCodeRequest(
  payload: FormPromoCode,
): PromoCodeActionsTypes {
  return {
    type: CREATE_PROMO_CODE_REQUEST,
    payload,
  };
}

export function createPromoCodeSuccess(
  payload: FullPromoCode,
): PromoCodeActionsTypes {
  return {
    type: CREATE_PROMO_CODE_SUCCESS,
    payload,
  };
}

export function createPromoCodeFailure(): PromoCodeActionsTypes {
  return {
    type: CREATE_PROMO_CODE_FAILURE,
  };
}

export function deletePromoCodeRequest(
  id: FullPromoCode['id'],
): PromoCodeActionsTypes {
  return {
    type: DELETE_PROMO_CODE_REQUEST,
    payload: {
      id,
    },
  };
}

export function deletePromoCodeSuccess(
  payload: FullPromoCode['id'],
): PromoCodeActionsTypes {
  return {
    type: DELETE_PROMO_CODE_SUCCESS,
    payload,
  };
}

export function deletePromoCodeFailure(): PromoCodeActionsTypes {
  return {
    type: DELETE_PROMO_CODE_FAILURE,
  };
}

export function getOnePromoCodeRequest({
  id,
  toList,
}: {
  id: number;
  toList?: boolean;
}): PromoCodeActionsTypes {
  return {
    type: GET_ONE_PROMO_CODE_REQUEST,
    payload: {
      id,
      toList,
    },
  };
}

export function getOnePromoCodeSuccess({
  data,
  toList,
}: {
  data: FullPromoCode;
  toList?: boolean;
}): PromoCodeActionsTypes {
  return {
    type: GET_ONE_PROMO_CODE_SUCCESS,
    payload: {
      data,
      toList,
    },
  };
}

export function getOnePromoCodeFailure(): PromoCodeActionsTypes {
  return {
    type: GET_ONE_PROMO_CODE_FAILURE,
  };
}

export function updatePromoCodeRequest(
  payload: Pick<FullPromoCode, 'id'> & FormPromoCode,
): PromoCodeActionsTypes {
  return {
    type: UPDATE_PROMO_CODE_REQUEST,
    payload,
  };
}

export function updatePromoCodeSuccess(
  payload: FullPromoCode,
): PromoCodeActionsTypes {
  return {
    type: UPDATE_PROMO_CODE_SUCCESS,
    payload,
  };
}

export function updatePromoCodeFailure(): PromoCodeActionsTypes {
  return {
    type: UPDATE_PROMO_CODE_FAILURE,
  };
}

export function clearPromoCode(): PromoCodeActionsTypes {
  return {
    type: CLEAR_PROMO_CODE,
  };
}

export function clearListPromoCode(): PromoCodeActionsTypes {
  return {
    type: CLEAR_LIST_PROMO_CODE,
  };
}
