import { HIDE_MODAL, SHOW_MODAL } from './Modal.constant';

export enum ModalType {
  CONFIRM = 'confirm',
  CREATE_BRAND = 'createBrand',
  CREATE_CATEGORY = 'createCategory',
  CREATE_EFFECT = 'createEffect',
  CREATE_ORDER = 'createOrder',
  CREATE_PRODUCTS = 'createProduct',
  CREATE_PROMO_CODE = 'createPromoCode',
  CREATE_RESERVATION = 'createReservation',
  CREATE_SHOP = 'createShop',
  EDIT_QUANTITIES = 'editQuantities',
  EDIT_QUANTITY = 'editQuantity',
  EDIT_REVIEW_BRAND = 'editReviewBrand',
  EDIT_REVIEW_USER = 'editReviewUser',
  FILTER = 'filter',
  PRODUCT_TO_DRIVER = 'productToDriver',
  PROFILE = 'profile',
  REWARD = 'reward',
  SEARCH_ENGINE = 'searchEngine',
  TAXES = 'taxes',
  USER = 'user',
}
export interface IModalState {
  active: boolean;
  modalProps?: any;
  modalType: ModalType | null;
}

export type IShowModal = {
  payload: Pick<IModalState, 'modalType' | 'modalProps'>;
  type: typeof SHOW_MODAL;
};

export type IHideModal = {
  type: typeof HIDE_MODAL;
};

export type ModalActionsTypes = IShowModal | IHideModal;
