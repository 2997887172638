import { useEffect } from 'react';

import { Form, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '@store/index';
import {
  clearOrder,
  clearOrderBasket,
  createOrderRequest,
  getOneOrderProductRequest,
  getOneOrderRequest,
  updateOrderRequest,
} from '@store/Order';
import { clearListProduct } from '@store/Product';
import { clearUserListStore } from '@store/User';
import { UIModal } from '@ui';

import WidgetModalOrderForm from './Form';
import { FormValues, FormValuesChange } from './Form/index.type';
import { Props } from './index.type';
import './style.scss';

const WidgetModalOrder = (props: Props) => {
  const { orderId } = props;

  const { order, products, loading, basket } = useSelector(
    (state: RootState) => state.order,
  );
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  const isCreate = props.type === 'create';

  useEffect(() => {
    dispatch(clearListProduct());
    dispatch(clearUserListStore());

    if (orderId) {
      dispatch(getOneOrderRequest(+orderId));
      dispatch(getOneOrderProductRequest(+orderId));
    }

    return () => {
      dispatch(clearOrderBasket());
      dispatch(clearOrder());
    };
  }, [dispatch, orderId]);

  const onFormSubmitHandler = (values: FormValues | FormValuesChange) => {
    if (isCreate) {
      dispatch(clearOrder());
      dispatch(createOrderRequest(values as FormValues));
    } else {
      dispatch(updateOrderRequest(values as FormValuesChange));
    }
  };

  return (
    <UIModal
      className="edit-form"
      title={isCreate ? 'Add order' : 'Edit order'}
      visible
      children={
        <Spin spinning={loading}>
          <WidgetModalOrderForm
            order={orderId ? order : null}
            form={form}
            basket={basket}
            isCreate={isCreate}
            onFormSubmit={onFormSubmitHandler}
            products={products}
          />
        </Spin>
      }
    />
  );
};

export default WidgetModalOrder;
