import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { Statistic } from '@entities/Statistic';
import { StatisticHttp } from '@services/http';
import { alertError } from '@store/Alert';

import {
  getListStatisticFailure,
  getListStatisticSuccess,
} from './Statistic.action';
import { GET_LIST_STATISTIC_REQUEST } from './Statistic.constant';
import { IGetListStatisticRequest } from './Statistic.type';

function* workerGetListStatistic(action: IGetListStatisticRequest) {
  try {
    const { query } = action.payload;
    const data: DataResponse<Statistic> = yield call(
      StatisticHttp.getList,
      query,
    );

    yield put(getListStatisticSuccess(data));
  } catch (error) {
    yield put(getListStatisticFailure());
    yield put(alertError(error));
  }
}

function* watchGetListStatistic() {
  yield takeLatest(GET_LIST_STATISTIC_REQUEST, workerGetListStatistic);
}

export const statisticWatchers: ForkEffect[] = [fork(watchGetListStatistic)];
