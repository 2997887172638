import { createContext, useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';

import { useAuth } from '@hooks';
import { OrderSocket } from '@services/socket';
import { useAppDispatch } from '@store/index';
import { SOCKET_API_URL, SOCKET_ORDER_NAMESPACE, SOCKET_PATH } from '@utils';

import { OrderSocketType, Props } from './index.type';

export const OrderSocketContext = createContext<OrderSocketType>({
  orderSocket: null,
});

export const OrderSocketProvider = ({ children }: Props) => {
  const { isAuth } = useAuth();
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();

  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (isAuth && !socket) {
      const socketConnect = io(`${SOCKET_API_URL}/${SOCKET_ORDER_NAMESPACE}`, {
        path: SOCKET_PATH,
        withCredentials: true,
      });

      OrderSocket.initSubscribe({
        socket: socketConnect,
        dispatch,
        pathname,
        query: search,
      });
      setSocket(socketConnect);
    }

    if (!isAuth && socket) {
      socket.disconnect();
      setSocket(null);
    }
  }, [isAuth, socket, dispatch, pathname, search]);

  // useEffect(() => {
  //   if (isAuth && socket) {
  //     OrderSocket.initSubscribe({
  //       socket,
  //       dispatch,
  //       pathname,
  //       query: search,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname, search]);

  return (
    <OrderSocketContext.Provider value={{ orderSocket: socket }}>
      {children}
    </OrderSocketContext.Provider>
  );
};

export const useOrderSocket = (): OrderSocketType => {
  const ctx = useContext(OrderSocketContext);

  if (ctx === undefined) {
    throw new Error(
      'useOrderSocket can only be used inside OrderSocketContext',
    );
  }

  return ctx;
};
