import { META_DEFAULT } from '@utils';

import {
  CLEAR_LIST_CSV,
  CREATE_CSV_FAILURE,
  CREATE_CSV_REQUEST,
  CREATE_CSV_SUCCESS,
  DELETE_CSV_FAILURE,
  DELETE_CSV_REQUEST,
  DELETE_CSV_SUCCESS,
  EXPORT_CSV_FAILURE,
  EXPORT_CSV_REQUEST,
  EXPORT_CSV_SUCCESS,
  GET_LIST_CSV_FAILURE,
  GET_LIST_CSV_REQUEST,
  GET_LIST_CSV_SUCCESS,
  UPDATE_CSV_FAILURE,
  UPDATE_CSV_REQUEST,
  UPDATE_LIST,
} from './Csv.constant';
import { CsvActionsTypes, ICsvState } from './Csv.type';

const initialState: ICsvState = {
  meta: META_DEFAULT,
  loading: false,
  csvFile: null,
  loadingExport: false,
  metrcList: null,
  isUpdate: false,
};

export default function csvReducer(
  state = initialState,
  action: CsvActionsTypes,
): ICsvState {
  switch (action.type) {
    case GET_LIST_CSV_REQUEST:
    case CREATE_CSV_REQUEST:
    case DELETE_CSV_REQUEST:
    case UPDATE_CSV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LIST:
      return {
        ...state,
        isUpdate: action.payload,
      };
    case EXPORT_CSV_REQUEST:
      return {
        ...state,
        loadingExport: true,
        csvFile: null,
      };
    case EXPORT_CSV_FAILURE:
      return {
        ...state,
        loadingExport: false,
        // csvFile: null,
      };
    case EXPORT_CSV_SUCCESS:
      return {
        ...state,
        loadingExport: false,
        // csvFile: action.payload,
      };
    case GET_LIST_CSV_SUCCESS: {
      return {
        ...state,
        metrcList: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      };
    }
    case CLEAR_LIST_CSV:
      return {
        ...state,
        metrcList: null,
        meta: META_DEFAULT,
      };
    // case UPDATE_CSV_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     metrcList: (state.metrcList || []).map((item) => {
    //       if (item.id === action.payload.id) {
    //         return action.payload;
    //       }
    //       return item;
    //     }),
    //   };
    case DELETE_CSV_SUCCESS:
    case CREATE_CSV_SUCCESS:
    case CREATE_CSV_FAILURE:
    case GET_LIST_CSV_FAILURE:
    case DELETE_CSV_FAILURE:
    case UPDATE_CSV_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
