import { LayoutMenu } from '@layouts';
import { ModalType, showModal } from '@store/Modal';
import { deleteRewardRequest } from '@store/Setting';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const RewardUIActions = ({ id, reward, query, dispatch }: Props) => {
  const onEditClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.REWARD,
        modalProps: { rewardId: id, type: 'edit', query },
      }),
    );
  };

  const onDeleteRewardHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: (
            <p>
              Are you sure you want to delete the reward
              <b> {reward?.name || ''}</b>?
            </p>
          ),
          action: () => dispatch(deleteRewardRequest(id)),
        },
      }),
    );
  };

  // const onDeactivateRewardHandler = (e: React.MouseEvent<HTMLElement>) => {
  //   e.stopPropagation();

  //   dispatch(
  //     updateRewardRequest({
  //       id,
  //       body: { isActive: !reward.isActive },
  //     }),
  //   );
  // };

  return UICellAction({
    dropdownMenu: LayoutMenu({
      buttons: [
        {
          title: 'Delete the reward',
          onClick: (e) => onDeleteRewardHandler(e),
        },
        // {
        //   title: `${reward?.isActive ? 'Deactivate' : 'Activate'} the reward`,
        //   onClick: (e) => onDeactivateRewardHandler(e),
        // },
      ],
    }),
    buttons: [
      {
        title: 'Edit',
        onClick: (e: React.MouseEvent<HTMLElement>) => onEditClickHandler(e),
      },
    ],
  });
};

export default RewardUIActions;
