import { ColumnsType } from 'antd/lib/table';

import { OrderProducts } from '@entities/Product';
import { UICellFullName, UICellLink, UICellTooltip } from '@ui';
import {
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  COLUMN_WIDTH_PRISE,
  LINK_PRODUCT,
  StringHelper,
} from '@utils';

export const columns = (): ColumnsType<OrderProducts & Id> => {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) =>
        UICellLink({ value, link: `/${LINK_PRODUCT}/${record?.product?.id}` }),
      width: COLUMN_WIDTH_ID,
    },
    {
      title: 'Product name',
      width: COLUMN_WIDTH_NAME,
      render: (_, record) =>
        UICellFullName({
          firstName: record?.product?.name || 'not name',
          src: record?.product?.image?.path,
          link: `/${LINK_PRODUCT}/${record?.product?.id}`,
        }),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value) => +value,
      width: COLUMN_WIDTH_NUMBER,
    },
    {
      title: 'Price',
      ellipsis: {
        showTitle: false,
      },
      width: COLUMN_WIDTH_PRISE,
      render: (_, record) =>
        record?.product?.price
          ? StringHelper.toMoney(record?.product?.price, 2)
          : 'not price',
    },
    {
      title: 'Total',
      ellipsis: {
        showTitle: false,
      },
      width: COLUMN_WIDTH_PRISE,
      render: (_, record) =>
        UICellTooltip({
          text:
            (
              +(record?.product?.price || 0) * +(record?.quantity || 0)
            ).toString() || 'not price',
        }),
    },
  ];
};
