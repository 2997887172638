import { useEffect } from 'react';

import { Button, Form, Input, InputNumber, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { en } from '@i18n';

import { INITIAL_VALUES } from './constant';
import { FormReward, Props } from './index.type';

const RewardEditForm = ({ reward, onFormSubmit, form }: Props) => {
  useEffect(() => {
    if (reward) {
      form.setFieldsValue(reward);
    }
  }, [form, reward]);

  const pointQuantity = Form.useWatch('pointQuantity', form);
  const promoCode = Form.useWatch('promoCode', form);

  const handlerFinish = (values: FormReward) => {
    onFormSubmit(values);
  };

  return (
    <Form
      form={form}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      labelCol={{ xxl: 4, span: 5 }}
      wrapperCol={{ xxl: 17, span: 19 }}
      validateMessages={en.validation}
    >
      <Form.Item
        label="Name"
        name="name"
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item
        label="% Promo code"
        name="promoCode"
        required
        rules={[{ required: true, min: 1, max: 99, type: 'number' }]}
      >
        <InputNumber
          min={1}
          max={99}
          type="number"
          {...(promoCode &&
            (+promoCode < 1 || +promoCode > 99) && { status: 'error' })}
          placeholder="Promo code"
        />
      </Form.Item>
      <Form.Item
        label="Points quantity"
        name="pointQuantity"
        required
        rules={[{ required: true, min: 1, type: 'number' }]}
      >
        <InputNumber
          min={0}
          type="number"
          {...(pointQuantity && +pointQuantity < 1 && { status: 'error' })}
          placeholder="Points quantity"
        />
      </Form.Item>
      <Form.Item label="Visible" name="isActive" required>
        <Switch defaultChecked={reward?.isActive} />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <TextArea showCount rows={4} placeholder="Enter the description" />
      </Form.Item>
      <div className="reward-edit__save-button">
        <Button
          key="save_reward"
          type="primary"
          onClick={() => form.submit()}
          size="large"
          className="edit-form__save-button"
        >
          Save changes
        </Button>
      </div>
    </Form>
  );
};

export default RewardEditForm;
