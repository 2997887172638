import { ModalType, showModal } from '@store/Modal';
import {
  changeProductDriversRequest,
  deleteProductDriversRequest,
} from '@store/Product';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const DriverUIActionQuantity = ({
  productId,
  id,
  name,
  quantity,
  dispatch,
  text,
}: Props) => {
  const onEditClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.EDIT_QUANTITY,
        modalProps: {
          name,
          quantity,
          update: (newQuantity: number) =>
            dispatch(
              changeProductDriversRequest({
                body: [
                  {
                    driverId: id,
                    quantity: newQuantity,
                  },
                ],
                id: productId,
              }),
            ),
          handleDelete: () =>
            dispatch(
              deleteProductDriversRequest({
                driverId: id,
                id: productId,
              }),
            ),
        },
      }),
    );
  };

  return UICellAction({
    buttons: [{ title: `${text} (Edit)`, onClick: onEditClickHandler }],
  });
};

export default DriverUIActionQuantity;
