import {
  CreateReviewBrandRequest,
  CreateReviewUserRequest,
  FullReviewBrand,
  FullReviewUser,
} from '@entities/Review';
import { FullUser } from '@entities/User';

import {
  CLEAR_LIST_REVIEW,
  CLEAR_LIST_USER,
  CREATE_REVIEW_BRAND_FAILURE,
  CREATE_REVIEW_BRAND_REQUEST,
  CREATE_REVIEW_BRAND_SUCCESS,
  CREATE_REVIEW_USER_FAILURE,
  CREATE_REVIEW_USER_REQUEST,
  CREATE_REVIEW_USER_SUCCESS,
  DELETE_REVIEW_BRAND_FAILURE,
  DELETE_REVIEW_BRAND_REQUEST,
  DELETE_REVIEW_BRAND_SUCCESS,
  DELETE_REVIEW_USER_FAILURE,
  DELETE_REVIEW_USER_REQUEST,
  DELETE_REVIEW_USER_SUCCESS,
  GET_LIST_REVIEW_BRAND_FAILURE,
  GET_LIST_REVIEW_BRAND_REQUEST,
  GET_LIST_REVIEW_BRAND_SUCCESS,
  GET_LIST_REVIEW_USER_FAILURE,
  GET_LIST_REVIEW_USER_REQUEST,
  GET_LIST_REVIEW_USER_SUCCESS,
  GET_LIST_USER_FAILURE,
  GET_LIST_USER_REQUEST,
  GET_LIST_USER_SUCCESS,
  UPDATE_REVIEW_BRAND_FAILURE,
  UPDATE_REVIEW_BRAND_REQUEST,
  UPDATE_REVIEW_BRAND_SUCCESS,
  UPDATE_REVIEW_USER_FAILURE,
  UPDATE_REVIEW_USER_REQUEST,
  UPDATE_REVIEW_USER_SUCCESS,
} from './Review.constant';
import { ReviewActionsTypes } from './Review.type';

export function getListReviewBrandRequest(query = ''): ReviewActionsTypes {
  return {
    type: GET_LIST_REVIEW_BRAND_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListReviewBrandSuccess(
  payload: DataResponse<FullReviewBrand[]>,
): ReviewActionsTypes {
  return {
    type: GET_LIST_REVIEW_BRAND_SUCCESS,
    payload,
  };
}

export function getListReviewBrandFailure(): ReviewActionsTypes {
  return {
    type: GET_LIST_REVIEW_BRAND_FAILURE,
  };
}

export function getListReviewUserRequest(query = ''): ReviewActionsTypes {
  return {
    type: GET_LIST_REVIEW_USER_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListReviewUserSuccess(
  payload: DataResponse<FullReviewUser[]>,
): ReviewActionsTypes {
  return {
    type: GET_LIST_REVIEW_USER_SUCCESS,
    payload,
  };
}

export function getListReviewUserFailure(): ReviewActionsTypes {
  return {
    type: GET_LIST_REVIEW_USER_FAILURE,
  };
}

export function getListUserRequest(query = ''): ReviewActionsTypes {
  return {
    type: GET_LIST_USER_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListUserSuccess(
  payload: DataResponse<FullUser[]>,
): ReviewActionsTypes {
  return {
    type: GET_LIST_USER_SUCCESS,
    payload,
  };
}

export function getListUserFailure(): ReviewActionsTypes {
  return {
    type: GET_LIST_USER_FAILURE,
  };
}

export function createReviewBrandRequest(
  payload: CreateReviewBrandRequest,
): ReviewActionsTypes {
  return {
    type: CREATE_REVIEW_BRAND_REQUEST,
    payload,
  };
}

export function createReviewBrandSuccess(): ReviewActionsTypes {
  return {
    type: CREATE_REVIEW_BRAND_SUCCESS,
  };
}

export function createReviewBrandFailure(): ReviewActionsTypes {
  return {
    type: CREATE_REVIEW_BRAND_FAILURE,
  };
}

export function createReviewUserRequest(
  payload: CreateReviewUserRequest,
): ReviewActionsTypes {
  return {
    type: CREATE_REVIEW_USER_REQUEST,
    payload,
  };
}

export function createReviewUserSuccess(): ReviewActionsTypes {
  return {
    type: CREATE_REVIEW_USER_SUCCESS,
  };
}

export function createReviewUserFailure(): ReviewActionsTypes {
  return {
    type: CREATE_REVIEW_USER_FAILURE,
  };
}

export function deleteReviewBrandRequest(payload: Id): ReviewActionsTypes {
  return {
    type: DELETE_REVIEW_BRAND_REQUEST,
    payload,
  };
}

export function deleteReviewBrandSuccess(
  payload: FullReviewBrand['id'],
): ReviewActionsTypes {
  return {
    type: DELETE_REVIEW_BRAND_SUCCESS,
    payload,
  };
}

export function deleteReviewBrandFailure(): ReviewActionsTypes {
  return {
    type: DELETE_REVIEW_BRAND_FAILURE,
  };
}

export function deleteReviewUserRequest(payload: Id): ReviewActionsTypes {
  return {
    type: DELETE_REVIEW_USER_REQUEST,
    payload,
  };
}

export function deleteReviewUserSuccess(
  payload: FullReviewUser['id'],
): ReviewActionsTypes {
  return {
    type: DELETE_REVIEW_USER_SUCCESS,
    payload,
  };
}

export function deleteReviewUserFailure(): ReviewActionsTypes {
  return {
    type: DELETE_REVIEW_USER_FAILURE,
  };
}

export function updateReviewBrandRequest(
  payload: Pick<FullReviewBrand, 'id' | 'comment' | 'rating'>,
): ReviewActionsTypes {
  return {
    type: UPDATE_REVIEW_BRAND_REQUEST,
    payload,
  };
}

export function updateReviewBrandSuccess(
  payload: FullReviewBrand,
): ReviewActionsTypes {
  return {
    type: UPDATE_REVIEW_BRAND_SUCCESS,
    payload,
  };
}

export function updateReviewBrandFailure(): ReviewActionsTypes {
  return {
    type: UPDATE_REVIEW_BRAND_FAILURE,
  };
}

export function updateReviewUserRequest(
  payload: Pick<FullReviewUser, 'id' | 'comment' | 'rating'>,
): ReviewActionsTypes {
  return {
    type: UPDATE_REVIEW_USER_REQUEST,
    payload,
  };
}

export function updateReviewUserSuccess(
  payload: FullReviewUser,
): ReviewActionsTypes {
  return {
    type: UPDATE_REVIEW_USER_SUCCESS,
    payload,
  };
}

export function updateReviewUserFailure(): ReviewActionsTypes {
  return {
    type: UPDATE_REVIEW_USER_FAILURE,
  };
}
export function clearListUser(): ReviewActionsTypes {
  return {
    type: CLEAR_LIST_USER,
  };
}

export function clearListReview(): ReviewActionsTypes {
  return {
    type: CLEAR_LIST_REVIEW,
  };
}
