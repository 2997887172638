import { useEffect, useState } from 'react';

import { Form, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { FullMedia } from '@entities/Media';
import { updateBrandRequest } from '@store/Brand';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { FileName, UploadFile } from '@utils';

import BrandEditForm from './Form';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';
import './style.scss';

const BrandEdit = ({ brand, loadingBrand }: Props) => {
  const [logoFile, setLogoFile] = useState<FullMedia | null>(null);
  const [backgroundFile, setBackgroundFile] = useState<FullMedia | null>(null);
  const {
    loading: loadingImage,
    file: fileStore,
    name: nameFile,
  } = useSelector((state: RootState) => state.media);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (fileStore) {
      switch (nameFile) {
        case 'logo': {
          setLogoFile(fileStore);
          break;
        }
        case 'background': {
          setBackgroundFile(fileStore);
          break;
        }
        default:
      }
    }
  }, [dispatch, fileStore, nameFile]);

  const onFormSubmitHandler = (values: FormValues) => {
    dispatch(updateBrandRequest({ ...values, id: brand.id }));
  };

  useEffect(() => {
    if (brand?.logo) {
      setLogoFile(brand?.logo);
    }
    if (brand?.background) {
      setBackgroundFile(brand?.background);
    }
  }, [brand?.background, brand?.logo]);

  const onUploadImageHandler = (
    file: UploadFile['file'],
    name?: string | null,
  ) => {
    dispatch(
      uploadMediaRequest({
        file,
        fileName: FileName.IMAGE,
        ...(name && { name }),
      }),
    );
  };

  const onRemoveImageHandler = (name?: string) => {
    dispatch(clearStore());
    switch (name) {
      case 'background': {
        setBackgroundFile(null);
        break;
      }
      case 'logo': {
        setLogoFile(null);
        break;
      }
      default:
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, [dispatch]);

  return (
    <div className="brand-edit card-brands-info-brand">
      <Spin spinning={loadingBrand || loadingImage}>
        <BrandEditForm
          brand={brand}
          logo={logoFile}
          background={backgroundFile}
          form={form}
          onFormSubmit={onFormSubmitHandler}
          onUploadImage={onUploadImageHandler}
          onRemoveImage={onRemoveImageHandler}
        />
      </Spin>
    </div>
  );
};

export default BrandEdit;
