import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CLEAR_EFFECT,
  CLEAR_IMAGE_TO_EFFECT,
  CLEAR_LIST_EFFECT,
  CREATE_EFFECT_FAILURE,
  CREATE_EFFECT_REQUEST,
  CREATE_EFFECT_SUCCESS,
  DELETE_EFFECT_SUCCESS,
  GET_LIST_EFFECT_FAILURE,
  GET_LIST_EFFECT_REQUEST,
  GET_LIST_EFFECT_SUCCESS,
  GET_ONE_EFFECT_REQUEST,
  GET_ONE_EFFECT_SUCCESS,
  UPDATE_EFFECT_FAILURE,
  UPDATE_EFFECT_REQUEST,
  UPDATE_EFFECT_SUCCESS,
} from './Effect.constant';
import { EffectActionsTypes, IEffectState } from './Effect.type';

const initialState: IEffectState = {
  status: ActionStatus.NONE,
  effectList: null,
  meta: META_DEFAULT,
  effect: null,
  loading: false,
};

export default function EffectReducer(
  state = initialState,
  action: EffectActionsTypes,
): IEffectState {
  switch (action.type) {
    case GET_LIST_EFFECT_REQUEST:
    case GET_ONE_EFFECT_REQUEST:
      return {
        ...state,
        status: ActionStatus.NONE,
        loading: true,
      };

    case GET_LIST_EFFECT_SUCCESS:
      return {
        ...state,
        effectList: [...(state.effectList || []), ...action.payload.data],
        meta: action.payload.meta,
        loading: false,
      };
    case GET_LIST_EFFECT_FAILURE:
    case UPDATE_EFFECT_FAILURE:
    case CREATE_EFFECT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_EFFECT_REQUEST:
    case UPDATE_EFFECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_EFFECT_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.CREATED,
        loading: false,
        effectList: [action.payload, ...(state.effectList || [])],
      };
    }
    case UPDATE_EFFECT_SUCCESS:
      return {
        ...state,
        status: ActionStatus.UPDATED,
        loading: false,
        effect: action.payload,
        effectList: (state.effectList || []).map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      };
    case DELETE_EFFECT_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.DELETED,
        loading: false,
        effectList: (state.effectList || []).filter(
          (item) => item.id !== action.payload,
        ),
      };
    }
    case GET_ONE_EFFECT_SUCCESS: {
      return {
        ...state,
        effect: action.payload,
        loading: false,
      };
    }
    case CLEAR_EFFECT: {
      return {
        ...state,
        status: ActionStatus.NONE,
        effect: null,
      };
    }
    case CLEAR_LIST_EFFECT: {
      return {
        ...state,
        effectList: null,
        meta: META_DEFAULT,
      };
    }
    case CLEAR_IMAGE_TO_EFFECT: {
      return {
        ...state,
        ...(state.effect && {
          effect: { ...state.effect, image: null },
        }),
      };
    }
    default:
      return state;
  }
}
