import {
  ADD_NOTIFICATION,
  CLEAR_AVATAR_PROFILE,
  CLEAR_NOTIFICATION,
  CLEAR_ONE_NOTIFICATION,
  CLEAR_PROFILE,
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from './Profile.constant';
import { IProfileState, ProfileActionsTypes } from './Profile.type';

const initialState: IProfileState = {
  profile: null,
  loading: false,
  notifications: [],
};

export default function profileReducer(
  state = initialState,
  action: ProfileActionsTypes,
): IProfileState {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notifications: [],
      };
    case CLEAR_ONE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (item) =>
            item.id !== action.payload.id && item.type !== action.payload.type,
        ),
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case GET_PROFILE_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.user,
      };
    case GET_PROFILE_FAILURE:
    case CLEAR_PROFILE:
      return {
        ...state,
        loading: false,
        profile: null,
      };
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_AVATAR_PROFILE:
      return {
        ...state,
        ...(state.profile && {
          profile: { ...state.profile, avatar: null },
        }),
      };
    default:
      return state;
  }
}
