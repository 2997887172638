import { ILocation } from '@entities/Address';

import { Properties } from '../Review';
import { FullUser } from '../User';

export type SupportUser = Pick<
  FullUser,
  'id' | 'avatar' | 'car' | 'email' | 'profile' | 'role'
> &
  Properties &
  ILocation;

export type SupportMessage = {
  createdAt: string;
  createdById: number;
  isRead: boolean;
  text: string;
};

export type SupportOne = {
  createdAt: string;
  message: SupportMessage;
  user: SupportUser;
};

export type CreateMessageSupport = {
  createdById: number;
  supportId: number;
  text: string;
  user: SupportUser;
};

export type FullSupport = Id & SupportOne;

export type SupportSocket = {
  chats: FullSupport[];
};

export enum SupportEvent {
  MESSAGE_RECEIVE = 'support:message:receive',
  MESSAGE_SEND = 'support:message:send',
}
