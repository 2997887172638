import axios, { CancelTokenSource } from 'axios';

import { FullProduct } from '@entities/Product';
import { FullShop } from '@entities/Shop';
import { http } from '@utils';

const basePath = '/api/stores';
let axiosSource: CancelTokenSource | null = null;

export const getList = async (
  query?: Query['query'],
  isCancel = true,
): Promise<{
  data: FullShop[];
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<FullShop[]>>(
    `${basePath}${query || ''}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};

export const getListProduct = async (
  id: number,
  query: Query['query'],
  isCancel = true,
): Promise<{
  data: {
    products: FullProduct[];
    store: FullShop;
  };
  meta: MetaType;
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<
    DataResponse<{
      products: FullProduct[];
      store: FullShop;
    }>
  >(`${basePath}/${id}/products${query}`, {
    ...(axiosSource && isCancel && { cancelToken: axiosSource.token }),
  });

  return data;
};

export const create = async (body: Pick<FullShop, 'name' | 'description'>) => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullShop>>(`${basePath}`, body);

  return data;
};

export const remove = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const getOne = async (id: FullShop['id']): Promise<FullShop> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullShop>>(`${basePath}/${id}`);

  return data;
};

export const update = async (
  id: number,
  body: Partial<FullShop>,
): Promise<FullShop> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullShop>>(`${basePath}/${id}`, body);

  return data;
};
