import { ColumnsType } from 'antd/lib/table';

import { FilterOption } from '@entities/Filter';
import { SortOption } from '@entities/Sort';
import { FullTransaction } from '@entities/Transaction';
import { FullUser } from '@entities/User';
import { UICellFullName, UITag } from '@ui';
import {
  COLUMN_WIDTH_FULL_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  COLUMN_WIDTH_TAG,
  DateHelper,
  FORMAT_DATE,
  FORMAT_DATE_TIME_WITH_DOT,
  LINK_USERS,
  StringHelper,
  TransactionClass,
  TransactionOperation,
  TransactionStatus,
  TransactionType,
  processClassOptions,
  processOperationOptions,
  processStatusOptions,
  processTypeOptions,
} from '@utils';

const renderUserColumn = (isUser?: boolean): ColumnsType<FullTransaction> => {
  if (isUser) {
    return [
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        width: COLUMN_WIDTH_NAME,
        render: (value: FullUser) =>
          UICellFullName({
            lastName: value?.profile?.lastName,
            firstName: value?.profile?.firstName,
            src: value?.avatar?.path,
            link: `/${LINK_USERS}/${value.id}`,
          }),
      },
    ];
  }

  return [];
};

export const columns = ({
  isUser,
}: {
  isUser?: boolean;
}): ColumnsType<FullTransaction> => {
  const columnList = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'number',
      width: COLUMN_WIDTH_ID,
    },
    ...renderUserColumn(isUser),
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: COLUMN_WIDTH_FULL_DATE,
      render: (value: string) =>
        DateHelper.formateDateToString(value, FORMAT_DATE_TIME_WITH_DOT),
    },
    {
      title: 'Class',
      dataIndex: 'txnClass',
      key: 'txnClass',
      width: COLUMN_WIDTH_TAG,
      render: (value: TransactionClass) =>
        UITag({
          value,
          values: processClassOptions,
        }),
    },
    {
      title: 'Type',
      dataIndex: 'txnType',
      key: 'type',
      width: COLUMN_WIDTH_TAG,
      render: (value: TransactionType) =>
        UITag({
          value,
          values: processTypeOptions,
        }),
    },
    {
      title: 'Operation',
      dataIndex: 'txnOperation',
      key: 'txnOperation',
      width: COLUMN_WIDTH_TAG,
      render: (value: TransactionOperation) =>
        UITag({
          value,
          values: processOperationOptions,
        }),
    },
    {
      title: 'Status',
      dataIndex: 'txnStatus',
      key: 'txnStatus',
      width: COLUMN_WIDTH_TAG,
      render: (value: TransactionStatus) =>
        UITag({
          value,
          values: processStatusOptions,
        }),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: COLUMN_WIDTH_NUMBER,
      render: (value: number, { txnType }: FullTransaction) => {
        const price = StringHelper.toMoney(value);

        return txnType === TransactionType.OUTCOME ? `-${price}` : price;
      },
    },
  ];

  return columnList;
};

const renderUserSort = (isUser?: boolean): SortOption[] => {
  if (isUser) {
    return [
      {
        label: 'User name (A-Z)',
        name: 'user_name',
        direction: 'asc',
      },
      {
        label: 'User name (Z-A)',
        name: 'user_name',
        direction: 'desc',
      },
    ];
  }

  return [];
};

export const sortOption = ({ isUser }: { isUser?: boolean }): SortOption[] => [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  ...renderUserSort(isUser),
  {
    label: 'Date (oldest first)',
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: 'Date (newest first)',
    name: 'created_at',
    direction: 'desc',
  },
  {
    label: 'Amount (ascending)',
    name: 'amount',
    direction: 'asc',
  },
  {
    label: 'Amount (descending)',
    name: 'amount',
    direction: 'desc',
  },
];

const today = new Date();

export const filterOptions: FilterOption[] = [
  {
    name: 'created_at',
    label: 'Date',
    type: 'radio-range',
    options: [
      {
        label: 'Today',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 7 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -7,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 30 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -30,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    ],
  },
  // {
  //   name: 'txnClass',
  //   label: 'Class',
  //   type: 'checkbox',
  //   options: [
  //     {
  //       label: StringHelper.capitalize(TransactionClass.EXTERNAL),
  //       value: TransactionClass.EXTERNAL,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionClass.INTERNAL),
  //       value: TransactionClass.INTERNAL,
  //     },
  //   ],
  // },
  // {
  //   name: 'txnType',
  //   label: 'Type',
  //   type: 'checkbox',
  //   options: [
  //     {
  //       label: StringHelper.capitalize(TransactionType.INCOME),
  //       value: TransactionType.INCOME,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionType.OUTCOME),
  //       value: TransactionType.OUTCOME,
  //     },
  //   ],
  // },
  // {
  //   name: 'txnOperation',
  //   label: 'Operation',
  //   type: 'checkbox',
  //   options: [
  //     {
  //       label: StringHelper.capitalize(TransactionOperation.EARNING),
  //       value: TransactionOperation.EARNING,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionOperation.PAYMENT),
  //       value: TransactionOperation.PAYMENT,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionOperation.SPENDING),
  //       value: TransactionOperation.SPENDING,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionOperation.WITHDRAWAL),
  //       value: TransactionOperation.WITHDRAWAL,
  //     },
  //   ],
  // },
  // {
  //   name: 'txnStatus',
  //   label: 'Status',
  //   type: 'checkbox',
  //   options: [
  //     {
  //       label: StringHelper.capitalize(TransactionStatus.APPROVED),
  //       value: TransactionStatus.APPROVED,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionStatus.AWAITING_PICKUP),
  //       value: TransactionStatus.AWAITING_PICKUP,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionStatus.CANCELED),
  //       value: TransactionStatus.CANCELED,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionStatus.COMPLETED),
  //       value: TransactionStatus.COMPLETED,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionStatus.DELIVERED),
  //       value: TransactionStatus.DELIVERED,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionStatus.PENDING_DRIVER),
  //       value: TransactionStatus.PENDING_DRIVER,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionStatus.SHIPPED),
  //       value: TransactionStatus.SHIPPED,
  //     },
  //     {
  //       label: StringHelper.capitalize(TransactionStatus.VERIFICATION_REQUIRED),
  //       value: TransactionStatus.VERIFICATION_REQUIRED,
  //     },
  //   ],
  // },
  {
    name: 'amount',
    label: 'Amount',
    type: 'number-range',
    step: 1,
    min: 0,
  },
];
