import { useEffect } from 'react';

import { Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { FullUser } from '@entities/User';
import { useQuery } from '@hooks';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import { clearUserListStore, getListUserRequest } from '@store/User';
import { UIHeader, UITable } from '@ui';
import { FilterTab, LIMIT_DEFAULT, QueryHelper, Role } from '@utils';

import options from './constants';

const User = () => {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const { query, updateQuery } = useQuery();
  const { userList, meta, loading } = useSelector(
    (state: RootState) => state.user,
  );
  const { profile } = useSelector((state: RootState) => state.profile);
  // const role = CookieStorage.parseToken()?.role;
  const navigate = useNavigate();

  const { tab, title, sortOption, headerButtons, columns, filterOptions } =
    options({
      dispatch,
      pathname,
      query,
      role: profile?.role as Role,
    });

  useEffect(() => {
    if (search === '') {
      updateQuery({ page: 1, limit: LIMIT_DEFAULT });
    } else {
      dispatch(clearUserListStore());
      dispatch(getListUserRequest(query));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, dispatch]);

  useEffect(() => {
    switch (QueryHelper.parse(query)?.filter?.role) {
      case Role.DRIVERS: {
        LocalStorage.set('tab', 'Driver');
        break;
      }
      case Role.USER: {
        LocalStorage.set('tab', 'Customer');
        break;
      }
      case Role.ADMIN: {
        LocalStorage.set('tab', 'Admin');
        break;
      }
      default: {
        LocalStorage.set('tab', 'All');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearUserListStore());
    };
  }, [dispatch]);

  useEffect(() => {
    LocalStorage.set('isBack', pathname + query);
  }, [pathname, query]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  return (
    <>
      <UIHeader
        title={title}
        isSearch
        buttons={headerButtons}
        sortOption={sortOption}
        filterOptions={filterOptions}
        searchTitle="Search user"
        tab={tab as FilterTab[]}
      />
      <Row>
        <UITable
          dataSource={userList || []}
          columns={columns}
          loading={loading}
          meta={meta}
          onRow={(item: FullUser) => navigate(`${pathname}/${item.id}`)}
          onPaginationChange={onPaginationChange}
          // isScrollDefault
        />
      </Row>
    </>
  );
};

export default User;
