/* eslint-disable indent */
import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CLEAR_CHAT,
  CLEAR_MESSAGE,
  GET_LIST_CHAT_FAILURE,
  GET_LIST_CHAT_REQUEST,
  GET_LIST_CHAT_SUCCESS,
  GET_LIST_MESSAGE_FAILURE,
  GET_LIST_MESSAGE_REQUEST,
  GET_LIST_MESSAGE_SUCCESS,
  GET_ONE_CHAT_FAILURE,
  GET_ONE_CHAT_REQUEST,
  GET_ONE_CHAT_SUCCESS,
  SET_DIALOG_ID,
  SET_MESSAGE_RECEIVE,
} from './Support.constant';
import { ISupportState, SupportActionsTypes } from './Support.type';

const initialState: ISupportState = {
  status: ActionStatus.NONE,
  chatList: null,
  messageList: null,
  chat: null,
  meta: META_DEFAULT,
  loaded: false,
  loading: false,
  supportId: null,
  notification: 0,
  dialogId: null,
};

export default function SupportSupportReducer(
  state = initialState,
  action: SupportActionsTypes,
): ISupportState {
  switch (action.type) {
    case SET_MESSAGE_RECEIVE: {
      const { chat } = action.payload;
      const { messageList, chatList, meta, dialogId } = state;
      const chatNev = !chatList?.map((i) => i.id)?.includes(chat.id)
        ? chat
        : null;

      return {
        ...state,
        notification:
          state.notification +
          (dialogId !== chat.id && chat.user.id === chat.message.createdById
            ? 1
            : 0),
        ...(chatList &&
          chatNev && {
            chatList: [
              chatNev,
              ...(state.chatList || []).filter(
                (item, index, arr) =>
                  arr.length !== meta.limit || index !== arr.length - 1,
              ),
            ],
          }),
        ...(messageList &&
          dialogId === chat.id && {
            messageList: [
              ...(state.messageList || []).filter(
                (item, index, arr) => arr.length !== meta.limit || index !== 0,
              ),
              chat.message,
            ],
          }),
        status: ActionStatus.UPDATED,
      };
    }
    case GET_LIST_CHAT_REQUEST:
    case GET_LIST_MESSAGE_REQUEST:
    case GET_ONE_CHAT_REQUEST:
      return {
        ...state,
        loading: true,
        notification: 0,
      };
    case SET_DIALOG_ID:
      return {
        ...state,
        dialogId: action.payload,
      };
    case GET_LIST_MESSAGE_SUCCESS: {
      return {
        ...state,
        messageList: [
          ...action.payload.data.reverse(),
          ...(state.messageList || []),
        ],
        meta: action.payload.meta,
        loaded: true,
        loading: false,
        status: ActionStatus.LOADED,
      };
    }
    case GET_LIST_CHAT_SUCCESS: {
      return {
        ...state,
        chatList: action.payload.data,
        meta: action.payload.meta,
        loaded: true,
        loading: false,
      };
    }
    case GET_ONE_CHAT_SUCCESS: {
      return {
        ...state,
        chat: action.payload,
        supportId: action.payload.id,
        loading: false,
      };
    }
    case GET_ONE_CHAT_FAILURE:
    case GET_LIST_CHAT_FAILURE:
    case GET_LIST_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        status: ActionStatus.NONE,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        messageList: null,
        chat: null,
        dialogId: null,
        status: ActionStatus.NONE,
      };
    case CLEAR_CHAT:
      return initialState;
    default:
      return state;
  }
}
