import { Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { DiscountUnit } from '@utils';

import { Props } from './index.type';
import './style.scss';

const ReferralDetailDescription = ({ loading, referral }: Props) => {
  return (
    <Spin size="large" spinning={loading}>
      <div className="card-brands-info-brand">
        <div className="card-brands-info-brand-content">
          <Title level={5}>For invitee</Title>
          <Text>
            {referral?.discountValueForInvited}{' '}
            {referral?.discountUnitForInvited === DiscountUnit.PERCENT
              ? '%'
              : '$'}
          </Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>For invitee</Title>
          <Text>
            {referral?.discountValueForInviter}{' '}
            {referral?.discountUnitForInviter === DiscountUnit.PERCENT
              ? '%'
              : '$'}
          </Text>
        </div>
      </div>
    </Spin>
  );
};

export default ReferralDetailDescription;
