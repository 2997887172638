import { Route, Routes } from 'react-router-dom';

import { SupportDetail, SupportList } from '@features/Supporp';

const ViewSupport = () => {
  return (
    <Routes>
      <Route path=":supportId" element={<SupportDetail />} />
      <Route path="/" element={<SupportList />} />
    </Routes>
  );
};

export default ViewSupport;
