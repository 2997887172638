import axios, { CancelTokenSource } from 'axios';

import { FullOrder } from '@entities/Order';
import { OrderProducts } from '@entities/Product';
import { http } from '@utils';

const basePath = '/api/orders';
let axiosSource: CancelTokenSource | null = null;

export const getList = async (
  query: string,
  isCancel = true,
): Promise<DataResponse<FullOrder[]>> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<FullOrder[]>>(
    `${basePath}${query}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};

export const getOne = async (
  id: number,
): Promise<DataResponse<Omit<FullOrder, 'products'>>> => {
  const { data } = await http.get<DataResponse<Omit<FullOrder, 'products'>>>(
    `${basePath}/${id}`,
  );

  return data;
};

export const getOneProduct = async (
  id: number,
): Promise<DataResponse<OrderProducts[]>> => {
  const { data } = await http.get<DataResponse<OrderProducts[]>>(
    `${basePath}/${id}/products`,
  );

  return data;
};

// export const getPayment = async (
//   id: number,
// ): Promise<DataResponse<OrderPayment>> => {
//   const { data } = await http.get<DataResponse<OrderPayment>>(
//     `${basePath}/${id}/payment`,
//   );

//   return data;
// };

export const update = async (
  id: number,
  data: Pick<FullOrder, 'status'> & {
    driverId?: number | null;
    shippingMethod?: string;
  },
): Promise<void> => {
  await http.put(`${basePath}/${id}`, data);
};

export const create = async (
  payload: Pick<FullOrder, 'shippingMethod' | 'tips'> & {
    addressId?: number | null;
    driverId?: number | null;
    products: { id: number; quantity: number }[];
    userId: number | null;
  },
) => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullOrder>>(`${basePath}/`, payload);

  return data;
};

export const successPayment = async (payload: {
  paymentToken: string;
  paymentTransaction: string;
}) => {
  await http.post(`${basePath}/payment`, payload);
};

export const basket = async (
  payload:
    | (Pick<FullOrder, 'shippingMethod'> & {
        addressId?: number | null;
        driverId?: number | null;
        products: { id: number; quantity: number }[];
      })
    | (Pick<FullOrder, 'status'> & {
        driverId?: number | null;
        id: number;
        shippingMethod?: string;
      }),
) => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullOrder>>(`${basePath}/basket`, payload);

  return data;
};
