import { META_DEFAULT } from '@utils';

import {
  CLEAR_LIST_TRANSACTION,
  GET_LIST_TRANSACTION_FAILURE,
  GET_LIST_TRANSACTION_REQUEST,
  GET_LIST_TRANSACTION_SUCCESS,
} from './Transaction.constant';
import { ITransactionState, TransactionActionsTypes } from './Transaction.type';

const initialState: ITransactionState = {
  transactionList: [],
  meta: META_DEFAULT,
  loading: false,
};

export default function transactionReducer(
  state = initialState,
  action: TransactionActionsTypes,
): ITransactionState {
  switch (action.type) {
    case GET_LIST_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_TRANSACTION_SUCCESS: {
      return {
        ...state,
        transactionList: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      };
    }
    case GET_LIST_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_LIST_TRANSACTION:
      return {
        ...state,
        transactionList: [],
        meta: META_DEFAULT,
      };
    default:
      return state;
  }
}
