import { useEffect, useState } from 'react';

import Title from 'antd/lib/typography/Title';

import { useEndLessScroll } from '@hooks';
import { clearMessage, getListMessageRequest } from '@store/Chat';
import { useAppDispatch } from '@store/index';
import { LIMIT_DEFAULT, Role } from '@utils';

import { ChatUiSeparator } from '../../../ui';
import ChatDetailMessageItem from '../Item';
import { Props } from './index.type';

const ChatDetailMessageList = ({ messages, chat, meta }: Props) => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    if (chat && page > 0) {
      dispatch(
        getListMessageRequest({
          id: chat.id,
          query: `?limit=${meta?.limit || LIMIT_DEFAULT}&page=${page}`,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, chat]);

  const [onScroll, containerRef] = useEndLessScroll({
    onIntersection: () => {
      if (meta.currentPage < meta.totalPages) {
        setPage(page + 1);
      }
    },
  });

  const renderList = () =>
    messages.length ? (
      messages.map((message, key) => {
        const roleMassage =
          chat && chat.user.id === message.createdById
            ? Role.USER
            : Role.DRIVERS;

        return (
          <div key={`message_${key}`}>
            <ChatUiSeparator
              step={key}
              currentDate={message.createdAt}
              prevDate={messages[key - 1]?.createdAt || message.createdAt}
            />
            <ChatDetailMessageItem
              user={
                roleMassage && roleMassage === Role.USER
                  ? chat?.user || null
                  : chat?.driver || null
              }
              role={roleMassage}
              message={message}
            />
          </div>
        );
      })
    ) : (
      <Title level={5}>Еhere are no messages yet</Title>
    );

  return (
    <div className="message-list chat-detail">
      <div
        ref={containerRef}
        className="massage-items"
        onScroll={onScroll as any}
      >
        {renderList()}
      </div>
    </div>
  );
};

export default ChatDetailMessageList;
