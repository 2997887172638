import { useEffect } from 'react';

import { Form, Input, InputNumber, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { en } from '@i18n';

import { INITIAL_VALUES } from './constant';
import { FormReward, Props } from './index.type';

const WidgetModalUserForm = ({
  reward,
  onFormSubmit,
  isCreate,
  form,
}: Props) => {
  useEffect(() => {
    if (reward) {
      form.setFieldsValue(reward);
    }
  }, [form, reward]);

  const pointQuantity = Form.useWatch('pointQuantity', form);
  const promoCode = Form.useWatch('promoCode', form);

  const handlerFinish = (values: FormReward) => {
    onFormSubmit(values);
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 18 }}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      className="modal-create"
      autoComplete="off"
      validateMessages={en.validation}
    >
      <Form.Item
        label="Name"
        name="name"
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item
        label="% Promo code"
        name="promoCode"
        required
        rules={[{ required: true, min: 1, max: 99, type: 'number' }]}
      >
        <InputNumber
          min={1}
          max={99}
          type="number"
          {...(promoCode &&
            (+promoCode < 1 || +promoCode > 99) && { status: 'error' })}
          placeholder="Promo code"
        />
      </Form.Item>
      <Form.Item
        label="Points quantity"
        name="pointQuantity"
        required
        rules={[{ required: true, min: 1, type: 'number' }]}
      >
        <InputNumber
          min={0}
          type="number"
          {...(pointQuantity && +pointQuantity < 1 && { status: 'error' })}
          placeholder="Points quantity"
        />
      </Form.Item>
      {reward || isCreate ? (
        <Form.Item label="Visible" name="isActive" required>
          <Switch defaultChecked={reward?.isActive} />
        </Form.Item>
      ) : null}
      <Form.Item label="Description" name="description">
        <TextArea showCount rows={4} placeholder="Enter the description" />
      </Form.Item>
    </Form>
  );
};

export default WidgetModalUserForm;
