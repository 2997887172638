import qs from 'qs';

import { QueryOptions, QueryToOptions } from '../utility-types';

type QsStringifyOption = {
  addQueryPrefix: boolean;
  encode: boolean;
};

type QsParseOption = {
  ignoreQueryPrefix: boolean;
};

export default (() => {
  const stringify = (
    { limit, page, filter, order, list, search }: QueryOptions,
    opt?: Partial<QsStringifyOption>,
  ) =>
    qs.stringify(
      {
        ...(order && { order }),
        ...(filter && { filter }),
        ...(limit && { limit }),
        ...(page && { page }),
        ...(list && { list }),
        ...(search && { search }),
      },
      {
        encode: false,
        skipNulls: true,
        addQueryPrefix: true,
        ...opt,
      },
    );

  const parse: QueryToOptions = (query: string, opt?: Partial<QsParseOption>) =>
    qs.parse(query, { ignoreQueryPrefix: true, ...opt });

  return {
    stringify,
    parse,
  };
})();
