/* eslint-disable indent */
import { ColumnsType } from 'antd/lib/table';

import { FilterOption } from '@entities/Filter';
import { SortOption } from '@entities/Sort';
import { FullUser } from '@entities/User';
import { LocalStorage } from '@services/storage';
import type { AppDispatch } from '@store/index';
import { UICellFullName, UICellLink, UICellStatus, UICellTooltip } from '@ui';
import {
  ActiveStatus,
  COLUMN_WIDTH_ACTIONS_SMALL,
  COLUMN_WIDTH_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  COLUMN_WIDTH_ONLINE,
  COLUMN_WIDTH_ROLE,
  DateHelper,
  FORMAT_DATE,
  FORMAT_SHORT_DATE,
  LIMIT_DEFAULT,
  Role,
  StringHelper,
} from '@utils';

import { UserUIActions, UserUIButton } from './ui';

const renderColumn = () => {
  const activeTab = LocalStorage.get('tab');

  if (activeTab && activeTab === 'All') {
    return [
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: COLUMN_WIDTH_ROLE,
      },
    ];
  }
  if (activeTab && (activeTab === 'Driver' || activeTab === 'Online driver')) {
    return [
      {
        title: 'Verify by Admin',
        dataIndex: 'isVerified',
        key: 'isVerified',
        width: 90,
        render: (value: boolean) =>
          UICellStatus({
            value,
            text: value ? 'Verified' : 'Unverified',
          }),
      },
    ];
  }
  // if (activeTab && activeTab !== 'Driver') {
  //   return [
  //     {
  //       title: 'Shop',
  //       dataIndex: 'store',
  //       key: 'store',
  //       ellipsis: true,
  //       width: COLUMN_WIDTH_ACTIONS,
  //       render: (value: { id: number; name: string }) =>
  //         UICellLink({
  //           value: value?.name || '',
  //           link: `/${LINK_SHOP}/${value?.id}`,
  //         }),
  //     },
  //   ];
  // }

  return [];
};

const renderVerifyByBerbix = () => {
  const activeTab = LocalStorage.get('tab');

  if (
    activeTab &&
    (activeTab === 'Driver' ||
      activeTab === 'Customer' ||
      activeTab === 'Online driver')
  ) {
    return [
      {
        title: 'Verify by Berbix',
        dataIndex: 'ageCheckStatus',
        key: 'ageCheckStatus',
        width: 90,
        render: (value: string) =>
          UICellStatus({
            value: value === 'success',
            text: value === 'success' ? 'Yes' : 'No',
          }),
      },
    ];
  }

  return [];
};

const renderOnline = () => {
  const activeTab = LocalStorage.get('tab');

  if (activeTab && activeTab !== 'Online driver') {
    return [
      {
        title: 'Online',
        dataIndex: 'isOnline',
        key: 'isOnline',
        width: COLUMN_WIDTH_ONLINE,
        render: (value: boolean) =>
          UICellStatus({
            value,
            text: value ? 'Yes' : 'No',
          }),
      },
    ];
  }

  return [];
};

const columns = ({
  pathname,
  query,
  dispatch,
}: {
  dispatch: AppDispatch;
  pathname: string;
  query: string;
}): ColumnsType<FullUser> => {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) =>
        UICellLink({ value, link: `${pathname}/${record.id}` }),
      width: COLUMN_WIDTH_ID,
    },
    {
      title: 'Full Name',
      dataIndex: ['profile', 'fullName'],
      key: 'fullName',
      width: COLUMN_WIDTH_NAME,
      render: (value, { profile, id, avatar }) =>
        UICellFullName({
          lastName: profile?.lastName,
          firstName: profile?.firstName,
          src: avatar?.path,
          link: `${pathname}/${id}`,
        }),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ellipsis: {
        showTitle: false,
      },
      width: COLUMN_WIDTH_NAME,
      render: (_, record) =>
        UICellTooltip({
          text:
            record.addresses?.find((i) => i.isDefault)?.formattedAddress ||
            'not specified',
        }),
    },
    ...renderColumn(),
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: COLUMN_WIDTH_NUMBER,
    },
    ...renderVerifyByBerbix(),
    ...renderOnline(),
    {
      title: 'Reg Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: COLUMN_WIDTH_DATE,
      render: (value: string) =>
        DateHelper.formateDateToString(value, FORMAT_SHORT_DATE),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      width: COLUMN_WIDTH_ACTIONS_SMALL,
      render: (id, user) =>
        UserUIActions({
          id,
          user,
          query,
          dispatch,
        }),
    },
  ];
};

const today = new Date();

const tab = (role: Role) => [
  {
    label: 'All',
    options: {
      page: 1,
      limit: LIMIT_DEFAULT,
    },
  },
  { label: 'Customer', options: { filter: { role: Role.USER } } },
  { label: 'Driver', options: { filter: { role: Role.DRIVERS } } },
  ...(role === Role.ADMIN
    ? [{ label: 'Admin', options: { filter: { role: Role.ADMIN } } }]
    : []),
  { label: 'Manager', options: { filter: { role: Role.MANAGER } } },
  {
    label: 'Unverified',
    options: { filter: { role: Role.DRIVERS, isVerified: false } },
  },
  {
    label: 'Online driver',
    options: { filter: { role: Role.DRIVERS, is_online: true } },
  },
];

export const sortOption: SortOption[] = [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  {
    label: 'Full Name (A-Z)',
    name: 'full_name',
    direction: 'asc',
  },
  {
    label: 'Full Name (Z-A)',
    name: 'full_name',
    direction: 'desc',
  },
  {
    label: 'Reg Date (oldest first)',
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: 'Reg Date (newest first)',
    name: 'created_at',
    direction: 'desc',
  },
];

const filterOptions = (role: Role) => [
  {
    name: 'role',
    label: 'Role',
    type: 'radio',
    options: [
      {
        label: 'Customer',
        value: Role.USER,
      },
      {
        label: 'Driver',
        value: Role.DRIVERS,
      },
      {
        label: 'Manager',
        value: Role.MANAGER,
      },
      ...(role === Role.ADMIN
        ? [
            {
              label: 'Admin',
              value: Role.ADMIN,
            },
          ]
        : []),
    ],
  },
  {
    name: 'is_online',
    label: 'Is online',
    type: 'radio',
    options: [
      {
        label: StringHelper.capitalize(ActiveStatus.YES),
        value: 'true',
      },
      {
        label: StringHelper.capitalize(ActiveStatus.NO),
        value: 'false',
      },
    ],
  },
  {
    name: 'is_free',
    label: 'Is free',
    type: 'radio',
    options: [
      {
        label: StringHelper.capitalize(ActiveStatus.YES),
        value: 'true',
      },
      {
        label: StringHelper.capitalize(ActiveStatus.NO),
        value: 'false',
      },
    ],
  },
  {
    name: 'isConfirmed21Age',
    label: 'Is confirmed age',
    type: 'radio',
    options: [
      {
        label: StringHelper.capitalize(ActiveStatus.YES),
        value: 'true',
      },
      {
        label: StringHelper.capitalize(ActiveStatus.NO),
        value: 'false',
      },
    ],
  },
  {
    name: 'isVerified',
    label: 'Verified',
    type: 'radio',
    options: [
      {
        label: StringHelper.capitalize(ActiveStatus.YES),
        value: 'true',
      },
      {
        label: StringHelper.capitalize(ActiveStatus.NO),
        value: 'false',
      },
    ],
  },
  // {
  //   name: 'is_confirmed_age_by_driver',
  //   label: 'Is confirmed age by driver',
  //   type: 'radio',
  //   options: [
  //     {
  //       label: StringHelper.capitalize(ActiveStatus.YES),
  //       value: 'true',
  //     },
  //     {
  //       label: StringHelper.capitalize(ActiveStatus.NO),
  //       value: 'false',
  //     },
  //   ],
  // },
  {
    name: 'created_at',
    label: 'Reg date',
    type: 'radio-range',
    options: [
      {
        label: 'Today',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 7 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -7,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 30 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -30,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    ],
  },
];

const options = ({
  pathname,
  query,
  dispatch,
  role,
}: {
  dispatch: AppDispatch;
  pathname: string;
  query: string;
  role: Role;
}) => ({
  title: 'User',
  tab: tab(role),
  sortOption,
  filterOptions: filterOptions(role) as FilterOption[],
  headerButtons: UserUIButton(),
  columns: columns({ dispatch, pathname, query }),
});

export default options;
