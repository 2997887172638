import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullMedia } from '@entities/Media';
import { MediaHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';

import { uploadMediaFailure, uploadMediaSuccess } from './Media.action';
import { UPLOAD_MEDIA_REQUEST } from './Media.constant';
import { IUploadMediaRequest } from './Media.type';

function* workerUploadMedia({ payload }: IUploadMediaRequest) {
  try {
    const { file, fileName, name, alert = true } = payload;
    const data: FullMedia = yield call(MediaHttp.uploadFile, fileName, file);

    yield put(uploadMediaSuccess({ media: data, ...(name && { name }) }));
    if (alert) {
      yield put(alertSuccess('File uploaded successfully!'));
    }
  } catch (error) {
    yield put(uploadMediaFailure());
    yield put(alertError(error));
  }
}

function* watchUploadMedia() {
  yield takeLatest(UPLOAD_MEDIA_REQUEST, workerUploadMedia);
}

export const mediaWatchers: ForkEffect[] = [fork(watchUploadMedia)];
