import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullProduct, FullStoresList } from '@entities/Product';
import { FullDriversList, FullUser } from '@entities/User';
import { ProductHttp, ShopHttp, UserHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';
import { getListUserDriverSuccess } from '@store/User';

import {
  changeProductDriversFailure,
  changeProductDriversSuccess,
  changeProductStoresFailure,
  changeProductStoresSuccess,
  createProductFailure,
  createProductSuccess,
  deleteProductAllFailure,
  deleteProductAllSuccess,
  deleteProductDriversFailure,
  deleteProductDriversSuccess,
  deleteProductFailure,
  deleteProductStoresFailure,
  deleteProductStoresSuccess,
  deleteProductSuccess,
  getDriversProductFailure,
  getDriversProductSuccess,
  getListProductFailure,
  getListProductSuccess,
  getOneProductFailure,
  getOneProductSuccess,
  getShopProductsFailure,
  getShopProductsSuccess,
  getStoresProductFailure,
  getStoresProductSuccess,
  getUserProductsFailure,
  getUserProductsSuccess,
  updateProductFailure,
  updateProductSuccess,
} from './Product.action';
import {
  CHANGE_PRODUCT_DRIVERS_REQUEST,
  CHANGE_PRODUCT_STORES_REQUEST,
  CREATE_PRODUCTS_REQUEST,
  DELETE_PRODUCTS_ALL_REQUEST,
  DELETE_PRODUCTS_DRIVERS_REQUEST,
  DELETE_PRODUCTS_REQUEST,
  DELETE_PRODUCT_STORE_REQUEST,
  GET_DRIVERS_PRODUCTS_REQUEST,
  GET_LIST_PRODUCTS_REQUEST,
  GET_ONE_PRODUCTS_REQUEST,
  GET_SHOP_PRODUCTS_REQUEST,
  GET_STORES_PRODUCTS_REQUEST,
  GET_USER_PRODUCTS_REQUEST,
  UPDATE_PRODUCTS_REQUEST,
} from './Product.constant';
import {
  IChangeProductDriversRequest,
  IChangeProductStoresRequest,
  ICreateProductRequest,
  IDeleteProductDriversRequest,
  IDeleteProductRequest,
  IDeleteProductStoresRequest,
  IGetDriversProductRequest,
  IGetListProductRequest,
  IGetOneProductRequest,
  IGetShopProductsRequest,
  IGetStoresProductRequest,
  IGetUserProductsRequest,
  IUpdateProductRequest,
} from './Product.type';

function* workerGetListProduct(action: IGetListProductRequest) {
  try {
    const { query } = action.payload;
    const data: DataResponse<FullProduct[]> = yield call(
      ProductHttp.getList,
      query,
    );

    yield put(getListProductSuccess(data));
  } catch (error) {
    yield put(getListProductFailure());
    yield put(alertError(error));
  }
}

function* workerGetUserProducts(action: IGetUserProductsRequest) {
  try {
    const { query, id, isCancel } = action.payload;
    const data: DataResponse<{
      products: FullDriversList[];
      user: FullUser;
    }> = yield call(UserHttp.getListProduct, id, query, isCancel);

    yield put(getUserProductsSuccess(data));
    yield put(getListUserDriverSuccess(data));
  } catch (error) {
    yield put(getUserProductsFailure());
    yield put(alertError(error));
  }
}

function* workerGetShopProducts(action: IGetShopProductsRequest) {
  try {
    const { query, id, isCancel } = action.payload;
    const data: DataResponse<FullStoresList[]> = yield call(
      ShopHttp.getListProduct,
      id,
      query,
      isCancel,
    );

    yield put(getShopProductsSuccess(data));
  } catch (error) {
    yield put(getShopProductsFailure());
    yield put(alertError(error));
  }
}

function* workerCreateProduct(action: ICreateProductRequest) {
  try {
    const data: FullProduct = yield call(ProductHttp.create, action.payload);

    yield put(createProductSuccess(data));
    yield put(alertSuccess('Product added successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(createProductFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteProduct(action: IDeleteProductRequest) {
  try {
    const { id } = action.payload;

    yield call(ProductHttp.remove, id);

    yield put(deleteProductSuccess(id));
    yield put(alertSuccess('Product successfully deleted!'));
  } catch (error) {
    yield put(deleteProductFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteProductAll() {
  try {
    yield call(ProductHttp.removeAll);

    yield put(deleteProductAllSuccess());
    yield put(alertSuccess('Products successfully deleted!'));
  } catch (error) {
    yield put(deleteProductAllFailure());
    yield put(alertError(error));
  }
}

function* workerGetOneProduct(action: IGetOneProductRequest) {
  try {
    const { id } = action.payload;
    const payload: FullProduct = yield call(ProductHttp.getOne, id);

    yield put(getOneProductSuccess(payload));
  } catch (error) {
    yield put(getOneProductFailure());
    yield put(alertError(error));
  }
}

function* workerGetStoresProduct(action: IGetStoresProductRequest) {
  try {
    const { query, id, isCancel } = action.payload;
    const data: DataResponse<FullStoresList[]> = yield call(
      ProductHttp.getStores,
      id,
      query,
      isCancel,
    );

    yield put(getStoresProductSuccess(data));
  } catch (error) {
    yield put(getStoresProductFailure());
    yield put(alertError(error));
  }
}

function* workerGetDriversProduct(action: IGetDriversProductRequest) {
  try {
    const { query, id, isCancel } = action.payload;
    const data: DataResponse<FullDriversList[]> = yield call(
      ProductHttp.getDrivers,
      id,
      query,
      isCancel,
    );

    yield put(getDriversProductSuccess(data));
  } catch (error) {
    yield put(getDriversProductFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateProduct(action: IUpdateProductRequest) {
  try {
    const { id, ...body } = action.payload;

    const data: FullProduct = yield call(ProductHttp.update, id, body);

    yield put(updateProductSuccess(data));
    yield put(alertSuccess('Product successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateProductFailure());
    if (
      (error as string).includes(
        'discount: Must match exactly one schema in oneOf',
      )
    ) {
      yield put(
        alertError('Please enter the start date and end date of the discount.'),
      );
    } else {
      yield put(alertError(error));
    }
  }
}

function* workerChangeProductDrivers(action: IChangeProductDriversRequest) {
  try {
    const { id, body } = action.payload;

    yield call(ProductHttp.changeDrivers, id, body);

    yield put(changeProductDriversSuccess());
    yield put(alertSuccess('Product successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(changeProductDriversFailure());
    yield put(alertError(error));
  }
}

function* workerChangeProductStores(action: IChangeProductStoresRequest) {
  try {
    const { id, body } = action.payload;

    yield call(ProductHttp.changeStores, id, body);

    yield put(changeProductStoresSuccess());
    yield put(alertSuccess('Product successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(changeProductStoresFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteProductStores(action: IDeleteProductStoresRequest) {
  try {
    const { id, storeId } = action.payload;

    yield call(ProductHttp.deleteStore, id, storeId);

    yield put(deleteProductStoresSuccess());
    yield put(alertSuccess('Product successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(deleteProductStoresFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteProductDrivers(action: IDeleteProductDriversRequest) {
  try {
    const { id, driverId } = action.payload;

    yield call(ProductHttp.deleteDriver, id, driverId);

    yield put(deleteProductDriversSuccess());
    yield put(alertSuccess('Product successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(deleteProductDriversFailure());
    yield put(alertError(error));
  }
}

function* watchUpdateProduct() {
  yield takeLatest(UPDATE_PRODUCTS_REQUEST, workerUpdateProduct);
}

function* watchGetOneProduct() {
  yield takeLatest(GET_ONE_PRODUCTS_REQUEST, workerGetOneProduct);
}

function* watchGetDriversProduct() {
  yield takeLatest(GET_DRIVERS_PRODUCTS_REQUEST, workerGetDriversProduct);
}

function* watchGetStoresProduct() {
  yield takeLatest(GET_STORES_PRODUCTS_REQUEST, workerGetStoresProduct);
}

function* watchDeleteProduct() {
  yield takeLatest(DELETE_PRODUCTS_REQUEST, workerDeleteProduct);
}

function* watchDeleteProductAll() {
  yield takeLatest(DELETE_PRODUCTS_ALL_REQUEST, workerDeleteProductAll);
}

function* watchCreateProduct() {
  yield takeLatest(CREATE_PRODUCTS_REQUEST, workerCreateProduct);
}

function* watchGetListProduct() {
  yield takeLatest(GET_LIST_PRODUCTS_REQUEST, workerGetListProduct);
}

function* watchGetUserProducts() {
  yield takeLatest(GET_USER_PRODUCTS_REQUEST, workerGetUserProducts);
}

function* watchGetShopProducts() {
  yield takeLatest(GET_SHOP_PRODUCTS_REQUEST, workerGetShopProducts);
}

function* watchChangeProductDrivers() {
  yield takeLatest(CHANGE_PRODUCT_DRIVERS_REQUEST, workerChangeProductDrivers);
}

function* watchChangeProductStores() {
  yield takeLatest(CHANGE_PRODUCT_STORES_REQUEST, workerChangeProductStores);
}

function* watchDeleteProductStores() {
  yield takeLatest(DELETE_PRODUCT_STORE_REQUEST, workerDeleteProductStores);
}

function* watchDeleteProductDrivers() {
  yield takeLatest(DELETE_PRODUCTS_DRIVERS_REQUEST, workerDeleteProductDrivers);
}

export const productWatchers: ForkEffect[] = [
  fork(watchGetListProduct),
  fork(watchCreateProduct),
  fork(watchDeleteProduct),
  fork(watchDeleteProductAll),
  fork(watchGetOneProduct),
  fork(watchGetDriversProduct),
  fork(watchGetStoresProduct),
  fork(watchUpdateProduct),
  fork(watchGetUserProducts),
  fork(watchDeleteProductDrivers),
  fork(watchDeleteProductStores),
  fork(watchChangeProductStores),
  fork(watchChangeProductDrivers),
  fork(watchGetShopProducts),
];
