import { FullEffect } from '@entities/Effect';

import {
  CLEAR_EFFECT,
  CLEAR_IMAGE_TO_EFFECT,
  CLEAR_LIST_EFFECT,
  CREATE_EFFECT_FAILURE,
  CREATE_EFFECT_REQUEST,
  CREATE_EFFECT_SUCCESS,
  DELETE_EFFECT_FAILURE,
  DELETE_EFFECT_REQUEST,
  DELETE_EFFECT_SUCCESS,
  GET_LIST_EFFECT_FAILURE,
  GET_LIST_EFFECT_REQUEST,
  GET_LIST_EFFECT_SUCCESS,
  GET_ONE_EFFECT_FAILURE,
  GET_ONE_EFFECT_REQUEST,
  GET_ONE_EFFECT_SUCCESS,
  UPDATE_EFFECT_FAILURE,
  UPDATE_EFFECT_REQUEST,
  UPDATE_EFFECT_SUCCESS,
} from './Effect.constant';
import { EffectActionsTypes } from './Effect.type';

export function getListEffectRequest(
  query?: Query['query'],
): EffectActionsTypes {
  return {
    type: GET_LIST_EFFECT_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListEffectSuccess(
  payload: DataResponse<FullEffect[]>,
): EffectActionsTypes {
  return {
    type: GET_LIST_EFFECT_SUCCESS,
    payload,
  };
}

export function getListEffectFailure(): EffectActionsTypes {
  return {
    type: GET_LIST_EFFECT_FAILURE,
  };
}

export function createEffectRequest(
  payload: Omit<FullEffect, 'id' | 'isActive'>,
): EffectActionsTypes {
  return {
    type: CREATE_EFFECT_REQUEST,
    payload,
  };
}

export function createEffectSuccess(payload: FullEffect): EffectActionsTypes {
  return {
    type: CREATE_EFFECT_SUCCESS,
    payload,
  };
}

export function createEffectFailure(): EffectActionsTypes {
  return {
    type: CREATE_EFFECT_FAILURE,
  };
}

export function deleteEffectRequest(id: FullEffect['id']): EffectActionsTypes {
  return {
    type: DELETE_EFFECT_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteEffectSuccess(
  payload: FullEffect['id'],
): EffectActionsTypes {
  return {
    type: DELETE_EFFECT_SUCCESS,
    payload,
  };
}

export function deleteEffectFailure(): EffectActionsTypes {
  return {
    type: DELETE_EFFECT_FAILURE,
  };
}

export function getOneEffectRequest(id: FullEffect['id']): EffectActionsTypes {
  return {
    type: GET_ONE_EFFECT_REQUEST,
    payload: {
      id,
    },
  };
}

export function getOneEffectSuccess(payload: FullEffect): EffectActionsTypes {
  return {
    type: GET_ONE_EFFECT_SUCCESS,
    payload,
  };
}

export function getOneEffectFailure(): EffectActionsTypes {
  return {
    type: GET_ONE_EFFECT_FAILURE,
  };
}

export function updateEffectRequest(
  payload: Pick<FullEffect, 'id'> & Partial<FullEffect>,
): EffectActionsTypes {
  return {
    type: UPDATE_EFFECT_REQUEST,
    payload,
  };
}

export function updateEffectSuccess(payload: FullEffect): EffectActionsTypes {
  return {
    type: UPDATE_EFFECT_SUCCESS,
    payload,
  };
}

export function updateEffectFailure(): EffectActionsTypes {
  return {
    type: UPDATE_EFFECT_FAILURE,
  };
}

export function clearEffect(): EffectActionsTypes {
  return {
    type: CLEAR_EFFECT,
  };
}

export function clearListEffect(): EffectActionsTypes {
  return {
    type: CLEAR_LIST_EFFECT,
  };
}

export function clearImageToEffect(): EffectActionsTypes {
  return {
    type: CLEAR_IMAGE_TO_EFFECT,
  };
}
