import { Button, Dropdown } from 'antd';

import { ReactComponent as SortIcon } from '@assets/svg/sort_icon.svg';

import { Props } from './index.type';
import UISortOverlay from './Overlay';

import './style.scss';

const UISort = (props: Props) => {
  return (
    <Dropdown overlay={<UISortOverlay {...props} />} placement="bottomRight">
      <Button
        type="primary"
        size="large"
        icon={<SortIcon />}
        className="sort__button"
      >
        Sort
      </Button>
    </Dropdown>
  );
};

export default UISort;
