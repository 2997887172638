import { useEffect, useState } from 'react';

import { Button, Space } from 'antd';

import WidgetModalUIPeople from '../../ui/People';
import { Props } from './index.type';

const WidgetModalFilterPeople = (props: Props) => {
  const { filterValue, onFilterUpdate } = props;

  const [defaultValues, setDefaultValues] = useState(filterValue);

  const clearHandler = () => {
    setDefaultValues([]);
    onFilterUpdate(null);
  };

  useEffect(() => {
    setDefaultValues(filterValue);
  }, [filterValue]);

  return (
    <Space direction="vertical" className="filter-slider__space">
      <WidgetModalUIPeople
        defaultValues={defaultValues}
        onChange={onFilterUpdate}
      />
      <Button
        type="link"
        className="modal-filter__button-clear"
        onClick={clearHandler}
      >
        Clear
      </Button>
    </Space>
  );
};

export default WidgetModalFilterPeople;
