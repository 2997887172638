import { FallOutlined, RiseOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import Text from 'antd/lib/typography/Text';

import { Props } from './index.type';

import './style.scss';

const DashboardUIProgress = (props: Props) => {
  const { value, prevValue = 0 } = props;

  const progress = +(((value - prevValue) / (prevValue || 1)) * 100).toFixed(2);

  if (progress > 0) {
    return (
      <Space size="small" direction="horizontal" wrap={false}>
        <RiseOutlined className="dashboard__progress--success" />
        <Text type="success">{progress}%</Text>
      </Space>
    );
  }

  if (progress < 0) {
    return (
      <Space>
        <FallOutlined className="dashboard__progress--danger" />
        <Text type="danger">{progress * -1}%</Text>
      </Space>
    );
  }

  return (
    <Space>
      <RiseOutlined className="dashboard__progress--warning" />
      <Text type="warning">{progress}%</Text>
    </Space>
  );
};

export default DashboardUIProgress;
