import { useEffect, useState } from 'react';

import { Form, Input, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PlacesAutocomplete from 'react-google-autocomplete';

import { Address, IAddressComponents } from '@entities/Address';
import { en } from '@i18n';
import { getInfoAboutPlace } from '@utils';

import { WidgetModalUIImage } from '../../ui';
import { INITIAL_VALUES } from './constant';
import { FormValues, Props } from './index.type';

const WidgetModalShopForm = ({
  onFormSubmit,
  form,
  shop,
  onUploadImage,
  image,
  onRemoveImage,
}: Props) => {
  const [address, setAddress] = useState<(Address & Partial<Id>) | null>(
    shop?.address || null,
  );

  useEffect(() => {
    if (shop) {
      form.setFieldsValue(shop);
      if (shop?.address) {
        setAddress(shop?.address);
      }
    }
  }, [shop, form]);

  const handleAddCity = (obj: google.maps.places.PlaceResult) => {
    const newAddress: Address = {
      ...getInfoAboutPlace(obj?.address_components as IAddressComponents[]),
      formattedAddress: obj.formatted_address || '',
      location: {
        lat: obj?.geometry?.location?.lat(),
        lng: obj?.geometry?.location?.lng(),
      },
    };

    form.setFieldValue('address', newAddress);
    setAddress(newAddress);
  };

  const handlerFinish = (values: FormValues) => {
    onFormSubmit({
      ...values,
      image,
      ...(address && { address }),
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      validateMessages={en.validation}
    >
      <WidgetModalUIImage
        name="image"
        view="image"
        path={image?.path || 'error'}
        onUploadImage={onUploadImage}
        onRemoveImage={onRemoveImage}
      />
      <Form.Item
        name="name"
        label="Name of the shop"
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input placeholder="Enter the shop" />
      </Form.Item>
      <Form.Item name={['address', 'formattedAddress']} label="Address">
        <PlacesAutocomplete
          className="ant-input"
          onPlaceSelected={(places) => handleAddCity(places)}
          apiKey="AIzaSyCaSLe663mSesOXEO5HXgF_tNE_osZNKl4"
          options={{ types: ['geocode', 'establishment'] }}
        />
      </Form.Item>
      {shop ? (
        <Form.Item label="Visible" name="isActive" required>
          <Switch defaultChecked={shop?.isActive} />
        </Form.Item>
      ) : null}
      <Form.Item label="Description" name="description">
        <TextArea showCount rows={4} placeholder="Enter the description" />
      </Form.Item>
    </Form>
  );
};

export default WidgetModalShopForm;
