import axios, { CancelTokenSource } from 'axios';
import { DeepPartial } from 'redux';

import { FullProduct } from '@entities/Product';
import { FullDriversList, FullUser, ProductListToDriver } from '@entities/User';
import { http } from '@utils';

const basePath = '/api/users';
let axiosSource: CancelTokenSource | null = null;

export const getListUser = async (
  query: Query['query'],
  isCancel = true,
): Promise<{
  data: FullUser[];
  meta: MetaType;
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<FullUser[]>>(
    `${basePath}${query}`,
    {
      ...(axiosSource && isCancel && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};

export const getListProduct = async (
  id: number,
  query: Query['query'],
  isCancel = true,
): Promise<{
  data: {
    products: FullProduct[];
    user: FullUser;
  };
  meta: MetaType;
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<
    DataResponse<{
      products: FullProduct[];
      user: FullUser;
    }>
  >(`${basePath}/${id}/products${query}`, {
    ...(axiosSource && isCancel && { cancelToken: axiosSource.token }),
  });

  return data;
};

export const getOneUser = async (id: number): Promise<FullUser> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullUser>>(`${basePath}/${id}`);

  return data;
};

export const updateUser = async ({
  id,
  body,
}: {
  body: DeepPartial<FullUser>;
  id: number;
}): Promise<FullUser> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullUser>>(`${basePath}/${id}`, body);

  return data;
};

export const deleteUser = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const createUser = async (
  body: DeepPartial<FullUser>,
): Promise<FullUser> => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullUser>>(`${basePath}`, body);

  return data;
};

export const createUserDriver = async ({
  body,
  id,
}: {
  body: DeepPartial<ProductListToDriver[]>;
  id: number;
}): Promise<ProductListToDriver[]> => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullDriversList[]>>(
    `${basePath}/${id}/products`,
    body,
  );

  return data;
};

export const resetUserPassword = async (id: number): Promise<void> => {
  await http.get(`${basePath}/${id}/reset-password`);
};

export const getLocation = async (): Promise<{
  data: FullUser[];
  meta: MetaType;
}> => {
  const { data } = await http.get<DataResponse<FullUser[]>>(
    `${basePath}/locations`,
  );

  return data;
};
