import { useEffect } from 'react';

import { Form, Spin } from 'antd';
import { useSelector } from 'react-redux';
import './style.scss';

import { RootState, useAppDispatch } from '@store/index';
import {
  clearPromoCode,
  createPromoCodeRequest,
  getOnePromoCodeRequest,
  updatePromoCodeRequest,
} from '@store/PromoCode';
import { UIModal } from '@ui';
import { DateHelper, FORMAT_DATE } from '@utils';

import WidgetModalPromoCodeForm from './Form';
import { INITIAL_VALUES } from './Form/constant';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';

const WidgetModalPromoCode = (props: Props) => {
  const { promoCodeId } = props;
  const { promoCode, loading: loadingPromoCode } = useSelector(
    (state: RootState) => state.promoCode,
  );
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (promoCodeId) {
      dispatch(clearPromoCode());
      dispatch(getOnePromoCodeRequest({ id: +promoCodeId }));
    }
  }, [dispatch, promoCodeId]);

  const isCreate = props.type === 'create';

  const onFormSubmitHandler = (values: FormValues) => {
    if (isCreate) {
      dispatch(
        createPromoCodeRequest({
          ...values,
          validUntil: DateHelper.formateDateToString(
            values.validUntil,
            FORMAT_DATE,
          ),
        }),
      );
    } else {
      dispatch(
        updatePromoCodeRequest({
          ...values,
          validUntil: DateHelper.formateDateToString(
            values.validUntil,
            FORMAT_DATE,
          ),
          id: +promoCodeId,
        }),
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearPromoCode());
    };
  }, [dispatch]);

  return (
    <UIModal
      className="edit-form promoCodeModal"
      title={isCreate ? 'Add promoCode' : 'Edit promoCode'}
      visible
      children={
        <Spin spinning={loadingPromoCode}>
          <WidgetModalPromoCodeForm
            promoCode={promoCodeId ? promoCode : INITIAL_VALUES}
            form={form}
            isCreate={isCreate}
            onFormSubmit={onFormSubmitHandler}
          />
        </Spin>
      }
    />
  );
};

export default WidgetModalPromoCode;
