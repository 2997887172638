import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullChat, Message } from '@entities/Chat';
import { ChatHttp } from '@services/http';

import { alertError } from '../Alert';
import {
  getListChatFailure,
  getListChatSuccess,
  getListMessageFailure,
  getListMessageSuccess,
  getOneChatFailure,
  getOneChatSuccess,
} from './Chat.action';
import {
  GET_LIST_CHAT_REQUEST,
  GET_LIST_MESSAGE_REQUEST,
  GET_ONE_CHAT_REQUEST,
} from './Chat.constant';
import {
  IGetListChatRequest,
  IGetListMessageRequest,
  IGetOneChatRequest,
} from './Chat.type';

function* workerGetListChat(action: IGetListChatRequest) {
  try {
    const { query } = action.payload;
    const payload: DataResponse<FullChat[]> = yield call(
      ChatHttp.getList,
      query,
    );

    yield put(getListChatSuccess(payload));
  } catch (error) {
    yield put(getListChatFailure());
    yield put(alertError(error));
  }
}

function* watchGetListChat() {
  yield takeLatest(GET_LIST_CHAT_REQUEST, workerGetListChat);
}

function* workerGetListMessage(action: IGetListMessageRequest) {
  try {
    const payload: DataResponse<Message[]> = yield call(
      ChatHttp.getListMessage,
      action.payload,
    );

    yield put(getListMessageSuccess(payload));
  } catch (error) {
    yield put(getListMessageFailure());
    yield put(alertError(error));
  }
}

function* watchGetListMessage() {
  yield takeLatest(GET_LIST_MESSAGE_REQUEST, workerGetListMessage);
}

function* workerGetOneChat(action: IGetOneChatRequest) {
  try {
    const { id } = action.payload;
    const payload: FullChat = yield call(ChatHttp.getOne, id);

    yield put(getOneChatSuccess(payload));
  } catch (error) {
    yield put(getOneChatFailure());
    yield put(alertError(error));
  }
}

function* watchGetOneChat() {
  yield takeLatest(GET_ONE_CHAT_REQUEST, workerGetOneChat);
}

export const chatWatchers: ForkEffect[] = [
  fork(watchGetListChat),
  fork(watchGetOneChat),
  fork(watchGetListMessage),
];
