import { Login } from '@entities/Auth';

import {
  AUTH_LOGIN_EMAIL_REQUEST,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
} from './Auth.constant';
import { AuthActionsTypes } from './Auth.type';

export function authLoginEmailRequest(payload: Login): AuthActionsTypes {
  return {
    type: AUTH_LOGIN_EMAIL_REQUEST,
    payload,
  };
}

export function authLoginSuccess(): AuthActionsTypes {
  return {
    type: AUTH_LOGIN_SUCCESS,
  };
}

export function authLoginFailure(): AuthActionsTypes {
  return {
    type: AUTH_LOGIN_FAILURE,
  };
}

export function authLogoutRequest(): AuthActionsTypes {
  return {
    type: AUTH_LOGOUT_REQUEST,
  };
}

export function authLogoutSuccess(): AuthActionsTypes {
  return {
    type: AUTH_LOGOUT_SUCCESS,
  };
}

export function authLogoutFailure(): AuthActionsTypes {
  return {
    type: AUTH_LOGOUT_FAILURE,
  };
}
