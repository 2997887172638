import { useEffect, useState } from 'react';

import { Form, Spin } from 'antd';
import { useSelector } from 'react-redux';
import './style.scss';

import { FullMedia } from '@entities/Media';
import {
  clearBrand,
  createBrandRequest,
  getOneBrandRequest,
  updateBrandRequest,
} from '@store/Brand';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { UIButton, UIModal } from '@ui';
import { Button, FileName, UploadFile } from '@utils';

import WidgetModalBrandForm from './Form';
import { INITIAL_VALUES } from './Form/constant';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';

const WidgetModalBrand = (props: Props) => {
  const { brandId } = props;

  const [logoFile, setLogoFile] = useState<FullMedia | null>(null);
  const [backgroundFile, setBackgroundFile] = useState<FullMedia | null>(null);
  const {
    loading: loadingImage,
    file: fileStore,
    name: nameFile,
  } = useSelector((state: RootState) => state.media);
  const { brand, loading: loadingBrand } = useSelector(
    (state: RootState) => state.brand,
  );
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (brandId) {
      dispatch(clearBrand());
      dispatch(getOneBrandRequest({ id: +brandId }));
    }
  }, [dispatch, brandId]);

  useEffect(() => {
    if (fileStore) {
      switch (nameFile) {
        case 'logo': {
          setLogoFile(fileStore);
          break;
        }
        case 'background': {
          setBackgroundFile(fileStore);
          break;
        }
        default:
      }
    }
  }, [dispatch, fileStore, nameFile]);

  const isCreate = props.type === 'create';

  const onFormSubmitHandler = (values: FormValues) => {
    if (isCreate) {
      dispatch(createBrandRequest(values));
    } else {
      dispatch(updateBrandRequest({ ...values, id: +brandId }));
    }
  };

  const onUploadImageHandler = (file: UploadFile['file'], name: string) => {
    dispatch(uploadMediaRequest({ file, fileName: FileName.IMAGE, name }));
  };

  useEffect(() => {
    if (brand?.logo) {
      setLogoFile(brand?.logo);
    }
    if (brand?.background) {
      setBackgroundFile(brand?.background);
    }
  }, [brand?.background, brand?.logo]);

  const onRemoveImageHandler = (name?: string) => {
    dispatch(clearStore());
    switch (name) {
      case 'background': {
        setBackgroundFile(null);
        break;
      }
      case 'logo': {
        setLogoFile(null);
        break;
      }
      default:
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearStore());
      dispatch(clearBrand());
    };
  }, [dispatch]);

  const footer = [
    <UIButton
      key="Save changes"
      typeAntBtn="primary"
      handler={() => form.submit()}
      type={Button.ANT_BUTTON}
      size="large"
      className="edit-form__save-button"
    >
      {isCreate ? 'Add brand' : 'Save'}
    </UIButton>,
  ];

  return (
    <UIModal
      className="edit-form"
      title={isCreate ? 'Add brand' : 'Edit brand'}
      footer={footer}
      visible
      children={
        <Spin spinning={loadingBrand || loadingImage}>
          <WidgetModalBrandForm
            brand={brandId ? brand : INITIAL_VALUES}
            logo={logoFile}
            background={backgroundFile}
            form={form}
            onFormSubmit={onFormSubmitHandler}
            onUploadImage={onUploadImageHandler}
            onRemoveImage={onRemoveImageHandler}
          />
        </Spin>
      }
    />
  );
};

export default WidgetModalBrand;
