import { Route, Routes } from 'react-router-dom';

import { CsvList } from '@features/CSV';

const ViewCsv = () => {
  return (
    <Routes>
      <Route path="/" element={<CsvList />} />
    </Routes>
  );
};

export default ViewCsv;
