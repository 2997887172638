import { Referral } from '@entities/Referral';
import { http } from '@utils';

const basePath = '/api/referral-settings';

export const getOne = async (): Promise<Referral> => {
  const {
    data: { data },
  } = await http.get<DataResponse<Referral>>(`${basePath}`);

  return data;
};

export const update = async (body: Partial<Referral>): Promise<Referral> => {
  const {
    data: { data },
  } = await http.put<DataResponse<Referral>>(`${basePath}`, body);

  return data;
};
