import {
  ApproveStatus,
  ProcessStatus,
  TransactionClass,
  TransactionOperation,
  TransactionStatus,
  TransactionType,
} from '../utility-types';

export const processAppreove = {
  [ApproveStatus.YES]: {
    title: ApproveStatus.YES,
    color: 'green',
  },
  [ApproveStatus.NO]: {
    title: ApproveStatus.NO,
    color: 'red',
  },
};

export const processClassOptions = {
  [TransactionClass.EXTERNAL]: {
    title: TransactionClass.EXTERNAL,
    color: 'green',
  },
  [TransactionClass.INTERNAL]: {
    title: TransactionClass.INTERNAL,
    color: 'magenta',
  },
};

export const processOperationOptions = {
  [TransactionOperation.EARNING]: {
    title: TransactionOperation.EARNING,
    color: 'green',
  },
  [TransactionOperation.PAYMENT]: {
    title: TransactionOperation.PAYMENT,
    color: 'magenta',
  },
  [TransactionOperation.SPENDING]: {
    title: TransactionOperation.SPENDING,
    color: 'magenta',
  },
  [TransactionOperation.WITHDRAWAL]: {
    title: TransactionOperation.WITHDRAWAL,
    color: 'magenta',
  },
};

export const processStatusOptions = {
  [ProcessStatus.APPROVED]: {
    title: TransactionStatus.APPROVED,
    color: 'green',
  },
  [ProcessStatus.AWAITING_PICKUP]: {
    title: TransactionStatus.AWAITING_PICKUP,
    color: 'green',
  },
  [ProcessStatus.CANCELED]: {
    title: TransactionStatus.CANCELED,
    color: 'red',
  },
  [ProcessStatus.CANCELED_BY_ADMIN]: {
    title: TransactionStatus.CANCELED_BY_ADMIN,
    color: 'red',
  },
  [ProcessStatus.CANCELED_BY_CUSTOMER]: {
    title: TransactionStatus.CANCELED_BY_CUSTOMER,
    color: 'red',
  },
  [ProcessStatus.CANCELED_BY_DRIVER]: {
    title: TransactionStatus.CANCELED_BY_DRIVER,
    color: 'red',
  },
  [ProcessStatus.COMPLETED]: {
    title: TransactionStatus.COMPLETED,
    color: 'green',
  },
  [ProcessStatus.DELIVERED]: {
    title: TransactionStatus.DELIVERED,
    color: 'green',
  },
  [ProcessStatus.PENDING_DRIVER]: {
    title: TransactionStatus.PENDING_DRIVER,
    color: 'orange',
  },
  [ProcessStatus.SHIPPED]: {
    title: TransactionStatus.SHIPPED,
    color: 'green',
  },
  [ProcessStatus.VERIFICATION_REQUIRED]: {
    title: TransactionStatus.VERIFICATION_REQUIRED,
    color: 'orange',
  },
};

export const processTypeOptions = {
  [TransactionType.INCOME]: {
    title: TransactionType.INCOME,
    color: 'green',
  },
  [TransactionType.OUTCOME]: {
    title: TransactionType.OUTCOME,
    color: 'magenta',
  },
};
