import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Space } from 'antd';

import { useAppDispatch } from '@store/index';
import { hideModal } from '@store/Modal';

import { Props } from './index.type';
import './style.scss';

const WidgetModalConfirm = ({ content, action }: Props) => {
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    action();
    dispatch(hideModal());
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      visible={true}
      title="Confirm action"
      onOk={onSubmit}
      okText="Confirm"
      cancelText="Cancel"
      onCancel={closeModal}
      className="confirm-modal"
    >
      <Space>
        <ExclamationCircleOutlined className="confirm-modal__warning-icon" />
        {content}
      </Space>
    </Modal>
  );
};

export default WidgetModalConfirm;
