import { useEffect, useState } from 'react';

import { Form, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { FullMedia } from '@entities/Media';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { updateUserRequest } from '@store/User';
import { FileName, UploadFile } from '@utils';

import UserEditForm from './Form';
import { FormUser } from './Form/index.type';
import { Props } from './index.type';

const UserEdit = ({ user, loadingUser }: Props) => {
  const dispatch = useAppDispatch();
  const [avatarFile, setAvatarFile] = useState<FullMedia | null>(null);
  const [form] = Form.useForm<FormUser>();

  const { loading: loadingImage, file: fileStore } = useSelector(
    (state: RootState) => state.media,
  );

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, [dispatch]);

  const onFormSubmitHandler = (values: FormUser) => {
    dispatch(
      updateUserRequest({
        id: user.id,
        body: values,
      }),
    );
  };

  const onUploadImageHandler = (file: UploadFile['file']) => {
    dispatch(uploadMediaRequest({ file, fileName: FileName.IMAGE }));
  };

  const onRemoveImageHandler = () => {
    dispatch(clearStore());
    setAvatarFile(null);
  };

  useEffect(() => {
    if (fileStore) {
      setAvatarFile(fileStore);
    }
  }, [dispatch, fileStore]);

  useEffect(() => {
    if (user?.avatar) {
      setAvatarFile(user?.avatar);
    }
  }, [user?.avatar]);

  return (
    <div className="user-profile user-edit">
      <Spin spinning={loadingUser || loadingImage}>
        <UserEditForm
          user={user}
          avatar={avatarFile}
          form={form}
          onFormSubmit={onFormSubmitHandler}
          onUploadImage={onUploadImageHandler}
          onRemoveImage={onRemoveImageHandler}
        />
      </Spin>
    </div>
  );
};

export default UserEdit;
