import { useState } from 'react';

import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { mainMenu } from '@data/menu';
import { LocalStorage } from '@services/storage';
import { RootState } from '@store/index';
import { LINK_ORDER, LINK_SUPPORT } from '@utils';

const WidgetMenu = () => {
  const location = useLocation();
  const { notification: notificationOrder } = useSelector(
    (state: RootState) => state.order,
  );
  const { notification: notificationSupport } = useSelector(
    (state: RootState) => state.support,
  );
  const { profile } = useSelector((state: RootState) => state.profile);

  const notifications = {
    [LINK_ORDER]: notificationOrder,
    [LINK_SUPPORT]: notificationSupport,
  };

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout.Sider
      width="255"
      breakpoint="xl"
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <Menu
        defaultSelectedKeys={['/']}
        selectedKeys={[
          location.pathname,
          location.pathname.split('/').slice(0, 2).join('/'),
        ]}
      >
        <Menu.Divider />
        {mainMenu(notifications, profile?.role)[0]?.map(
          ({ icon, title, link, notification }) => (
            <Menu.Item
              icon={
                <span>
                  {icon}
                  {notification ? (
                    <p className="menu__notification">{notification}</p>
                  ) : null}
                </span>
              }
              key={link}
            >
              <Link
                onClick={() => {
                  LocalStorage.clear('isBack');
                  LocalStorage.clear('tabs');
                  LocalStorage.clear('tab');
                }}
                to={link}
              >
                {title}
              </Link>
            </Menu.Item>
          ),
        )}
        <Menu.Divider />
        {mainMenu(notifications, profile?.role)[1]?.map(
          ({ icon, title, link }) => (
            <Menu.Item icon={icon} key={link}>
              <Link
                onClick={() => {
                  LocalStorage.clear('isBack');
                  LocalStorage.clear('tabs');
                  LocalStorage.clear('tab');
                }}
                to={link}
              >
                {title}
              </Link>
            </Menu.Item>
          ),
        )}
      </Menu>
    </Layout.Sider>
  );
};

export default WidgetMenu;
