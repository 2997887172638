import { useCallback, useEffect, useMemo, useState } from 'react';

import { Space, Tag } from 'antd';

import { filterValueTypeToString } from '@entities/Filter/utils';
import { useQuery } from '@hooks';

import { Props, TagOptions } from './index.type';

const UIViewTag = (props: Props) => {
  const { sortOption, filterOptions } = props;

  const {
    queryOptions: { filter, order },
    updateQuery,
  } = useQuery();

  const [tags, setTags] = useState<TagOptions[]>([]);

  useEffect(() => {
    const arr: TagOptions[] = [];

    if (order) {
      for (const key in order) {
        if (Object.prototype.hasOwnProperty.call(order, key)) {
          const option = sortOption?.find(
            (elem) => elem.name === key && elem.direction === order[key],
          );

          if (option) {
            arr.push({
              label: option.label,
              name: option.name,
              type: 'order',
            });
          }
        }
      }
    }

    if (filter) {
      for (const key in filter) {
        if (Object.prototype.hasOwnProperty.call(filter, key)) {
          const option = filterOptions?.find((elem) => elem.name === key);

          if (option) {
            arr.push({
              label: filterValueTypeToString(option, filter[key]),
              name: option.name,
              type: 'filter',
            });
          }
        }
      }
    }

    setTags(arr);
  }, [filter, filterOptions, order, sortOption]);

  const removeTag = useCallback(
    (option: TagOptions) => {
      if (option.type === 'filter' && filter) {
        updateQuery({ filter: { ...filter, [option.name]: null } });
      } else {
        updateQuery({ [option.type]: { [option.name]: null } });
      }
    },
    [filter, updateQuery],
  );

  const renderViewTag = useMemo(
    () =>
      tags?.map((tag) => (
        <Tag
          closable={true}
          onClose={() => removeTag(tag)}
          key={`tag_${tag.name}`}
        >
          {tag.label}
        </Tag>
      )),
    [removeTag, tags],
  );

  return (
    <Space direction="horizontal" wrap className="client-view__view-container">
      {renderViewTag}
    </Space>
  );
};

export default UIViewTag;
