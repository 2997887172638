import { useEffect } from 'react';

import { Form, Spin } from 'antd';

import { useAppDispatch } from '@store/index';
import { clearStore } from '@store/Media';
import { updateTaxesRequest } from '@store/Setting';

import TaxesEditForm from './Form';
import { FormTaxes } from './Form/index.type';
import { Props } from './index.type';

const TaxesEdit = ({ taxes, loading }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormTaxes>();

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, [dispatch]);

  const onFormSubmitHandler = (values: FormTaxes) => {
    dispatch(
      updateTaxesRequest({
        ...values,
        id: taxes.id,
      }),
    );
  };

  return (
    <div className="taxes-profile taxes-edit">
      <Spin spinning={loading}>
        <TaxesEditForm
          taxes={taxes}
          form={form}
          onFormSubmit={onFormSubmitHandler}
        />
      </Spin>
    </div>
  );
};

export default TaxesEdit;
