/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import { ColumnsType } from 'antd/lib/table';

import { FilterOption } from '@entities/Filter';
import { FullOrder } from '@entities/Order';
import { SortOption } from '@entities/Sort';
import { FullUser } from '@entities/User';
import { LocalStorage } from '@services/storage';
import type { AppDispatch } from '@store/index';
import { clearUserListStore, getListUserRequest } from '@store/User';
import { UICellLink, UICellStatus, UICellTooltip, UITag } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_FULL_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_LONG_TEXT,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  COLUMN_WIDTH_STATUS,
  DateHelper,
  FORMAT_DATE,
  FORMAT_DATE_TIME_WITH_DOT,
  FilterTab,
  LIMIT_DEFAULT,
  LINK_ORDER,
  LINK_USERS,
  ProcessStatus,
  Role,
  StringHelper,
  TransactionStatus,
  processStatusOptions,
} from '@utils';

import { OrderUIActions } from '../ui';

const today = new Date();

export const tab: FilterTab[] = [
  {
    label: 'All',
    options: {
      page: 1,
      limit: LIMIT_DEFAULT,
    },
  },
  {
    label: 'Today',
    options: {
      filter: {
        created_at: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    },
  },
  {
    label: 'Yesterday',
    options: {
      filter: {
        created_at: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -1,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -1,
          }),
        },
      },
    },
  },
  {
    label: 'Request',
    options: {
      filter: {
        status: ProcessStatus.VERIFICATION_REQUIRED,
      },
    },
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderActionColumn = (
  dispatch?: AppDispatch,
): ColumnsType<FullOrder & { newStatus: ProcessStatus }> => {
  if (dispatch) {
    return [
      {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        width: COLUMN_WIDTH_ACTIONS,
        render: (_value: FullOrder, order: FullOrder) =>
          OrderUIActions({ ...order, dispatch }),
      },
    ];
  }

  return [];
};
const renderStatus = () => {
  const activeTab = LocalStorage.get('tab');

  if (activeTab !== 'Request') {
    return [
      {
        title: 'Status',
        dataIndex: 'newStatus',
        key: 'newStatus',
        width: COLUMN_WIDTH_STATUS,
        render: (value: ProcessStatus) =>
          UITag({
            value,
            values: processStatusOptions,
          }),
      },
    ];
  }

  return [];
};

export const columns = ({
  dispatch,
}: {
  dispatch?: AppDispatch;
}): ColumnsType<FullOrder & { newStatus: ProcessStatus }> => [
  {
    title: 'Id',
    key: 'number',
    render: (value: FullOrder) =>
      UICellLink({
        link: `/${LINK_ORDER}/${value.id}`,
        value: value.id,
      }),
    width: COLUMN_WIDTH_ID,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    width: COLUMN_WIDTH_NAME,
    render: (value: FullUser) =>
      UICellLink({
        value: value?.profile?.fullName || 'no name',
        link: `/${LINK_USERS}/${value?.id}`,
        className: 'p-0',
      }),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    ellipsis: {
      showTitle: false,
    },
    width: COLUMN_WIDTH_LONG_TEXT,
    render: (_, record) =>
      UICellTooltip({
        text: record.address?.formattedAddress || 'not specified',
      }),
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: COLUMN_WIDTH_FULL_DATE,
    render: (value: string) =>
      DateHelper.formateDateToString(value, FORMAT_DATE_TIME_WITH_DOT),
  },
  ...renderStatus(),
  {
    title: 'Transaction',
    dataIndex: 'paymentTransaction',
    key: 'paymentTransaction',
    width: COLUMN_WIDTH_STATUS,
    render: (value, order) =>
      order?.paymentMethod === 'cash'
        ? '-'
        : UICellStatus({
            value: !!value,
            text: value ? 'paid for' : 'not paid',
          }),
  },
  {
    title: 'Tips',
    dataIndex: 'tips',
    key: 'tips',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: number) => StringHelper.toMoney(value, 2),
  },
  {
    title: 'Taxes',
    width: COLUMN_WIDTH_NUMBER,
    render: (_, order: FullOrder) =>
      StringHelper.toMoney(
        (order.exciseTax || 0) +
          (order.salesTax || 0) +
          (order.businessTax || 0),
        2,
      ),
  },
  // {
  //   title: 'Excise Tax',
  //   dataIndex: 'exciseTax',
  //   key: 'exciseTax',
  //   width: COLUMN_WIDTH_NUMBER,
  //   render: (value: number) => StringHelper.toMoney(value, 2),
  // },
  // {
  //   title: 'Sales tax',
  //   dataIndex: 'salesTax',
  //   key: 'salesTax',
  //   width: COLUMN_WIDTH_NUMBER,
  //   render: (value: number) => StringHelper.toMoney(value, 2),
  // },
  // {
  //   title: 'Business tax',
  //   dataIndex: 'businessTax',
  //   key: 'businessTax',
  //   width: COLUMN_WIDTH_NUMBER,
  //   render: (value: number) => StringHelper.toMoney(value, 2),
  // },
  {
    title: 'Delivery fee',
    dataIndex: 'deliveryFee',
    key: 'deliveryFee',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: number) => StringHelper.toMoney(value, 2),
  },
  {
    title: 'Price',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: number) => StringHelper.toMoney(value, 2),
  },
  ...renderActionColumn(dispatch),
];

export const sortOption = (): SortOption[] => [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  {
    label: 'Tips (ascending)',
    name: 'tips',
    direction: 'asc',
  },
  {
    label: 'Tips (descending)',
    name: 'tips',
    direction: 'desc',
  },
  {
    label: 'Total (ascending)',
    name: 'totalPrice',
    direction: 'asc',
  },
  {
    label: 'Total (descending)',
    name: 'totalPrice',
    direction: 'desc',
  },
  {
    label: 'Create date (oldest first)',
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: 'Create date (newest first)',
    name: 'created_at',
    direction: 'desc',
  },
];

const renderActionFilter = (dispatch: AppDispatch | undefined) => {
  if (dispatch) {
    return [
      {
        name: 'userIds',
        label: 'User',
        type: 'radio-select',
        className: 'scroll-y h200px w100',
        load: {
          get: (query: string) =>
            dispatch(
              getListUserRequest(`${query}&filter[role]=${Role.USER}`, false),
            ),
          clear: () => dispatch(clearUserListStore(Role.USER)),
          store: 'user',
          listName: ['userCustomer', 'list'],
        },
      },
      {
        name: 'driverIds',
        label: 'Driver',
        type: 'radio-select',
        className: 'scroll-y h200px w100',
        load: {
          get: (query: string) =>
            dispatch(
              getListUserRequest(
                `${query}&filter[role]=${Role.DRIVERS}`,
                false,
              ),
            ),
          clear: () => dispatch(clearUserListStore(Role.DRIVERS)),
          store: 'user',
          listName: ['userDriver', 'list'],
        },
      },
    ];
  }

  return [];
};

export const filterOptions = (dispatch: AppDispatch) =>
  [
    {
      name: 'created_at',
      label: 'Create date',
      type: 'radio-range',
      options: [
        {
          label: 'Today',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
        {
          label: 'Last 7 days',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
              amountDays: -7,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
        {
          label: 'Last 30 days',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
              amountDays: -30,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
      ],
    },
    {
      name: 'tips',
      label: 'Amount tips',
      type: 'number-range',
      step: 1,
      min: 0,
    },
    {
      name: 'totalPrice',
      label: 'Amount paid',
      type: 'number-range',
      step: 1,
      min: 0,
    },
    {
      name: 'shipping_method',
      label: 'Delivery method',
      type: 'radio',
      options: [
        {
          label: 'Pickup',
          value: 'pickup',
        },
        {
          label: 'Delivery',
          value: 'delivery',
        },
      ],
    },
    // {
    //   name: 'payment_method',
    //   label: 'Payment method',
    //   type: 'radio',
    //   options: [
    //     {
    //       label: 'Card',
    //       value: 'card',
    //     },
    //     {
    //       label: 'Cash',
    //       value: 'cash',
    //     },
    //   ],
    // },
    ...renderActionFilter(dispatch),
    {
      name: 'status',
      label: 'Status',
      type: 'radio',
      exception: 'canceled_by_whom',
      options: [
        // {
        //   label: StringHelper.capitalize(
        //     `${TransactionStatus.APPROVED}(${ProcessStatus.APPROVED})`,
        //   ),
        //   value: ProcessStatus.APPROVED,
        // },
        {
          label: StringHelper.capitalize(
            `${TransactionStatus.AWAITING_PICKUP}(${ProcessStatus.AWAITING_PICKUP})`,
          ),
          value: ProcessStatus.AWAITING_PICKUP,
        },
        {
          label: StringHelper.capitalize(TransactionStatus.COMPLETED),
          value: ProcessStatus.COMPLETED,
        },
        {
          label: StringHelper.capitalize(TransactionStatus.DELIVERED),
          value: ProcessStatus.DELIVERED,
        },
        {
          label: StringHelper.capitalize(
            `${TransactionStatus.PENDING_DRIVER}(${ProcessStatus.PENDING_DRIVER})`,
          ),
          value: ProcessStatus.PENDING_DRIVER,
        },
        {
          label: StringHelper.capitalize(TransactionStatus.SHIPPED),
          value: ProcessStatus.SHIPPED,
        },
        {
          label: StringHelper.capitalize(
            TransactionStatus.VERIFICATION_REQUIRED,
          ),
          value: ProcessStatus.VERIFICATION_REQUIRED,
        },
      ],
    },
    {
      name: 'canceled_by_whom',
      label: 'Status by whom',
      type: 'radio',
      exception: 'status',
      options: [
        {
          label: StringHelper.capitalize(TransactionStatus.CANCELED_BY_DRIVER),
          value: ProcessStatus.CANCELED_BY_DRIVER,
        },
        {
          label: StringHelper.capitalize(
            TransactionStatus.CANCELED_BY_CUSTOMER,
          ),
          value: ProcessStatus.CANCELED_BY_CUSTOMER,
        },
        {
          label: StringHelper.capitalize(TransactionStatus.CANCELED_BY_ADMIN),
          value: ProcessStatus.CANCELED_BY_ADMIN,
        },
      ],
    },
  ] as FilterOption[];
