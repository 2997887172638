export const GET_LIST_BRAND_REQUEST = '@brand/GET_LIST_BRAND_REQUEST';
export const GET_LIST_BRAND_SUCCESS = '@brand/GET_LIST_BRAND_SUCCESS';
export const GET_LIST_BRAND_FAILURE = '@brand/GET_LIST_BRAND_FAILURE';

export const CREATE_BRAND_REQUEST = '@brand/CREATE_BRAND_REQUEST';
export const CREATE_BRAND_SUCCESS = '@brand/CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_FAILURE = '@brand/CREATE_BRAND_FAILURE';

export const GET_ONE_BRAND_REQUEST = '@brand/GET_ONE_BRAND_REQUEST';
export const GET_ONE_BRAND_SUCCESS = '@brand/GET_ONE_BRAND_SUCCESS';
export const GET_ONE_BRAND_FAILURE = '@brand/GET_ONE_BRAND_FAILURE';

export const DELETE_BRAND_REQUEST = '@brand/DELETE_BRAND_REQUEST';
export const DELETE_BRAND_SUCCESS = '@brand/DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_FAILURE = '@brand/DELETE_BRAND_FAILURE';

export const UPDATE_BRAND_REQUEST = '@brand/UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = '@brand/UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_FAILURE = '@brand/UPDATE_BRAND_FAILURE';

export const CLEAR_BRAND = '@brand/CLEAR_BRAND';
export const CLEAR_IMAGE_TO_BRAND = '@brand/CLEAR_IMAGE_TO_BRAND';
export const CLEAR_BACKGROUND_TO_BRAND = '@brand/CLEAR_BACKGROUND_TO_BRAND';
export const CLEAR_LIST_BRANDS = '@brand/CLEAR_LIST_BRANDS';
