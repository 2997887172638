import { FullAddress, ILocation } from '@entities/Address';
import { FullMedia } from '@entities/Media';
import { OrderProducts } from '@entities/Product';
import { FullProfile } from '@entities/User';
import { ProcessStatus, Role } from '@utils';

export type OrderUser = {
  avatar?: FullMedia;
  driverLicenseNumber: string;
  email: string | null;
  id: number;
  isActive?: boolean;
  isFree?: boolean;
  isOnline: boolean;
  location?: ILocation;
  phone: string;
  profile: FullProfile;
  role: Role;
};

export type OrderBasket = {
  businessTax: number | null;
  countOfPack: number | null;
  countOfProduct: number | null;
  deliveryFee: number | null;
  discountPrice: number | null;
  exciseTax: number | null;
  rewardAmount: number | null;
  salesTax: number | null;
  subTotalPrice: number | null;
  totalPrice: number | null;
};

export type Order = {
  address: FullAddress | null;
  businessTax: number;
  canceledByWhom: ProcessStatus;
  change: number;
  comment: string;
  createdAt: string;
  deliveryFee: number;
  discountPrice: number;
  driver: OrderUser;
  driverLicense: {
    folderName: string;
    id: number;
    mimeType: string | null;
    name: string | null;
    path: string;
    size: number | null;
    thumbnailPath: string;
  };
  exciseTax: number;
  paymentMethod: string;
  paymentTransaction: string | null;
  products?: OrderProducts[];
  salesTax: number;
  shippingMethod: string;
  status: ProcessStatus;
  subTotalPrice: number;
  tip: number;
  tips: number;
  totalPaid: number;
  totalPrice: number;
  updatedAt?: string;
  updatedById?: number | null;
  user: OrderUser;
};

export type FullOrder = Id & Order;

export type PaymentToken = {
  orderId: number | null;
  totalPrice: number | string | null;
};

export enum OrderEvent {
  ORDER_SUBSCRIBE = 'order:notification',
}
