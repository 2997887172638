import { Space } from 'antd';
import Text from 'antd/lib/typography/Text';

import { DashboardUIProgress } from '@features/Dashboard/ui';
import { LayoutContainerWhite } from '@layouts';

import { Props } from './index.type';

import './style.scss';

const UIDashboardInfo = (props: Props) => {
  const {
    title,
    span = 4,
    offset,
    flex,
    value,
    prevValue,
    group,
    percent,
    last = true,
  } = props;

  return (
    <LayoutContainerWhite
      title={title}
      span={span}
      flex={flex}
      offset={offset}
      className="dashboard-info"
    >
      <Space direction="vertical">
        <Text strong className="dashboard-info__value">
          {value}
          {percent && '%'}
        </Text>
        {last ? (
          <Space size="small" direction="horizontal" wrap={false}>
            <DashboardUIProgress value={value} prevValue={prevValue} />
            <Text type="secondary">Last {group}</Text>
          </Space>
        ) : null}
      </Space>
    </LayoutContainerWhite>
  );
};

export default UIDashboardInfo;
