/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UserProps } from '@entities/User';
import { LayoutContent } from '@layouts';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import {
  clearSupport,
  getOneSupportRequest,
  setDialogId,
} from '@store/Support';
import { UIHeader, UIOverview } from '@ui';

import './style.scss';
import { SupportDetailMessageList } from './Message';
import SupportDetailSend from './Send';

const SupportDetail = ({ maxRows = 6 }) => {
  const dispatch = useAppDispatch();
  const { messageList, meta, loading, chat, status } = useSelector(
    (state: RootState) => state.support,
  );
  const { supportId } = useParams<'supportId'>();

  useEffect(() => {
    if (supportId) {
      dispatch(getOneSupportRequest(+supportId));
      dispatch(setDialogId(+supportId));
    }
  }, [dispatch, supportId]);

  useEffect(() => {
    if (supportId) {
      localStorage.setItem('supportId', supportId);
    }

    return () => {
      dispatch(clearSupport());
      LocalStorage.clear('supportId');
    };
  }, [dispatch]);

  const users = (): UserProps[] => {
    if (!chat) {
      return [];
    }

    return [
      {
        id: chat.user.id,
        role: chat.user.role,
        firstName: chat.user.profile.firstName,
        lastName: chat.user.profile.lastName,
        avatar: chat.user.avatar?.path,
      },
    ].filter((i) => i);
  };

  const content = (
    <div className="chat">
      <SupportDetailMessageList
        chat={chat}
        status={status}
        messages={messageList || []}
        meta={meta}
      />
      <div className="chat-newMessage">
        <SupportDetailSend maxRows={maxRows} />
      </div>
    </div>
  );

  const aside = (
    <>
      <UIOverview users={users()} />
    </>
  );

  return (
    <>
      <UIHeader title="Support" isBack />
      <LayoutContent
        content={content}
        loadingContent={loading}
        loadingAside={loading}
        aside={aside}
      />
    </>
  );
};

export default SupportDetail;
