import { useEffect } from 'react';

import { Form, Input } from 'antd';

import { DateHelper, FORMAT_SHORT_DATE, VALIDATE_MAX_PHONE } from '@utils';

import { WidgetModalUIImage } from '../../ui';
import { INITIAL_VALUES } from './constant';
import { FormValues, Props } from './index.type';

const WidgetModalProfileForm = ({
  user,
  avatar,
  onFormSubmit,
  form,
  onUploadImage,
  onRemoveImage,
}: Props) => {
  useEffect(() => {
    if (user) {
      const { email, profile, phone } = user;

      form.setFieldsValue({ email, phone, profile });
    }
  }, [user, form]);

  const handlerFinish = (values: FormValues) => {
    onFormSubmit({
      ...values,
      ...(avatar && { avatar }),
      profile: {
        ...values.profile,
      },
    });
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      labelAlign="right"
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
    >
      <WidgetModalUIImage
        view="avatar"
        path={avatar?.path}
        onUploadImage={onUploadImage}
        onRemoveImage={onRemoveImage}
      />
      <Form.Item
        label="First Name"
        name={['profile', 'firstName']}
        required
        rules={[{ required: true, message: 'Please input First Name!' }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        label="Last name"
        name={['profile', 'lastName']}
        required
        rules={[{ required: true, message: 'Please input Last Name!' }]}
      >
        <Input placeholder="Last name" />
      </Form.Item>
      {/* <Form.Item label="Password" name="password">
        <Input.Password placeholder="Password" autoComplete="off" />
      </Form.Item> */}
      <Form.Item
        label="Email"
        name="email"
        required
        rules={[
          {
            required: true,
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
        ]}
      >
        <Input placeholder="Email" autoComplete="off" />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone"
        rules={[{ max: VALIDATE_MAX_PHONE }]}
      >
        <Input placeholder="Phone" />
      </Form.Item>
      <Form.Item label="Reg Date">
        <Input
          value={DateHelper.formateDateToString(
            user.createdAt,
            FORMAT_SHORT_DATE,
          )}
          disabled
        />
      </Form.Item>
    </Form>
  );
};

export default WidgetModalProfileForm;
