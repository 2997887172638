import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullMetrc } from '@entities/Csv';
import { CsvHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';

import {
  createCsvFailure,
  createCsvSuccess,
  deleteCsvFailure,
  deleteCsvSuccess,
  exportCsvFailure,
  exportCsvSuccess,
  getListCsvFailure,
  getListCsvRequest,
  getListCsvSuccess,
  updateCsvFailure,
} from './Csv.action';
import {
  CREATE_CSV_REQUEST,
  DELETE_CSV_REQUEST,
  EXPORT_CSV_REQUEST,
  GET_LIST_CSV_REQUEST,
  UPDATE_CSV_REQUEST,
} from './Csv.constant';
import { IExportCsvRequest, IGetListCsvRequest } from './Csv.type';

function* workerGetListCsv(action: IGetListCsvRequest) {
  try {
    const { query } = action.payload;
    const payload: DataResponse<FullMetrc[]> = yield call(
      CsvHttp.getListCsv,
      query,
    );

    yield put(getListCsvSuccess(payload));
  } catch (error) {
    yield put(getListCsvFailure());
    yield put(alertError(error));
  }
}
function* watchGetListCsv() {
  yield takeLatest(GET_LIST_CSV_REQUEST, workerGetListCsv);
}

function* workerCreateCsv() {
  // action: ICreateCsvRequest
  try {
    // const data = action.payload;

    // yield call(CsvHttp.createBrand, data);

    yield put(createCsvSuccess());
    yield put(getListCsvRequest());
    yield put(hideModal());
  } catch (error) {
    yield put(createCsvFailure());
    yield put(alertError(error));
  }
}
function* watchCreateCsv() {
  yield takeLatest(CREATE_CSV_REQUEST, workerCreateCsv);
}

function* workerDeleteCsv() {
  // action: IDeleteCsvRequest
  try {
    // const { id } = action.payload;

    // yield call(CsvHttp.deleteCsv, id);

    yield put(deleteCsvSuccess());
  } catch (error) {
    yield put(deleteCsvFailure());
    yield put(alertError(error));
  }
}
function* watchDeleteCsv() {
  yield takeLatest(DELETE_CSV_REQUEST, workerDeleteCsv);
}

function* workerUpdateCsv() {
  // action: IUpdateCsvRequest
  try {
    // const { id, ...body } = action.payload;

    // const payload: FullCsv = yield call(CsvHttp.updateBrand, id, body);

    // yield put(updateCsvSuccess(payload));
    yield put(alertSuccess('Review successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateCsvFailure());
    yield put(alertError(error));
  }
}
function* watchUpdateCsv() {
  yield takeLatest(UPDATE_CSV_REQUEST, workerUpdateCsv);
}

function* workerExportCsv(action: IExportCsvRequest) {
  try {
    yield call(CsvHttp.exportCsv, action.payload);

    yield put(exportCsvSuccess());
    // yield put(alertSuccess('Review successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(exportCsvFailure());
    yield put(alertError(error));
  }
}
function* watchExportCsv() {
  yield takeLatest(EXPORT_CSV_REQUEST, workerExportCsv);
}

export const csvWatchers: ForkEffect[] = [
  fork(watchCreateCsv),
  fork(watchGetListCsv),
  fork(watchDeleteCsv),
  fork(watchUpdateCsv),
  fork(watchExportCsv),
];
