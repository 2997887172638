import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UserProps } from '@entities/User';
import { LayoutContent } from '@layouts';
import { clearChat, getOneChatRequest } from '@store/Chat';
import { RootState, useAppDispatch } from '@store/index';
import { getOneOrderRequest } from '@store/Order';
import { UIHeader, UIOverview } from '@ui';
import { ActionStatus, Role } from '@utils';

import './style.scss';
import { infoList } from '../constant';
import { ChatDetailMessageList } from './Message';

const ChatDetail = () => {
  const dispatch = useAppDispatch();
  const { messageList, meta, loading, chat, status } = useSelector(
    (state: RootState) => state.chat,
  );
  const { order } = useSelector((state: RootState) => state.order);
  const { chatId } = useParams<'chatId'>();

  useEffect(() => {
    if (chatId) {
      dispatch(getOneChatRequest(+chatId));
    }
  }, [dispatch, chatId]);

  useEffect(() => {
    return () => {
      dispatch(clearChat());
    };
  }, [dispatch]);

  useEffect(() => {
    if (chat && chat.orderId) {
      dispatch(getOneOrderRequest(chat.orderId));
    }
  }, [dispatch, chat]);

  useEffect(() => {
    if (status === ActionStatus.DELETED) {
      window.history.back();
    }
  }, [status]);

  const users = (): UserProps[] => {
    if (!(chat && chat.driver)) {
      return [];
    }

    const { driver, user } = chat;

    return [
      {
        id: driver.id,
        role: Role.DRIVERS,
        firstName: driver.profile.firstName,
        lastName: driver.profile.lastName,
        avatar: driver.avatar?.path,
      },
      {
        id: user.id,
        role: Role.USER,
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        avatar: user.avatar?.path,
      },
    ].filter((i) => i);
  };

  const content = (
    <div className="chat">
      <ChatDetailMessageList
        chat={chat}
        messages={messageList || []}
        meta={meta}
      />
    </div>
  );

  const aside = (
    <>
      <UIOverview
        {...(order && { infoList: infoList(order) })}
        users={users()}
        date={chat?.createdAt}
      />
    </>
  );

  return (
    <>
      <UIHeader title="Chat" isBack />
      <LayoutContent
        content={content}
        loadingContent={loading}
        loadingAside={loading}
        aside={aside}
      />
    </>
  );
};

export default ChatDetail;
