import { useCallback, useEffect } from 'react';

import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { FullCategory } from '@entities/Category';
import { useQuery } from '@hooks';
import { clearListCategory, getListCategoryRequest } from '@store/Category';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { UIHeader, UITable } from '@ui';
import { LIMIT_DEFAULT } from '@utils';

import { columns, filterOptions, orderOption } from './constants';

const CategoryList = () => {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const { query, updateQuery } = useQuery();
  const { categoryList, loading, meta } = useSelector(
    (state: RootState) => state.category,
  );
  const navigate = useNavigate();

  const getRequest = useCallback(() => {
    dispatch(clearListCategory());
    dispatch(getListCategoryRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    if (search === '') {
      updateQuery({ page: 1, limit: LIMIT_DEFAULT });
    } else {
      getRequest();
    }

    return () => {
      dispatch(clearListCategory());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest]);

  const onCreateCategory = useCallback(() => {
    dispatch(
      showModal({
        modalType: ModalType.CREATE_CATEGORY,
        modalProps: { type: 'create' },
      }),
    );
  }, [dispatch]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  const headerButtons = (
    <Button size="large" type="primary" onClick={onCreateCategory}>
      Add category
    </Button>
  );

  return (
    <>
      <UIHeader
        title="Category"
        isSearch={true}
        buttons={headerButtons}
        sortOption={orderOption}
        filterOptions={filterOptions}
        searchTitle="Search categories"
      />
      <Row>
        <UITable
          dataSource={categoryList || []}
          loading={loading}
          meta={meta}
          columns={columns({ path: pathname, dispatch })}
          onPaginationChange={onPaginationChange}
          onRow={(item: FullCategory) => navigate(`${pathname}/${item?.id}`)}
          // isScrollDefault
          adaptive={false}
        />
      </Row>
    </>
  );
};

export default CategoryList;
