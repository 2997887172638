/* eslint-disable indent */
import { Image, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { UIImage } from '@ui';

import { Props } from './index.type';

import './style.scss';

const ProductDetailDiscription = ({ loading, product }: Props) => {
  const booleanToString = (bol: boolean | undefined) => (bol ? 'Yes' : 'No');

  return (
    <Spin size="large" spinning={loading}>
      <div className="card-products-info-product">
        <div className="card-products-info-product-header">
          <div>
            <Title level={5}>Image</Title>
            <UIImage path={product?.image?.path || 'error'} />
          </div>
        </div>
        <div className="card-products-info-product-line"></div>
        <div className="card-products-info-product-content">
          <Title level={5}>Name of the products</Title>
          <Text>{product?.name}</Text>
        </div>
        <div className="card-products-info-product-line"></div>
        <div className="card-products-info-product-content">
          <Title level={5}>Brand</Title>
          <Text>{product?.brand?.name}</Text>
        </div>
        <div className="card-products-info-product-line"></div>
        <div className="card-products-info-product-content">
          <Title level={5}>Category</Title>
          <Text>{product?.category?.name}</Text>
        </div>
        <div className="card-products-info-product-line"></div>
        <div className="card-products-info-product-content">
          <Title level={5}>Description</Title>
          <Text>{product?.description}</Text>
        </div>
        <div className="card-products-info-product-line"></div>
        <div className="card-products-info-product-content">
          <Title level={5}>Effect</Title>
          <div className="card-products-info-product-effect">
            {product?.effects?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="card-products-info-product-effect__item"
                >
                  <Title level={5}>{item.name}</Title>
                  {item.image?.path ? (
                    <Image height={100} src={item.image?.path} />
                  ) : null}
                  <span>{item.description}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="card-products-info-product-line"></div>
        {/* <div className="card-products-info-product-content">
          <Title level={5}>Shops</Title>
          <div className="card-products-info-product-effect">
            {product?.stores
              ? product?.stores?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="card-products-info-product-effect__item"
                    >
                      <Title level={5}>{item.store.name}</Title>
                      <span>Quantity: {item.quantity}</span>
                    </div>
                  );
                })
              : 'Not in stores'}
          </div>
        </div> */}
        <div className="card-products-info-product-line"></div>
        <div className="card-products-info-product-content">
          <Title level={5}>is fastest</Title>
          <Text>{booleanToString(product?.isFastest)}</Text>
        </div>
        <div className="card-products-info-product-line"></div>
        <div className="card-products-info-product-content">
          <Title level={5}>is most popular</Title>
          <Text>{booleanToString(product?.isMostPopular)}</Text>
        </div>
        <div className="card-products-info-product-line"></div>
        <div className="card-products-info-product-content">
          <Title level={5}>is today deals</Title>
          <Text>{booleanToString(product?.isTodayDeals)}</Text>
        </div>
        <div className="card-products-info-product-line"></div>
        <div className="card-products-info-product-content">
          <Title level={5}>is promotion</Title>
          <Text>{booleanToString(product?.isPromotion)}</Text>
        </div>
      </div>
    </Spin>
  );
};

export default ProductDetailDiscription;
