import { useEffect } from 'react';

import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './style.scss';

import { LayoutContent } from '@layouts';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import {
  clearPromoCode,
  deletePromoCodeRequest,
  getOnePromoCodeRequest,
} from '@store/PromoCode';
import { UIButton, UIHeader } from '@ui';
import { ActionStatus, Button as ButtonType } from '@utils';

import PromoCodeDetailDiscription from './Discription';
import PromoCodeEdit from './Edit';

const PromoCodeDetail = () => {
  const dispatch = useAppDispatch();
  const { status, promoCode, loading } = useSelector(
    (state: RootState) => state.promoCode,
  );
  const { promoCodeId } = useParams<'promoCodeId'>();

  useEffect(() => {
    if (promoCodeId) {
      dispatch(clearPromoCode());
      dispatch(getOnePromoCodeRequest({ id: +promoCodeId }));
    }
  }, [promoCodeId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearPromoCode());
    };
  }, [dispatch]);

  useEffect(() => {
    if (status === ActionStatus.DELETED) {
      window.history.back();
    }
  }, [status]);

  // const onChangeVisible = () => {
  //   if (promoCodesId) {
  //     dispatch(
  //       updatePromoCodeRequest({
  //         id: +promoCodesId,
  //         isActive: !promoCode?.isActive,
  //       }),
  //     );
  //   }
  // };

  const aside = [
    // <Row justify="space-between" align="middle" className="visible-wrapper">
    //   <Text strong>Is active</Text>
    //   <Switch checked={promoCodes?.isActive} onChange={onChangeVisible} />
    // </Row>,
    <PromoCodeDetailDiscription promoCode={promoCode} loading={loading} />,
    <UIButton
      className="delete-promoCode-button"
      children="Delete PromoCode"
      size="middle"
      type={ButtonType.ANT_BUTTON}
      prop={{ danger: true }}
      handler={() => {
        if (promoCodeId) {
          dispatch(
            showModal({
              modalType: ModalType.CONFIRM,
              modalProps: {
                content: <p>Are you sure you want to delete the PromoCode</p>,
                action: () => dispatch(deletePromoCodeRequest(+promoCodeId)),
              },
            }),
          );
        }
      }}
    />,
  ];

  const content = [
    promoCode && <PromoCodeEdit promoCode={promoCode} loading={loading} />,
  ];

  return (
    <Space direction="vertical">
      <UIHeader isBack title={promoCode?.code} />
      <LayoutContent content={content} aside={aside} loadingAside={loading} />
    </Space>
  );
};

export default PromoCodeDetail;
