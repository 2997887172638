import { Form, Spin } from 'antd';

import { useAppDispatch } from '@store/index';
import { updatePromoCodeRequest } from '@store/PromoCode';
import { DateHelper, FORMAT_DATE } from '@utils';

import PromoCodeEditForm from './Form';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';
import './style.scss';

const PromoCodeEdit = ({ promoCode, loading }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  const onFormSubmitHandler = (values: FormValues) => {
    dispatch(
      updatePromoCodeRequest({
        ...values,
        validUntil: DateHelper.formateDateToString(
          values.validUntil,
          FORMAT_DATE,
        ),
        id: +promoCode.id,
      }),
    );
  };

  return (
    <div className="promoCode-edit card-promoCode-info-promoCode">
      <Spin spinning={loading}>
        <PromoCodeEditForm
          promoCode={promoCode}
          form={form}
          onFormSubmit={onFormSubmitHandler}
        />
      </Spin>
    </div>
  );
};

export default PromoCodeEdit;
