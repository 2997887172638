import { Route, Routes } from 'react-router-dom';

import User from '@features/User';
import UserDetail from '@features/User/Detail';

const ViewUsers = () => {
  return (
    <Routes>
      <Route path=":userId" element={<UserDetail />} />
      <Route path="/" element={<User />} />
    </Routes>
  );
};

export default ViewUsers;
