import { Dispatch } from 'redux';
import { Socket } from 'socket.io-client';

import { FullSupport, SupportEvent } from '@entities/Support';
import { addNotification } from '@store/Profile';
import { setMessageReceive } from '@store/Support';
import { NotificationType } from '@utils';

export const initSubscribe = (params: {
  dispatch: Dispatch;
  socket: Socket;
}) => {
  const { socket, dispatch } = params;

  socket.on(SupportEvent.MESSAGE_RECEIVE, (data: { data: FullSupport }) => {
    dispatch(setMessageReceive({ chat: data.data }));
    if (!localStorage.getItem('supportId')) {
      dispatch(
        addNotification({
          createdAt: data.data.createdAt,
          id: data.data.id,
          text: data.data.message.text,
          type: NotificationType.SUPPORT,
        }),
      );
    }
  });
};
