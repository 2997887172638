import { useEffect, useState } from 'react';

import { Col, Form, Row, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useSelector } from 'react-redux';

import './style.scss';

import { FullMedia } from '@entities/Media';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { updateProductRequest } from '@store/Product';
import {
  DateHelper,
  FORMAT_DATE_TIME_WITH_DOT,
  FileName,
  UploadFile,
} from '@utils';

import ProductEditForm from './Form';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';

const ProductEdit = ({ product, loadingProduct }: Props) => {
  const [imageFile, setImageFile] = useState<FullMedia | null>(null);
  const [promotionImageFile, setPromotionImageFile] =
    useState<FullMedia | null>(null);
  const {
    loading: loadingImage,
    name: nameFile,
    file: fileStore,
  } = useSelector((state: RootState) => state.media);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (fileStore) {
      switch (nameFile) {
        case 'image': {
          setImageFile(fileStore);
          break;
        }
        case 'promotionImage': {
          setPromotionImageFile(fileStore);
          break;
        }
        default:
      }
    }
  }, [dispatch, fileStore, nameFile]);

  const onFormSubmitHandler = (values: FormValues) => {
    dispatch(updateProductRequest({ ...values, id: product.id }));
  };

  const onUploadImageHandler = (
    file: UploadFile['file'],
    name?: string | null,
  ) => {
    dispatch(
      uploadMediaRequest({
        file,
        fileName: FileName.IMAGE,
        ...(name && { name }),
      }),
    );
  };

  useEffect(() => {
    if (product.image) {
      setImageFile(product.image);
    }
    if (product?.promotionImage) {
      setPromotionImageFile(product?.promotionImage);
    }
  }, [product.image, product?.promotionImage]);

  const onRemoveImageHandler = (name?: string) => {
    dispatch(clearStore());
    switch (name) {
      case 'image': {
        setImageFile(null);
        break;
      }
      case 'promotionImage': {
        setPromotionImageFile(null);
        break;
      }
      default:
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, [dispatch]);

  return (
    <div className="product-edit card-products-info-product">
      <Spin spinning={loadingProduct || loadingImage}>
        <ProductEditForm
          product={product}
          image={imageFile}
          promotionImage={promotionImageFile}
          form={form}
          onFormSubmit={onFormSubmitHandler}
          onUploadImage={onUploadImageHandler}
          onRemoveImage={onRemoveImageHandler}
        />
        {product.metrc && (
          <Row>
            <Col span={12}>
              <div>
                <Text strong>Tag: </Text>
                {product.metrc.tag}
              </div>
              <div>
                <Text strong>Source Package(s): </Text>
                {product.metrc.sourcePackages}
              </div>
              <div>
                <Text strong>Item: </Text>
                {product.metrc.item}
              </div>
              <div>
                <Text strong>Item Strain: </Text>
                {product.metrc.itemStrain}
              </div>
              <div>
                <Text strong>Production Batch Number: </Text>
                {product.metrc.productionBatchNumber}
              </div>
              <div>
                <Text strong>Administrative Hold: </Text>
                {product.metrc.administrativeHold}
              </div>
              <div>
                <Text strong>Received: </Text>
                {product.metrc.received}
              </div>
              {product?.metrc?.updatedAt && (
                <div>
                  <Text strong>Updated at: </Text>
                  {DateHelper.formateDateToString(
                    product?.metrc?.updatedAt,
                    FORMAT_DATE_TIME_WITH_DOT,
                  )}
                </div>
              )}
            </Col>
            <Col span={12}>
              <div>
                <Text strong>Source Harvest(s): </Text>
                {product.metrc.sourceHarvests}
              </div>
              <div>
                <Text strong>Source Processing Job(s): </Text>
                {product.metrc.sourceProcessingJobs}
              </div>
              <div>
                <Text strong>Category: </Text>
                {product.metrc.category}
              </div>
              <div>
                <Text strong>Quantity: </Text>
                {product.metrc.quantity}
              </div>
              <div>
                <Text strong>Lab Testing: </Text>
                {product.metrc.labTesting}
              </div>
              <div>
                <Text strong>Packaged Date: </Text>
                {product?.metrc?.packagedDate}
              </div>
              {product?.metrc?.createdAt && (
                <div>
                  <Text strong>Created at: </Text>
                  {DateHelper.formateDateToString(
                    product?.metrc?.createdAt,
                    FORMAT_DATE_TIME_WITH_DOT,
                  )}
                </div>
              )}
            </Col>
          </Row>
        )}
      </Spin>
    </div>
  );
};

export default ProductEdit;
