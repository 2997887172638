import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import DashboardUITooltip from '../../Tooltip';
import { Props } from './index.type';
import DashboardUIChartAreaTick from './Tick';

const DashboardUIChartArea = <T,>({
  data,
  dataKey,
  theme = 'blue',
  XAxisDataKey,
  fullInterval,
  syncId,
  tooltipValue,
  tooltipLabelType,
}: Props<T>) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        syncId={syncId}
        margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
      >
        <defs>
          <linearGradient
            id="purpleGradient"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
            gradientTransform="rotate(-90)"
          >
            <stop offset="0%" stopColor="#3751FF88" stopOpacity={0.3} />
            <stop offset="90%" stopColor="#3751FF00" stopOpacity={0.2} />
          </linearGradient>
          <linearGradient id="blueGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#109CF166" stopOpacity={0.6} />
            <stop offset="85%" stopColor="#109CF100" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey={`${XAxisDataKey}`}
          stroke="transparent"
          tick={<DashboardUIChartAreaTick fullInterval={fullInterval} />}
          height={60}
          interval={fullInterval ? 0 : data.length / 5}
          // crossOrigin={'anonymous'}
        />
        <YAxis
          orientation="right"
          stroke="transparent"
          tick={{ fill: '#8C8C8C' }}
        />
        <CartesianGrid vertical={false} />
        <Tooltip
          content={
            <DashboardUITooltip
              tooltipValue={tooltipValue}
              tooltipLabelType={tooltipLabelType}
            />
          }
        />
        <Area
          type="monotone"
          dataKey={`${dataKey}`}
          stroke="#109CF1"
          strokeWidth={2}
          fillOpacity={1}
          fill={`url(#${theme}Gradient)`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default DashboardUIChartArea;
