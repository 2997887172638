import { Route, Routes } from 'react-router-dom';

import { CategoryDetail, CategoryList } from '@features/Category';

const ViewCategory = () => {
  return (
    <Routes>
      <Route path=":categoryId" element={<CategoryDetail />} />
      <Route path="/" element={<CategoryList />} />
    </Routes>
  );
};

export default ViewCategory;
