import { useEffect } from 'react';

import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './style.scss';

import { LayoutContent } from '@layouts';
import {
  clearBrand,
  deleteBrandRequest,
  getOneBrandRequest,
} from '@store/Brand';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { UIButton, UIHeader } from '@ui';
import { ActionStatus, Button as ButtonType } from '@utils';

import BrandDetailDiscription from './Discription';
import BrandEdit from './Edit';

const BrandDetail = () => {
  const dispatch = useAppDispatch();
  const { status, brand, loading } = useSelector(
    (state: RootState) => state.brand,
  );
  const { brandId } = useParams<'brandId'>();

  useEffect(() => {
    if (brandId) {
      dispatch(getOneBrandRequest({ id: +brandId }));
    }
  }, [brandId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearBrand());
    };
  }, [dispatch]);

  useEffect(() => {
    if (status === ActionStatus.DELETED) {
      window.history.back();
    }
  }, [status]);

  // const onChangeVisible = () => {
  //   if (brandId) {
  //     dispatch(
  //       updateBrandRequest({
  //         id: +brandId,
  //         isActive: !brand?.isActive,
  //       }),
  //     );
  //   }
  // };

  const aside = [
    // <Row justify="space-between" align="middle" className="visible-wrapper">
    //   <Text strong>Visible</Text>
    //   <Switch checked={brand?.isActive} onChange={onChangeVisible} />
    // </Row>,
    <BrandDetailDiscription brand={brand} loading={loading} />,
    <UIButton
      className="delete-brand-button"
      children="Delete Brand"
      size="middle"
      type={ButtonType.ANT_BUTTON}
      prop={{ danger: true }}
      handler={() => {
        if (brandId) {
          dispatch(
            showModal({
              modalType: ModalType.CONFIRM,
              modalProps: {
                content: <p>Are you sure you want to delete the Brand</p>,
                action: () => dispatch(deleteBrandRequest(+brandId)),
              },
            }),
          );
        }
      }}
    />,
  ];

  const content = [brand && <BrandEdit brand={brand} loadingBrand={loading} />];

  return (
    <Space direction="vertical">
      <UIHeader isBack title={brand?.name} />
      <LayoutContent content={content} aside={aside} loadingAside={loading} />
    </Space>
  );
};

export default BrandDetail;
