import { FullShop } from '@entities/Shop';

import {
  CLEAR_IMAGE_TO_SHOP,
  CLEAR_LIST_SHOP,
  CLEAR_SHOP,
  CREATE_SHOP_FAILURE,
  CREATE_SHOP_REQUEST,
  CREATE_SHOP_SUCCESS,
  DELETE_SHOP_FAILURE,
  DELETE_SHOP_REQUEST,
  DELETE_SHOP_SUCCESS,
  GET_LIST_SHOP_FAILURE,
  GET_LIST_SHOP_REQUEST,
  GET_LIST_SHOP_SUCCESS,
  GET_ONE_SHOP_FAILURE,
  GET_ONE_SHOP_REQUEST,
  GET_ONE_SHOP_SUCCESS,
  UPDATE_SHOP_FAILURE,
  UPDATE_SHOP_REQUEST,
  UPDATE_SHOP_SUCCESS,
} from './Shop.constant';
import { ShopActionsTypes } from './Shop.type';

export function getListShopRequest(query?: Query['query']): ShopActionsTypes {
  return {
    type: GET_LIST_SHOP_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListShopSuccess(
  payload: DataResponse<FullShop[]>,
): ShopActionsTypes {
  return {
    type: GET_LIST_SHOP_SUCCESS,
    payload,
  };
}

export function getListShopFailure(): ShopActionsTypes {
  return {
    type: GET_LIST_SHOP_FAILURE,
  };
}

export function createShopRequest(
  payload: Omit<FullShop, 'id' | 'isActive'>,
): ShopActionsTypes {
  return {
    type: CREATE_SHOP_REQUEST,
    payload,
  };
}

export function createShopSuccess(payload: FullShop): ShopActionsTypes {
  return {
    type: CREATE_SHOP_SUCCESS,
    payload,
  };
}

export function createShopFailure(): ShopActionsTypes {
  return {
    type: CREATE_SHOP_FAILURE,
  };
}

export function deleteShopRequest(id: FullShop['id']): ShopActionsTypes {
  return {
    type: DELETE_SHOP_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteShopSuccess(payload: FullShop['id']): ShopActionsTypes {
  return {
    type: DELETE_SHOP_SUCCESS,
    payload,
  };
}

export function deleteShopFailure(): ShopActionsTypes {
  return {
    type: DELETE_SHOP_FAILURE,
  };
}

export function getOneShopRequest({
  id,
  toList,
}: {
  id: number;
  toList?: boolean;
}): ShopActionsTypes {
  return {
    type: GET_ONE_SHOP_REQUEST,
    payload: {
      id,
      toList,
    },
  };
}

export function getOneShopSuccess({
  data,
  toList,
}: {
  data: FullShop;
  toList?: boolean;
}): ShopActionsTypes {
  return {
    type: GET_ONE_SHOP_SUCCESS,
    payload: {
      data,
      toList,
    },
  };
}

export function getOneShopFailure(): ShopActionsTypes {
  return {
    type: GET_ONE_SHOP_FAILURE,
  };
}

export function updateShopRequest(
  payload: Pick<FullShop, 'id'> & Partial<FullShop>,
): ShopActionsTypes {
  return {
    type: UPDATE_SHOP_REQUEST,
    payload,
  };
}

export function updateShopSuccess(payload: FullShop): ShopActionsTypes {
  return {
    type: UPDATE_SHOP_SUCCESS,
    payload,
  };
}

export function updateShopFailure(): ShopActionsTypes {
  return {
    type: UPDATE_SHOP_FAILURE,
  };
}

export function clearShop(): ShopActionsTypes {
  return {
    type: CLEAR_SHOP,
  };
}

export function clearListShop(): ShopActionsTypes {
  return {
    type: CLEAR_LIST_SHOP,
  };
}

export function clearImageToShop(): ShopActionsTypes {
  return {
    type: CLEAR_IMAGE_TO_SHOP,
  };
}
