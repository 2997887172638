import { useCallback, useEffect } from 'react';

import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { FullOrder } from '@entities/Order';
import { useQuery } from '@hooks';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, hideModal, showModal } from '@store/Modal';
import { clearListOrder, getListOrderRequest } from '@store/Order';
import { clearUserListStore } from '@store/User';
import { UIHeader, UITable } from '@ui';
import { ActionStatus, LIMIT_DEFAULT, LINK_ORDER, ProcessStatus } from '@utils';

import { columns, filterOptions, sortOption, tab } from './constant';

const OrderList = () => {
  const { orderList, meta, loading, status } = useSelector(
    (state: RootState) => state.order,
  );
  const { modalType } = useSelector((state: RootState) => state.modal);

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();

  const { query, updateQuery } = useQuery();

  useEffect(() => {
    dispatch(clearUserListStore());
  }, [dispatch, modalType]);

  useEffect(() => {
    return () => {
      dispatch(clearListOrder());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRequest = useCallback(() => {
    dispatch(clearListOrder());
    dispatch(getListOrderRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    if (status === ActionStatus.UPDATED) {
      getRequest();
      dispatch(hideModal());
    }
  }, [dispatch, getRequest, status]);

  useEffect(() => {
    if (search === '') {
      updateQuery({ page: 1, limit: LIMIT_DEFAULT });
    } else {
      getRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  const onCreateOrder = useCallback(() => {
    dispatch(
      showModal({
        modalType: ModalType.CREATE_ORDER,
        modalProps: { type: 'create' },
      }),
    );
  }, [dispatch]);

  const headerButtons = (
    <Button size="large" type="primary" onClick={onCreateOrder}>
      Add order
    </Button>
  );

  return (
    <>
      <UIHeader
        tab={tab}
        title="Order"
        sortOption={sortOption()}
        filterOptions={filterOptions(dispatch)}
        isCalendar
        calendarField="created_at"
        calendarPlaceholder={['Start date min', 'Start date max']}
        buttons={headerButtons}
      />
      <Row>
        <UITable
          dataSource={
            orderList?.map((item) => ({
              ...item,
              newStatus:
                item.status === ProcessStatus.CANCELED
                  ? item.canceledByWhom || item.status
                  : item.status,
            })) || []
          }
          pageSizeOptions={[10, 20, 50]}
          loading={loading}
          columns={columns({ dispatch })}
          onRow={(item: FullOrder) => navigate(`/${LINK_ORDER}/${item?.id}`)}
          meta={meta}
          onPaginationChange={onPaginationChange}
          // isScrollDefault
        />
      </Row>
    </>
  );
};

export default OrderList;
