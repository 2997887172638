import axios, { CancelTokenSource } from 'axios';

import { FullEffect } from '@entities/Effect';
import { http } from '@utils';

const basePath = '/api/effects';
let axiosSource: CancelTokenSource | null = null;

export const getList = async (
  query?: Query['query'],
  isCancel = true,
): Promise<{
  data: FullEffect[];
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<FullEffect[]>>(
    `${basePath}${query || ''}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};

export const create = async (
  body: Pick<FullEffect, 'name' | 'description'>,
) => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullEffect>>(`${basePath}`, body);

  return data;
};

export const remove = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const getOne = async (id: FullEffect['id']): Promise<FullEffect> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullEffect>>(`${basePath}/${id}`);

  return data;
};

export const update = async (
  id: number,
  body: Partial<FullEffect>,
): Promise<FullEffect> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullEffect>>(`${basePath}/${id}`, body);

  return data;
};
