import {
  CLEAR_LIST_STATISTICS,
  GET_LIST_STATISTIC_FAILURE,
  GET_LIST_STATISTIC_REQUEST,
  GET_LIST_STATISTIC_SUCCESS,
} from './Statistic.constant';
import { IStatisticState, StatisticActionsTypes } from './Statistic.type';

const initialState: IStatisticState = {
  statistic: null,
  loading: false,
};

export default function StatisticReducer(
  state = initialState,
  action: StatisticActionsTypes,
): IStatisticState {
  switch (action.type) {
    case GET_LIST_STATISTIC_REQUEST:
      return {
        ...state,
        loading: true,
        statistic: null,
      };

    case GET_LIST_STATISTIC_SUCCESS:
      return {
        ...state,
        statistic: action.payload.data,
        // statistic: [...(state.statistic || []), ...action.payload.data],
        loading: false,
      };
    case GET_LIST_STATISTIC_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_LIST_STATISTICS: {
      return {
        ...state,
        statistic: null,
      };
    }
    default:
      return state;
  }
}
