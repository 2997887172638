export const SET_DIALOG_ID = '@chat/SET_DIALOG_ID';

export const GET_LIST_CHAT_REQUEST = '@chat/GET_LIST_CHAT_REQUEST';
export const GET_LIST_CHAT_SUCCESS = '@chat/GET_LIST_CHAT_SUCCESS';
export const GET_LIST_CHAT_FAILURE = '@chat/GET_LIST_CHAT_FAILURE';

export const GET_LIST_MESSAGE_REQUEST = '@chat/GET_LIST_MESSAGE_REQUEST';
export const GET_LIST_MESSAGE_SUCCESS = '@chat/GET_LIST_MESSAGE_SUCCESS';
export const GET_LIST_MESSAGE_FAILURE = '@chat/GET_LIST_MESSAGE_FAILURE';

export const GET_ONE_CHAT_REQUEST = '@chat/GET_ONE_CHAT_REQUEST';
export const GET_ONE_CHAT_SUCCESS = '@chat/GET_ONE_CHAT_SUCCESS';
export const GET_ONE_CHAT_FAILURE = '@chat/GET_ONE_CHAT_FAILURE';

export const CLEAR_CHAT = '@chat/CLEAR_CHAT';
export const CLEAR_MESSAGE = '@chat/CLEAR_MESSAGE';
