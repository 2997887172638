import { Login } from '@entities/Auth';
import { http } from '@utils';

const basePath = '/api/auth';

export const login = async (body: Login): Promise<void> => {
  await http.post<DataResponse<Token>>(`${basePath}/login`, body);
};

export const logout = async (): Promise<void> => {
  await http.post(`${basePath}/logout`);
};
