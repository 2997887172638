import { all } from 'redux-saga/effects';

import { authWatchers } from './Auth';
import { brandWatchers } from './Brand';
import { categoryWatchers } from './Category';
import { chatWatchers } from './Chat';
import { commentWatchers } from './Comment';
import { csvWatchers } from './Csv';
import { effectWatchers } from './Effect';
import { mediaWatchers } from './Media';
import { orderWatchers } from './Order';
import { productWatchers } from './Product';
import { profileWatchers } from './Profile';
import { promoCodeWatchers } from './PromoCode';
import { reviewWatchers } from './Review';
import { scheduleWatchers } from './Schedule';
import { settingWatchers } from './Setting';
import { shopWatchers } from './Shop';
import { statisticWatchers } from './Statistic';
import { supportWatchers } from './Support';
import { transactionWatchers } from './Transaction';
import { userWatchers } from './User';

export function* rootSagas() {
  const watchers = [
    ...authWatchers,
    ...chatWatchers,
    ...supportWatchers,
    ...profileWatchers,
    ...userWatchers,
    ...orderWatchers,
    ...transactionWatchers,
    ...commentWatchers,
    ...mediaWatchers,
    ...productWatchers,
    ...brandWatchers,
    ...shopWatchers,
    ...categoryWatchers,
    ...scheduleWatchers,
    ...effectWatchers,
    ...reviewWatchers,
    ...promoCodeWatchers,
    ...csvWatchers,
    ...settingWatchers,
    ...statisticWatchers,
  ];

  yield all(watchers);
}
