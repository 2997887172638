import { useEffect } from 'react';

import { Button, Col, Form, Input, Row, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { en } from '@i18n';
import { UIImageUpload } from '@ui';

import { INITIAL_VALUES } from './constant';
import { FormValues, Props } from './index.type';

const EffectEditForm = ({
  onFormSubmit,
  form,
  effect,
  onUploadImage,
  image,
  onRemoveImage,
}: Props) => {
  useEffect(() => {
    if (effect) {
      form.setFieldsValue(effect);
    }
  }, [effect, form]);

  const handlerFinish = (values: FormValues) => {
    onFormSubmit({
      ...values,
      image,
    });
  };

  const imageWrap = {
    labelCol: { span: 0, xxl: 7 },
    wrapperCol: { span: 24, xxl: 17 },
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ xxl: 4, span: 5 }}
      wrapperCol={{ xxl: 17, span: 19 }}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      validateMessages={en.validation}
    >
      <Row justify="center">
        <Col span={12}>
          <Form.Item {...imageWrap} name="image" label="Image">
            <UIImageUpload
              view="image"
              label="Image"
              path={image?.path || 'error'}
              onUploadImage={onUploadImage}
              onRemoveImage={onRemoveImage}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="name" label="Name of the effect" required>
        <Input placeholder="Enter the effect" />
      </Form.Item>
      <Form.Item label="Visible" name="isActive" required>
        <Switch defaultChecked={effect?.isActive} />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <TextArea showCount rows={4} placeholder="Enter the description" />
      </Form.Item>
      <div className="effect-edit__save-button">
        <Button
          key="Save changes"
          type="primary"
          onClick={() => form.submit()}
          size="large"
          className="edit-form__save-button"
        >
          Save changes
        </Button>
      </div>
    </Form>
  );
};

export default EffectEditForm;
