import { useCallback, useEffect, useState } from 'react';

import { Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@hooks';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import {
  clearListReview,
  getListReviewBrandRequest,
  getListReviewUserRequest,
} from '@store/Review';
import { UIHeader, UITable } from '@ui';
import { LIMIT_DEFAULT } from '@utils';

import {
  columnsBrand,
  columnsUser,
  filterOptions,
  sortOption,
} from '../constant';
import { ReviewBrandUIButton } from '../ui';

export const ReviewList = () => {
  const dispatch = useAppDispatch();
  const { search, pathname } = useLocation();
  const { query, updateQuery } = useQuery();
  const { reviewListBrand, reviewListUser, meta, loading } = useSelector(
    (state: RootState) => state.review,
  );
  const [tabsActive, setTabsActive] = useState<string | null>(null);
  const tabsLocal = LocalStorage.get('tabs');

  const getRequest = useCallback(() => {
    dispatch(clearListReview());
    switch (tabsActive) {
      case 'user': {
        dispatch(getListReviewUserRequest(query));
        break;
      }
      default: {
        dispatch(getListReviewBrandRequest(query));
        break;
      }
    }
  }, [dispatch, tabsActive, query]);

  useEffect(() => {
    if (search === '') {
      updateQuery({ page: 1, limit: LIMIT_DEFAULT });
    } else {
      getRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, dispatch, tabsActive]);

  useEffect(() => {
    return () => {
      dispatch(clearListReview());
    };
  }, [dispatch]);

  useEffect(() => {
    if (tabsLocal) {
      setTabsActive(tabsLocal as string);
    } else {
      LocalStorage.set('tabs', 'brand');
      setTabsActive('brand');
    }
  }, [tabsLocal]);

  useEffect(() => {
    LocalStorage.set('isBack', pathname + query);
  }, [pathname, query]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  const tabs = [
    {
      title: 'Brand',
      key: 'brand',
    },
    {
      title: 'User',
      key: 'user',
    },
  ];

  return (
    <>
      <UIHeader
        title="Review"
        tabs={tabs}
        buttons={(() => {
          switch (tabsActive) {
            case 'user': {
              return null;
              // return ReviewUserUIButton();
            }
            default:
              return ReviewBrandUIButton();
          }
        })()}
        sortOption={sortOption()}
        filterOptions={filterOptions({ dispatch, tabsActive })}
        onClickTabs={(e) => {
          updateQuery({ page: 1, limit: LIMIT_DEFAULT });
          setTabsActive((e as string).split('tabs_')[1]);
        }}
        {...(tabsActive && { activeTab: `tabs_${tabsActive}` })}
      />
      <Row>
        {(() => {
          switch (tabsActive) {
            case 'user': {
              return (
                <UITable
                  dataSource={reviewListUser || []}
                  loading={loading}
                  columns={columnsUser({ dispatch })}
                  meta={meta}
                  onPaginationChange={onPaginationChange}
                  // isScrollDefault
                />
              );
            }
            default:
              return (
                <UITable
                  dataSource={reviewListBrand || []}
                  loading={loading}
                  columns={columnsBrand({ dispatch })}
                  meta={meta}
                  onPaginationChange={onPaginationChange}
                  // isScrollDefault
                />
              );
          }
        })()}
      </Row>
    </>
  );
};
