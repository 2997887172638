import { LayoutMenu } from '@layouts';
import { ModalType, showModal } from '@store/Modal';
import { deleteUserRequest } from '@store/User';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const UserUIActions = ({ id, user, query, dispatch }: Props) => {
  const onEditClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.USER,
        modalProps: { userId: id, query },
      }),
    );
  };

  const onDeleteUserHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: (
            <p>
              Are you sure you want to delete the Client
              <b> {user?.profile?.fullName || ''}</b>?
            </p>
          ),
          action: () => dispatch(deleteUserRequest({ id })),
        },
      }),
    );
  };

  // const onDeactivateUserHandler = (e: React.MouseEvent<HTMLElement>) => {
  //   e.stopPropagation();

  //   dispatch(
  //     updateUserRequest({
  //       id,
  //       body: { isActive: !user.isActive },
  //     }),
  //   );
  // };

  return UICellAction({
    dropdownMenu: LayoutMenu({
      buttons: [
        {
          title: 'Delete the user',
          onClick: (e) => onDeleteUserHandler(e),
        },
        // {
        //   title: `${user?.isActive ? 'Deactivate' : 'Activate'} the user`,
        //   onClick: (e) => onDeactivateUserHandler(e),
        // },
      ],
    }),
    buttons: [
      {
        title: 'Edit',
        onClick: (e: React.MouseEvent<HTMLElement>) => onEditClickHandler(e),
      },
    ],
  });
};

export default UserUIActions;
