import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { Referral } from '@entities/Referral';
import { FullReward } from '@entities/Reward';
import { FullTaxes } from '@entities/Taxes';
import { ReferralHttp, RewardHttp, TaxesHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';

import {
  createRewardFailure,
  createRewardSuccess,
  createTaxesFailure,
  createTaxesSuccess,
  deleteRewardFailure,
  deleteRewardSuccess,
  deleteTaxesFailure,
  deleteTaxesSuccess,
  getListRewardFailure,
  getListRewardSuccess,
  getListTaxesFailure,
  getListTaxesSuccess,
  getOneReferralFailure,
  getOneReferralSuccess,
  getOneRewardFailure,
  getOneRewardSuccess,
  getOneTaxesFailure,
  getOneTaxesSuccess,
  updateReferralFailure,
  updateReferralSuccess,
  updateRewardFailure,
  updateRewardSuccess,
  updateTaxesFailure,
  updateTaxesSuccess,
} from './Setting.action';
import {
  CREATE_REWARD_REQUEST,
  CREATE_TAXES_REQUEST,
  DELETE_REWARD_REQUEST,
  DELETE_TAXES_REQUEST,
  GET_LIST_REWARD_REQUEST,
  GET_LIST_TAXES_REQUEST,
  GET_ONE_REFERRAL_REQUEST,
  GET_ONE_REWARD_REQUEST,
  GET_ONE_TAXES_REQUEST,
  UPDATE_REFERRAL_REQUEST,
  UPDATE_REWARD_REQUEST,
  UPDATE_TAXES_REQUEST,
} from './Setting.constant';
import {
  ICreateRewardRequest,
  ICreateTaxesRequest,
  IDeleteRewardRequest,
  IDeleteTaxesRequest,
  IGetListRewardRequest,
  IGetListTaxesRequest,
  IGetOneRewardRequest,
  IGetOneTaxesRequest,
  IUpdateReferralRequest,
  IUpdateRewardRequest,
  IUpdateTaxesRequest,
} from './Setting.type';

function* workerGetListReward(action: IGetListRewardRequest) {
  try {
    const { query } = action.payload;
    const data: DataResponse<FullReward[]> = yield call(
      RewardHttp.getList,
      query,
    );

    yield put(getListRewardSuccess(data));
  } catch (error) {
    yield put(getListRewardFailure());
    yield put(alertError(error));
  }
}

function* workerCreateReward(action: ICreateRewardRequest) {
  try {
    const data: FullReward = yield call(RewardHttp.create, action.payload);

    yield put(createRewardSuccess(data));
    yield put(alertSuccess('Reward added successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(createRewardFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteReward(action: IDeleteRewardRequest) {
  try {
    const { id } = action.payload;

    yield call(RewardHttp.remove, id);

    yield put(deleteRewardSuccess(id));
    yield put(alertSuccess('Reward successfully deleted!'));
  } catch (error) {
    yield put(deleteRewardFailure());
    yield put(alertError(error));
  }
}

function* workerGetOneReward(action: IGetOneRewardRequest) {
  try {
    const { id, toList } = action.payload;
    const payload: FullReward = yield call(RewardHttp.getOne, id);

    yield put(getOneRewardSuccess({ data: payload, toList }));
  } catch (error) {
    yield put(getOneRewardFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateReward(action: IUpdateRewardRequest) {
  try {
    const { id, ...body } = action.payload;

    const data: FullReward = yield call(RewardHttp.update, id, body);

    yield put(updateRewardSuccess(data));
    yield put(alertSuccess('Reward successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateRewardFailure());
    yield put(alertError(error));
  }
}

function* workerGetListTaxes(action: IGetListTaxesRequest) {
  try {
    const { query } = action.payload;
    const data: DataResponse<FullTaxes[]> = yield call(
      TaxesHttp.getList,
      query,
    );

    yield put(getListTaxesSuccess(data));
  } catch (error) {
    yield put(getListTaxesFailure());
    yield put(alertError(error));
  }
}

function* workerCreateTaxes(action: ICreateTaxesRequest) {
  try {
    const data: FullTaxes = yield call(TaxesHttp.create, action.payload);

    yield put(createTaxesSuccess(data));
    yield put(alertSuccess('Taxes added successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(createTaxesFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteTaxes(action: IDeleteTaxesRequest) {
  try {
    const { id } = action.payload;

    yield call(TaxesHttp.remove, id);

    yield put(deleteTaxesSuccess(id));
    yield put(alertSuccess('Taxes successfully deleted!'));
  } catch (error) {
    yield put(deleteTaxesFailure());
    yield put(alertError(error));
  }
}

function* workerGetOneTaxes(action: IGetOneTaxesRequest) {
  try {
    const { id, toList } = action.payload;
    const payload: FullTaxes = yield call(TaxesHttp.getOne, id);

    yield put(getOneTaxesSuccess({ data: payload, toList }));
  } catch (error) {
    yield put(getOneTaxesFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateTaxes(action: IUpdateTaxesRequest) {
  try {
    const { id, ...body } = action.payload;

    const data: FullTaxes = yield call(TaxesHttp.update, id, body);

    yield put(updateTaxesSuccess(data));
    yield put(alertSuccess('Taxes successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateTaxesFailure());
    yield put(alertError(error));
  }
}

function* workerGetOneReferral() {
  try {
    const payload: Referral = yield call(ReferralHttp.getOne);

    yield put(getOneReferralSuccess({ data: payload }));
  } catch (error) {
    yield put(getOneReferralFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateReferral(action: IUpdateReferralRequest) {
  try {
    const data: Referral = yield call(ReferralHttp.update, action.payload);

    yield put(updateReferralSuccess(data));
    yield put(alertSuccess('Referral successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateReferralFailure());
    yield put(alertError(error));
  }
}

function* watchUpdateReward() {
  yield takeLatest(UPDATE_REWARD_REQUEST, workerUpdateReward);
}

function* watchGetOneReward() {
  yield takeLatest(GET_ONE_REWARD_REQUEST, workerGetOneReward);
}

function* watchDeleteReward() {
  yield takeLatest(DELETE_REWARD_REQUEST, workerDeleteReward);
}

function* watchCreateReward() {
  yield takeLatest(CREATE_REWARD_REQUEST, workerCreateReward);
}

function* watchGetListReward() {
  yield takeLatest(GET_LIST_REWARD_REQUEST, workerGetListReward);
}

function* watchUpdateTaxes() {
  yield takeLatest(UPDATE_TAXES_REQUEST, workerUpdateTaxes);
}

function* watchGetOneTaxes() {
  yield takeLatest(GET_ONE_TAXES_REQUEST, workerGetOneTaxes);
}

function* watchDeleteTaxes() {
  yield takeLatest(DELETE_TAXES_REQUEST, workerDeleteTaxes);
}

function* watchCreateTaxes() {
  yield takeLatest(CREATE_TAXES_REQUEST, workerCreateTaxes);
}

function* watchGetListTaxes() {
  yield takeLatest(GET_LIST_TAXES_REQUEST, workerGetListTaxes);
}

function* watchUpdateReferral() {
  yield takeLatest(UPDATE_REFERRAL_REQUEST, workerUpdateReferral);
}

function* watchGetOneReferral() {
  yield takeLatest(GET_ONE_REFERRAL_REQUEST, workerGetOneReferral);
}

export const settingWatchers: ForkEffect[] = [
  fork(watchGetListReward),
  fork(watchCreateReward),
  fork(watchDeleteReward),
  fork(watchGetOneReward),
  fork(watchUpdateReward),
  fork(watchGetOneReferral),
  fork(watchUpdateReferral),
  fork(watchGetListTaxes),
  fork(watchCreateTaxes),
  fork(watchDeleteTaxes),
  fork(watchGetOneTaxes),
  fork(watchUpdateTaxes),
];
