import { useCallback, useEffect } from 'react';

import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { FullEffect } from '@entities/Effect';
import { useQuery } from '@hooks';
import { clearListEffect, getListEffectRequest } from '@store/Effect';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { UIHeader, UITable } from '@ui';
import { LIMIT_DEFAULT } from '@utils';

import { columns, filterOptions, orderOption } from './constants';

const EffectList = () => {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const { query, updateQuery } = useQuery();
  const { effectList, loading, meta } = useSelector(
    (state: RootState) => state.effect,
  );
  const navigate = useNavigate();

  const getRequest = useCallback(() => {
    dispatch(clearListEffect());
    dispatch(getListEffectRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    if (search === '') {
      updateQuery({ page: 1, limit: LIMIT_DEFAULT });
    } else {
      getRequest();
    }

    return () => {
      dispatch(clearListEffect());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest]);

  const onCreateEffect = useCallback(() => {
    dispatch(
      showModal({
        modalType: ModalType.CREATE_EFFECT,
        modalProps: { type: 'create' },
      }),
    );
  }, [dispatch]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  const headerButtons = (
    <Button size="large" type="primary" onClick={onCreateEffect}>
      Add effect
    </Button>
  );

  return (
    <>
      <UIHeader
        title="Effect"
        isSearch={true}
        buttons={headerButtons}
        sortOption={orderOption}
        filterOptions={filterOptions}
        searchTitle="Search effect"
      />
      <Row>
        <UITable
          dataSource={effectList || []}
          loading={loading}
          meta={meta}
          columns={columns({ path: pathname, dispatch })}
          onPaginationChange={onPaginationChange}
          onRow={(item: FullEffect) => navigate(`${pathname}/${item?.id}`)}
          // isScrollDefault
          adaptive={false}
        />
      </Row>
    </>
  );
};

export default EffectList;
