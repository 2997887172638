import { Route, Routes } from 'react-router-dom';

import Settings from '@features/Settings';
import SettingsRewardDetail from '@features/Settings/DetailReward';
import SettingsTaxDetail from '@features/Settings/DetailTax';

const ViewSettings = () => {
  return (
    <Routes>
      <Route path="/tax/:settingsId" element={<SettingsTaxDetail />} />
      <Route path="/reward/:settingsId" element={<SettingsRewardDetail />} />
      <Route path="/" element={<Settings />} />
    </Routes>
  );
};

export default ViewSettings;
