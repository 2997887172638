import axios, { CancelTokenSource } from 'axios';

import { Statistic } from '@entities/Statistic';
import { http } from '@utils';

const basePath = '/api/statistics';
let axiosSource: CancelTokenSource | null = null;

export const getList = async (
  query?: Query['query'],
  isCancel = true,
): Promise<{
  data: Statistic;
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<Statistic>>(
    `${basePath}${query || ''}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};
