import { ColumnsType } from 'antd/lib/table';

import { FullDriversList } from '@entities/User';
import { AppDispatch } from '@store/index';
import { UICellFullName, UICellLink, UICellStatus, UICellTooltip } from '@ui';
import {
  COLUMN_WIDTH_ACTIVE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  COLUMN_WIDTH_QUANTITY,
  LINK_USERS,
} from '@utils';

import { DriverUIActionQuantity } from '../ui';

export const columns = ({
  dispatch,
}: {
  dispatch: AppDispatch;
}): ColumnsType<FullDriversList & Id> => {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) =>
        UICellLink({ value, link: `/${LINK_USERS}/${record.id}` }),
      width: COLUMN_WIDTH_ID,
    },
    {
      title: 'Full Name',
      width: COLUMN_WIDTH_NAME,
      render: (_, { driver }) =>
        UICellFullName({
          lastName: driver?.profile?.lastName,
          firstName: driver?.profile?.firstName,
          src: driver?.avatar?.path,
          link: `/${LINK_USERS}/${driver?.id}`,
        }),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value, record) =>
        DriverUIActionQuantity({
          productId: record?.productId,
          id: record?.driverId,
          quantity: +value,
          name: record?.driver?.profile?.fullName,
          dispatch,
          text: value,
        }),
      width: COLUMN_WIDTH_QUANTITY,
    },
    // {
    //   title: 'Address',
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   width: COLUMN_WIDTH_NAME,
    //   render: (_, { driver }) =>
    //     UICellTooltip({
    //       text:
    //         driver?.addresses?.find((i) => i.isDefault)?.formattedAddress ||
    //         'not specified',
    //     }),
    // },
    {
      title: 'Phone',
      width: COLUMN_WIDTH_NUMBER,
      render: (_, { driver }) =>
        UICellTooltip({
          text: driver?.phone || 'not phone',
        }),
    },
    {
      title: 'Verify by Admin',
      width: COLUMN_WIDTH_ACTIVE,
      render: (_, { driver }) =>
        UICellStatus({
          value: driver?.isVerified,
          text: driver?.isVerified ? 'Verified' : 'Unverified',
        }),
    },
    // {
    //   title: 'Confirmed Email',
    //   width: COLUMN_WIDTH_TAG,
    //   render: (_, { driver }) =>
    //     UICellStatus({
    //       value: driver?.isConfirmedEmail,
    //       text: driver?.isConfirmedEmail ? 'Yes' : 'No',
    //     }),
    // },
    // {
    //   title: 'Reg Date',
    //   width: COLUMN_WIDTH_DATE,
    //   render: (_, { driver }) =>
    //     DateHelper.formateDateToString(driver?.createdAt, FORMAT_SHORT_DATE),
    // },
    // {
    //   title: 'Actions',
    //   dataIndex: 'id',
    //   key: 'id',
    //   align: 'right',
    //   width: COLUMN_WIDTH_ACTIONS_SMALL,
    //   render: (id, item) =>
    //     UserUIActions({
    //       id,
    //       user: item.driver,
    //       query: '',
    //       dispatch,
    //     }),
    // },
  ];
};
