import { Fragment, useCallback, useEffect, useState } from 'react';

import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Row, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { RcFile, UploadFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ExportMetrc } from '@entities/Csv';
import { FilterRange } from '@entities/Filter';
import { FullMedia } from '@entities/Media';
import { useQuery } from '@hooks';
import { LocalStorage } from '@services/storage';
import {
  clearListCsv,
  exportCsvRequest,
  getListCsvRequest,
  updateListMetrc,
} from '@store/Csv';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { UICalendar, UIHeader, UITable } from '@ui';
import {
  DateHelper,
  FORMAT_DATE,
  FileName,
  LIMIT_DEFAULT,
  RangeValue,
} from '@utils';

import { columns } from '../constant';

// import { columns, filterOptions, sortOption } from '../constant';

export const CsvList = () => {
  const { loading, file: fileStore } = useSelector(
    (state: RootState) => state.media,
  );

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { query, updateQuery } = useQuery();
  const [fileList, setFileList] = useState<FullMedia[]>([]);
  const [tabsActive, setTabsActive] = useState<string | null>(null);
  const [period, setPeriod] = useState<FilterRange | null>(null);
  const { metrcList, isUpdate, meta, loadingExport } = useSelector(
    (state: RootState) => state.csv,
  );

  const getRequest = useCallback(() => {
    dispatch(clearListCsv());
    dispatch(getListCsvRequest(query));
    dispatch(updateListMetrc(false));
  }, [dispatch, query]);

  useEffect(() => {
    if (search === '') {
      updateQuery({ page: 1, limit: LIMIT_DEFAULT });
    } else {
      getRequest();
    }

    return () => {
      dispatch(clearListCsv());
      LocalStorage.clear('tabs');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest]);

  useEffect(() => {
    if (isUpdate) {
      getRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  useEffect(() => {
    LocalStorage.set('tabs', 'Csv');
    setTabsActive('Csv');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  const tabs = [
    {
      title: 'Csv',
    },
    {
      title: 'Xlsx',
    },
  ];

  useEffect(() => {
    if (fileStore) {
      setFileList([
        {
          ...fileStore,
          name: `${fileStore.name}${
            fileStore.date ? ` - ${fileStore.date}` : ''
          }`,
        },
      ]);
    }
  }, [fileStore]);

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, [dispatch]);

  const onUploadCsvHandler = (file: RcFile) => {
    dispatch(
      uploadMediaRequest({
        file,
        fileName: FileName.METRC,
        ...(file.name && { name: file.name }),
        alert: false,
      }),
    );
  };

  const onExportCsvHandler = () => {
    const timezoneOffset = new Date().getTimezoneOffset();

    dispatch(
      exportCsvRequest({
        ...(period && { createdAt: period }),
        timezoneOffset,
      } as ExportMetrc),
    );
  };

  const onChangeHandler = (values: RangeValue<Dayjs>) => {
    if (!values) {
      setPeriod(null);

      return;
    }

    setPeriod({
      min: DateHelper.getStartDayFormatString({
        date: values[0],
        format: FORMAT_DATE,
      }),
      max: DateHelper.getEndDayFormatString({
        date: values[1],
        format: FORMAT_DATE,
      }),
    });
  };

  const headerButtons = [
    <Fragment key={1}>
      <Text>Date range:</Text>
      <UICalendar
        placeholder={['Start date min', 'Start date max']}
        onChange={onChangeHandler}
      />
    </Fragment>,
    <Button
      style={{ marginLeft: 'auto' }}
      type="primary"
      icon={<DownloadOutlined />}
      size={'large'}
      disabled={loadingExport}
      onClick={onExportCsvHandler}
    >
      Export csv
      {/* {tabsActive === 'Csv' ? 'csv' : 'xlsx'} */}
    </Button>,
  ];

  return (
    <Spin spinning={loadingExport}>
      <UIHeader
        title="Csv/xlsx"
        buttons={headerButtons}
        tabs={tabs}
        onClickTabs={(e) => setTabsActive((e as string).split('tabs_')[1])}
        // sortOption={sortOption()}
        // filterOptions={filterOptions({ dispatch, tabsActive })}
      />
      <Row>
        <Spin spinning={loading}>
          <Dragger
            customRequest={(file) => onUploadCsvHandler(file.file as RcFile)}
            maxCount={1}
            accept={tabsActive === 'Csv' ? '.csv' : '.xlsx'}
            fileList={fileList as unknown as UploadFile<any>[]}
            onRemove={() => setFileList([])}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload (max <b>10 Mb</b>)
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Dragger>
        </Spin>
        <UITable
          dataSource={metrcList || []}
          loading={loading}
          columns={columns()}
          meta={meta}
          onPaginationChange={onPaginationChange}
          // isScrollDefault
          adaptive={false}
        />
      </Row>
    </Spin>
  );
};
