import { useCallback, useEffect, useState } from 'react';

import { Form } from 'antd';
import { useSelector } from 'react-redux';

import {
  clearListBrands,
  getListBrandRequest,
  getOneBrandRequest,
} from '@store/Brand';
import { RootState, useAppDispatch } from '@store/index';
import {
  createReviewBrandRequest,
  updateReviewBrandRequest,
} from '@store/Review';
import {
  UIButton,
  UICellRate,
  UIModal,
  UISelectFormItem,
  UITextArea,
} from '@ui';
import { Button } from '@utils';

import { Props } from './index.type';

const WidgetModalReviewBrandEdit = (props: Props) => {
  const { review } = props;

  const dispatch = useAppDispatch();

  const {
    brandList,
    loading: loadingBrand,
    meta: metaBrand,
  } = useSelector((state: RootState) => state.brand);
  const [comment, setComment] = useState<string>('');
  const [rating, setRating] = useState<number>(0);
  const [brandId, setBrandId] = useState<number | null>(null);

  useEffect(() => {
    if (review) {
      setRating(review.rating);
      setComment(review.comment);
    }
  }, [review]);

  const onAcceptHandler = useCallback(() => {
    if (review) {
      dispatch(
        updateReviewBrandRequest({
          id: (review?.id || brandId) as number,
          rating,
          comment,
        }),
      );
    } else {
      dispatch(
        createReviewBrandRequest({
          brandId: brandId as number,
          rating,
          comment,
        }),
      );
    }
  }, [dispatch, rating, review, brandId, comment]);

  const footer = [
    <UIButton
      key="Save changes"
      typeAntBtn="primary"
      handler={() => onAcceptHandler()}
      type={Button.ANT_BUTTON}
      size="large"
      className="edit-form__save-button"
    >
      {review ? 'Save' : 'Add'} review
    </UIButton>,
  ];

  return (
    <UIModal
      title="Edit review"
      footer={footer}
      visible
      onAccept={onAcceptHandler}
    >
      {!review ? (
        <UISelectFormItem
          label="Brand"
          rules={[{ required: true }]}
          required
          options={
            brandList?.map((item) => ({ name: item.name, id: item.id })) || []
          }
          getOption={(id) =>
            id &&
            dispatch(
              getOneBrandRequest({
                id: +id,
                toList: true,
              }),
            )
          }
          valueId={brandId}
          getOptions={(query) =>
            dispatch(getListBrandRequest(`${query}&filter[isActive]=true`))
          }
          onChange={(id) => setBrandId(id as unknown as number)}
          clearOptions={() => dispatch(clearListBrands())}
          meta={metaBrand}
          loading={loadingBrand}
        />
      ) : (
        <></>
      )}
      <Form.Item label="Rating">
        <UICellRate
          onChangedHandler={setRating}
          rating={rating}
          count={5}
          allowHalf={false}
        />
      </Form.Item>
      <Form.Item label="Text">
        <UITextArea rows={4} onChangeHandler={setComment} value={comment} />
      </Form.Item>
    </UIModal>
  );
};

export default WidgetModalReviewBrandEdit;
