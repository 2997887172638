import { FormTaxes } from './index.type';

export const INITIAL_VALUES: FormTaxes = {
  country: '',
  state: '',
  county: '',
  city: '',
  saleRate: null,
  businessMedicalRate: null,
  businessRecreationalRate: null,
  isActive: false,
};
