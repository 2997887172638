import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CLEAR_BACKGROUND_TO_BRAND,
  CLEAR_BRAND,
  CLEAR_IMAGE_TO_BRAND,
  CLEAR_LIST_BRANDS,
  CREATE_BRAND_FAILURE,
  CREATE_BRAND_REQUEST,
  CREATE_BRAND_SUCCESS,
  DELETE_BRAND_SUCCESS,
  GET_LIST_BRAND_FAILURE,
  GET_LIST_BRAND_REQUEST,
  GET_LIST_BRAND_SUCCESS,
  GET_ONE_BRAND_REQUEST,
  GET_ONE_BRAND_SUCCESS,
  UPDATE_BRAND_FAILURE,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
} from './Brand.constant';
import { BrandActionsTypes, IBrandState } from './Brand.type';

const initialState: IBrandState = {
  status: ActionStatus.NONE,
  brandList: null,
  meta: META_DEFAULT,
  brand: null,
  loading: false,
};

export default function BrandReducer(
  state = initialState,
  action: BrandActionsTypes,
): IBrandState {
  switch (action.type) {
    case GET_LIST_BRAND_REQUEST:
    case GET_ONE_BRAND_REQUEST:
      return {
        ...state,
        status: ActionStatus.NONE,
        loading: true,
      };

    case GET_LIST_BRAND_SUCCESS:
      return {
        ...state,
        brandList: [...(state.brandList || []), ...action.payload.data],
        meta: action.payload.meta,
        loading: false,
      };
    case GET_LIST_BRAND_FAILURE:
    case UPDATE_BRAND_FAILURE:
    case CREATE_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_BRAND_REQUEST:
    case UPDATE_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_BRAND_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.CREATED,
        loading: false,
        brandList: [action.payload, ...(state.brandList || [])],
      };
    }
    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        status: ActionStatus.UPDATED,
        loading: false,
        brand: action.payload,
        brandList: (state.brandList || []).map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      };
    case DELETE_BRAND_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.DELETED,
        loading: false,
        brandList: (state.brandList || []).filter(
          (item) => item.id !== action.payload,
        ),
      };
    }
    case GET_ONE_BRAND_SUCCESS: {
      return {
        ...state,
        brand: action.payload.data,
        loading: false,
        ...(action.payload.toList && {
          brandList: [action.payload.data, ...(state.brandList || [])],
        }),
      };
    }
    case CLEAR_BRAND: {
      return {
        ...state,
        status: ActionStatus.NONE,
        brand: null,
      };
    }
    case CLEAR_LIST_BRANDS: {
      return {
        ...state,
        brandList: null,
        meta: META_DEFAULT,
      };
    }
    case CLEAR_IMAGE_TO_BRAND: {
      return {
        ...state,
        ...(state.brand && {
          brand: { ...state.brand, logo: null },
        }),
      };
    }
    case CLEAR_BACKGROUND_TO_BRAND: {
      return {
        ...state,
        ...(state.brand && {
          brand: { ...state.brand, background: null },
        }),
      };
    }
    default:
      return state;
  }
}
