export const GET_PROFILE_REQUEST = '@profile/GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = '@profile/GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = '@profile/GET_PROFILE_FAILURE';

export const CLEAR_PROFILE = '@profile/CLEAR_PROFILE';

export const UPDATE_PROFILE_REQUEST = '@profile/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = '@profile/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = '@profile/UPDATE_PROFILE_FAILURE';

export const ADD_NOTIFICATION = '@profile/ADD_NOTIFICATION';
export const CLEAR_NOTIFICATION = '@profile/CLEAR_NOTIFICATION';
export const CLEAR_ONE_NOTIFICATION = '@profile/CLEAR_ONE_NOTIFICATION';

export const CLEAR_AVATAR_PROFILE = '@profile/CLEAR_AVATAR_PROFILE';
