/* eslint-disable no-nested-ternary */
import { Col, PageHeader, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@hooks';
import { DateHelper, FORMAT_DATE, RangeValue } from '@utils';

import UICalendar from '../Calendar';
import UIFilter from '../Filter';
import UISearch from '../Search';
import UISort from '../Sort';
import { UITabContent } from '../Tab';
import UITabFilter from '../Tab/Filter';
import { UIViewTag } from '../View';
import { Props } from './index.type';

const UIHeader = ({
  title,
  isSearch,
  sortOption,
  filterOptions,
  searchDisabled = false,
  isBack,
  searchTitle,
  buttons,
  isCalendar,
  calendarField,
  calendarPlaceholder,
  tab,
  tabs,
  defaultTitle,
  backLink,
  activeTab,
  onClickTabs,
}: Props) => {
  const {
    updateQuery,
    queryOptions: { search },
  } = useQuery({}, { encode: true });
  const navigate = useNavigate();

  const onChangeHandler = (values: RangeValue<Dayjs>) => {
    if (calendarField) {
      if (!values) {
        updateQuery({
          filter: {
            [calendarField]: null,
          },
        });

        return;
      }

      updateQuery({
        filter: {
          [calendarField]: {
            min: DateHelper.getStartDayFormatString({
              date: values[0],
              format: FORMAT_DATE,
            }),
            max: DateHelper.getEndDayFormatString({
              date: values[1],
              format: FORMAT_DATE,
            }),
          },
        },
      });
    }
  };

  const onSearchChange = (searchStr: string | null) => {
    if (searchStr?.trim()) {
      updateQuery({ search: searchStr, page: 1 });
    } else {
      updateQuery({ search: null });
    }
  };

  return (
    <>
      {(title || isBack) && (
        <>
          <PageHeader
            className="page-header"
            title={
              <Title className="page-header__title" level={3}>
                {title || 'No title'}
              </Title>
            }
            {...(isBack && {
              onBack: () => {
                if (backLink) {
                  navigate(backLink);
                } else {
                  window.history.back();
                }
              },
            })}
            extra={buttons}
          />
          {(isSearch || filterOptions || sortOption) && tab && (
            <UITabFilter tab={tab} />
          )}
        </>
      )}
      {tabs && (
        <UITabContent
          onTabClick={onClickTabs}
          tabs={tabs}
          activeTab={activeTab}
          defaultTitle={defaultTitle}
          {...(!title && { buttons })}
        />
      )}
      <Row
        className="page-header__buttons"
        justify="space-between"
        gutter={[16, 4]}
      >
        {isSearch && (
          <Col flex="auto">
            <UISearch
              placeholder={searchTitle}
              search={search}
              onSearchChange={onSearchChange}
              searchDisabled={searchDisabled}
            />
          </Col>
        )}

        <Col span={24}>
          <UIViewTag sortOption={sortOption} filterOptions={filterOptions} />
        </Col>
        {(filterOptions || sortOption || isCalendar) && (
          <>
            {isCalendar && (
              <Col style={!isSearch ? { flexGrow: 1 } : {}}>
                <UICalendar
                  placeholder={calendarPlaceholder}
                  onChange={onChangeHandler}
                />
              </Col>
            )}
            {sortOption && (
              <Col>
                <UISort sortOption={sortOption} />
              </Col>
            )}
            {filterOptions && (
              <Col>
                <UIFilter filterOptions={filterOptions} />
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default UIHeader;
