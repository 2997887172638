import { Route, Routes } from 'react-router-dom';

import { OrderDetail, OrderList } from '@features/Order';

const ViewOrder = () => {
  return (
    <Routes>
      <Route path=":orderId" element={<OrderDetail />} />
      <Route path="/" element={<OrderList />} />
    </Routes>
  );
};

export default ViewOrder;
