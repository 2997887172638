import { Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { UIImage } from '@ui';

import { Props } from './index.type';

import './style.scss';

const EffectDetailDiscription = ({ loading, effect }: Props) => {
  return (
    <Spin size="large" spinning={loading}>
      <div className="card-effects-info-effect">
        <div className="card-effects-info-effect-header">
          <div>
            <Title level={5}>Logo</Title>
            <UIImage path={effect?.image?.path || 'error'} />
          </div>
          {/* <div>
            <Link onClick={onEditClickHandler}>Edit</Link>
          </div> */}
        </div>
        <div className="card-effects-info-effect-line"></div>
        <div className="card-effects-info-effect-content">
          <Title level={5}>Name of the effects</Title>
          <Text>{effect?.name}</Text>
        </div>
        <div className="card-effects-info-effect-line"></div>
        <div className="card-effects-info-effect-content">
          <Title level={5}>Description</Title>
          <Text>{effect?.description}</Text>
        </div>
      </div>
    </Spin>
  );
};

export default EffectDetailDiscription;
