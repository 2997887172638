import { Role } from '@utils';

import { FormUser } from './index.type';

export const INITIAL_VALUES: FormUser = {
  avatar: null,
  role: Role.USER,
  email: '',
  phone: '',
  profile: {
    firstName: '',
    lastName: '',
  },
  addresses: [],
};
