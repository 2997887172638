import { useCallback, useEffect, useState } from 'react';

import { Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { FullReward } from '@entities/Reward';
import { FullTaxes } from '@entities/Taxes';
import { useQuery } from '@hooks';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import {
  clearListReward,
  clearListTaxes,
  clearReferral,
  getListRewardRequest,
  getListTaxesRequest,
  getOneReferralRequest,
} from '@store/Setting';
import { UIHeader, UITable } from '@ui';
import { LIMIT_DEFAULT } from '@utils';

import options from './constants';
import SettingsReferralDetail from './DetailReferral';

const Settings = () => {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const { query, updateQuery } = useQuery();
  const { rewardList, taxesList, meta, loading } = useSelector(
    (state: RootState) => state.setting,
  );
  const [tabsActive, setTabsActive] = useState<string | null>(null);
  const navigate = useNavigate();
  const tabsLocal = LocalStorage.get('tabs');

  const { tabs, title, sortOption, headerButtons, columns, filterOptions } =
    options({
      dispatch,
      pathname,
      query,
      tabsActive,
    });

  const getRequest = useCallback(() => {
    switch (tabsActive) {
      case 'taxes': {
        dispatch(clearListTaxes());
        dispatch(getListTaxesRequest(query));
        break;
      }
      case 'referral': {
        dispatch(clearReferral());
        dispatch(getOneReferralRequest());
        break;
      }
      default: {
        dispatch(clearListReward());
        dispatch(getListRewardRequest(query));
        break;
      }
    }
  }, [dispatch, query, tabsActive]);

  useEffect(() => {
    if (tabsActive) {
      if (search === '') {
        updateQuery({ page: 1, limit: LIMIT_DEFAULT });
      } else {
        getRequest();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, tabsActive]);

  useEffect(() => {
    return () => {
      dispatch(clearListReward());
      dispatch(clearReferral());
      dispatch(clearListTaxes());
    };
  }, [dispatch]);

  useEffect(() => {
    if (tabsLocal) {
      setTabsActive(tabsLocal as string);
    } else {
      LocalStorage.set('tabs', 'rewards');
      setTabsActive('rewards');
    }
  }, [tabsLocal]);

  useEffect(() => {
    LocalStorage.set('isBack', pathname + query);
  }, [pathname, query]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  return (
    <>
      <UIHeader
        title={title}
        {...(tabsActive !== 'referral' && {
          filterOptions,
          sortOption,
          buttons: headerButtons,
        })}
        onClickTabs={(e) => {
          updateQuery({ page: 1, limit: LIMIT_DEFAULT });
          setTabsActive((e as string).split('tabs_')[1]);
        }}
        tabs={tabs}
        {...(tabsActive && { activeTab: `tabs_${tabsActive}` })}
      />
      {(() => {
        switch (tabsActive) {
          case 'taxes': {
            return (
              <Row>
                <UITable
                  dataSource={taxesList || []}
                  columns={columns as ColumnsType<FullTaxes>}
                  loading={loading}
                  meta={meta}
                  onRow={(item: FullTaxes) =>
                    navigate(`${pathname}/tax/${item.id}`)
                  }
                  onPaginationChange={onPaginationChange}
                  // isScrollDefault
                />
              </Row>
            );
          }
          case 'referral': {
            return <SettingsReferralDetail />;
          }
          default:
            return (
              <Row>
                <UITable
                  dataSource={rewardList || []}
                  columns={columns as ColumnsType<FullReward>}
                  loading={loading}
                  meta={meta}
                  onRow={(item: FullReward) =>
                    navigate(`${pathname}/reward/${item.id}`)
                  }
                  onPaginationChange={onPaginationChange}
                  // isScrollDefault
                  adaptive={false}
                />
              </Row>
            );
        }
      })()}
    </>
  );
};

export default Settings;
