import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CLEAR_CATEGORY,
  CLEAR_IMAGE_TO_CATEGORY,
  CLEAR_LIST_CATEGORY,
  CREATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  GET_LIST_CATEGORY_FAILURE,
  GET_LIST_CATEGORY_REQUEST,
  GET_LIST_CATEGORY_SUCCESS,
  GET_ONE_CATEGORY_REQUEST,
  GET_ONE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
} from './Category.constant';
import { CategoryActionsTypes, ICategoryState } from './Category.type';

const initialState: ICategoryState = {
  status: ActionStatus.NONE,
  categoryList: null,
  meta: META_DEFAULT,
  category: null,
  loading: false,
};

export default function CategoryReducer(
  state = initialState,
  action: CategoryActionsTypes,
): ICategoryState {
  switch (action.type) {
    case GET_LIST_CATEGORY_REQUEST:
    case GET_ONE_CATEGORY_REQUEST:
      return {
        ...state,
        status: ActionStatus.NONE,
        loading: true,
      };

    case GET_LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: [...(state.categoryList || []), ...action.payload.data],
        meta: action.payload.meta,
        loading: false,
      };
    case GET_LIST_CATEGORY_FAILURE:
    case UPDATE_CATEGORY_FAILURE:
    case CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_CATEGORY_REQUEST:
    case UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.CREATED,
        loading: false,
        categoryList: [action.payload, ...(state.categoryList || [])],
      };
    }
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        status: ActionStatus.UPDATED,
        loading: false,
        category: action.payload,
        categoryList: (state.categoryList || []).map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      };
    case DELETE_CATEGORY_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.DELETED,
        loading: false,
        categoryList: (state.categoryList || []).filter(
          (item) => item.id !== action.payload,
        ),
      };
    }
    case GET_ONE_CATEGORY_SUCCESS: {
      return {
        ...state,
        category: action.payload.data,
        loading: false,
        ...(action.payload.toList && {
          categoryList: [action.payload.data, ...(state.categoryList || [])],
        }),
      };
    }
    case CLEAR_CATEGORY: {
      return {
        ...state,
        status: ActionStatus.NONE,
        category: null,
      };
    }
    case CLEAR_LIST_CATEGORY: {
      return {
        ...state,
        categoryList: null,
        meta: META_DEFAULT,
      };
    }
    case CLEAR_IMAGE_TO_CATEGORY: {
      return {
        ...state,
        ...(state.category && {
          category: { ...state.category, image: null },
        }),
      };
    }
    default:
      return state;
  }
}
