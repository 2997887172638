export const GET_LIST_EFFECT_REQUEST = '@effect/GET_LIST_EFFECT_REQUEST';
export const GET_LIST_EFFECT_SUCCESS = '@effect/GET_LIST_EFFECT_SUCCESS';
export const GET_LIST_EFFECT_FAILURE = '@effect/GET_LIST_EFFECT_FAILURE';

export const CREATE_EFFECT_REQUEST = '@effect/CREATE_EFFECT_REQUEST';
export const CREATE_EFFECT_SUCCESS = '@effect/CREATE_EFFECT_SUCCESS';
export const CREATE_EFFECT_FAILURE = '@effect/CREATE_EFFECT_FAILURE';

export const GET_ONE_EFFECT_REQUEST = '@effect/GET_ONE_EFFECT_REQUEST';
export const GET_ONE_EFFECT_SUCCESS = '@effect/GET_ONE_EFFECT_SUCCESS';
export const GET_ONE_EFFECT_FAILURE = '@effect/GET_ONE_EFFECT_FAILURE';

export const DELETE_EFFECT_REQUEST = '@effect/DELETE_EFFECT_REQUEST';
export const DELETE_EFFECT_SUCCESS = '@effect/DELETE_EFFECT_SUCCESS';
export const DELETE_EFFECT_FAILURE = '@effect/DELETE_EFFECT_FAILURE';

export const UPDATE_EFFECT_REQUEST = '@effect/UPDATE_EFFECT_REQUEST';
export const UPDATE_EFFECT_SUCCESS = '@effect/UPDATE_EFFECT_SUCCESS';
export const UPDATE_EFFECT_FAILURE = '@effect/UPDATE_EFFECT_FAILURE';

export const CLEAR_EFFECT = '@effect/CLEAR_EFFECT';
export const CLEAR_LIST_EFFECT = '@effect/CLEAR_LIST_EFFECT';
export const CLEAR_IMAGE_TO_EFFECT = '@effect/CLEAR_IMAGE_TO_EFFECT';
