import { Route, Routes } from 'react-router-dom';

import { BrandDetail, BrandList } from '@features/Brand';

const ViewBrand = () => {
  return (
    <Routes>
      <Route path=":brandId" element={<BrandDetail />} />
      <Route path="/" element={<BrandList />} />
    </Routes>
  );
};

export default ViewBrand;
