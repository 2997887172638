/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Button, Col, Form, Input, InputNumber, Row, Switch } from 'antd';
import { useSelector } from 'react-redux';

import { en } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import {
  clearUserListStore,
  getListUserRequest,
  getOneUserRequest,
} from '@store/User';
import { UICalendar, UISelectFormItem } from '@ui';
import { DiscountUnit, META_DEFAULT, Role } from '@utils';

import { INITIAL_VALUES } from './constant';
import { FormValues, Props } from './index.type';

const PromoCodeEditForm = ({ onFormSubmit, form, promoCode }: Props) => {
  const discountValue = Form.useWatch('discountValue', form);
  const discountUnitValue = Form.useWatch('discountUnit', form);
  const userId = Form.useWatch('userId', form);
  const [timeActive, setTimeActive] = useState<[string, string] | []>([]);
  const dispatch = useAppDispatch();
  const isPercent = discountUnitValue === DiscountUnit.PERCENT;

  const {
    userList,
    meta,
    loading: loadingUser,
  } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (promoCode) {
      form.setFieldsValue(promoCode);
      if (promoCode?.validFrom && promoCode?.validUntil) {
        setTimeActive([promoCode.validFrom, promoCode.validUntil]);
      }
    }
  }, [promoCode, form]);

  const handlerFinish = ({ userId, ...val }: FormValues) => {
    onFormSubmit({
      ...val,
      ...(userId && { userId }),
      ...(timeActive.length && {
        validFrom: timeActive[0],
        validUntil: timeActive[1],
      }),
    });
  };

  const formCheckbox = {
    labelCol: { span: 'auto' },
    wrapperCol: { span: 'auto' },
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 14 }}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      validateMessages={en.validation}
    >
      <Row justify="center">
        <Col xxl={7} span={12}>
          {isPercent ? (
            <Form.Item
              label="Percent"
              name="discountValue"
              required={isPercent}
              rules={[
                {
                  required: isPercent,
                  min: 1,
                  max: 100,
                  type: 'number',
                },
              ]}
            >
              <InputNumber
                min={1}
                step={1}
                type="number"
                {...(discountValue &&
                  (+discountValue < 1 || +discountValue > 100) && {
                    status: 'error',
                  })}
                placeholder="Enter value"
              />
            </Form.Item>
          ) : (
            <Form.Item
              label="Discount"
              name="discountValue"
              required={!isPercent}
              rules={[{ required: !isPercent, min: 0.01, type: 'number' }]}
            >
              <InputNumber
                min={0}
                type="number"
                {...(discountValue &&
                  +discountValue < 0.01 && { status: 'error' })}
                placeholder="Price"
              />
            </Form.Item>
          )}
        </Col>
        <Col xxl={7} span={12}>
          <UISelectFormItem
            label="User"
            name="userId"
            options={
              (userList || [])
                .map((item) => {
                  if (item.profile?.fullName) {
                    return {
                      id: item.id,
                      name: item.profile?.fullName,
                    };
                  }

                  return null;
                })
                .filter((i) => i) as {
                id: number | string;
                name: string;
              }[]
            }
            getOptions={(query) => dispatch(getListUserRequest(query, false))}
            getOption={(id) =>
              id && dispatch(getOneUserRequest({ id: +id, role: Role.USER }))
            }
            valueId={userId}
            clearOptions={() => dispatch(clearUserListStore())}
            queryString={`filter[role]=${Role.USER}&filter[isConfirmed21Age]=true`}
            meta={meta || META_DEFAULT}
            loading={loadingUser}
          />
        </Col>
        <Col xxl={10} span={24}>
          <Form.Item required rules={[{ required: true }]} label="Lifetime">
            <UICalendar
              value={timeActive}
              onChange={(_, i) => setTimeActive(i)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={14}>
          <Form.Item name="code" label="Code">
            <Input placeholder="Code" />
          </Form.Item>
        </Col>
        <Col offset={1} span={4}>
          <Form.Item {...formCheckbox} label="Is percent" name="discountUnit">
            <Switch
              onChange={(item) => {
                form.setFieldValue(
                  'discountUnit',
                  item ? DiscountUnit.PERCENT : DiscountUnit.CURRENCY,
                );
                form.setFieldValue('discountValue', null);
              }}
              checked={isPercent}
            />
          </Form.Item>
        </Col>
        <Col offset={1} span={4}>
          {promoCode ? (
            <Form.Item {...formCheckbox} label="Is active" name="isActive">
              <Switch defaultChecked={promoCode?.isActive} />
            </Form.Item>
          ) : null}
        </Col>
      </Row>
      <div className="promoCode-edit__save-button">
        <Button
          key="Save changes"
          type="primary"
          onClick={() => form.submit()}
          size="large"
          className="edit-form__save-button"
        >
          Save changes
        </Button>
      </div>
    </Form>
  );
};

export default PromoCodeEditForm;
