import { ColumnsType } from 'antd/lib/table';

import { FilterOption } from '@entities/Filter';
import { FullOrder } from '@entities/Order';
import { SortOption } from '@entities/Sort';
import { FullUser } from '@entities/User';
import { OrderUIActions } from '@features/Order/ui';
import { LocalStorage } from '@services/storage';
import type { AppDispatch } from '@store/index';
import { UICellLink, UICellTooltip, UITag } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_FULL_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_LONG_TEXT,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  COLUMN_WIDTH_TAG,
  DateHelper,
  FORMAT_DATE,
  FORMAT_DATE_TIME_WITH_DOT,
  FilterTab,
  LIMIT_DEFAULT,
  LINK_ORDER,
  LINK_USERS,
  ProcessStatus,
  StringHelper,
  processStatusOptions,
} from '@utils';

const today = new Date();

export const tab: FilterTab[] = [
  {
    label: 'All',
    options: {
      page: 1,
      limit: LIMIT_DEFAULT,
    },
  },
  {
    label: 'Today',
    options: {
      filter: {
        created_at: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    },
  },
  {
    label: 'Yesterday',
    options: {
      filter: {
        created_at: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -1,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -1,
          }),
        },
      },
    },
  },
  {
    label: 'Request',
    options: {
      filter: {
        status: ProcessStatus.VERIFICATION_REQUIRED,
      },
    },
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderActionColumn = (dispatch?: AppDispatch): ColumnsType<FullOrder> => {
  if (dispatch) {
    return [
      {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        width: COLUMN_WIDTH_ACTIONS,
        render: (_value: FullOrder, order: FullOrder) =>
          OrderUIActions({ ...order, dispatch }),
      },
    ];
  }

  return [];
};
const renderStatus = () => {
  const activeTab = LocalStorage.get('tab');

  if (activeTab && activeTab !== 'Request') {
    return [
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: COLUMN_WIDTH_TAG,
        render: (value: ProcessStatus) =>
          UITag({
            value,
            values: processStatusOptions,
          }),
      },
    ];
  }

  return [];
};

export const columns = ({
  dispatch,
}: {
  dispatch?: AppDispatch;
}): ColumnsType<FullOrder> => [
  {
    title: 'Id',
    key: 'number',
    render: (value: FullOrder) =>
      UICellLink({
        link: `/${LINK_ORDER}/${value.id}`,
        value: value.id,
      }),
    width: COLUMN_WIDTH_ID,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    width: COLUMN_WIDTH_NAME,
    render: (value: FullUser) =>
      UICellLink({
        value: value?.profile?.fullName || 'no name',
        link: `/${LINK_USERS}/${value?.id}`,
        className: 'p-0',
      }),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    ellipsis: {
      showTitle: false,
    },
    width: COLUMN_WIDTH_LONG_TEXT,
    render: (_, record) =>
      UICellTooltip({
        text: record.address?.formattedAddress || 'not specified',
      }),
  },
  {
    title: 'Date',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: COLUMN_WIDTH_FULL_DATE,
    render: (value: string) =>
      DateHelper.formateDateToString(value, FORMAT_DATE_TIME_WITH_DOT),
  },
  ...renderStatus(),
  {
    title: 'Tips',
    dataIndex: 'tips',
    key: 'tips',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: number) => StringHelper.toMoney(value, 2),
  },
  {
    title: 'Price',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: number) => StringHelper.toMoney(value, 2),
  },
  ...renderActionColumn(dispatch),
];

export const sortOption = (): SortOption[] => [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  {
    label: 'Tips (ascending)',
    name: 'tips',
    direction: 'asc',
  },
  {
    label: 'Tips (descending)',
    name: 'tips',
    direction: 'desc',
  },
  {
    label: 'Total (ascending)',
    name: 'totalPrice',
    direction: 'asc',
  },
  {
    label: 'Total (descending)',
    name: 'totalPrice',
    direction: 'desc',
  },
  {
    label: 'Create date (oldest first)',
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: 'Create date (newest first)',
    name: 'created_at',
    direction: 'desc',
  },
];

export const filterOptions = () =>
  [
    {
      name: 'created_at',
      label: 'Create date',
      type: 'radio-range',
      options: [
        {
          label: 'Today',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
        {
          label: 'Last 7 days',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
              amountDays: -7,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
        {
          label: 'Last 30 days',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
              amountDays: -30,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
      ],
    },
    {
      name: 'tips',
      label: 'Amount tips',
      type: 'number-range',
      step: 1,
      min: 0,
    },
    {
      name: 'totalPrice',
      label: 'Amount paid',
      type: 'number-range',
      step: 1,
      min: 0,
    },
    {
      name: 'shipping_method',
      label: 'Delivery method',
      type: 'radio',
      options: [
        {
          label: 'Pickup',
          value: 'pickup',
        },
        {
          label: 'Delivery',
          value: 'delivery',
        },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'radio',
      options: [
        {
          label: StringHelper.capitalize(ProcessStatus.APPROVED),
          value: ProcessStatus.APPROVED,
        },
        {
          label: StringHelper.capitalize(ProcessStatus.AWAITING_PICKUP),
          value: ProcessStatus.AWAITING_PICKUP,
        },
        {
          label: StringHelper.capitalize(ProcessStatus.CANCELED),
          value: ProcessStatus.CANCELED,
        },
        {
          label: StringHelper.capitalize(ProcessStatus.COMPLETED),
          value: ProcessStatus.COMPLETED,
        },
        {
          label: StringHelper.capitalize(ProcessStatus.DELIVERED),
          value: ProcessStatus.DELIVERED,
        },
        {
          label: StringHelper.capitalize(ProcessStatus.PENDING_DRIVER),
          value: ProcessStatus.PENDING_DRIVER,
        },
        {
          label: StringHelper.capitalize(ProcessStatus.SHIPPED),
          value: ProcessStatus.SHIPPED,
        },
        {
          label: StringHelper.capitalize(ProcessStatus.VERIFICATION_REQUIRED),
          value: ProcessStatus.VERIFICATION_REQUIRED,
        },
      ],
    },
  ] as FilterOption[];
