import { Route, Routes } from 'react-router-dom';

import Dashboard from '@features/Dashboard';

const ViewDashboard = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
    </Routes>
  );
};

export default ViewDashboard;
