import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CLEAR_LIST_REWARD,
  CLEAR_LIST_TAXES,
  CLEAR_REFERRAL,
  CLEAR_REWARD,
  CLEAR_TAXES,
  CREATE_REWARD_FAILURE,
  CREATE_REWARD_REQUEST,
  CREATE_REWARD_SUCCESS,
  CREATE_TAXES_FAILURE,
  CREATE_TAXES_REQUEST,
  CREATE_TAXES_SUCCESS,
  DELETE_REWARD_SUCCESS,
  DELETE_TAXES_SUCCESS,
  GET_LIST_REWARD_FAILURE,
  GET_LIST_REWARD_REQUEST,
  GET_LIST_REWARD_SUCCESS,
  GET_LIST_TAXES_FAILURE,
  GET_LIST_TAXES_REQUEST,
  GET_LIST_TAXES_SUCCESS,
  GET_ONE_REFERRAL_FAILURE,
  GET_ONE_REFERRAL_REQUEST,
  GET_ONE_REFERRAL_SUCCESS,
  GET_ONE_REWARD_FAILURE,
  GET_ONE_REWARD_REQUEST,
  GET_ONE_REWARD_SUCCESS,
  GET_ONE_TAXES_FAILURE,
  GET_ONE_TAXES_REQUEST,
  GET_ONE_TAXES_SUCCESS,
  UPDATE_REFERRAL_FAILURE,
  UPDATE_REFERRAL_REQUEST,
  UPDATE_REFERRAL_SUCCESS,
  UPDATE_REWARD_FAILURE,
  UPDATE_REWARD_REQUEST,
  UPDATE_REWARD_SUCCESS,
  UPDATE_TAXES_FAILURE,
  UPDATE_TAXES_REQUEST,
  UPDATE_TAXES_SUCCESS,
} from './Setting.constant';
import { ISettingState, SettingActionsTypes } from './Setting.type';

const initialState: ISettingState = {
  status: ActionStatus.NONE,
  rewardList: null,
  referral: null,
  taxesList: null,
  meta: META_DEFAULT,
  reward: null,
  taxes: null,
  loading: false,
};

export default function SettingReducer(
  state = initialState,
  action: SettingActionsTypes,
): ISettingState {
  switch (action.type) {
    case GET_LIST_REWARD_REQUEST:
    case GET_ONE_REWARD_REQUEST:
    case GET_ONE_REFERRAL_REQUEST:
    case GET_LIST_TAXES_REQUEST:
    case GET_ONE_TAXES_REQUEST:
      return {
        ...state,
        status: ActionStatus.NONE,
        loading: true,
      };

    case GET_LIST_REWARD_SUCCESS:
      return {
        ...state,
        rewardList: [...(state.rewardList || []), ...action.payload.data],
        meta: action.payload.meta,
        loading: false,
      };
    case GET_LIST_TAXES_SUCCESS:
      return {
        ...state,
        taxesList: [...(state.taxesList || []), ...action.payload.data],
        meta: action.payload.meta,
        loading: false,
      };
    case GET_LIST_TAXES_FAILURE:
    case UPDATE_TAXES_FAILURE:
    case CREATE_TAXES_FAILURE:
    case GET_ONE_TAXES_FAILURE:
    case GET_LIST_REWARD_FAILURE:
    case UPDATE_REWARD_FAILURE:
    case CREATE_REWARD_FAILURE:
    case GET_ONE_REWARD_FAILURE:
    case UPDATE_REFERRAL_FAILURE:
    case GET_ONE_REFERRAL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_TAXES_REQUEST:
    case UPDATE_TAXES_REQUEST:
    case CREATE_REWARD_REQUEST:
    case UPDATE_REWARD_REQUEST:
    case UPDATE_REFERRAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_REWARD_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.CREATED,
        loading: false,
        rewardList: [action.payload, ...(state.rewardList || [])],
      };
    }
    case CREATE_TAXES_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.CREATED,
        loading: false,
        taxesList: [action.payload, ...(state.taxesList || [])],
      };
    }
    case UPDATE_TAXES_SUCCESS:
      return {
        ...state,
        status: ActionStatus.UPDATED,
        loading: false,
        taxes: action.payload,
        taxesList: (state.taxesList || []).map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      };
    case UPDATE_REWARD_SUCCESS:
      return {
        ...state,
        status: ActionStatus.UPDATED,
        loading: false,
        reward: action.payload,
        rewardList: (state.rewardList || []).map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      };
    case UPDATE_REFERRAL_SUCCESS:
      return {
        ...state,
        status: ActionStatus.UPDATED,
        loading: false,
        referral: action.payload,
      };
    case DELETE_REWARD_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.DELETED,
        loading: false,
        rewardList: (state.rewardList || []).filter(
          (item) => item.id !== action.payload,
        ),
      };
    }
    case DELETE_TAXES_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.DELETED,
        loading: false,
        taxesList: (state.taxesList || []).filter(
          (item) => item.id !== action.payload,
        ),
      };
    }
    case GET_ONE_TAXES_SUCCESS: {
      return {
        ...state,
        taxes: action.payload.data,
        loading: false,
        ...(action.payload.toList && {
          taxesList: [action.payload.data, ...(state.taxesList || [])],
        }),
      };
    }
    case GET_ONE_REWARD_SUCCESS: {
      return {
        ...state,
        reward: action.payload.data,
        loading: false,
        ...(action.payload.toList && {
          rewardList: [action.payload.data, ...(state.rewardList || [])],
        }),
      };
    }
    case GET_ONE_REFERRAL_SUCCESS: {
      return {
        ...state,
        referral: action.payload.data,
        loading: false,
      };
    }
    case CLEAR_REWARD: {
      return {
        ...state,
        status: ActionStatus.NONE,
        reward: null,
      };
    }
    case CLEAR_REFERRAL: {
      return {
        ...state,
        status: ActionStatus.NONE,
        referral: null,
      };
    }
    case CLEAR_TAXES: {
      return {
        ...state,
        status: ActionStatus.NONE,
        taxes: null,
      };
    }
    case CLEAR_LIST_REWARD: {
      return {
        ...state,
        rewardList: null,
        meta: META_DEFAULT,
      };
    }
    case CLEAR_LIST_TAXES: {
      return {
        ...state,
        taxesList: null,
        meta: META_DEFAULT,
      };
    }
    default:
      return state;
  }
}
