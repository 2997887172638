import { useEffect, useState } from 'react';

import { Form, Spin } from 'antd';
import { useSelector } from 'react-redux';
import './style.scss';

import { FullMedia } from '@entities/Media';
import {
  clearCategory,
  createCategoryRequest,
  getOneCategoryRequest,
  updateCategoryRequest,
} from '@store/Category';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { UIButton, UIModal } from '@ui';
import { Button, FileName, UploadFile } from '@utils';

import WidgetModalCategoryForm from './Form';
import { INITIAL_VALUES } from './Form/constant';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';

const WidgetModalCategory = ({ categoryId, type }: Props) => {
  const { loading: loadingImage, file: fileStore } = useSelector(
    (state: RootState) => state.media,
  );
  const { category, loading: loadingCategory } = useSelector(
    (state: RootState) => state.category,
  );
  const [imageFile, setImageFile] = useState<FullMedia | null>(null);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (categoryId) {
      dispatch(clearCategory());
      dispatch(getOneCategoryRequest({ id: +categoryId }));
    }
  }, [dispatch, categoryId]);

  const isCreate = type === 'create';

  const onFormSubmitHandler = (values: FormValues) => {
    if (isCreate) {
      dispatch(createCategoryRequest(values));
    } else {
      dispatch(updateCategoryRequest({ ...values, id: +categoryId }));
    }
  };

  const onUploadImageHandler = (file: UploadFile['file']) => {
    dispatch(uploadMediaRequest({ file, fileName: FileName.IMAGE }));
  };

  const onRemoveImageHandler = () => {
    dispatch(clearStore());
    setImageFile(null);
  };

  useEffect(() => {
    return () => {
      dispatch(clearStore());
      dispatch(clearCategory());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fileStore) {
      setImageFile(fileStore);
    }
  }, [dispatch, fileStore]);

  useEffect(() => {
    if (category?.image) {
      setImageFile(category?.image);
    }
  }, [category?.image]);

  const footer = [
    <UIButton
      key="Save changes"
      typeAntBtn="primary"
      handler={() => form.submit()}
      type={Button.ANT_BUTTON}
      size="large"
      className="edit-form__save-button"
    >
      {isCreate ? 'Add category' : 'Save'}
    </UIButton>,
  ];

  return (
    <UIModal
      className="edit-form"
      title={isCreate ? 'Add category' : 'Edit category'}
      footer={footer}
      visible
      children={
        <Spin spinning={loadingCategory || loadingImage}>
          <WidgetModalCategoryForm
            category={categoryId ? category : INITIAL_VALUES}
            image={imageFile}
            form={form}
            onFormSubmit={onFormSubmitHandler}
            onUploadImage={onUploadImageHandler}
            onRemoveImage={onRemoveImageHandler}
          />
        </Spin>
      }
    />
  );
};

export default WidgetModalCategory;
