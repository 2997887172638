import { ColumnsType } from 'antd/lib/table';

import type { FilterOption, FullMetrc, SortOption } from '@entities/index';
import { UICellLink } from '@ui';
import {
  COLUMN_WIDTH_FULL_DATE,
  COLUMN_WIDTH_ID,
  DateHelper,
  FORMAT_DATE,
  FORMAT_DATE_TIME_WITH_DOT,
} from '@utils';

export const columns = (): ColumnsType<FullMetrc> => {
  return [
    {
      title: 'Id',
      key: 'id',
      width: COLUMN_WIDTH_ID,
      render: (value: FullMetrc) => value.id,
    },
    {
      title: 'Name',
      dataIndex: 'file',
      key: 'file',
      width: COLUMN_WIDTH_FULL_DATE,
      render: (value: FullMetrc['file']) => value.name,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (value: string) =>
        DateHelper.formateDateToString(value, FORMAT_DATE_TIME_WITH_DOT),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 80,
      render: (value: string) => value,
    },
    {
      title: 'Download',
      dataIndex: 'file',
      key: 'file',
      width: COLUMN_WIDTH_FULL_DATE,
      render: (value: FullMetrc['file']) =>
        UICellLink({ value: value.name, link: value.path, target: '_blank' }),
    },
  ];
};

export const sortOption = (): SortOption[] => [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  {
    label: 'Date (oldest first)',
    name: 'createdAt',
    direction: 'asc',
  },
  {
    label: 'Date (newest first)',
    name: 'createdAt',
    direction: 'desc',
  },
];

const today = new Date();

export const filterOptions = () =>
  [
    {
      name: 'createdAt',
      label: 'Date',
      type: 'radio-range',
      options: [
        {
          label: 'Today',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
        {
          label: 'Last 7 days',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
              amountDays: -7,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
        {
          label: 'Last 30 days',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
              amountDays: -30,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
      ],
    },
  ] as FilterOption[];
