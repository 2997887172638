import { DeepPartial } from 'redux';

import { FullUser } from '@entities/User';
import { Notification, NotificationType } from '@utils';

import {
  ADD_NOTIFICATION,
  CLEAR_AVATAR_PROFILE,
  CLEAR_NOTIFICATION,
  CLEAR_ONE_NOTIFICATION,
  CLEAR_PROFILE,
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from './Profile.constant';
import { ProfileActionsTypes } from './Profile.type';

export function getProfileRequest(): ProfileActionsTypes {
  return {
    type: GET_PROFILE_REQUEST,
  };
}

export function getProfileSuccess(user: FullUser): ProfileActionsTypes {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: {
      user,
    },
  };
}

export function getProfileFailure(): ProfileActionsTypes {
  return {
    type: GET_PROFILE_FAILURE,
  };
}

export function clearProfile(): ProfileActionsTypes {
  return {
    type: CLEAR_PROFILE,
  };
}

export function updateProfileRequest(
  body: DeepPartial<FullUser>,
): ProfileActionsTypes {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload: {
      body,
    },
  };
}

export function updateProfileSuccess(user: FullUser): ProfileActionsTypes {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: { user },
  };
}

export function updateProfileFailure(): ProfileActionsTypes {
  return {
    type: UPDATE_PROFILE_FAILURE,
  };
}

export function clearAvatarProfile(): ProfileActionsTypes {
  return {
    type: CLEAR_AVATAR_PROFILE,
  };
}

export function addNotification(
  notification: Notification,
): ProfileActionsTypes {
  return {
    type: ADD_NOTIFICATION,
    payload: notification,
  };
}

export function clearNotification(): ProfileActionsTypes {
  return {
    type: CLEAR_NOTIFICATION,
  };
}

export function clearOneNotification(payload: {
  id?: number;
  type: NotificationType;
}): ProfileActionsTypes {
  return {
    type: CLEAR_ONE_NOTIFICATION,
    payload,
  };
}
