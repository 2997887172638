import { Button, Col, Row, Space, Upload } from 'antd';

import UIAvatar from '../Avatar';
import UIImage from '../Image';
import { Props } from './index.type';

const UIImageUpload = ({
  view,
  path,
  onUploadImage,
  onRemoveImage,
  name,
  label,
}: Props) => {
  return (
    <Space className="imageUpload" direction="horizontal" wrap={false}>
      {view === 'avatar' ? (
        <UIAvatar path={path} className="user__avatar" size="large" />
      ) : (
        <UIImage path={path} />
      )}
      <Row justify="center">
        <Col span={24}>
          <div
            className="hide-desktop uploadLabel d-block"
            style={{ marginBottom: 20 }}
          >
            <label {...(name && { htmlFor: name })} title={label}>
              {label}:
            </label>
          </div>
          <Upload
            accept=".jpg, .jpeg, .png"
            showUploadList={false}
            beforeUpload={(file) => {
              onUploadImage(file, name);

              return false;
            }}
          >
            <Button
              key="Save changes"
              type="primary"
              size="large"
              className="edit-form__save-photo"
            >
              Upload image
            </Button>
          </Upload>
          {path && path !== 'error' && (
            <Button
              key="Save changes"
              type="link"
              danger
              onClick={() => onRemoveImage(name || null)}
              size="large"
            >
              Remove image
            </Button>
          )}
        </Col>
      </Row>
    </Space>
  );
};

export default UIImageUpload;
