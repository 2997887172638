import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';

import { DateHelper, FORMAT_DATE } from '@utils';

import { Props } from './index.type';

import './style.scss';

const DashboardUITooltip = ({
  payload,
  label,
  active,
  tooltipValue,
  tooltipLabelType = 'string',
}: Props) => {
  if (active && payload) {
    const renderTitle = () => {
      if (tooltipLabelType === 'date') {
        return (
          <>
            <Col span="12">
              <Text type="secondary">Date:</Text>
            </Col>
            <Col span="12">
              <Text strong>
                {DateHelper.formateDateToString(label, FORMAT_DATE)}
              </Text>
            </Col>
          </>
        );
      }

      return (
        <Col span="24">
          <Text type="secondary">{label}</Text>
        </Col>
      );
    };

    return (
      <div className="area-tooltip">
        <Row justify="start">
          {renderTitle()}
          <Col span="12">
            <Text type="secondary">{tooltipValue}:</Text>
          </Col>
          <Col span="12">
            <Text strong>{payload[0]?.value}</Text>
          </Col>
        </Row>
      </div>
    );
  }

  return null;
};

export default DashboardUITooltip;
