import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullSupport, SupportEvent, SupportMessage } from '@entities/Support';
import { SupportHttp } from '@services/http';

import { alertError } from '../Alert';
import {
  getListMessageFailure,
  getListMessageSuccess,
  getListSupportFailure,
  getListSupportSuccess,
  getOneSupportFailure,
  getOneSupportSuccess,
} from './Support.action';
import {
  CREATE_MESSAGE_REQUEST,
  GET_LIST_CHAT_REQUEST,
  GET_LIST_MESSAGE_REQUEST,
  GET_ONE_CHAT_REQUEST,
} from './Support.constant';
import {
  ICreateMessageRequest,
  IGetListMessageRequest,
  IGetListSupportRequest,
  IGetOneSupportRequest,
} from './Support.type';

function* workerCreateMessage({ payload }: ICreateMessageRequest) {
  try {
    const {
      socket,
      message: { supportId, text },
    } = payload;

    socket.emit(SupportEvent.MESSAGE_SEND, {
      supportId,
      text,
    });
  } catch (error) {
    yield put(alertError(error));
  }
}
function* watcherCreateMessage() {
  yield takeLatest(CREATE_MESSAGE_REQUEST, workerCreateMessage);
}

function* workerGetListChat(action: IGetListSupportRequest) {
  try {
    const { query } = action.payload;
    const payload: DataResponse<FullSupport[]> = yield call(
      SupportHttp.getList,
      query,
    );

    yield put(getListSupportSuccess(payload));
  } catch (error) {
    yield put(getListSupportFailure());
    yield put(alertError(error));
  }
}

function* watchGetListChat() {
  yield takeLatest(GET_LIST_CHAT_REQUEST, workerGetListChat);
}

function* workerGetListMessage(action: IGetListMessageRequest) {
  try {
    const payload: DataResponse<SupportMessage[]> = yield call(
      SupportHttp.getListMessage,
      action.payload,
    );

    yield put(getListMessageSuccess(payload));
  } catch (error) {
    yield put(getListMessageFailure());
    yield put(alertError(error));
  }
}

function* watchGetListMessage() {
  yield takeLatest(GET_LIST_MESSAGE_REQUEST, workerGetListMessage);
}

function* workerGetOneChat(action: IGetOneSupportRequest) {
  try {
    const { id } = action.payload;
    const payload: FullSupport = yield call(SupportHttp.getOne, id);

    yield put(getOneSupportSuccess(payload));
  } catch (error) {
    yield put(getOneSupportFailure());
    yield put(alertError(error));
  }
}

function* watchGetOneChat() {
  yield takeLatest(GET_ONE_CHAT_REQUEST, workerGetOneChat);
}

// function* workerDeleteChat(action: IDeleteSupportRequest) {
//   try {
//     const { id } = action.payload;

//     yield call(SupportHttp.remove, id);

//     yield put(deleteSupportSuccess());
//     yield put(alertSuccess('Chat successfully deleted!'));
//     yield put(getListSupportRequest());
//   } catch (error) {
//     yield put(deleteSupportFailure());
//     yield put(alertError(error));
//   }
// }

// function* watchDeleteChat() {
//   yield takeLatest(DELETE_CHAT_REQUEST, workerDeleteChat);
// }

export const supportWatchers: ForkEffect[] = [
  fork(watchGetListChat),
  fork(watchGetOneChat),
  // fork(watchDeleteChat),
  fork(watchGetListMessage),
  fork(watcherCreateMessage),
];
