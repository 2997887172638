import { useEffect } from 'react';

import { Button, Form, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '@store/index';
import { clearStore } from '@store/Media';
import { hideModal } from '@store/Modal';
import {
  clearTaxes,
  createTaxesRequest,
  getOneTaxesRequest,
  updateTaxesRequest,
} from '@store/Setting';

import WidgetModalTaxesForm from './Form';
import { FormTaxes } from './Form/index.type';
import { Props } from './index.type';

const WidgetModalTaxes = ({ taxesId, type }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormTaxes>();

  const { taxes, loading } = useSelector((state: RootState) => state.setting);

  useEffect(() => {
    if (taxesId) {
      dispatch(getOneTaxesRequest({ id: taxesId }));
    }
  }, [dispatch, taxesId]);

  useEffect(() => {
    return () => {
      dispatch(clearStore());
      dispatch(clearTaxes());
    };
  }, [dispatch]);

  const closeModal = () => {
    form.resetFields();
    dispatch(hideModal());
  };

  const saveProfile = () => {
    form.submit();
  };

  const onFormSubmitHandler = (values: FormTaxes) => {
    if (taxesId) {
      dispatch(updateTaxesRequest({ ...values, id: taxesId }));
    } else {
      dispatch(createTaxesRequest(values));
    }
  };

  return (
    <Modal
      visible
      title={`${type === 'create' ? 'Create' : 'Edit'} taxes`}
      onOk={saveProfile}
      onCancel={closeModal}
      footer={[
        <Button
          key="save_taxes"
          type="primary"
          onClick={saveProfile}
          size="large"
          className="edit-form__save-button"
        >
          {!taxesId ? 'Create' : 'Save changes'}
        </Button>,
      ]}
      className="edit-form"
    >
      <Spin spinning={loading}>
        <WidgetModalTaxesForm
          taxes={taxesId ? taxes : null}
          form={form}
          isCreate={type === 'create'}
          onFormSubmit={onFormSubmitHandler}
        />
      </Spin>
    </Modal>
  );
};

export default WidgetModalTaxes;
