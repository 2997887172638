import { useEffect, useState } from 'react';

import { Button, Space } from 'antd';
import { useSelector } from 'react-redux';

import { FullUser } from '@entities/User';
import { RootState } from '@store/index';
import { UISelectFormItem } from '@ui';
import { META_DEFAULT, OptionSelect, QueryHelper } from '@utils';

import { AllRadioSelect, AllRadioSelectStore, Props } from './index.type';

const getStorePath = (obj: { [keyof in string]: any }, arrKey: string[]) => {
  let data = obj;

  arrKey.forEach((item: string) => {
    data = data[item];
  });

  return data || [];
};

const WidgetModalRadioSelect = ({
  filterValue,
  onFilterUpdate,
  className,
  load,
}: Props) => {
  const [value, setValue] = useState<OptionSelect[] | null>();

  const list = useSelector(
    (state: RootState) => state[load?.store as keyof AllRadioSelectStore],
  );

  const dataList: AllRadioSelect[] = getStorePath(
    list,
    load?.listName || [],
  ) as AllRadioSelect[];

  const setValueAll = (val: OptionSelect[]) => {
    setValue(val);
  };

  const changeHandler = (ids: string[] | number[], values: OptionSelect[]) => {
    const newValue = ids.map((id) =>
      [...values, ...(value || [])].find((e) => +e.value === +id),
    ) as OptionSelect[];

    setValueAll(newValue);
    onFilterUpdate(newValue.map((i) => i.value));
  };

  const clearHandler = () => {
    onFilterUpdate(null);
  };

  const meta = list?.meta || META_DEFAULT;
  const notIds: number[] = [];

  const getOptions = (query: string) =>
    load && load?.get && load?.get(query, false);

  const trueName = (item: AllRadioSelect) => {
    return (
      (item as Exclude<AllRadioSelect, FullUser>).name ||
      (item as FullUser).profile?.fullName
    );
  };

  useEffect(() => {
    const arrNotTrue = value
      ?.map((i) => +i.value)
      ?.filter((id) => !filterValue?.includes(id.toString()));

    if (
      dataList &&
      dataList?.length &&
      (filterValue?.length !== value?.length || arrNotTrue?.length)
    ) {
      const fullLocalList: OptionSelect[] = [];

      filterValue?.forEach((e) => {
        const localItem = value?.find((item) => +item.value === +e);

        if (!localItem) {
          const stateItem = (dataList || [])?.find((item) => +item.id === +e);

          if (stateItem) {
            fullLocalList.push({
              value: stateItem.id.toString(),
              label: trueName(stateItem) || `No name, id - ${stateItem.id}`,
            });
          } else {
            notIds.push(+e);
          }
        } else {
          fullLocalList.push(localItem);
        }
      });
      setValueAll(fullLocalList);
      if (notIds?.length) {
        getOptions(QueryHelper.stringify({ filter: { ids: notIds } }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList]);

  return (
    <Space className={className} direction="vertical">
      <UISelectFormItem
        options={(dataList || []).map((item) => ({
          id: item?.id?.toString(),
          name: trueName(item) || `No name, id - ${item?.id}`,
        }))}
        value={value as OptionSelect[]}
        mode="multiple"
        loading={list.loading}
        getOptions={getOptions}
        clearOptions={() => load && load?.clear && load?.clear()}
        meta={meta}
        onChange={(e, valueChange) => {
          if (e.length < 21) {
            changeHandler(e as string[], valueChange as OptionSelect[]);
          }
        }}
      />
      <Button
        type="link"
        className="modal-filter__button-clear"
        onClick={clearHandler}
      >
        Clear
      </Button>
    </Space>
  );
};

export default WidgetModalRadioSelect;
