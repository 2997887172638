import { Fragment } from 'react';

import { Col, Row, Space, Spin } from 'antd';

import { Props } from './index.type';

import './style.scss';

const LayoutContent = ({
  content,
  aside,
  loadingContent = false,
  loadingAside = false,
  loading = false,
  children,
}: Props) => {
  const renderContent = () => {
    if (Array.isArray(content)) {
      return content.map((item, key) => (
        <Fragment key={`content_${key}`}>{item}</Fragment>
      ));
    }

    return content || null;
  };

  const renderAside = () => {
    if (Array.isArray(aside)) {
      return aside.map((item, key) => (
        <Fragment key={`aside_${key}`}>{item}</Fragment>
      ));
    }

    return aside;
  };

  if (!content && !aside) {
    return (
      <Row justify="space-between" align="top" wrap={false} gutter={16}>
        <Col span={24}>
          <Spin spinning={loading}>
            <Space
              direction="vertical"
              size="large"
              className="content-layout__content"
            >
              {children}
            </Space>
          </Spin>
        </Col>
      </Row>
    );
  }

  return (
    <Row justify="space-between" align="top" wrap={false} gutter={16}>
      <Col span={18}>
        <Spin spinning={loadingContent}>
          <Space
            direction="vertical"
            size="large"
            className="content-layout__content"
          >
            {renderContent()}
          </Space>
        </Spin>
      </Col>
      <Col span={6}>
        <Spin spinning={loadingAside}>
          <Space
            direction="vertical"
            size="large"
            className="content-layout__content"
          >
            {renderAside()}
          </Space>
        </Spin>
      </Col>
    </Row>
  );
};

export default LayoutContent;
