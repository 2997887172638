export const GET_LIST_REWARD_REQUEST = '@setting/GET_LIST_REWARD_REQUEST';
export const GET_LIST_REWARD_SUCCESS = '@setting/GET_LIST_REWARD_SUCCESS';
export const GET_LIST_REWARD_FAILURE = '@setting/GET_LIST_REWARD_FAILURE';

export const CREATE_REWARD_REQUEST = '@setting/CREATE_REWARD_REQUEST';
export const CREATE_REWARD_SUCCESS = '@setting/CREATE_REWARD_SUCCESS';
export const CREATE_REWARD_FAILURE = '@setting/CREATE_REWARD_FAILURE';

export const GET_ONE_REWARD_REQUEST = '@setting/GET_ONE_REWARD_REQUEST';
export const GET_ONE_REWARD_SUCCESS = '@setting/GET_ONE_REWARD_SUCCESS';
export const GET_ONE_REWARD_FAILURE = '@setting/GET_ONE_REWARD_FAILURE';

export const DELETE_REWARD_REQUEST = '@setting/DELETE_REWARD_REQUEST';
export const DELETE_REWARD_SUCCESS = '@setting/DELETE_REWARD_SUCCESS';
export const DELETE_REWARD_FAILURE = '@setting/DELETE_REWARD_FAILURE';

export const UPDATE_REWARD_REQUEST = '@setting/UPDATE_REWARD_REQUEST';
export const UPDATE_REWARD_SUCCESS = '@setting/UPDATE_REWARD_SUCCESS';
export const UPDATE_REWARD_FAILURE = '@setting/UPDATE_REWARD_FAILURE';

export const GET_ONE_REFERRAL_REQUEST = '@setting/GET_ONE_REFERRAL_REQUEST';
export const GET_ONE_REFERRAL_SUCCESS = '@setting/GET_ONE_REFERRAL_SUCCESS';
export const GET_ONE_REFERRAL_FAILURE = '@setting/GET_ONE_REFERRAL_FAILURE';

export const UPDATE_REFERRAL_REQUEST = '@setting/UPDATE_REFERRAL_REQUEST';
export const UPDATE_REFERRAL_SUCCESS = '@setting/UPDATE_REFERRAL_SUCCESS';
export const UPDATE_REFERRAL_FAILURE = '@setting/UPDATE_REFERRAL_FAILURE';

export const GET_LIST_TAXES_REQUEST = '@setting/GET_LIST_TAXES_REQUEST';
export const GET_LIST_TAXES_SUCCESS = '@setting/GET_LIST_TAXES_SUCCESS';
export const GET_LIST_TAXES_FAILURE = '@setting/GET_LIST_TAXES_FAILURE';

export const CREATE_TAXES_REQUEST = '@setting/CREATE_TAXES_REQUEST';
export const CREATE_TAXES_SUCCESS = '@setting/CREATE_TAXES_SUCCESS';
export const CREATE_TAXES_FAILURE = '@setting/CREATE_TAXES_FAILURE';

export const GET_ONE_TAXES_REQUEST = '@setting/GET_ONE_TAXES_REQUEST';
export const GET_ONE_TAXES_SUCCESS = '@setting/GET_ONE_TAXES_SUCCESS';
export const GET_ONE_TAXES_FAILURE = '@setting/GET_ONE_TAXES_FAILURE';

export const DELETE_TAXES_REQUEST = '@setting/DELETE_TAXES_REQUEST';
export const DELETE_TAXES_SUCCESS = '@setting/DELETE_TAXES_SUCCESS';
export const DELETE_TAXES_FAILURE = '@setting/DELETE_TAXES_FAILURE';

export const UPDATE_TAXES_REQUEST = '@setting/UPDATE_TAXES_REQUEST';
export const UPDATE_TAXES_SUCCESS = '@setting/UPDATE_TAXES_SUCCESS';
export const UPDATE_TAXES_FAILURE = '@setting/UPDATE_TAXES_FAILURE';

export const CLEAR_TAXES = '@setting/CLEAR_TAXES';
export const CLEAR_LIST_TAXES = '@setting/CLEAR_LIST_TAXES';
export const CLEAR_REWARD = '@setting/CLEAR_REWARD';
export const CLEAR_LIST_REWARD = '@setting/CLEAR_LIST_REWARD';
export const CLEAR_REFERRAL = '@setting/CLEAR_REFERRAL';
