import { useEffect } from 'react';

import { Button, Col, Form, Input, Spin, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Login } from '@entities/Auth';
import { useAuth } from '@hooks';
import { authLoginEmailRequest } from '@store/Auth';
import { RootState, useAppDispatch } from '@store/index';
import { LINK_ORDER } from '@utils';

import './style.scss';

const AuthLogin = () => {
  const loadingLogin = useSelector(
    (state: RootState) => state.auth.loadingLogin,
  );
  const { isAuth } = useAuth();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: Login) => {
    dispatch(authLoginEmailRequest(values));
  };

  useEffect(() => {
    if (isAuth) {
      navigate(`/${LINK_ORDER}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <Col className="register-form">
      <Spin spinning={loadingLogin}>
        <Typography.Title className="register-form__header">
          Login
        </Typography.Title>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Col>
  );
};

export default AuthLogin;
