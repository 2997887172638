import { createContext, useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';

import { useAuth } from '@hooks';
import { NotificationSocket } from '@services/socket';
import { useAppDispatch } from '@store/index';
import {
  SOCKET_API_URL,
  SOCKET_NOTIFICATION_NAMESPACE,
  SOCKET_PATH,
} from '@utils';

import { NotificationSocketType, Props } from './index.type';

export const NotificationSocketContext = createContext<NotificationSocketType>({
  notificationSocket: null,
});

export const NotificationSocketProvider = ({ children }: Props) => {
  const { isAuth } = useAuth();
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();

  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (isAuth && !socket) {
      const socketConnect = io(
        `${SOCKET_API_URL}/${SOCKET_NOTIFICATION_NAMESPACE}`,
        {
          path: SOCKET_PATH,
          withCredentials: true,
        },
      );

      NotificationSocket.initSubscribe({
        socket: socketConnect,
        dispatch,
        pathname,
        query: search,
      });
      setSocket(socketConnect);
    }

    if (!isAuth && socket) {
      socket.disconnect();
      setSocket(null);
    }
  }, [isAuth, socket, dispatch, pathname, search]);

  return (
    <NotificationSocketContext.Provider value={{ notificationSocket: socket }}>
      {children}
    </NotificationSocketContext.Provider>
  );
};

export const useNotificationSocketSocket = (): NotificationSocketType => {
  const ctx = useContext(NotificationSocketContext);

  if (ctx === undefined) {
    throw new Error(
      'useNotificationSocket can only be used inside NotificationSocketContext',
    );
  }

  return ctx;
};
