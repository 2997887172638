import { Button, Col, Row, Space, Upload } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import { UIAvatar, UIImage } from '@ui';

import { Props } from './index.type';

const WidgetModalUIImage = ({
  name = 'avatar',
  view,
  label,
  path,
  onUploadImage,
  onRemoveImage,
}: Props) => {
  return (
    <FormItem label={label} name={name}>
      <Space direction="horizontal" wrap={false}>
        {view === 'avatar' ? (
          <UIAvatar path={path} className="user__avatar" size="large" />
        ) : (
          <UIImage path={path} />
        )}
        <Row justify="center">
          <Col span={24}>
            <Upload
              accept=".jpg, .jpeg, .png"
              showUploadList={false}
              beforeUpload={(file) => {
                onUploadImage(file, name);

                return false;
              }}
            >
              <Button
                key="Save changes"
                type="primary"
                size="large"
                className="edit-form__save-photo"
              >
                Upload image
              </Button>
            </Upload>
          </Col>
          <Col span={24}>
            {path && path !== 'error' && (
              <Button
                key="Save changes"
                type="link"
                danger
                onClick={() => onRemoveImage(name)}
                size="large"
              >
                Remove image
              </Button>
            )}
          </Col>
        </Row>
      </Space>
    </FormItem>
  );
};

export default WidgetModalUIImage;
