import { useEffect, useState } from 'react';

import { Form, Spin } from 'antd';
import { useSelector } from 'react-redux';
import './style.scss';

import { FullMedia } from '@entities/Media';
import {
  clearEffect,
  createEffectRequest,
  getOneEffectRequest,
  updateEffectRequest,
} from '@store/Effect';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { UIButton, UIModal } from '@ui';
import { Button, FileName, UploadFile } from '@utils';

import WidgetModalEffectForm from './Form';
import { INITIAL_VALUES } from './Form/constant';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';

const WidgetModalEffect = ({ effectId, type }: Props) => {
  const { loading: loadingImage, file: fileStore } = useSelector(
    (state: RootState) => state.media,
  );
  const { effect, loading: loadingEffect } = useSelector(
    (state: RootState) => state.effect,
  );
  const [imageFile, setImageFile] = useState<FullMedia | null>(null);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (effectId) {
      dispatch(clearEffect());
      dispatch(getOneEffectRequest(+effectId));
    }
  }, [dispatch, effectId]);

  const isCreate = type === 'create';

  const onFormSubmitHandler = (values: FormValues) => {
    if (isCreate) {
      dispatch(createEffectRequest(values));
    } else {
      dispatch(updateEffectRequest({ ...values, id: +effectId }));
    }
  };

  const onUploadImageHandler = (file: UploadFile['file']) => {
    dispatch(uploadMediaRequest({ file, fileName: FileName.IMAGE }));
  };

  const onRemoveImageHandler = () => {
    dispatch(clearStore());
    setImageFile(null);
  };

  useEffect(() => {
    if (fileStore) {
      setImageFile(fileStore);
    }
  }, [dispatch, fileStore]);

  useEffect(() => {
    if (effect?.image) {
      setImageFile(effect?.image);
    }
  }, [effect?.image]);

  useEffect(() => {
    return () => {
      dispatch(clearStore());
      dispatch(clearEffect());
    };
  }, [dispatch]);

  const footer = [
    <UIButton
      key="Save changes"
      typeAntBtn="primary"
      handler={() => form.submit()}
      type={Button.ANT_BUTTON}
      size="large"
      className="edit-form__save-button"
    >
      {isCreate ? 'Add effect' : 'Save'}
    </UIButton>,
  ];

  return (
    <UIModal
      className="edit-form"
      title={isCreate ? 'Add effect' : 'Edit effect'}
      footer={footer}
      visible
      children={
        <Spin spinning={loadingEffect || loadingImage}>
          <WidgetModalEffectForm
            effect={effectId ? effect : INITIAL_VALUES}
            image={imageFile}
            form={form}
            onFormSubmit={onFormSubmitHandler}
            onUploadImage={onUploadImageHandler}
            onRemoveImage={onRemoveImageHandler}
          />
        </Spin>
      }
    />
  );
};

export default WidgetModalEffect;
