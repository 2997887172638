import { Layout, Row } from 'antd';

import { AuthLogin } from '@features/Auth';
import { WidgetHeader } from '@widgets';

import './styles.scss';

const ViewAuthLogin = () => {
  return (
    <>
      <WidgetHeader />
      <Layout>
        <Layout.Content>
          <Row align="middle" justify="center" className="login-layout">
            <AuthLogin />
          </Row>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default ViewAuthLogin;
