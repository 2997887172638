export const GET_LIST_PRODUCTS_REQUEST = '@product/GET_LIST_PRODUCTS_REQUEST';
export const GET_LIST_PRODUCTS_SUCCESS = '@product/GET_LIST_PRODUCTS_SUCCESS';
export const GET_LIST_PRODUCTS_FAILURE = '@product/GET_LIST_PRODUCTS_FAILURE';

export const CREATE_PRODUCTS_REQUEST = '@product/CREATE_PRODUCTS_REQUEST';
export const CREATE_PRODUCTS_SUCCESS = '@product/CREATE_PRODUCTS_SUCCESS';
export const CREATE_PRODUCTS_FAILURE = '@product/CREATE_PRODUCTS_FAILURE';

export const GET_ONE_PRODUCTS_REQUEST = '@product/GET_ONE_PRODUCTS_REQUEST';
export const GET_ONE_PRODUCTS_SUCCESS = '@product/GET_ONE_PRODUCTS_SUCCESS';
export const GET_ONE_PRODUCTS_FAILURE = '@product/GET_ONE_PRODUCTS_FAILURE';

export const GET_DRIVERS_PRODUCTS_REQUEST =
  '@product/GET_DRIVERS_PRODUCTS_REQUEST';
export const GET_DRIVERS_PRODUCTS_SUCCESS =
  '@product/GET_DRIVERS_PRODUCTS_SUCCESS';
export const GET_DRIVERS_PRODUCTS_FAILURE =
  '@product/GET_DRIVERS_PRODUCTS_FAILURE';

export const GET_STORES_PRODUCTS_REQUEST =
  '@product/GET_STORES_PRODUCTS_REQUEST';
export const GET_STORES_PRODUCTS_SUCCESS =
  '@product/GET_STORES_PRODUCTS_SUCCESS';
export const GET_STORES_PRODUCTS_FAILURE =
  '@product/GET_STORES_PRODUCTS_FAILURE';

export const DELETE_PRODUCTS_REQUEST = '@product/DELETE_PRODUCTS_REQUEST';
export const DELETE_PRODUCTS_SUCCESS = '@product/DELETE_PRODUCTS_SUCCESS';
export const DELETE_PRODUCTS_FAILURE = '@product/DELETE_PRODUCTS_FAILURE';

export const DELETE_PRODUCTS_ALL_REQUEST =
  '@product/DELETE_PRODUCTS_ALL_REQUEST';
export const DELETE_PRODUCTS_ALL_SUCCESS =
  '@product/DELETE_PRODUCTS_ALL_SUCCESS';
export const DELETE_PRODUCTS_ALL_FAILURE =
  '@product/DELETE_PRODUCTS_ALL_FAILURE';

export const GET_USER_PRODUCTS_FAILURE = '@product/GET_USER_PRODUCTS_FAILURE';
export const GET_USER_PRODUCTS_REQUEST = '@product/GET_USER_PRODUCTS_REQUEST';
export const GET_USER_PRODUCTS_SUCCESS = '@product/GET_USER_PRODUCTS_SUCCESS';

export const GET_SHOP_PRODUCTS_FAILURE = '@product/GET_SHOP_PRODUCTS_FAILURE';
export const GET_SHOP_PRODUCTS_REQUEST = '@product/GET_SHOP_PRODUCTS_REQUEST';
export const GET_SHOP_PRODUCTS_SUCCESS = '@product/GET_SHOP_PRODUCTS_SUCCESS';

export const CHANGE_PRODUCT_DRIVERS_FAILURE =
  '@product/CHANGE_PRODUCT_DRIVERS_FAILURE';
export const CHANGE_PRODUCT_DRIVERS_REQUEST =
  '@product/CHANGE_PRODUCT_DRIVERS_REQUEST';
export const CHANGE_PRODUCT_DRIVERS_SUCCESS =
  '@product/CHANGE_PRODUCT_DRIVERS_SUCCESS';

export const CHANGE_PRODUCT_STORES_FAILURE =
  '@product/CHANGE_PRODUCT_STORES_FAILURE';
export const CHANGE_PRODUCT_STORES_REQUEST =
  '@product/CHANGE_PRODUCT_STORES_REQUEST';
export const CHANGE_PRODUCT_STORES_SUCCESS =
  '@product/CHANGE_PRODUCT_STORES_SUCCESS';

export const DELETE_PRODUCT_STORE_FAILURE =
  '@product/DELETE_PRODUCT_STORE_FAILURE';
export const DELETE_PRODUCT_STORE_REQUEST =
  '@product/DELETE_PRODUCT_STORE_REQUEST';
export const DELETE_PRODUCT_STORE_SUCCESS =
  '@product/DELETE_PRODUCT_STORE_SUCCESS';

export const DELETE_PRODUCTS_DRIVERS_FAILURE =
  '@product/DELETE_PRODUCTS_DRIVERS_FAILURE';
export const DELETE_PRODUCTS_DRIVERS_REQUEST =
  '@product/DELETE_PRODUCTS_DRIVERS_REQUEST';
export const DELETE_PRODUCTS_DRIVERS_SUCCESS =
  '@product/DELETE_PRODUCTS_DRIVERS_SUCCESS';

export const UPDATE_PRODUCTS_REQUEST = '@product/UPDATE_PRODUCTS_REQUEST';
export const UPDATE_PRODUCTS_SUCCESS = '@product/UPDATE_PRODUCTS_SUCCESS';
export const UPDATE_PRODUCTS_FAILURE = '@product/UPDATE_PRODUCTS_FAILURE';

export const CLEAR_PRODUCTS = '@product/CLEAR_PRODUCTS';
export const CLEAR_PRODUCTS_STORES = '@product/CLEAR_PRODUCTS_STORES';
export const CLEAR_PRODUCTS_DRIVERS = '@product/CLEAR_PRODUCTS_DRIVERS';
export const CLEAR_LIST_PRODUCTS = '@product/CLEAR_LIST_PRODUCTS';
export const CLEAR_IMAGE_TO_PRODUCTS = '@product/CLEAR_IMAGE_TO_PRODUCTS';
export const CLEAR_PROMOTION_IMAGE = '@product/CLEAR_PROMOTION_IMAGE';
