import {
  CLEAR_STOR,
  UPLOAD_MEDIA_FAILURE,
  UPLOAD_MEDIA_REQUEST,
  UPLOAD_MEDIA_SUCCESS,
} from './Media.constant';
import { IMediaState, MediaActionsTypes } from './Media.type';

const initialState: IMediaState = {
  file: null,
  loading: false,
  name: null,
};

export default function mediaReducer(
  state = initialState,
  action: MediaActionsTypes,
): IMediaState {
  switch (action.type) {
    case UPLOAD_MEDIA_REQUEST:
      return {
        ...state,
        file: null,
        loading: true,
      };
    case UPLOAD_MEDIA_SUCCESS: {
      return {
        ...state,
        file: action.payload.media,
        ...(action.payload.name && { name: action.payload.name }),
        loading: false,
      };
    }
    case UPLOAD_MEDIA_FAILURE:
      return { ...state, loading: false };
    case CLEAR_STOR:
      return {
        ...state,
        file: null,
        loading: false,
      };
    default:
      return state;
  }
}
