import { useState } from 'react';

import { Col, Form, Input, Row, Spin } from 'antd';
import './style.scss';

import { en } from '@i18n';
import { UIButton, UIModal } from '@ui';
import { Button } from '@utils';

import { Props } from './index.type';

const WidgetEditQuantity = (props: Props) => {
  const { handleDelete, update, name, quantity: quantityInitial } = props;

  const [form] = Form.useForm();
  const [quantity, setQuantity] = useState(quantityInitial);

  const onFormSubmitHandler = () => {
    update(quantity);
  };

  const footer = [
    <Row gutter={10}>
      <Col span={12}>
        <UIButton
          key="Save changes"
          typeAntBtn="ghost"
          handler={() => handleDelete()}
          type={Button.ANT_BUTTON}
          size="large"
          className="edit-form__save-button"
        >
          Delete
        </UIButton>
      </Col>
      <Col span={12}>
        <UIButton
          key="Save changes"
          typeAntBtn="primary"
          handler={() => form.submit()}
          type={Button.ANT_BUTTON}
          size="large"
          className="edit-form__save-button"
        >
          Save
        </UIButton>
      </Col>
    </Row>,
  ];

  return (
    <UIModal
      className="edit-form"
      title={'Edit'}
      footer={footer}
      visible
      children={
        <Spin spinning={false}>
          <Form
            form={form}
            requiredMark={false}
            labelCol={{ span: 14 }}
            wrapperCol={{ span: 4 }}
            size="large"
            onFinish={onFormSubmitHandler}
            autoComplete="off"
            validateMessages={en.validation}
          >
            <Form.Item label={name}>
              <Input
                value={+quantity}
                {...(+quantity < 1 && { status: 'error' })}
                min={1}
                type="number"
                placeholder="Quantity"
                onChange={(e) => {
                  setQuantity(+e.target.value);
                }}
              />
            </Form.Item>
          </Form>
        </Spin>
      }
    />
  );
};

export default WidgetEditQuantity;
