import { FullFilter } from '@entities/Filter';
import { FullSort } from '@entities/Sort';

export enum ActionStatus {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  LOADED = 'LOADED',
  NONE = 'NONE',
  UPDATED = 'UPDATED',
}

export enum ActiveStatus {
  NO = 'no',
  YES = 'yes',
}

export enum ApproveStatus {
  NO = 'no',
  YES = 'yes',
}

export enum Button {
  ANT_BUTTON = 'ant_button',
  BUTTON = 'button',
  LINK = 'link',
  SUBMIT = 'submit',
}

export enum NotificationType {
  CHAT = 'chat',
  NOTIFICATION = 'notification',
  ORDER = 'order',
  SUPPORT = 'support',
}

export type Notification = {
  createdAt: string;
  id?: number;
  text: string;
  type: NotificationType;
};

export type ButtonProps = {
  name: string;
  onClick?: () => void;
  type?: 'primary' | 'link' | 'text' | 'ghost' | 'default' | 'dashed';
};

export type EventValue<DateType> = DateType | null;

export type FilterTab = {
  label: string;
  options: QueryOptions;
};

export type FullQuery = {
  filter?: FullFilter | null;
  order?: FullSort | null;
};

export enum Header {
  BUTTON = 'button',
  TITLE = 'title',
}

export enum HttpExceptionType {
  REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
  REFRESH_TOKEN_VERIFY = 'REFRESH_TOKEN_VERIFY',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  TOKEN_MALFORMED = 'TOKEN_MALFORMED',
  TOKEN_NOT_PROVIDED = 'TOKEN_NOT_PROVIDED',
  TOKEN_VERIFY = 'TOKEN_VERIFY',
}

export enum HttpStatus {
  OK = 200,
  Created = 201,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export type OptionsToQuery = (
  { limit, page, filter, order, list }: QueryOptions,
  addQueryPrefix?: boolean,
) => string;

export enum ProcessStatus {
  APPROVED = 'approved',
  AWAITING_PICKUP = 'awaitingPickup',
  CANCELED = 'canceled',
  CANCELED_BY_ADMIN = 'canceledByAdmin',
  CANCELED_BY_CUSTOMER = 'canceledByCustomer',
  CANCELED_BY_DRIVER = 'canceledByDriver',
  COMPLETED = 'completed',
  DELIVERED = 'delivered',
  PENDING_DRIVER = 'pendingDriver',
  SHIPPED = 'shipped',
  VERIFICATION_REQUIRED = 'verificationRequired',
}

export type QueryOptions = {
  filter?: FullFilter | null;
  limit?: number;
  list?: string;
  order?: FullSort | null;
  page?: number;
  pagination?: boolean;
  search?: string | null;
};

type QsParseOption = {
  ignoreQueryPrefix: boolean;
};

export type QueryToOptions = (
  query: string,
  opt?: Partial<QsParseOption>,
) => QueryOptions;

export type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null;

export enum Role {
  ADMIN = 'admin',
  DRIVERS = 'driver',
  MANAGER = 'manager',
  USER = 'customer',
}

export enum DiscountUnit {
  CURRENCY = 'currency',
  PERCENT = 'percentage',
}

export enum TransactionClass {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export enum PaymentMethod {
  CARD = 'card',
  CASH = 'cash',
}

export enum ShippingMethod {
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
}

export enum TransactionOperation {
  EARNING = 'earning',
  PAYMENT = 'payment',
  SPENDING = 'spending',
  WITHDRAWAL = 'withdrawal',
}

export enum TransactionStatus {
  APPROVED = 'On The Way', // когда Драйвер принял заказ в работу (повез клиенту)
  AWAITING_PICKUP = 'Taken To Work', // когда ищется водитель или когда заказ отправлен на Pickup
  CANCELED = 'Canceled',
  CANCELED_BY_ADMIN = 'Canceled by admin',
  CANCELED_BY_CUSTOMER = 'Canceled by customer',
  CANCELED_BY_DRIVER = 'Canceled by driver',
  COMPLETED = 'Completed', // когда водитель проверил и загрузил документ, заказ считается завершённым, или клиент забрал заказ сам (т.е. pickup order когда был
  DELIVERED = 'Delivered', // когда водитель приехал на место, но ещё не отдал заказа, так как ему нужно проверить документы
  PENDING_DRIVER = 'Taken To Work', // когда ищется водитель или когда заказ отправлен на Pickup
  SHIPPED = 'On the way', // когда Драйвер принял заказ в работу (повез клиенту)
  VERIFICATION_REQUIRED = 'Pending', // когда не нашелся Драйвер или любой статус, который требует внимания админа
}

export enum TransactionType {
  INCOME = 'income',
  OUTCOME = 'outcome',
}

export type UploadFile = {
  file: Blob;
  image: 'avatar';
};

export type View = {
  name: string;
  query: string;
};

export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
}

export enum FileName {
  AUDIO = 'audio',
  DOCS = 'docs',
  IMAGE = 'image',
  METRC = 'metrc',
  VIDEO = 'video',
}

export type OptionSelect = {
  label: string;
  value: number | string;
};

export enum NotificationEvent {
  NOTIFICATION_SUBSCRIBE = 'notification:subscribe',
}
