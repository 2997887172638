import { useEffect, useState } from 'react';

import { Form, Spin } from 'antd';
import { useSelector } from 'react-redux';
import './style.scss';

import { FullMedia } from '@entities/Media';
import { updateCategoryRequest } from '@store/Category';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { FileName, UploadFile } from '@utils';

import CategoryEditForm from './Form';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';

const CategoryEdit = ({ category, loadingCategory }: Props) => {
  const { loading: loadingImage, file: fileStore } = useSelector(
    (state: RootState) => state.media,
  );
  const [imageFile, setImageFile] = useState<FullMedia | null>(null);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  const onFormSubmitHandler = (values: FormValues) => {
    dispatch(updateCategoryRequest({ ...values, id: category?.id }));
  };

  const onUploadImageHandler = (file: UploadFile['file']) => {
    dispatch(uploadMediaRequest({ file, fileName: FileName.IMAGE }));
  };

  const onRemoveImageHandler = () => {
    dispatch(clearStore());
    setImageFile(null);
  };

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fileStore) {
      setImageFile(fileStore);
    }
  }, [dispatch, fileStore]);

  useEffect(() => {
    if (category?.image) {
      setImageFile(category?.image);
    }
  }, [category?.image]);

  return (
    <div className="category-edit card-categories-info-category">
      <Spin spinning={loadingCategory || loadingImage}>
        <CategoryEditForm
          category={category}
          image={imageFile}
          form={form}
          onFormSubmit={onFormSubmitHandler}
          onUploadImage={onUploadImageHandler}
          onRemoveImage={onRemoveImageHandler}
        />
      </Spin>
    </div>
  );
};

export default CategoryEdit;
