import { Space } from 'antd';
import Text from 'antd/lib/typography/Text';

import {
  DashboardUIChartArea,
  DashboardUIProgress,
} from '@features/Dashboard/ui';
import { LayoutContainerWhite } from '@layouts';

import { Props } from './index.type';

const DashboardDetailChartArea = ({
  data,
  dataKey,
  theme,
  XAxisDataKey,
  span,
  flex,
  percent,
  value,
  prevValue,
  title,
  fullInterval,
  syncId,
  tooltipValue,
  tooltipLabelType,
}: Props) => {
  return (
    <LayoutContainerWhite
      title={title}
      titleSize="large"
      span={span}
      flex={flex}
    >
      {value && (
        <Space>
          <Text strong className="dashboard-info__value">
            {value}
            {percent && '%'}
          </Text>
          <DashboardUIProgress value={value} prevValue={prevValue} />
        </Space>
      )}
      <div style={{ height: '400px' }}>
        <DashboardUIChartArea
          data={data}
          dataKey={dataKey}
          theme={theme}
          XAxisDataKey={XAxisDataKey}
          fullInterval={fullInterval}
          syncId={syncId}
          tooltipValue={tooltipValue}
          tooltipLabelType={tooltipLabelType}
        />
      </div>
    </LayoutContainerWhite>
  );
};

export default DashboardDetailChartArea;
