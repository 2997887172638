import { Button, Slider, Space } from 'antd';

import { Props } from './index.type';

import './style.scss';

const WidgetModalFilterNumber = (props: Props) => {
  const { filterValue, onFilterUpdate, min, max, step } = props;

  const clearHandler = () => {
    onFilterUpdate(null);
  };

  const onChangeHandler = ([minVal, maxVal]: [number, number]) => {
    if (min === minVal && max === maxVal) {
      onFilterUpdate(null);
    } else if (min === minVal) {
      onFilterUpdate({ max: maxVal });
    } else if (max === maxVal) {
      onFilterUpdate({ min: minVal });
    } else {
      onFilterUpdate({ min: minVal, max: maxVal });
    }
  };

  return (
    <Space direction="vertical" className="filter-slider__space">
      <Slider
        range
        step={step}
        min={min}
        max={max}
        value={[filterValue?.min || min, filterValue?.max || max]}
        onChange={onChangeHandler}
        marks={{ [min]: `${min}`, [max]: `${max}` }}
      />
      <Button
        type="link"
        className="modal-filter__button-clear"
        onClick={clearHandler}
      >
        Clear
      </Button>
    </Space>
  );
};

export default WidgetModalFilterNumber;
