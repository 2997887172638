/* eslint-disable indent */
import { ColumnsType } from 'antd/lib/table';

import { FilterOption } from '@entities/Filter';
import { FullReward } from '@entities/Reward';
import { SortOption } from '@entities/Sort';
import { FullTaxes } from '@entities/Taxes';
import type { AppDispatch } from '@store/index';
import { UICellLink, UICellStatus, UICellTooltip } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS_SMALL,
  COLUMN_WIDTH_ACTIVE,
  COLUMN_WIDTH_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  DateHelper,
  FORMAT_DATE,
  FORMAT_SHORT_DATE,
} from '@utils';

import { RewardUIActions, RewardUIButton, TaxesUIActions } from './ui';

const today = new Date();

const columnsReward = ({
  pathname,
  query,
  dispatch,
}: {
  dispatch: AppDispatch;
  pathname: string;
  query: string;
}): ColumnsType<FullReward> => {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) =>
        UICellLink({ value, link: `${pathname}/${record.id}` }),
      width: COLUMN_WIDTH_ID,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: COLUMN_WIDTH_NAME,
      render: (text) => UICellTooltip({ text }),
    },
    {
      title: 'Points quantity',
      dataIndex: 'pointQuantity',
      key: 'pointQuantity',
      width: 100,
      render: (text) => UICellTooltip({ text }),
    },
    {
      title: 'Promo code',
      dataIndex: 'promoCode',
      key: 'promoCode',
      width: 100,
      render: (text) => UICellTooltip({ text }),
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      width: COLUMN_WIDTH_ACTIVE,
      render: (value) =>
        UICellStatus({
          value,
          text: value ? 'Active' : 'Inactive',
        }),
    },
    {
      title: 'Reg Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: COLUMN_WIDTH_DATE,
      render: (value: string) =>
        DateHelper.formateDateToString(value, FORMAT_SHORT_DATE),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      width: COLUMN_WIDTH_ACTIONS_SMALL,
      render: (id, reward) =>
        RewardUIActions({
          id,
          reward,
          query,
          dispatch,
        }),
    },
  ];
};

const columnsTaxes = ({
  pathname,
  query,
  dispatch,
}: {
  dispatch: AppDispatch;
  pathname: string;
  query: string;
}): ColumnsType<FullTaxes> => {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) =>
        UICellLink({ value, link: `${pathname}/${record.id}` }),
      width: COLUMN_WIDTH_ID,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: 40,
      render: (text) => UICellTooltip({ text }),
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 40,
      render: (text) => UICellTooltip({ text }),
    },
    {
      title: 'County',
      dataIndex: 'county',
      key: 'county',
      width: 150,
      render: (text) => UICellTooltip({ text }),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 150,
      render: (text) => UICellTooltip({ text }),
    },
    {
      title: 'Sale rate',
      dataIndex: 'saleRate',
      key: 'saleRate',
      width: COLUMN_WIDTH_NUMBER,
      render: (value: number) => `${value}%`,
    },
    {
      title: 'Business medical rate',
      dataIndex: 'businessMedicalRate',
      key: 'businessMedicalRate',
      width: COLUMN_WIDTH_NUMBER,
      render: (value: number) => `${value}%`,
    },
    {
      title: 'Business recreational rate',
      dataIndex: 'businessRecreationalRate',
      key: 'businessRecreationalRate',
      width: COLUMN_WIDTH_NUMBER,
      render: (value: number) => `${value}%`,
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      width: COLUMN_WIDTH_ACTIVE,
      render: (value) =>
        UICellStatus({
          value,
          text: value ? 'Active' : 'Inactive',
        }),
    },
    {
      title: 'Reg Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: COLUMN_WIDTH_DATE,
      render: (value: string) =>
        DateHelper.formateDateToString(value, FORMAT_SHORT_DATE),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      width: COLUMN_WIDTH_ACTIONS_SMALL,
      render: (id, taxes) =>
        TaxesUIActions({
          id,
          taxes,
          query,
          dispatch,
        }),
    },
  ];
};

export const sortOptionReward: SortOption[] = [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  {
    label: 'Name (A-Z)',
    name: 'name',
    direction: 'asc',
  },
  {
    label: 'Name (Z-A)',
    name: 'name',
    direction: 'desc',
  },
  {
    label: 'Reg Date (oldest first)',
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: 'Reg Date (newest first)',
    name: 'created_at',
    direction: 'desc',
  },
];

export const sortOptionTaxes: SortOption[] = [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  {
    label: 'Reg Date (oldest first)',
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: 'Reg Date (newest first)',
    name: 'created_at',
    direction: 'desc',
  },
];

const filterOptionsReward = () => [
  {
    name: 'is_active',
    label: 'Active',
    type: 'radio',
    options: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'created_at',
    label: 'Reg date',
    type: 'radio-range',
    options: [
      {
        label: 'Today',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 7 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -7,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 30 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -30,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    ],
  },
];

const filterOptionsTaxes = () => [
  {
    name: 'is_active',
    label: 'Active',
    type: 'radio',
    options: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
  {
    name: 'created_at',
    label: 'Reg date',
    type: 'radio-range',
    options: [
      {
        label: 'Today',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 7 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -7,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 30 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -30,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    ],
  },
];

const tabs = [
  {
    title: 'Rewards',
    key: 'rewards',
  },
  // {
  //   title: 'Delivery fee',
  //   key: 'deliveryFee',
  // },
  // {
  //   title: 'ExciseTax',
  //   key: 'exciseTax',
  // },
  {
    title: 'Taxes',
    key: 'taxes',
  },
  {
    title: 'Referral system',
    key: 'referral',
  },
];

const options = ({
  pathname,
  query,
  dispatch,
  tabsActive,
}: {
  dispatch: AppDispatch;
  pathname: string;
  query: string;
  tabsActive: string | null;
}) => ({
  title: 'Settings',
  tabs,
  sortOption: (() => {
    switch (tabsActive) {
      case 'taxes': {
        return sortOptionTaxes;
      }
      default:
        return sortOptionReward;
    }
  })(),
  filterOptions: (() => {
    switch (tabsActive) {
      case 'taxes': {
        return filterOptionsTaxes();
      }
      default:
        return filterOptionsReward();
    }
  })() as FilterOption[],
  headerButtons: (() => {
    switch (tabsActive) {
      case 'taxes': {
        return null;
        // return TaxesUIButton();
      }
      default:
        return RewardUIButton();
    }
  })(),
  columns: (() => {
    switch (tabsActive) {
      case 'taxes': {
        return columnsTaxes({ dispatch, pathname, query });
      }
      default:
        return columnsReward({ dispatch, pathname, query });
    }
  })(),
});

export default options;
