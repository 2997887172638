import { Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { UICellStatus } from '@ui';
import { DateHelper, FORMAT_SHORT_DATE, StringHelper } from '@utils';

import { Props } from './index.type';

import './style.scss';

const TaxesDetailDescription = ({ loading, taxes }: Props) => {
  return (
    <Spin size="large" spinning={loading}>
      <div className="card-brands-info-brand">
        <div className="card-brands-info-brand-content">
          <Title level={5}>City</Title>
          <Text>{taxes?.city}</Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>County</Title>
          <Text>{taxes?.county}</Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Country</Title>
          <Text>{taxes?.country}</Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>State</Title>
          <Text>{taxes?.state}</Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Sale rate</Title>
          <Text>{taxes?.saleRate || 0}%</Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Business medical rate</Title>
          <Text>{StringHelper.toMoney(taxes?.businessMedicalRate || 0)}</Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Business recreational rate</Title>
          <Text>
            {StringHelper.toMoney(taxes?.businessRecreationalRate || 0)}
          </Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Active</Title>
          <Text>
            {UICellStatus({
              value: !!taxes?.isActive,
              text: taxes?.isActive ? 'Active' : 'Inactive',
            })}
          </Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Reg Date</Title>
          <Text>
            {DateHelper.formateDateToString(
              taxes?.createdAt,
              FORMAT_SHORT_DATE,
            )}
          </Text>
        </div>
      </div>
    </Spin>
  );
};

export default TaxesDetailDescription;
