export const GET_LIST_SHOP_REQUEST = '@shop/GET_LIST_SHOP_REQUEST';
export const GET_LIST_SHOP_SUCCESS = '@shop/GET_LIST_SHOP_SUCCESS';
export const GET_LIST_SHOP_FAILURE = '@shop/GET_LIST_SHOP_FAILURE';

export const CREATE_SHOP_REQUEST = '@shop/CREATE_SHOP_REQUEST';
export const CREATE_SHOP_SUCCESS = '@shop/CREATE_SHOP_SUCCESS';
export const CREATE_SHOP_FAILURE = '@shop/CREATE_SHOP_FAILURE';

export const GET_ONE_SHOP_REQUEST = '@shop/GET_ONE_SHOP_REQUEST';
export const GET_ONE_SHOP_SUCCESS = '@shop/GET_ONE_SHOP_SUCCESS';
export const GET_ONE_SHOP_FAILURE = '@shop/GET_ONE_SHOP_FAILURE';

export const DELETE_SHOP_REQUEST = '@shop/DELETE_SHOP_REQUEST';
export const DELETE_SHOP_SUCCESS = '@shop/DELETE_SHOP_SUCCESS';
export const DELETE_SHOP_FAILURE = '@shop/DELETE_SHOP_FAILURE';

export const UPDATE_SHOP_REQUEST = '@shop/UPDATE_SHOP_REQUEST';
export const UPDATE_SHOP_SUCCESS = '@shop/UPDATE_SHOP_SUCCESS';
export const UPDATE_SHOP_FAILURE = '@shop/UPDATE_SHOP_FAILURE';

export const CLEAR_SHOP = '@shop/CLEAR_SHOP';
export const CLEAR_LIST_SHOP = '@shop/CLEAR_LIST_SHOP';
export const CLEAR_IMAGE_TO_SHOP = '@shop/CLEAR_IMAGE_TO_SHOP';
