/* eslint-disable indent */
import React, { useEffect } from 'react';

import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LayoutContent } from '@layouts';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import {
  clearTaxes,
  deleteTaxesRequest,
  getOneTaxesRequest,
} from '@store/Setting';
import { UIButton, UIHeader } from '@ui';
import { Button, LINK_SETTING } from '@utils';

import TaxesDetailDescription from './Description';
import TaxesEdit from './Edit';
import './style.scss';

const SettingsTaxDetail = () => {
  const dispatch = useAppDispatch();
  const { settingsId } = useParams<'settingsId'>();
  const { taxes, loading } = useSelector((state: RootState) => state.setting);

  useEffect(() => {
    if (settingsId) {
      dispatch(getOneTaxesRequest({ id: +settingsId }));
    }
  }, [dispatch, settingsId]);

  useEffect(() => {
    return () => {
      LocalStorage.clear('isBack');
      dispatch(clearTaxes());
    };
  }, [dispatch]);

  // const onChangeStatus = () => {
  //   if (taxes) {
  //     dispatch(
  //       showModal({
  //         modalType: ModalType.CONFIRM,
  //         modalProps: {
  //           content: (
  //             <p>
  //               Are you sure you want to{' '}
  //               {!taxes.isActive ? 'verify' : 'unverify'} the taxes
  //               <b> {taxes?.name || ''}</b>?
  //             </p>
  //           ),
  //           action: () =>
  //             dispatch(
  //               updateTaxesRequest({
  //                 id: taxes.id,
  //                 isActive: !taxes.isActive,
  //               }),
  //             ),
  //         },
  //       }),
  //     );
  //   }
  // };

  if (!settingsId) {
    return null;
  }

  const onDeleteTaxesHandler = () => {
    if (taxes) {
      dispatch(
        showModal({
          modalType: ModalType.CONFIRM,
          modalProps: {
            content: (
              <p>
                Are you sure you want to delete the Taxes
                <b> {taxes?.city || ''}</b>?
              </p>
            ),
            action: () => dispatch(deleteTaxesRequest(taxes.id)),
          },
        }),
      );
    }
  };

  const aside = [
    <TaxesDetailDescription taxes={taxes} loading={loading} />,
    // taxes && (
    //   <UIButton
    //     className="delete-brand-button"
    //     children={`${!taxes?.isActive ? 'Verify' : 'Unverify'} the taxes`}
    //     size="middle"
    //     type={Button.ANT_BUTTON}
    //     prop={{ danger: taxes?.isActive }}
    //     handler={onChangeStatus}
    //   />
    // ),
    <UIButton
      className="delete-brand-button"
      children="Delete the taxes"
      size="middle"
      type={Button.ANT_BUTTON}
      prop={{ danger: true }}
      handler={onDeleteTaxesHandler}
    />,
  ];

  const content = [taxes && <TaxesEdit taxes={taxes} loading={loading} />];

  return (
    <Space direction="vertical">
      <UIHeader
        isBack
        backLink={LocalStorage.get('isBack') || `/${taxes && LINK_SETTING}`}
        title={taxes?.city || ''}
      />
      <LayoutContent
        loadingContent={loading}
        content={content}
        loadingAside={loading}
        aside={aside}
      />
    </Space>
  );
};

export default React.memo(SettingsTaxDetail);
