export const CLEAR_LIST_CSV = '@csv/CLEAR_LIST_CSV';
export const UPDATE_LIST = '@csv/UPDATE_LIST';

export const GET_LIST_CSV_REQUEST = '@csv/GET_LIST_CSV_REQUEST';
export const GET_LIST_CSV_SUCCESS = '@csv/GET_LIST_CSV_SUCCESS';
export const GET_LIST_CSV_FAILURE = '@csv/GET_LIST_CSV_FAILURE';

export const CREATE_CSV_REQUEST = '@csv/CREATE_CSV_REQUEST';
export const CREATE_CSV_SUCCESS = '@csv/CREATE_CSV_SUCCESS';
export const CREATE_CSV_FAILURE = '@csv/CREATE_CSV_FAILURE';

export const DELETE_CSV_REQUEST = '@csv/DELETE_CSV_REQUEST';
export const DELETE_CSV_SUCCESS = '@csv/DELETE_CSV_SUCCESS';
export const DELETE_CSV_FAILURE = '@csv/DELETE_CSV_FAILURE';

export const UPDATE_CSV_REQUEST = '@csv/UPDATE_CSV_REQUEST';
export const UPDATE_CSV_SUCCESS = '@csv/UPDATE_CSV_SUCCESS';
export const UPDATE_CSV_FAILURE = '@csv/UPDATE_CSV_FAILURE';

export const EXPORT_CSV_REQUEST = '@csv/EXPORT_CSV_REQUEST';
export const EXPORT_CSV_SUCCESS = '@csv/EXPORT_CSV_SUCCESS';
export const EXPORT_CSV_FAILURE = '@csv/EXPORT_CSV_FAILURE';
