import { useEffect } from 'react';

import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './style.scss';

import { LayoutContent } from '@layouts';
import {
  clearEffect,
  deleteEffectRequest,
  getOneEffectRequest,
} from '@store/Effect';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { UIButton, UIHeader } from '@ui';
import { ActionStatus, Button as ButtonType } from '@utils';

import EffectDetailDiscription from './Discription';
import EffectEdit from './Edit';

const EffectDetail = () => {
  const dispatch = useAppDispatch();
  const { status, effect, loading } = useSelector(
    (state: RootState) => state.effect,
  );
  const { effectId } = useParams<'effectId'>();

  useEffect(() => {
    if (effectId) {
      dispatch(getOneEffectRequest(+effectId));
    }
  }, [effectId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearEffect());
    };
  }, [dispatch]);

  useEffect(() => {
    if (status === ActionStatus.DELETED) {
      window.history.back();
    }
  }, [status]);

  // const onChangeVisible = () => {
  //   if (effectId) {
  //     dispatch(
  //       updateEffectRequest({
  //         id: +effectId,
  //         isActive: !effect?.isActive,
  //       }),
  //     );
  //   }
  // };

  const aside = [
    // <Row justify="space-between" align="middle" className="visible-wrapper">
    //   <Text strong>Visible</Text>
    //   <Switch checked={effect?.isActive} onChange={onChangeVisible} />
    // </Row>,
    <EffectDetailDiscription effect={effect} loading={loading} />,
    <UIButton
      className="delete-effect-button"
      children="Delete Effect"
      size="middle"
      type={ButtonType.ANT_BUTTON}
      prop={{ danger: true }}
      handler={() => {
        if (effectId) {
          dispatch(
            showModal({
              modalType: ModalType.CONFIRM,
              modalProps: {
                content: <p>Are you sure you want to delete the Effect</p>,
                action: () => dispatch(deleteEffectRequest(+effectId)),
              },
            }),
          );
        }
      }}
    />,
  ];

  const content = [
    effect && <EffectEdit effect={effect} loadingEffect={loading} />,
  ];

  return (
    <Space direction="vertical">
      <UIHeader isBack title={effect?.name} />
      <LayoutContent content={content} loadingAside={loading} aside={aside} />
    </Space>
  );
};

export default EffectDetail;
