import axios, { CancelTokenSource } from 'axios';

import { FullReward } from '@entities/Reward';
import { http } from '@utils';

const basePath = '/api/rewards';
let axiosSource: CancelTokenSource | null = null;

export const getList = async (
  query?: Query['query'],
  isCancel = true,
): Promise<{
  data: FullReward[];
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<FullReward[]>>(
    `${basePath}${query || ''}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};

export const create = async (
  body: Pick<FullReward, 'name' | 'description'>,
) => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullReward>>(`${basePath}`, body);

  return data;
};

export const remove = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const getOne = async (id: FullReward['id']): Promise<FullReward> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullReward>>(`${basePath}/${id}`);

  return data;
};

export const update = async (
  id: number,
  body: Partial<FullReward>,
): Promise<FullReward> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullReward>>(`${basePath}/${id}`, body);

  return data;
};
