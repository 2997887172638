import { Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { UICellStatus } from '@ui';
import { DateHelper, FORMAT_SHORT_DATE } from '@utils';

import { Props } from './index.type';

import './style.scss';

const RewardDetailDescription = ({ loading, reward }: Props) => {
  return (
    <Spin size="large" spinning={loading}>
      <div className="card-brands-info-brand">
        <div className="card-brands-info-brand-content">
          <Title level={5}>Name of the reward</Title>
          <Text>{reward?.name}</Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Promo code</Title>
          <Text>{reward?.promoCode}</Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Points quantity</Title>
          <Text>{reward?.pointQuantity}</Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Description</Title>
          <Text>{reward?.description}</Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Active</Title>
          <Text>
            {UICellStatus({
              value: !!reward?.isActive,
              text: reward?.isActive ? 'Active' : 'Inactive',
            })}
          </Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Reg Date</Title>
          <Text>
            {DateHelper.formateDateToString(
              reward?.createdAt,
              FORMAT_SHORT_DATE,
            )}
          </Text>
        </div>
      </div>
    </Spin>
  );
};

export default RewardDetailDescription;
