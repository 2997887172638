/* eslint-disable indent */
import React, { useEffect } from 'react';

import { Space } from 'antd';
import { useSelector } from 'react-redux';

import { LayoutContent } from '@layouts';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import { clearReferral } from '@store/Setting';
import { UIHeader } from '@ui';

import ReferralDetailDescription from './Description';
import ReferralEdit from './Edit';
import './style.scss';

const SettingsReferralDetail = () => {
  const dispatch = useAppDispatch();
  const { referral, loading } = useSelector(
    (state: RootState) => state.setting,
  );

  useEffect(() => {
    return () => {
      LocalStorage.clear('isBack');
      dispatch(clearReferral());
    };
  }, [dispatch]);

  const aside = [
    <ReferralDetailDescription referral={referral} loading={loading} />,
  ];

  const content = [<ReferralEdit referral={referral} loading={loading} />];

  return (
    <Space direction="vertical">
      <UIHeader title={'Referral system'} />
      <LayoutContent
        loadingContent={loading}
        content={content}
        loadingAside={loading}
        aside={aside}
      />
    </Space>
  );
};

export default React.memo(SettingsReferralDetail);
