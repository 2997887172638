import { ExportMetrc, FullCsv, FullMetrc } from '@entities/Csv';

import {
  CLEAR_LIST_CSV,
  CREATE_CSV_FAILURE,
  CREATE_CSV_REQUEST,
  CREATE_CSV_SUCCESS,
  DELETE_CSV_FAILURE,
  DELETE_CSV_REQUEST,
  DELETE_CSV_SUCCESS,
  EXPORT_CSV_FAILURE,
  EXPORT_CSV_REQUEST,
  EXPORT_CSV_SUCCESS,
  GET_LIST_CSV_FAILURE,
  GET_LIST_CSV_REQUEST,
  GET_LIST_CSV_SUCCESS,
  UPDATE_CSV_FAILURE,
  UPDATE_CSV_REQUEST,
  UPDATE_CSV_SUCCESS,
  UPDATE_LIST,
} from './Csv.constant';
import { CsvActionsTypes } from './Csv.type';

export function getListCsvRequest(query = ''): CsvActionsTypes {
  return {
    type: GET_LIST_CSV_REQUEST,
    payload: {
      query,
    },
  };
}

export function updateListMetrc(payload: boolean): CsvActionsTypes {
  return {
    type: UPDATE_LIST,
    payload,
  };
}

export function getListCsvSuccess(
  payload: DataResponse<FullMetrc[]>,
): CsvActionsTypes {
  return {
    type: GET_LIST_CSV_SUCCESS,
    payload,
  };
}

export function getListCsvFailure(): CsvActionsTypes {
  return {
    type: GET_LIST_CSV_FAILURE,
  };
}

export function createCsvRequest(payload: FullCsv): CsvActionsTypes {
  return {
    type: CREATE_CSV_REQUEST,
    payload,
  };
}

export function createCsvSuccess(): CsvActionsTypes {
  return {
    type: CREATE_CSV_SUCCESS,
  };
}

export function createCsvFailure(): CsvActionsTypes {
  return {
    type: CREATE_CSV_FAILURE,
  };
}

export function deleteCsvRequest(payload: Id): CsvActionsTypes {
  return {
    type: DELETE_CSV_REQUEST,
    payload,
  };
}

export function deleteCsvSuccess(): CsvActionsTypes {
  return {
    type: DELETE_CSV_SUCCESS,
  };
}

export function deleteCsvFailure(): CsvActionsTypes {
  return {
    type: DELETE_CSV_FAILURE,
  };
}

export function updateCsvRequest(
  payload: Pick<FullCsv, 'id'>,
): CsvActionsTypes {
  return {
    type: UPDATE_CSV_REQUEST,
    payload,
  };
}

export function updateCsvSuccess(payload: FullCsv): CsvActionsTypes {
  return {
    type: UPDATE_CSV_SUCCESS,
    payload,
  };
}

export function updateCsvFailure(): CsvActionsTypes {
  return {
    type: UPDATE_CSV_FAILURE,
  };
}

export function clearListCsv(): CsvActionsTypes {
  return {
    type: CLEAR_LIST_CSV,
  };
}

export function exportCsvRequest(payload: ExportMetrc): CsvActionsTypes {
  return {
    type: EXPORT_CSV_REQUEST,
    payload,
  };
}

export function exportCsvFailure(): CsvActionsTypes {
  return {
    type: EXPORT_CSV_FAILURE,
  };
}

export function exportCsvSuccess(): CsvActionsTypes {
  return {
    type: EXPORT_CSV_SUCCESS,
  };
}
