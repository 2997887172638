import { ExportMetrc, FullCsv, FullMetrc } from '@entities/Csv';
import { http } from '@utils';

const basePath = '/api/csv';
const metricPath = '/api/metrc-files';

export const getListCsv = async (
  query: string,
): Promise<DataResponse<FullMetrc[]>> => {
  const { data } = await http.get<DataResponse<FullMetrc[]>>(
    `${metricPath}${query}`,
  );

  return data;
};

export const createCsv = async (data: FullCsv): Promise<void> => {
  await http.post(basePath, data);
};

export const deleteCsv = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const updateCsv = async (
  id: number,
  body: FullCsv,
): Promise<FullCsv> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullCsv>>(`${basePath}/${id}`, body);

  return data;
};

export const exportCsv = async (body: ExportMetrc): Promise<void> => {
  await http.post(`${metricPath}/export`, body);
};
