import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullTransaction } from '@entities/Transaction';
import { TransactionHttp } from '@services/http';
import { alertError } from '@store/Alert';

import {
  getListTransactionFailure,
  getListTransactionSuccess,
} from './Transaction.action';
import { GET_LIST_TRANSACTION_REQUEST } from './Transaction.constant';
import { IGetListTransactionRequest } from './Transaction.type';

function* workerGetListTransaction(action: IGetListTransactionRequest) {
  try {
    const { query } = action.payload;

    const payload: DataResponse<FullTransaction[]> = yield call(
      TransactionHttp.getList,
      query,
    );

    yield put(getListTransactionSuccess(payload));
  } catch (error) {
    yield put(getListTransactionFailure());
    yield put(alertError(error));
  }
}

function* watchGetListTransaction() {
  yield takeLatest(GET_LIST_TRANSACTION_REQUEST, workerGetListTransaction);
}

export const transactionWatchers: ForkEffect[] = [
  fork(watchGetListTransaction),
];
