import { Socket } from 'socket.io-client';

import {
  CreateMessageSupport,
  FullSupport,
  SupportMessage,
} from '@entities/Support';

import {
  CLEAR_CHAT,
  CLEAR_MESSAGE,
  CREATE_MESSAGE_REQUEST,
  GET_LIST_CHAT_FAILURE,
  GET_LIST_CHAT_REQUEST,
  GET_LIST_CHAT_SUCCESS,
  GET_LIST_MESSAGE_FAILURE,
  GET_LIST_MESSAGE_REQUEST,
  GET_LIST_MESSAGE_SUCCESS,
  GET_ONE_CHAT_FAILURE,
  GET_ONE_CHAT_REQUEST,
  GET_ONE_CHAT_SUCCESS,
  SET_DIALOG_ID,
  SET_MESSAGE_RECEIVE,
} from './Support.constant';
import { SupportActionsTypes } from './Support.type';

export function setDialogId(payload: number | null): SupportActionsTypes {
  return {
    type: SET_DIALOG_ID,
    payload,
  };
}

export function setMessageReceive({
  chat,
}: {
  chat: FullSupport;
}): SupportActionsTypes {
  return {
    type: SET_MESSAGE_RECEIVE,
    payload: {
      chat,
    },
  };
}

export function createMessageRequest(payload: {
  message: CreateMessageSupport;
  socket: Socket;
}): SupportActionsTypes {
  return {
    type: CREATE_MESSAGE_REQUEST,
    payload,
  };
}

export function getListSupportRequest(
  query?: Query['query'],
): SupportActionsTypes {
  return {
    type: GET_LIST_CHAT_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListSupportSuccess(
  payload: DataResponse<FullSupport[]>,
): SupportActionsTypes {
  return {
    type: GET_LIST_CHAT_SUCCESS,
    payload,
  };
}

export function getListSupportFailure(): SupportActionsTypes {
  return {
    type: GET_LIST_CHAT_FAILURE,
  };
}

export function getListMessageRequest(payload: {
  id: number;
  query?: Query['query'];
}): SupportActionsTypes {
  return {
    type: GET_LIST_MESSAGE_REQUEST,
    payload,
  };
}

export function getListMessageSuccess(
  payload: DataResponse<SupportMessage[]>,
): SupportActionsTypes {
  return {
    type: GET_LIST_MESSAGE_SUCCESS,
    payload,
  };
}

export function getListMessageFailure(): SupportActionsTypes {
  return {
    type: GET_LIST_MESSAGE_FAILURE,
  };
}

export function getOneSupportRequest(
  id: FullSupport['id'],
): SupportActionsTypes {
  return {
    type: GET_ONE_CHAT_REQUEST,
    payload: {
      id,
    },
  };
}

export function getOneSupportSuccess(
  payload: FullSupport,
): SupportActionsTypes {
  return {
    type: GET_ONE_CHAT_SUCCESS,
    payload,
  };
}

export function getOneSupportFailure(): SupportActionsTypes {
  return {
    type: GET_ONE_CHAT_FAILURE,
  };
}

export function clearSupport(): SupportActionsTypes {
  return {
    type: CLEAR_CHAT,
  };
}

export function clearSupportMessage(): SupportActionsTypes {
  return {
    type: CLEAR_MESSAGE,
  };
}
