import { Route, Routes } from 'react-router-dom';

import { ChatDetail, ChatList } from '@features/Chat';

const ViewChat = () => {
  return (
    <Routes>
      <Route path=":chatId" element={<ChatDetail />} />
      <Route path="/" element={<ChatList />} />
    </Routes>
  );
};

export default ViewChat;
