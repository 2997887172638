import { Referral } from '@entities/Referral';
import { FullReward } from '@entities/Reward';
import { FullTaxes } from '@entities/Taxes';

import {
  CLEAR_LIST_REWARD,
  CLEAR_LIST_TAXES,
  CLEAR_REFERRAL,
  CLEAR_REWARD,
  CLEAR_TAXES,
  CREATE_REWARD_FAILURE,
  CREATE_REWARD_REQUEST,
  CREATE_REWARD_SUCCESS,
  CREATE_TAXES_FAILURE,
  CREATE_TAXES_REQUEST,
  CREATE_TAXES_SUCCESS,
  DELETE_REWARD_FAILURE,
  DELETE_REWARD_REQUEST,
  DELETE_REWARD_SUCCESS,
  DELETE_TAXES_FAILURE,
  DELETE_TAXES_REQUEST,
  DELETE_TAXES_SUCCESS,
  GET_LIST_REWARD_FAILURE,
  GET_LIST_REWARD_REQUEST,
  GET_LIST_REWARD_SUCCESS,
  GET_LIST_TAXES_FAILURE,
  GET_LIST_TAXES_REQUEST,
  GET_LIST_TAXES_SUCCESS,
  GET_ONE_REFERRAL_FAILURE,
  GET_ONE_REFERRAL_REQUEST,
  GET_ONE_REFERRAL_SUCCESS,
  GET_ONE_REWARD_FAILURE,
  GET_ONE_REWARD_REQUEST,
  GET_ONE_REWARD_SUCCESS,
  GET_ONE_TAXES_FAILURE,
  GET_ONE_TAXES_REQUEST,
  GET_ONE_TAXES_SUCCESS,
  UPDATE_REFERRAL_FAILURE,
  UPDATE_REFERRAL_REQUEST,
  UPDATE_REFERRAL_SUCCESS,
  UPDATE_REWARD_FAILURE,
  UPDATE_REWARD_REQUEST,
  UPDATE_REWARD_SUCCESS,
  UPDATE_TAXES_FAILURE,
  UPDATE_TAXES_REQUEST,
  UPDATE_TAXES_SUCCESS,
} from './Setting.constant';
import { SettingActionsTypes } from './Setting.type';

export function getListRewardRequest(
  query?: Query['query'],
): SettingActionsTypes {
  return {
    type: GET_LIST_REWARD_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListRewardSuccess(
  payload: DataResponse<FullReward[]>,
): SettingActionsTypes {
  return {
    type: GET_LIST_REWARD_SUCCESS,
    payload,
  };
}

export function getListRewardFailure(): SettingActionsTypes {
  return {
    type: GET_LIST_REWARD_FAILURE,
  };
}

export function createRewardRequest(
  payload: Omit<FullReward, 'id' | 'isActive'>,
): SettingActionsTypes {
  return {
    type: CREATE_REWARD_REQUEST,
    payload,
  };
}

export function createRewardSuccess(payload: FullReward): SettingActionsTypes {
  return {
    type: CREATE_REWARD_SUCCESS,
    payload,
  };
}

export function createRewardFailure(): SettingActionsTypes {
  return {
    type: CREATE_REWARD_FAILURE,
  };
}

export function deleteRewardRequest(id: FullReward['id']): SettingActionsTypes {
  return {
    type: DELETE_REWARD_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteRewardSuccess(
  payload: FullReward['id'],
): SettingActionsTypes {
  return {
    type: DELETE_REWARD_SUCCESS,
    payload,
  };
}

export function deleteRewardFailure(): SettingActionsTypes {
  return {
    type: DELETE_REWARD_FAILURE,
  };
}

export function getOneRewardRequest({
  id,
  toList,
}: {
  id: number;
  toList?: boolean;
}): SettingActionsTypes {
  return {
    type: GET_ONE_REWARD_REQUEST,
    payload: {
      id,
      toList,
    },
  };
}

export function getOneRewardSuccess({
  data,
  toList,
}: {
  data: FullReward;
  toList?: boolean;
}): SettingActionsTypes {
  return {
    type: GET_ONE_REWARD_SUCCESS,
    payload: {
      data,
      toList,
    },
  };
}

export function getOneRewardFailure(): SettingActionsTypes {
  return {
    type: GET_ONE_REWARD_FAILURE,
  };
}

export function updateRewardRequest(
  payload: Pick<FullReward, 'id'> & Partial<FullReward>,
): SettingActionsTypes {
  return {
    type: UPDATE_REWARD_REQUEST,
    payload,
  };
}

export function updateRewardSuccess(payload: FullReward): SettingActionsTypes {
  return {
    type: UPDATE_REWARD_SUCCESS,
    payload,
  };
}

export function updateRewardFailure(): SettingActionsTypes {
  return {
    type: UPDATE_REWARD_FAILURE,
  };
}

export function clearReward(): SettingActionsTypes {
  return {
    type: CLEAR_REWARD,
  };
}

export function clearListReward(): SettingActionsTypes {
  return {
    type: CLEAR_LIST_REWARD,
  };
}

export function getListTaxesRequest(
  query?: Query['query'],
): SettingActionsTypes {
  return {
    type: GET_LIST_TAXES_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListTaxesSuccess(
  payload: DataResponse<FullTaxes[]>,
): SettingActionsTypes {
  return {
    type: GET_LIST_TAXES_SUCCESS,
    payload,
  };
}

export function getListTaxesFailure(): SettingActionsTypes {
  return {
    type: GET_LIST_TAXES_FAILURE,
  };
}

export function createTaxesRequest(
  payload: Omit<FullTaxes, 'id' | 'isActive'>,
): SettingActionsTypes {
  return {
    type: CREATE_TAXES_REQUEST,
    payload,
  };
}

export function createTaxesSuccess(payload: FullTaxes): SettingActionsTypes {
  return {
    type: CREATE_TAXES_SUCCESS,
    payload,
  };
}

export function createTaxesFailure(): SettingActionsTypes {
  return {
    type: CREATE_TAXES_FAILURE,
  };
}

export function deleteTaxesRequest(id: FullTaxes['id']): SettingActionsTypes {
  return {
    type: DELETE_TAXES_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteTaxesSuccess(
  payload: FullTaxes['id'],
): SettingActionsTypes {
  return {
    type: DELETE_TAXES_SUCCESS,
    payload,
  };
}

export function deleteTaxesFailure(): SettingActionsTypes {
  return {
    type: DELETE_TAXES_FAILURE,
  };
}

export function getOneTaxesRequest({
  id,
  toList,
}: {
  id: number;
  toList?: boolean;
}): SettingActionsTypes {
  return {
    type: GET_ONE_TAXES_REQUEST,
    payload: {
      id,
      toList,
    },
  };
}

export function getOneTaxesSuccess({
  data,
  toList,
}: {
  data: FullTaxes;
  toList?: boolean;
}): SettingActionsTypes {
  return {
    type: GET_ONE_TAXES_SUCCESS,
    payload: {
      data,
      toList,
    },
  };
}

export function getOneTaxesFailure(): SettingActionsTypes {
  return {
    type: GET_ONE_TAXES_FAILURE,
  };
}

export function updateTaxesRequest(
  payload: Pick<FullTaxes, 'id'> & Partial<FullTaxes>,
): SettingActionsTypes {
  return {
    type: UPDATE_TAXES_REQUEST,
    payload,
  };
}

export function updateTaxesSuccess(payload: FullTaxes): SettingActionsTypes {
  return {
    type: UPDATE_TAXES_SUCCESS,
    payload,
  };
}

export function updateTaxesFailure(): SettingActionsTypes {
  return {
    type: UPDATE_TAXES_FAILURE,
  };
}

export function clearTaxes(): SettingActionsTypes {
  return {
    type: CLEAR_TAXES,
  };
}

export function clearListTaxes(): SettingActionsTypes {
  return {
    type: CLEAR_LIST_TAXES,
  };
}

export function getOneReferralRequest(): SettingActionsTypes {
  return {
    type: GET_ONE_REFERRAL_REQUEST,
  };
}

export function getOneReferralSuccess({
  data,
}: {
  data: Referral;
}): SettingActionsTypes {
  return {
    type: GET_ONE_REFERRAL_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getOneReferralFailure(): SettingActionsTypes {
  return {
    type: GET_ONE_REFERRAL_FAILURE,
  };
}

export function updateReferralRequest(
  payload: Partial<Referral>,
): SettingActionsTypes {
  return {
    type: UPDATE_REFERRAL_REQUEST,
    payload,
  };
}

export function updateReferralSuccess(payload: Referral): SettingActionsTypes {
  return {
    type: UPDATE_REFERRAL_SUCCESS,
    payload,
  };
}

export function updateReferralFailure(): SettingActionsTypes {
  return {
    type: UPDATE_REFERRAL_FAILURE,
  };
}

export function clearReferral(): SettingActionsTypes {
  return {
    type: CLEAR_REFERRAL,
  };
}
