import { useCallback, useEffect } from 'react';

import { Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@hooks';
import { RootState, useAppDispatch } from '@store/index';
import { clearProductDrivers, getUserProductsRequest } from '@store/Product';
import { UITable } from '@ui';
import { LIMIT_DEFAULT } from '@utils';

import './style.scss';
import { columns } from './constant';

const ProductToDriver = ({ id }: { id: number }) => {
  const { query, updateQuery } = useQuery();
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  const { isChangeDriversList, driversList, loading, metaUser } = useSelector(
    (state: RootState) => state.product,
  );

  const getRequest = useCallback(() => {
    dispatch(clearProductDrivers());
    dispatch(getUserProductsRequest({ id, query }));
  }, [dispatch, query, id]);

  useEffect(() => {
    if (isChangeDriversList) {
      dispatch(clearProductDrivers());
      dispatch(getUserProductsRequest({ id, query }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isChangeDriversList]);

  useEffect(() => {
    if (search === '') {
      updateQuery({
        page: 1,
        limit: LIMIT_DEFAULT,
      });
    } else {
      getRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest]);

  const onPaginationChange = (page: number, limit = metaUser.limit) => {
    updateQuery({ page, limit });
  };

  return (
    <Spin size="large" spinning={false}>
      <div className="card-shops-info-shop">
        {driversList?.length ? (
          <div className="card-shops-info-shop-content">
            <Title level={5}>Products, assigned to the driver:</Title>
            <UITable
              dataSource={driversList?.map((item) => ({
                ...item,
                id: item.productId,
              }))}
              loading={loading}
              columns={columns({ dispatch })}
              onPaginationChange={onPaginationChange}
              meta={metaUser}
              adaptive={false}
            />
          </div>
        ) : (
          <div className="card-shops-info-shop-content">
            <Title level={5}>No products were assigned to this driver.</Title>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default ProductToDriver;
