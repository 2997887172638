import { LayoutMenu } from '@layouts';
import { ModalType, showModal } from '@store/Modal';
import { deleteProductRequest } from '@store/Product';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const ProductUIActionList = ({ id, dispatch }: Props) => {
  const onEditClickHandler = (
    e: React.MouseEvent<HTMLElement | HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.CREATE_PRODUCTS,
        modalProps: { productId: id },
      }),
    );
  };

  const onDeleteProductHandler = (
    e: React.MouseEvent<HTMLElement | HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: <p>Are you sure you want to delete the Product</p>,
          action: () => dispatch(deleteProductRequest(id)),
        },
      }),
    );
  };

  return UICellAction({
    dropdownMenu: LayoutMenu({
      buttons: [
        {
          title: 'Delete',
          onClick: (e) => onDeleteProductHandler(e),
        },
      ],
    }),
    buttons: [
      {
        title: 'Edit',
        onClick: (e) => onEditClickHandler(e),
      },
    ],
  });
};

export default ProductUIActionList;
