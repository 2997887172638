import { FullBrand } from '@entities/Brand';

import {
  CLEAR_BACKGROUND_TO_BRAND,
  CLEAR_BRAND,
  CLEAR_IMAGE_TO_BRAND,
  CLEAR_LIST_BRANDS,
  CREATE_BRAND_FAILURE,
  CREATE_BRAND_REQUEST,
  CREATE_BRAND_SUCCESS,
  DELETE_BRAND_FAILURE,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_SUCCESS,
  GET_LIST_BRAND_FAILURE,
  GET_LIST_BRAND_REQUEST,
  GET_LIST_BRAND_SUCCESS,
  GET_ONE_BRAND_FAILURE,
  GET_ONE_BRAND_REQUEST,
  GET_ONE_BRAND_SUCCESS,
  UPDATE_BRAND_FAILURE,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
} from './Brand.constant';
import { BrandActionsTypes } from './Brand.type';

export function getListBrandRequest(query?: Query['query']): BrandActionsTypes {
  return {
    type: GET_LIST_BRAND_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListBrandSuccess(
  payload: DataResponse<FullBrand[]>,
): BrandActionsTypes {
  return {
    type: GET_LIST_BRAND_SUCCESS,
    payload,
  };
}

export function getListBrandFailure(): BrandActionsTypes {
  return {
    type: GET_LIST_BRAND_FAILURE,
  };
}

export function createBrandRequest(
  payload: Omit<FullBrand, 'id' | 'isActive'>,
): BrandActionsTypes {
  return {
    type: CREATE_BRAND_REQUEST,
    payload,
  };
}

export function createBrandSuccess(payload: FullBrand): BrandActionsTypes {
  return {
    type: CREATE_BRAND_SUCCESS,
    payload,
  };
}

export function createBrandFailure(): BrandActionsTypes {
  return {
    type: CREATE_BRAND_FAILURE,
  };
}

export function deleteBrandRequest(id: FullBrand['id']): BrandActionsTypes {
  return {
    type: DELETE_BRAND_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteBrandSuccess(
  payload: FullBrand['id'],
): BrandActionsTypes {
  return {
    type: DELETE_BRAND_SUCCESS,
    payload,
  };
}

export function deleteBrandFailure(): BrandActionsTypes {
  return {
    type: DELETE_BRAND_FAILURE,
  };
}

export function getOneBrandRequest({
  id,
  toList,
}: {
  id: number;
  toList?: boolean;
}): BrandActionsTypes {
  return {
    type: GET_ONE_BRAND_REQUEST,
    payload: {
      id,
      toList,
    },
  };
}

export function getOneBrandSuccess({
  data,
  toList,
}: {
  data: FullBrand;
  toList?: boolean;
}): BrandActionsTypes {
  return {
    type: GET_ONE_BRAND_SUCCESS,
    payload: {
      data,
      toList,
    },
  };
}

export function getOneBrandFailure(): BrandActionsTypes {
  return {
    type: GET_ONE_BRAND_FAILURE,
  };
}

export function updateBrandRequest(
  payload: Pick<FullBrand, 'id'> & Partial<FullBrand>,
): BrandActionsTypes {
  return {
    type: UPDATE_BRAND_REQUEST,
    payload,
  };
}

export function updateBrandSuccess(payload: FullBrand): BrandActionsTypes {
  return {
    type: UPDATE_BRAND_SUCCESS,
    payload,
  };
}

export function updateBrandFailure(): BrandActionsTypes {
  return {
    type: UPDATE_BRAND_FAILURE,
  };
}

export function clearBrand(): BrandActionsTypes {
  return {
    type: CLEAR_BRAND,
  };
}

export function clearListBrands(): BrandActionsTypes {
  return {
    type: CLEAR_LIST_BRANDS,
  };
}

export function clearImageToBrand(): BrandActionsTypes {
  return {
    type: CLEAR_IMAGE_TO_BRAND,
  };
}

export function clearBackgroundToBrand(): BrandActionsTypes {
  return {
    type: CLEAR_BACKGROUND_TO_BRAND,
  };
}
