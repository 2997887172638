import { useEffect, useState } from 'react';

import { Button, Form, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { FullMedia } from '@entities/Media';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { hideModal } from '@store/Modal';
import {
  clearUserOneStore,
  createUserRequest,
  getOneUserRequest,
  updateUserRequest,
} from '@store/User';
import { FileName, UploadFile } from '@utils';

import WidgetModalUserForm from './Form';
import { FormUser } from './Form/index.type';
import { Props } from './index.type';

const WidgetModalUser = ({ userId }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormUser>();

  const [avatarFile, setAvatarFile] = useState<FullMedia | null>(null);
  const { user, loadingUser } = useSelector((state: RootState) => state.user);
  const { loading: loadingImage, file: fileStore } = useSelector(
    (state: RootState) => state.media,
  );

  useEffect(() => {
    if (userId) {
      dispatch(getOneUserRequest({ id: userId }));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    return () => {
      dispatch(clearStore());
      dispatch(clearUserOneStore());
    };
  }, [dispatch]);

  const closeModal = () => {
    form.resetFields();
    dispatch(hideModal());
  };

  const saveProfile = () => {
    form.submit();
  };

  const onFormSubmitHandler = (values: FormUser) => {
    if (userId) {
      dispatch(
        updateUserRequest({
          id: userId,
          body: values,
        }),
      );
    } else {
      dispatch(createUserRequest({ body: values }));
    }
  };

  const onUploadImageHandler = (file: UploadFile['file']) => {
    dispatch(uploadMediaRequest({ file, fileName: FileName.IMAGE }));
  };

  const onRemoveImageHandler = () => {
    dispatch(clearStore());
    setAvatarFile(null);
  };

  useEffect(() => {
    if (fileStore) {
      setAvatarFile(fileStore);
    }
  }, [dispatch, fileStore]);

  useEffect(() => {
    if (user?.avatar) {
      setAvatarFile(user?.avatar);
    }
  }, [user?.avatar]);

  return (
    <Modal
      visible
      title={`${userId ? 'Edit' : 'Create'} user`}
      onOk={saveProfile}
      onCancel={closeModal}
      footer={[
        <Button
          key="save_user"
          type="primary"
          onClick={saveProfile}
          size="large"
          className="edit-form__save-button"
        >
          {!userId ? 'Create' : 'Save changes'}
        </Button>,
      ]}
      className="edit-form"
    >
      <Spin spinning={loadingUser || loadingImage}>
        <WidgetModalUserForm
          user={userId ? user : null}
          avatar={avatarFile}
          form={form}
          onFormSubmit={onFormSubmitHandler}
          onUploadImage={onUploadImageHandler}
          onRemoveImage={onRemoveImageHandler}
        />
      </Spin>
    </Modal>
  );
};

export default WidgetModalUser;
