import { DateHelper, FORMAT_DATE_WITH_MONTH, FORMAT_DAY } from '@utils';

import { Props } from './index.type';

const DashboardUIChartAreaTick = (props: Props) => {
  const { x, y, payload, fullInterval } = props;
  const offsetX = fullInterval ? 9 : 45;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={x === 0 ? offsetX : 0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#8C8C8C"
      >
        {DateHelper.formateDateToString(
          payload?.value,
          fullInterval ? FORMAT_DAY : FORMAT_DATE_WITH_MONTH,
        )}
      </text>
    </g>
  );
};

export default DashboardUIChartAreaTick;
