import { META_DEFAULT } from '@utils';

import {
  CLEAR_LIST_REVIEW,
  CLEAR_LIST_USER,
  CREATE_REVIEW_BRAND_FAILURE,
  CREATE_REVIEW_BRAND_REQUEST,
  CREATE_REVIEW_BRAND_SUCCESS,
  CREATE_REVIEW_USER_FAILURE,
  CREATE_REVIEW_USER_REQUEST,
  CREATE_REVIEW_USER_SUCCESS,
  DELETE_REVIEW_BRAND_FAILURE,
  DELETE_REVIEW_BRAND_REQUEST,
  DELETE_REVIEW_BRAND_SUCCESS,
  DELETE_REVIEW_USER_FAILURE,
  DELETE_REVIEW_USER_REQUEST,
  DELETE_REVIEW_USER_SUCCESS,
  GET_LIST_REVIEW_BRAND_FAILURE,
  GET_LIST_REVIEW_BRAND_REQUEST,
  GET_LIST_REVIEW_BRAND_SUCCESS,
  GET_LIST_REVIEW_USER_FAILURE,
  GET_LIST_REVIEW_USER_REQUEST,
  GET_LIST_REVIEW_USER_SUCCESS,
  GET_LIST_USER_FAILURE,
  GET_LIST_USER_REQUEST,
  GET_LIST_USER_SUCCESS,
  UPDATE_REVIEW_BRAND_FAILURE,
  UPDATE_REVIEW_BRAND_REQUEST,
  UPDATE_REVIEW_BRAND_SUCCESS,
  UPDATE_REVIEW_USER_FAILURE,
  UPDATE_REVIEW_USER_REQUEST,
  UPDATE_REVIEW_USER_SUCCESS,
} from './Review.constant';
import { IReviewState, ReviewActionsTypes } from './Review.type';

const initialState: IReviewState = {
  meta: META_DEFAULT,
  reviewListBrand: null,
  reviewListUser: null,
  loading: false,
  loaded: false,
  userList: null,
  userLoading: false,
};

export default function reviewReducer(
  state = initialState,
  action: ReviewActionsTypes,
): IReviewState {
  switch (action.type) {
    case GET_LIST_REVIEW_BRAND_REQUEST:
    case CREATE_REVIEW_BRAND_REQUEST:
    case DELETE_REVIEW_BRAND_REQUEST:
    case UPDATE_REVIEW_BRAND_REQUEST:
    case GET_LIST_REVIEW_USER_REQUEST:
    case CREATE_REVIEW_USER_REQUEST:
    case DELETE_REVIEW_USER_REQUEST:
    case UPDATE_REVIEW_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_USER_REQUEST:
      return {
        ...state,
        userLoading: true,
      };
    case GET_LIST_REVIEW_BRAND_SUCCESS: {
      return {
        ...state,
        reviewListBrand: action.payload.data,
        meta: action.payload.meta,
        loaded: true,
        loading: false,
      };
    }
    case GET_LIST_REVIEW_USER_SUCCESS: {
      return {
        ...state,
        reviewListUser: action.payload.data,
        meta: action.payload.meta,
        loaded: true,
        loading: false,
      };
    }

    case GET_LIST_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userList: action.payload.data,
      };
    case CLEAR_LIST_USER:
      return {
        ...state,
        userList: null,
        meta: META_DEFAULT,
      };
    case CLEAR_LIST_REVIEW:
      return {
        ...state,
        reviewListBrand: null,
        reviewListUser: null,
        meta: META_DEFAULT,
      };
    case GET_LIST_USER_FAILURE:
      return {
        ...state,
        userLoading: false,
      };
    case UPDATE_REVIEW_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewListBrand: (state.reviewListBrand || []).map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      };
    case UPDATE_REVIEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewListUser: (state.reviewListUser || []).map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      };
    case CREATE_REVIEW_BRAND_SUCCESS:
    case CREATE_REVIEW_BRAND_FAILURE:
    case GET_LIST_REVIEW_BRAND_FAILURE:
    case DELETE_REVIEW_BRAND_FAILURE:
    case UPDATE_REVIEW_BRAND_FAILURE:
    case CREATE_REVIEW_USER_SUCCESS:
    case CREATE_REVIEW_USER_FAILURE:
    case GET_LIST_REVIEW_USER_FAILURE:
    case DELETE_REVIEW_USER_FAILURE:
    case UPDATE_REVIEW_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DELETE_REVIEW_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        reviewListBrand: (state.reviewListBrand || []).filter(
          (item) => item.id !== action.payload,
        ),
      };
    }
    case DELETE_REVIEW_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        reviewListUser: (state.reviewListUser || []).filter(
          (item) => item.id !== action.payload,
        ),
      };
    }
    default:
      return state;
  }
}
