import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullShop } from '@entities/Shop';
import { ShopHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';

import {
  createShopFailure,
  createShopSuccess,
  deleteShopFailure,
  deleteShopSuccess,
  getListShopFailure,
  getListShopSuccess,
  getOneShopFailure,
  getOneShopSuccess,
  updateShopFailure,
  updateShopSuccess,
} from './Shop.action';
import {
  CREATE_SHOP_REQUEST,
  DELETE_SHOP_REQUEST,
  GET_LIST_SHOP_REQUEST,
  GET_ONE_SHOP_REQUEST,
  UPDATE_SHOP_REQUEST,
} from './Shop.constant';
import {
  ICreateShopRequest,
  IDeleteShopRequest,
  IGetListShopRequest,
  IGetOneShopRequest,
  IUpdateShopRequest,
} from './Shop.type';

function* workerGetListShop(action: IGetListShopRequest) {
  try {
    const { query } = action.payload;
    const data: DataResponse<FullShop[]> = yield call(ShopHttp.getList, query);

    yield put(getListShopSuccess(data));
  } catch (error) {
    yield put(getListShopFailure());
    yield put(alertError(error));
  }
}

function* workerCreateShop(action: ICreateShopRequest) {
  try {
    const data: FullShop = yield call(ShopHttp.create, action.payload);

    yield put(createShopSuccess(data));
    yield put(alertSuccess('Shop added successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(createShopFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteShop(action: IDeleteShopRequest) {
  try {
    const { id } = action.payload;

    yield call(ShopHttp.remove, id);

    yield put(deleteShopSuccess(id));
    yield put(alertSuccess('Shop successfully deleted!'));
  } catch (error) {
    yield put(deleteShopFailure());
    yield put(alertError(error));
  }
}

function* workerGetOneShop(action: IGetOneShopRequest) {
  try {
    const { id, toList } = action.payload;
    const payload: FullShop = yield call(ShopHttp.getOne, id);

    yield put(getOneShopSuccess({ data: payload, toList }));
  } catch (error) {
    yield put(getOneShopFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateShop(action: IUpdateShopRequest) {
  try {
    const { id, ...body } = action.payload;

    const data: FullShop = yield call(ShopHttp.update, id, body);

    yield put(updateShopSuccess(data));
    yield put(alertSuccess('Shop successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateShopFailure());
    yield put(alertError(error));
  }
}

function* watchUpdateShop() {
  yield takeLatest(UPDATE_SHOP_REQUEST, workerUpdateShop);
}

function* watchGetOneShop() {
  yield takeLatest(GET_ONE_SHOP_REQUEST, workerGetOneShop);
}

function* watchDeleteShop() {
  yield takeLatest(DELETE_SHOP_REQUEST, workerDeleteShop);
}

function* watchCreateShop() {
  yield takeLatest(CREATE_SHOP_REQUEST, workerCreateShop);
}

function* watchGetListShop() {
  yield takeLatest(GET_LIST_SHOP_REQUEST, workerGetListShop);
}

export const shopWatchers: ForkEffect[] = [
  fork(watchGetListShop),
  fork(watchCreateShop),
  fork(watchDeleteShop),
  fork(watchGetOneShop),
  fork(watchUpdateShop),
];
