import { useEffect } from 'react';

import { Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './style.scss';
import { OrderProducts } from '@entities/Product';
import { RootState, useAppDispatch } from '@store/index';
import { clearListOrder, getListOrderRequest } from '@store/Order';
import { clearUserListStore } from '@store/User';
import { UITable } from '@ui';

import { columns } from './constant';

const ProductAllToDriver = ({
  id,
  tabsActive,
}: {
  id: number;
  tabsActive: string;
}) => {
  // const { query, updateQuery } = useQuery();
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  const { orderList, loading } = useSelector((state: RootState) => state.order);

  useEffect(() => {
    return () => {
      dispatch(clearListOrder());
      dispatch(clearUserListStore());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (tabsActive === 'Inventory tracking') {
      dispatch(clearListOrder());
      dispatch(
        getListOrderRequest(`?limit=100&page=1&filter[driverIds][0]=${+id}`),
      );
    }
  }, [dispatch, search, id, tabsActive]);

  // const getRequest = useCallback(() => {
  //   dispatch(clearListOrder());
  //   dispatch(
  //     getListOrderRequest(`?limit=100&page=1&filter[driverIds][0]=${+id}`),
  //   );
  //   // dispatch(getListOrderRequest(`${query}&filter[driverIds][0]=${+id}`));
  // }, [dispatch, id]);

  // useEffect(() => {
  //   if (status === ActionStatus.UPDATED) {
  //     getRequest();
  //     dispatch(hideModal());
  //   }
  // }, [dispatch, getRequest, status]);

  // useEffect(() => {
  //   if (search === '') {
  //     updateQuery({ page: 1, limit: 100 });
  //   } else {
  //     getRequest();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getRequest]);

  // const onPaginationChange = (page: number, limit = 100) => {
  //   updateQuery({ page, limit });
  // };

  const products: OrderProducts[] = [];

  orderList?.forEach((item) =>
    item?.products?.forEach((item2) => {
      const productsIndex = products.findIndex(
        (item3) => item3?.product.id === item2?.product.id,
      );

      if (productsIndex > -1) {
        products[productsIndex] = {
          ...item2,
          quantity:
            (products[productsIndex]?.quantity || 0) + (item2?.quantity || 0),
        };
      } else {
        products.push(item2);
      }
    }),
  );

  return (
    <Spin size="large" spinning={false}>
      <div className="card-shops-info-shop">
        {products?.length ? (
          <div className="card-shops-info-shop-content">
            <Title level={5}>All sold products by this driver:</Title>
            <UITable
              dataSource={products?.map((item) => ({
                ...item,
                id: item.product.id,
              }))}
              loading={loading}
              columns={columns()}
              adaptive={false}
            />
          </div>
        ) : (
          <div className="card-shops-info-shop-content">
            <Title level={5}>This driver hasn’t sold any products yet.</Title>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default ProductAllToDriver;
