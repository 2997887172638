/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Form, Input, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { FullStoresList } from '@entities/Product';
import {
  DriverProduct,
  FullShop,
  ProductDeliveryForm,
  ShopProduct,
} from '@entities/Shop';
import { FullDriversList, FullUser } from '@entities/User';
import { en } from '@i18n';
import { LocalStorage } from '@services/storage';
import { RootState } from '@store/index';
import { UIButton, UIModal, UISelectFormItem } from '@ui';
import { Button, OptionSelect } from '@utils';

import './style.scss';
import { Props } from './index.type';

const WidgetEditQuantities = (props: Props) => {
  const { update, get, clear, listActive, type, allQuantities } = props;

  const [form] = Form.useForm();
  const editQuantities: ProductDeliveryForm[] | null =
    LocalStorage.get('editQuantities');
  const [items, setItems] = useState<ProductDeliveryForm[] | null | undefined>(
    editQuantities,
  );
  const [col, setCol] = useState<number>(0);
  const [colAdd, setColAdd] = useState<number>(0);

  const {
    shopList,
    loading: loadingShop,
    meta: metaShop,
  } = useSelector((state: RootState) => state.shop);
  const {
    userList,
    loading: loadingUser,
    meta: metaUser,
  } = useSelector((state: RootState) => state.user);

  const setValueAll = (val: ProductDeliveryForm[]) => {
    LocalStorage.set('editQuantities', val);
    setItems(val);
  };

  const listInfo = () => {
    if (type === 'store') {
      return {
        list: shopList,
        meta: metaShop,
        loading: loadingShop,
      };
    }
    if (type === 'driver') {
      return {
        list: userList,
        meta: metaUser,
        loading: loadingUser,
      };
    }

    return null;
  };

  useEffect(() => {
    if (listActive) {
      setValueAll(
        listActive.map((item) => {
          if (item?.quantity) {
            setCol((prev) => +prev + item.quantity);
          }

          return {
            quantity: item.quantity,
            id:
              (item as FullStoresList)?.storeId ||
              (item as FullDriversList)?.driverId,
            name:
              (item as FullStoresList)?.store?.name ||
              (item as FullDriversList)?.driver?.profile?.fullName,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, listActive]);

  const onFormSubmitHandler = () => {
    const newItems = [...(items || [])];

    listActive?.forEach((item) => {
      const asd = items?.find(
        (item2) =>
          item2.id === (item as FullDriversList)?.driverId ||
          item2.id === (item as FullStoresList)?.storeId,
      );

      if (!asd) {
        newItems.push({
          quantity: 0,
          id:
            (item as FullDriversList)?.driverId ||
            (item as FullStoresList)?.storeId,
          name:
            (item as FullDriversList)?.driver?.profile?.fullName ||
            (item as FullStoresList)?.store?.name,
        });
      }
    });

    if (newItems?.length) {
      update(
        newItems.map((item) => {
          // setColAdd((prev) => +prev + item.quantity);

          return {
            quantity: +item.quantity,
            [`${type}Id`]: item.id,
          };
        }) as ShopProduct[] | DriverProduct[],
      );
      // setColAdd((prev) => +prev - col);
    }
  };

  const changeUtilsInStock = (data: ProductDeliveryForm[]) => {
    setColAdd(0);
    data.forEach((item2) => {
      if (item2?.quantity) {
        setColAdd((prev) => prev + item2.quantity);
      }
    });
    setColAdd((prev) => prev - col);
  };

  const footer = [
    <UIButton
      key="Save changes"
      typeAntBtn="primary"
      handler={() => form.submit()}
      type={Button.ANT_BUTTON}
      disabled={
        !!(
          (allQuantities && colAdd > allQuantities) ||
          items?.map((item) => item?.quantity)?.includes(0)
        )
      }
      size="large"
      className="edit-form__save-button"
    >
      Save
    </UIButton>,
  ];

  return (
    <UIModal
      className="edit-form"
      title={`Add ${type}`}
      footer={footer}
      visible
      children={
        <Spin spinning={false}>
          <Form
            form={form}
            requiredMark={false}
            size="large"
            onFinish={onFormSubmitHandler}
            autoComplete="off"
            validateMessages={en.validation}
          >
            <UISelectFormItem
              label={`${type} (Units in stock - ${
                (allQuantities || 0) - colAdd
              })`}
              value={items?.map((i) => ({
                label: i.name,
                value: i.id,
              }))}
              loading={listInfo()?.loading}
              mode="multiple"
              onChange={(ids, values) => {
                const newValue = (ids as number[])?.map((id) =>
                  [
                    ...(values as OptionSelect[]),
                    ...((items || []).map((i) => ({
                      label: i.name,
                      value: i.id,
                    })) || []),
                  ].find((e) => +e.value === +id),
                );
                const data = (newValue as OptionSelect[]).map((e) => {
                  const itemOld = items?.find((item) => e.value === item.id);

                  if (itemOld) {
                    return itemOld;
                  }

                  return {
                    quantity: 1,
                    id: e.value,
                    name: e.label,
                  } as ProductDeliveryForm;
                });

                changeUtilsInStock(data);
                setValueAll(data);
              }}
              options={(listInfo()?.list || [])?.map((item) => ({
                id: item.id,
                name:
                  (item as FullShop)?.name ||
                  (item as FullUser)?.profile?.fullName,
              }))}
              getOptions={get}
              clearOptions={clear}
              meta={listInfo()?.meta}
            />
            {items?.map((item) => (
              <Form.Item label={`${item?.name} - quantity`}>
                <Input
                  value={+item.quantity}
                  {...((+item.quantity < 1 ||
                    (allQuantities && colAdd > allQuantities)) && {
                    status: 'error',
                  })}
                  min={1}
                  type="number"
                  placeholder="Quantity"
                  onChange={(e) => {
                    const data = [
                      ...items.map((item2) => {
                        if (item.id === item2.id) {
                          return {
                            ...item2,
                            quantity: +e.target.value,
                          };
                        }

                        return item2;
                      }),
                    ];

                    changeUtilsInStock(data);
                    setValueAll(data);
                  }}
                />
              </Form.Item>
            ))}
          </Form>
        </Spin>
      }
    />
  );
};

export default WidgetEditQuantities;
