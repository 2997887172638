export const GET_LIST_CATEGORY_REQUEST = '@category/GET_LIST_CATEGORY_REQUEST';
export const GET_LIST_CATEGORY_SUCCESS = '@category/GET_LIST_CATEGORY_SUCCESS';
export const GET_LIST_CATEGORY_FAILURE = '@category/GET_LIST_CATEGORY_FAILURE';

export const CREATE_CATEGORY_REQUEST = '@category/CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = '@category/CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = '@category/CREATE_CATEGORY_FAILURE';

export const GET_ONE_CATEGORY_REQUEST = '@category/GET_ONE_CATEGORY_REQUEST';
export const GET_ONE_CATEGORY_SUCCESS = '@category/GET_ONE_CATEGORY_SUCCESS';
export const GET_ONE_CATEGORY_FAILURE = '@category/GET_ONE_CATEGORY_FAILURE';

export const DELETE_CATEGORY_REQUEST = '@category/DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = '@category/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = '@category/DELETE_CATEGORY_FAILURE';

export const UPDATE_CATEGORY_REQUEST = '@category/UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = '@category/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = '@category/UPDATE_CATEGORY_FAILURE';

export const CLEAR_CATEGORY = '@category/CLEAR_CATEGORY';
export const CLEAR_LIST_CATEGORY = '@category/CLEAR_LIST_CATEGORY';
export const CLEAR_IMAGE_TO_CATEGORY = '@category/CLEAR_IMAGE_TO_CATEGORY';
