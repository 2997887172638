import { Button, Space } from 'antd';

import { useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';

const UserUIButton = () => {
  const dispatch = useAppDispatch();

  const onCreateClientClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(showModal({ modalType: ModalType.USER }));
  };

  return (
    <Space>
      <Button type="primary" onClick={onCreateClientClick} size="large">
        Create user
      </Button>
    </Space>
  );
};

export default UserUIButton;
