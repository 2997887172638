import cn from 'classnames';

import { UIAvatar } from '@ui';
import { DateHelper, FORMAT_TIME } from '@utils';

import { Props } from './index.type';

const ChatDetailMessageItem = (props: Props) => {
  const { message, chat } = props;

  return (
    <div
      className={cn('chat-block', {
        'chat-block-expert': chat?.user?.id !== message.createdById,
      })}
    >
      <div className="chat-message">
        <div className="chat-message-text">{message?.text}</div>
        <div className="chat-message-info">
          <div className="chat-message-info-time">
            {DateHelper.formateDateToString(message.createdAt, FORMAT_TIME)}
          </div>
          <div className="chat-message-info-name">
            {chat?.user?.id === message.createdById
              ? chat?.user?.profile?.fullName
              : 'Admin'}
          </div>
        </div>
      </div>
      {chat?.user?.id === message.createdById ? (
        <div className="chat-user">
          <UIAvatar path={chat?.user?.avatar?.path} />
        </div>
      ) : null}
    </div>
  );
};

export default ChatDetailMessageItem;
