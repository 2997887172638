import { Route, Routes } from 'react-router-dom';

import { ReviewList } from '@features/Review';

const ViewReview = () => {
  return (
    <Routes>
      <Route path="/" element={<ReviewList />} />
    </Routes>
  );
};

export default ViewReview;
