/* eslint-disable indent */
import { Col, Form, InputNumber, Row, Switch } from 'antd';

import { Referral } from '@entities/Referral';
import { en } from '@i18n';
import { UIHeader } from '@ui';
import { DiscountUnit } from '@utils';

import { INITIAL_VALUES } from './constant';
import { Props } from './index.type';

const ReferralEditForm = ({ onFormSubmit, form, title, nameKey }: Props) => {
  const discountValue = Form.useWatch(`discountValue${nameKey}`, form);
  const discountUnitValue = Form.useWatch(`discountUnit${nameKey}`, form);
  const isPercent = discountUnitValue === DiscountUnit.PERCENT;

  const handlerFinish = (values: Referral) => {
    onFormSubmit(values);
  };

  const formCheckbox = {
    labelCol: { span: 'auto' },
    wrapperCol: { span: 'auto' },
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 14 }}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      validateMessages={en.validation}
    >
      <UIHeader title={title} />
      <Row justify="center">
        <Col xxl={7} span={12}>
          {isPercent ? (
            <Form.Item
              label="Percent"
              name={`discountValue${nameKey}`}
              required={isPercent}
              rules={[
                {
                  required: isPercent,
                  min: 1,
                  max: 100,
                  type: 'number',
                },
              ]}
            >
              <InputNumber
                min={1}
                step={1}
                type="number"
                {...(discountValue &&
                  (+discountValue < 1 || +discountValue > 100) && {
                    status: 'error',
                  })}
                placeholder="Enter value"
              />
            </Form.Item>
          ) : (
            <Form.Item
              label="Discount"
              name={`discountValue${nameKey}`}
              required={!isPercent}
              rules={[{ required: !isPercent, min: 0.01, type: 'number' }]}
            >
              <InputNumber
                min={0}
                type="number"
                {...(discountValue &&
                  +discountValue < 0.01 && { status: 'error' })}
                placeholder="Price"
              />
            </Form.Item>
          )}
        </Col>
        <Col offset={1} span={4}>
          <Form.Item
            {...formCheckbox}
            label="Is percent"
            name={`discountUnit${nameKey}`}
          >
            <Switch
              onChange={(item) => {
                form.setFieldValue(
                  `discountUnit${nameKey}`,
                  item ? DiscountUnit.PERCENT : DiscountUnit.CURRENCY,
                );
                form.setFieldValue(`discountValue${nameKey}`, null);
              }}
              checked={isPercent}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ReferralEditForm;
