import { Layout } from 'antd';
import { Provider } from 'react-redux';

import {
  AppProvider,
  NotificationSocketProvider,
  OrderSocketProvider,
  SupportSocketProvider,
} from './context';
import store from './store';
import ViewRouter from './views';
import { WidgetAlert, WidgetModal } from './widgets';

import 'antd/dist/antd.min.css';

function App() {
  return (
    <Provider store={store}>
      <AppProvider>
        <OrderSocketProvider>
          <SupportSocketProvider>
            <NotificationSocketProvider>
              <Layout className="page-layout">
                <ViewRouter />
                <WidgetAlert />
                <WidgetModal />
              </Layout>
            </NotificationSocketProvider>
          </SupportSocketProvider>
        </OrderSocketProvider>
      </AppProvider>
    </Provider>
  );
}

export default App;
