export const GET_LIST_ORDER_REQUEST = '@order/GET_LIST_ORDER_REQUEST';
export const GET_LIST_ORDER_SUCCESS = '@order/GET_LIST_ORDER_SUCCESS';
export const GET_LIST_ORDER_FAILURE = '@order/GET_LIST_ORDER_FAILURE';

export const GET_ONE_ORDER_REQUEST = '@order/GET_ONE_ORDER_REQUEST';
export const GET_ONE_ORDER_SUCCESS = '@order/GET_ONE_ORDER_SUCCESS';
export const GET_ONE_ORDER_FAILURE = '@order/GET_ONE_ORDER_FAILURE';

// export const GET_ORDER_PAYMENT_REQUEST = '@order/GET_ORDER_PAYMENT_REQUEST';
// export const GET_ORDER_PAYMENT_SUCCESS = '@order/GET_ORDER_PAYMENT_SUCCESS';
// export const GET_ORDER_PAYMENT_FAILURE = '@order/GET_ORDER_PAYMENT_FAILURE';

export const ORDER_PAYMENT_REQUEST = '@order/ORDER_PAYMENT_REQUEST';
export const ORDER_PAYMENT_SUCCESS = '@order/ORDER_PAYMENT_SUCCESS';
export const ORDER_PAYMENT_FAILURE = '@order/ORDER_PAYMENT_FAILURE';

export const ORDER_BASKET_REQUEST = '@order/ORDER_BASKET_REQUEST';
export const ORDER_BASKET_SUCCESS = '@order/ORDER_BASKET_SUCCESS';
export const ORDER_BASKET_FAILURE = '@order/ORDER_BASKET_FAILURE';

export const GET_ONE_ORDER_PRODUCT_REQUEST =
  '@order/GET_ONE_ORDER_PRODUCT_REQUEST';
export const GET_ONE_ORDER_PRODUCT_SUCCESS =
  '@order/GET_ONE_ORDER_PRODUCT_SUCCESS';
export const GET_ONE_ORDER_PRODUCT_FAILURE =
  '@order/GET_ONE_ORDER_PRODUCT_FAILURE';

export const UPDATE_ORDER_REQUEST = '@order/UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = '@order/UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = '@order/UPDATE_ORDER_FAILURE';

export const CREATE_ORDER_REQUEST = '@order/CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = '@order/CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = '@order/CREATE_ORDER_FAILURE';

export const SOCKET_ORDER = '@order/SOCKET_ORDER';
export const CLEAR_LIST_ORDER = '@order/CLEAR_LIST_ORDER';
export const CLEAR_ORDER = '@order/CLEAR_ORDER';
export const CLEAR_ORDER_BASKET = '@order/CLEAR_ORDER_BASKET';
