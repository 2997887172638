/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { LIMIT_DEFAULT, QueryHelper, QueryOptions } from '@utils';

type QueryCtx = {
  encode: boolean;
};

const useQuery = (data?: QueryOptions, ctx?: Partial<QueryCtx>) => {
  const option = {
    addQueryPrefix: false,
    ...(ctx?.encode && { encode: ctx.encode }),
  };
  const query = data ? `&${QueryHelper.stringify(data, option)}` : '';

  const [queryOptions, setQueryOptions] = useState<QueryOptions>({});

  const havigate = useNavigate();
  const { search } = useLocation();

  const updateQuery = ({ pagination = true, ...options }: QueryOptions) => {
    havigate({
      search: QueryHelper.stringify(
        {
          ...queryOptions,
          ...options,
          ...((options.filter || options.order) &&
            pagination && {
              page: 1,
              limit: LIMIT_DEFAULT,
            }),
        },
        {
          ...(ctx?.encode && { encode: ctx.encode }),
        },
      ),
    });
  };

  const setQuery = (options: QueryOptions) => {
    havigate({
      search: QueryHelper.stringify(options, {
        ...(ctx?.encode && { encode: ctx.encode }),
      }),
    });
  };

  useEffect(() => {
    setQueryOptions(QueryHelper.parse(search));
  }, [search]);

  // useEffect(() => {
  //   if (
  //     (queryOptions &&
  //       queryOptions.page &&
  //       queryOptions.page > 1
  //   ) {
  //     // updateQuery({ page: 1, limit: LIMIT_DEFAULT });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [options]);

  return {
    query: `?${search.slice(1)}${query}`,
    queryOptions,
    setQuery,
    updateQuery,
  };
};

export default useQuery;
