import { useEffect, useState } from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';

import { Button, LIMIT_DEFAULT, OptionSelect } from '@utils';

import UIButton from '../Button';
import { Props } from './index.type';

import './style.scss';

const UISelectFormItem = (props: Props) => {
  const {
    options,
    getOption,
    getOptions,
    clearOptions,
    loading,
    label,
    name,
    value,
    className,
    classNameSelect,
    mode,
    meta,
    onChange,
    limit = meta?.limit || LIMIT_DEFAULT,
    showSearch = true,
    placeholder = label,
    queryString,
    disabled,
    style,
    valueId,
    clearQuery,
    rules,
    required,
    wrapperCol,
    labelCol,
    resending = false,
    onResending,
    ...prop
  } = props;
  const [page, setPage] = useState(meta?.currentPage || 1);
  const [isGetOne, setIsGetOne] = useState(false);
  const selectOptions = options.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  useEffect(() => {
    if (getOptions && page > 0) {
      getOptions(
        `?limit=${limit}&page=${page}${queryString ? `&${queryString}` : ''}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (clearOptions) {
      clearOptions(clearQuery);
    }

    return () => {
      if (clearOptions) {
        clearOptions(clearQuery);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !mode &&
      getOption &&
      valueId &&
      options &&
      options !== null &&
      !isGetOne &&
      !options.find((i) => +i.id === +valueId)
    ) {
      getOption(+valueId);
      setIsGetOne(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueId, meta]);

  const handlerResending = () => {
    if (getOptions && clearOptions) {
      clearOptions(clearQuery);
      if (page !== 1) {
        setPage(1);
      } else {
        getOptions(
          `?limit=${limit}&page=${page}${queryString ? `&${queryString}` : ''}`,
        );
      }
    }
  };

  useEffect(() => {
    if (onResending) {
      handlerResending();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onResending]);

  return (
    <div className="selectFormItemWrap">
      <Form.Item
        required={required}
        rules={rules}
        className={className}
        name={name}
        label={label}
        wrapperCol={wrapperCol}
        labelCol={labelCol}
      >
        <Select
          onBlur={() => {
            if (mode && clearOptions && page === 0) {
              clearOptions(clearQuery);
              setPage(1);
            }
          }}
          className={classNameSelect}
          placeholder={placeholder}
          disabled={disabled}
          mode={mode}
          style={style}
          loading={loading}
          onChange={onChange as any}
          {...(value && { value })}
          onPopupScroll={(e) => {
            if (getOptions && page > 0) {
              const { scrollHeight, clientHeight, scrollTop } =
                e.target as Element;

              if (
                scrollHeight - scrollTop - clientHeight <= 0 &&
                meta &&
                page < meta.totalPages
              ) {
                setPage(page + 1);
              }
            }
          }}
          options={[
            ...(new Map(
              selectOptions.map((item) => [item.value, item]),
            ).values() as unknown as OptionSelect[]),
          ]}
          showSearch={showSearch}
          onSearch={(val) => {
            if (getOptions && clearOptions) {
              if (val.length) {
                setPage(0);
                clearOptions(clearQuery);
                getOptions(
                  `?search=${val}&limit=${limit}${
                    queryString ? `&${queryString}` : ''
                  }`,
                );
              } else {
                clearOptions(clearQuery);
                setPage(1);
              }
            }
          }}
          onSelect={(i: any) => {
            if (!mode && getOption) {
              getOption(+i);
            }
          }}
          autoClearSearchValue={false}
          filterOption={(inputValue, option: OptionSelect | undefined) => {
            if (option) {
              return option.label
                ?.toLowerCase()
                ?.includes(inputValue?.toLowerCase());
            }

            return false;
          }}
          {...prop}
        />
      </Form.Item>
      {resending ? (
        <UIButton
          size="small"
          className="select__reload"
          type={Button.ANT_BUTTON}
          handler={handlerResending}
        >
          <ReloadOutlined />
        </UIButton>
      ) : null}
    </div>
  );
};

export default UISelectFormItem;
