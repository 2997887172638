import { useEffect } from 'react';

import { Button, Form, Input, InputNumber } from 'antd';

import { en } from '@i18n';

import { INITIAL_VALUES } from './constant';
import { FormTaxes, Props } from './index.type';

const TaxesEditForm = ({ taxes, onFormSubmit, form }: Props) => {
  useEffect(() => {
    if (taxes) {
      form.setFieldsValue(taxes);
    }
  }, [form, taxes]);

  const saleRate = Form.useWatch('saleRate', form);

  const handlerFinish = (values: FormTaxes) => {
    onFormSubmit(values);
  };

  return (
    <Form
      form={form}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 14 }}
      validateMessages={en.validation}
    >
      <Form.Item
        label="Sale rate"
        name="saleRate"
        required
        rules={[{ required: true, min: 0.01, type: 'number' }]}
      >
        <InputNumber
          min={0.01}
          addonAfter="%"
          type="number"
          {...(saleRate && +saleRate < 0.01 && { status: 'error' })}
          placeholder="Sale rate"
        />
      </Form.Item>
      <Form.Item
        label="Business medical rate"
        name="businessMedicalRate"
        rules={[{ type: 'number' }]}
      >
        <InputNumber
          addonAfter="%"
          type="number"
          placeholder="Business medical rate"
        />
      </Form.Item>

      <Form.Item
        label="Business recreational rate"
        name="businessRecreationalRate"
        rules={[{ type: 'number' }]}
      >
        <InputNumber
          addonAfter="%"
          type="number"
          placeholder="Business recreational rate"
        />
      </Form.Item>
      <Form.Item
        label="Country"
        name="country"
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input disabled placeholder="Country" />
      </Form.Item>
      <Form.Item
        label="State"
        name="state"
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input disabled placeholder="State" />
      </Form.Item>
      <Form.Item
        label="County"
        name="county"
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input disabled placeholder="County" />
      </Form.Item>
      <Form.Item
        label="City"
        name="city"
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input disabled placeholder="City" />
      </Form.Item>

      {/* <Form.Item label="Visible" name="isActive" required>
        <Switch disabled defaultChecked={taxes?.isActive} />
      </Form.Item> */}
      <div className="taxes-edit__save-button">
        <Button
          key="save_taxes"
          type="primary"
          onClick={() => form.submit()}
          size="large"
          className="edit-form__save-button"
        >
          Save changes
        </Button>
      </div>
    </Form>
  );
};

export default TaxesEditForm;
