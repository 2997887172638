import { FullOrder, OrderBasket } from '@entities/Order';
import { OrderProducts } from '@entities/Product';

import {
  CLEAR_LIST_ORDER,
  CLEAR_ORDER,
  CLEAR_ORDER_BASKET,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  GET_LIST_ORDER_FAILURE,
  GET_LIST_ORDER_REQUEST,
  GET_LIST_ORDER_SUCCESS,
  GET_ONE_ORDER_FAILURE,
  GET_ONE_ORDER_PRODUCT_FAILURE,
  GET_ONE_ORDER_PRODUCT_REQUEST,
  GET_ONE_ORDER_PRODUCT_SUCCESS,
  GET_ONE_ORDER_REQUEST,
  GET_ONE_ORDER_SUCCESS,
  // GET_ORDER_PAYMENT_FAILURE,
  // GET_ORDER_PAYMENT_REQUEST,
  // GET_ORDER_PAYMENT_SUCCESS,
  ORDER_BASKET_FAILURE,
  ORDER_BASKET_REQUEST,
  ORDER_BASKET_SUCCESS,
  ORDER_PAYMENT_FAILURE,
  ORDER_PAYMENT_REQUEST,
  ORDER_PAYMENT_SUCCESS,
  SOCKET_ORDER,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
} from './Order.constant';
import { OrderActionsTypes } from './Order.type';

export function getListOrderRequest(query = ''): OrderActionsTypes {
  return {
    type: GET_LIST_ORDER_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListOrderSuccess(
  payload: DataResponse<FullOrder[]>,
): OrderActionsTypes {
  return {
    type: GET_LIST_ORDER_SUCCESS,
    payload,
  };
}

export function getListOrderFailure(): OrderActionsTypes {
  return {
    type: GET_LIST_ORDER_FAILURE,
  };
}

export function getOneOrderRequest(orderId: number): OrderActionsTypes {
  return {
    type: GET_ONE_ORDER_REQUEST,
    payload: {
      orderId,
    },
  };
}

export function getOneOrderSuccess(
  payload: DataResponse<Omit<FullOrder, 'products'>>,
): OrderActionsTypes {
  return {
    type: GET_ONE_ORDER_SUCCESS,
    payload,
  };
}

export function getOneOrderFailure(): OrderActionsTypes {
  return {
    type: GET_ONE_ORDER_FAILURE,
  };
}

// export function getOrderPaymentRequest(payload: number): OrderActionsTypes {
//   return {
//     type: GET_ORDER_PAYMENT_REQUEST,
//     payload,
//   };
// }

// export function getOrderPaymentSuccess(
//   payload: DataResponse<OrderPayment>,
// ): OrderActionsTypes {
//   return {
//     type: GET_ORDER_PAYMENT_SUCCESS,
//     payload,
//   };
// }

// export function getOrderPaymentFailure(): OrderActionsTypes {
//   return {
//     type: GET_ORDER_PAYMENT_FAILURE,
//   };
// }

export function orderPaymentRequest(payload: {
  paymentToken: string;
  paymentTransaction: string;
}): OrderActionsTypes {
  return {
    type: ORDER_PAYMENT_REQUEST,
    payload,
  };
}

export function orderPaymentSuccess(): OrderActionsTypes {
  return {
    type: ORDER_PAYMENT_SUCCESS,
  };
}

export function orderPaymentFailure(): OrderActionsTypes {
  return {
    type: ORDER_PAYMENT_FAILURE,
  };
}

export function getOneOrderProductRequest(orderId: number): OrderActionsTypes {
  return {
    type: GET_ONE_ORDER_PRODUCT_REQUEST,
    payload: {
      orderId,
    },
  };
}

export function getOneOrderProductSuccess(
  payload: DataResponse<OrderProducts[]>,
): OrderActionsTypes {
  return {
    type: GET_ONE_ORDER_PRODUCT_SUCCESS,
    payload,
  };
}

export function getOneOrderProductFailure(): OrderActionsTypes {
  return {
    type: GET_ONE_ORDER_PRODUCT_FAILURE,
  };
}

export function updateOrderRequest(
  payload: Pick<FullOrder, 'status'> & {
    driverId?: number | null;
    id: number;
    shippingMethod?: string;
  },
): OrderActionsTypes {
  return {
    type: UPDATE_ORDER_REQUEST,
    payload,
  };
}

export function updateOrderSuccess(): OrderActionsTypes {
  return {
    type: UPDATE_ORDER_SUCCESS,
  };
}

export function updateOrderFailure(): OrderActionsTypes {
  return {
    type: UPDATE_ORDER_FAILURE,
  };
}

export function createOrderRequest(
  payload: Pick<FullOrder, 'shippingMethod' | 'tips'> & {
    addressId?: number | null;
    driverId?: number | null;
    products: { id: number; quantity: number }[];
    userId: number | null;
  },
): OrderActionsTypes {
  return {
    type: CREATE_ORDER_REQUEST,
    payload,
  };
}

export function orderBasketRequest(
  payload:
    | (Pick<FullOrder, 'shippingMethod'> & {
        addressId?: number | null;
        driverId?: number | null;
        products: { id: number; quantity: number }[];
      })
    | (Pick<FullOrder, 'status'> & {
        driverId?: number | null;
        id: number;
        shippingMethod?: string;
      }),
): OrderActionsTypes {
  return {
    type: ORDER_BASKET_REQUEST,
    payload,
  };
}

export function orderBasketSuccess(payload: OrderBasket): OrderActionsTypes {
  return {
    type: ORDER_BASKET_SUCCESS,
    payload,
  };
}

export function orderBasketFailure(): OrderActionsTypes {
  return {
    type: ORDER_BASKET_FAILURE,
  };
}

export function createOrderSuccess(payload: FullOrder): OrderActionsTypes {
  return {
    type: CREATE_ORDER_SUCCESS,
    payload,
  };
}

export function createOrderFailure(): OrderActionsTypes {
  return {
    type: CREATE_ORDER_FAILURE,
  };
}

export function clearListOrder(): OrderActionsTypes {
  return {
    type: CLEAR_LIST_ORDER,
  };
}

export function clearOrder(): OrderActionsTypes {
  return {
    type: CLEAR_ORDER,
  };
}

export function clearOrderBasket(): OrderActionsTypes {
  return {
    type: CLEAR_ORDER_BASKET,
  };
}

export function socketOrder(
  payload: DataResponse<FullOrder>,
): OrderActionsTypes {
  return {
    type: SOCKET_ORDER,
    payload,
  };
}
