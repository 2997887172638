import { Col, Divider, Image, Row, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import './style.scss';

import { useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { UIAvatar, UIDetailRow, UITag } from '@ui';
import { DateHelper, FORMAT_SHORT_DATE, Role } from '@utils';

import { Props } from './index.type';

const UserDetailProfile = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    user: {
      profile,
      id,
      avatar,
      createdAt,
      email,
      isActive,
      role,
      phone,
      addresses,
      store,
      document,
      properties,
      ageCheckStatus,
    },
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onEditClickHandler = () => {
    dispatch(
      showModal({
        modalType: ModalType.USER,
        modalProps: { userId: id, query: '' },
      }),
    );
  };

  // const onResetPassword = () => {
  //   dispatch(resetUserPasswordRequest(id));
  // };

  const isActiveValues = {
    true: {
      title: 'Active',
      color: 'green',
    },
    false: {
      title: 'Inactive',
      color: 'red',
    },
  };

  return (
    <div className="user-profile">
      <Row
        justify="space-between"
        className="user-profile__container"
        id="user-profile-header"
        align="middle"
      >
        <Col span={4}>
          <UIAvatar path={avatar?.path} />
        </Col>
        <Col span={16}>
          <Space direction="vertical" size={0}>
            <Text strong>{profile?.fullName || ''}</Text>
          </Space>
        </Col>
        <Col span={4}>
          <UITag value={`${isActive}`} values={isActiveValues} />
        </Col>
      </Row>
      {/* <Row className="user-profile__container" align="middle" gutter={16}>
        <Col>
          <UICellRate rating={rating || 0} votes={votes || 0} />
        </Col>
      </Row> */}
      <Divider />
      <Row
        className="user-profile__container"
        justify="space-between"
        wrap={false}
        align="middle"
      >
        <Col span={9}>
          <Title level={5}>Overview</Title>
        </Col>
        {/* <Col>
          <Link onClick={onResetPassword}>
            <LockOutlined />
            Reset password
          </Link>
        </Col> */}
        {/* <Col>
          <Link onClick={onEditClickHandler}>
            <EditOutlined />
            Edit profile
          </Link>
        </Col> */}
      </Row>
      <UIDetailRow
        title="Email"
        children={email}
        className="user-profile__container"
      />
      {role === Role.DRIVERS ? (
        <UIDetailRow
          title="Shop"
          children={store?.name || 'not Shop'}
          className="user-profile__container"
        />
      ) : null}
      <UIDetailRow
        title="Role"
        children={role}
        className="user-profile__container"
      />
      {phone ? (
        <UIDetailRow
          title="Phone"
          children={phone}
          className="user-profile__container"
        />
      ) : null}
      {addresses?.length ? (
        <UIDetailRow
          title="Address"
          children={addresses.find((i) => i.isDefault)?.formattedAddress}
          className="user-profile__container"
        />
      ) : null}
      {properties?.rewardAmount ? (
        <UIDetailRow
          title="Rewards points"
          children={properties?.rewardAmount}
          className="user-profile__container"
        />
      ) : null}
      {ageCheckStatus ? (
        <UIDetailRow
          title="Verify by Berbix"
          children={ageCheckStatus === 'success' ? 'Yes' : 'No'}
          className="user-profile__container"
        />
      ) : null}
      {/* <UIDetailRow
        title="Confirmed Email"
        children={isConfirmedEmail}
        className="user-profile__container"
      /> */}
      {/* {role === Role.DRIVERS ? (
        <UIDetailRow
          title="Confirmed age by driver"
          children={isConfirmedAgeByDriver}
          className="user-profile__container"
        />
      ) : null} */}
      <UIDetailRow
        title="Reg Date"
        children={DateHelper.formateDateToString(createdAt, FORMAT_SHORT_DATE)}
        className="user-profile__container"
      />
      {document ? (
        <>
          {document.age && (
            <UIDetailRow
              title="Age"
              children={document.age}
              className="user-profile__container"
            />
          )}
          {document.dateOfBirth && (
            <UIDetailRow
              title="Date of Birth"
              children={document.dateOfBirth}
              className="user-profile__container"
            />
          )}
          {document.familyName && (
            <UIDetailRow
              title="Family name"
              children={document.familyName}
              className="user-profile__container"
            />
          )}
          {document.givenName && (
            <UIDetailRow
              title="Given name"
              children={document.givenName}
              className="user-profile__container"
            />
          )}
          {document.idIssuer && (
            <UIDetailRow
              title="Id issuer"
              children={document.idIssuer}
              className="user-profile__container"
            />
          )}
          {document.idNumber && (
            <UIDetailRow
              title="Id number"
              children={document.idNumber}
              className="user-profile__container"
            />
          )}
          {document.idType && (
            <UIDetailRow
              title="Id type"
              children={document.idType}
              className="user-profile__container"
            />
          )}
          {document.sex && (
            <UIDetailRow
              title="Sex"
              children={document.sex}
              className="user-profile__container"
            />
          )}
          {document?.images?.front?.fullImage && (
            <Image height={100} src={document.images.front.fullImage} />
          )}
          {document?.images?.back?.fullImage && (
            <Image height={100} src={document.images.back.fullImage} />
          )}
        </>
      ) : null}
    </div>
  );
};

export default UserDetailProfile;
