import { ChangeEvent, useEffect, useRef, useState } from 'react';

import Search from 'antd/lib/input/Search';

import { useValidation } from '@hooks';

import { Props } from './index.type';

const UISearch = ({
  placeholder,
  search,
  onSearchChange,
  searchDisabled = false,
}: Props) => {
  const [strValue, setStrValue] = useState('');
  const timer: { current: NodeJS.Timeout | null } = useRef(null);
  const { trimValue } = useValidation({ value: strValue });

  useEffect(() => {
    setStrValue(search as string);
  }, [search]);

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setStrValue(value);

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      onSearchChange(value || null);
    }, 500);
  };

  return (
    <Search
      allowClear
      placeholder={placeholder}
      size="large"
      onChange={onChange}
      value={trimValue}
      disabled={searchDisabled}
    />
  );
};

export default UISearch;
