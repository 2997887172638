import { ColumnsType } from 'antd/lib/table';

import { FilterOption } from '@entities/Filter';
import { FullPromoCode } from '@entities/PromoCode';
import { SortOption } from '@entities/Sort';
import type { AppDispatch } from '@store/index';
import { UICellFullName, UICellLink, UICellStatus } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  DateHelper,
  DiscountUnit,
  FORMAT_DATE,
  FORMAT_SHORT_DATE,
  LINK_USERS,
} from '@utils';

import { PromoCodeUIActionList } from '../ui';

export const columns = ({
  dispatch,
  path,
}: {
  dispatch: AppDispatch;
  path: string;
}): ColumnsType<FullPromoCode> => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    render: (value, record) =>
      UICellLink({ value, link: `${path}/${record.id}` }),
    width: COLUMN_WIDTH_ID,
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    width: 120,
    render: (value, record) =>
      UICellLink({ value, link: `${path}/${record.id}` }),
  },
  {
    title: 'Discount unit',
    dataIndex: 'discountUnit',
    key: 'discountUnit',
    width: 100,
    render: (value: string) => value,
  },
  {
    title: 'Value',
    dataIndex: 'discountValue',
    key: 'discountValue',
    width: 120,
    render: (value: string, { discountUnit }) =>
      `${value}${discountUnit === DiscountUnit.PERCENT ? '%' : '$'}`,
  },
  {
    title: 'User',
    dataIndex: ['profile', 'fullName'],
    key: 'fullName',
    width: COLUMN_WIDTH_NAME,
    render: (value, { user }) =>
      UICellFullName({
        lastName: user?.profile?.lastName,
        firstName: user?.profile?.firstName,
        src: user?.avatar?.path,
        link: `/${LINK_USERS}/${user?.id}`,
      }),
  },
  {
    title: 'Created by',
    dataIndex: ['profile', 'fullName'],
    key: 'fullName',
    width: COLUMN_WIDTH_NAME,
    render: (value, { createdBy }) =>
      UICellFullName({
        lastName: createdBy?.profile?.lastName,
        firstName: createdBy?.profile?.firstName,
        src: createdBy?.avatar?.path,
        link: `/${LINK_USERS}/${createdBy?.id}`,
      }),
  },
  {
    title: 'Valid from / to',
    width: 140,
    render: (_, { validFrom, validUntil }) =>
      `${DateHelper.formateDateToString(
        validFrom,
        FORMAT_SHORT_DATE,
      )} / ${DateHelper.formateDateToString(validUntil, FORMAT_SHORT_DATE)}`,
  },
  {
    title: 'Is active',
    dataIndex: 'isActive',
    key: 'isActive',
    width: 80,
    render: (value) =>
      UICellStatus({
        value,
        text: value ? 'Yes' : 'No',
      }),
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    key: 'id',
    width: COLUMN_WIDTH_ACTIONS,
    render: (id) => PromoCodeUIActionList({ id, dispatch }),
    align: 'right',
  },
];

export const orderOption: SortOption[] = [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  {
    label: 'Code (A-Z)',
    name: 'code',
    direction: 'asc',
  },
  {
    label: 'Code (Z-A)',
    name: 'code',
    direction: 'desc',
  },
  {
    label: 'Create date (oldest first)',
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: 'Create date (newest first)',
    name: 'created_at',
    direction: 'desc',
  },
  {
    label: 'Valid from (oldest first)',
    name: 'valid_from',
    direction: 'asc',
  },
  {
    label: 'Valid from (newest first)',
    name: 'valid_from',
    direction: 'desc',
  },
];

const today = new Date();

export const filterOptions: FilterOption[] = [
  {
    name: 'created_at',
    label: 'Create date',
    type: 'radio-range',
    options: [
      {
        label: 'Today',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 7 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -7,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 30 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -30,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    ],
  },
  // {
  //   name: 'valid_from',
  //   label: 'Valid from',
  //   type: 'radio-range',
  //   options: [
  //     {
  //       label: 'Today',
  //       value: {
  //         min: DateHelper.getStartDayFormatString({
  //           date: today,
  //           format: FORMAT_DATE,
  //         }),
  //         max: DateHelper.getEndDayFormatString({
  //           date: today,
  //           format: FORMAT_DATE,
  //         }),
  //       },
  //     },
  //     {
  //       label: 'Last 7 days',
  //       value: {
  //         min: DateHelper.getStartDayFormatString({
  //           date: today,
  //           format: FORMAT_DATE,
  //           amountDays: -7,
  //         }),
  //         max: DateHelper.getEndDayFormatString({
  //           date: today,
  //           format: FORMAT_DATE,
  //         }),
  //       },
  //     },
  //     {
  //       label: 'Last 30 days',
  //       value: {
  //         min: DateHelper.getStartDayFormatString({
  //           date: today,
  //           format: FORMAT_DATE,
  //           amountDays: -30,
  //         }),
  //         max: DateHelper.getEndDayFormatString({
  //           date: today,
  //           format: FORMAT_DATE,
  //         }),
  //       },
  //     },
  //   ],
  // },
  // {
  //   name: 'min_order_value',
  //   label: 'Min order value',
  //   type: 'number-range',
  //   step: 1,
  //   min: 0,
  // },
  // {
  //   name: 'discount_value',
  //   label: 'Discount value',
  //   type: 'number-range',
  //   step: 1,
  //   min: 0,
  // },
  {
    name: 'isActive',
    label: 'Is active',
    type: 'radio',
    options: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
];
