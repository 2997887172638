import { parse } from 'cookie';

import { COOKIE_ACCESS_TOKEN, decode } from '@utils';

export default (() => {
  const parseToken = (): TokenPayload | null => {
    if (typeof window !== 'undefined') {
      const cookies = parse(document?.cookie || '');

      const payload = decode<TokenPayload>(
        (cookies && cookies[COOKIE_ACCESS_TOKEN]) || '',
      );

      return payload;
    }

    return null;
  };

  const clearAccessToken = () => {
    document.cookie = `accessToken=; Path=/; Domain=${document.domain
      .replace('admin', '')
      .replace('local', '')}`;
  };

  return { parseToken, clearAccessToken };
})();
