import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullReviewBrand, FullReviewUser } from '@entities/Review';
import { FullUser } from '@entities/User';
import { ReviewHttp, UserHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';

import {
  createReviewBrandFailure,
  createReviewBrandSuccess,
  createReviewUserFailure,
  createReviewUserSuccess,
  deleteReviewBrandFailure,
  deleteReviewBrandSuccess,
  deleteReviewUserFailure,
  deleteReviewUserSuccess,
  getListReviewBrandFailure,
  getListReviewBrandRequest,
  getListReviewBrandSuccess,
  getListReviewUserFailure,
  getListReviewUserRequest,
  getListReviewUserSuccess,
  getListUserFailure,
  getListUserSuccess,
  updateReviewBrandFailure,
  updateReviewBrandSuccess,
  updateReviewUserFailure,
  updateReviewUserSuccess,
} from './Review.action';
import {
  CREATE_REVIEW_BRAND_REQUEST,
  CREATE_REVIEW_USER_REQUEST,
  DELETE_REVIEW_BRAND_REQUEST,
  DELETE_REVIEW_USER_REQUEST,
  GET_LIST_REVIEW_BRAND_REQUEST,
  GET_LIST_REVIEW_USER_REQUEST,
  GET_LIST_USER_REQUEST,
  UPDATE_REVIEW_BRAND_REQUEST,
  UPDATE_REVIEW_USER_REQUEST,
} from './Review.constant';
import {
  ICreateReviewBrandRequest,
  ICreateReviewUserRequest,
  IDeleteReviewBrandRequest,
  IDeleteReviewUserRequest,
  IGetListReviewBrandRequest,
  IGetListReviewUserRequest,
  IGetListUserRequest,
  IUpdateReviewBrandRequest,
  IUpdateReviewUserRequest,
} from './Review.type';

function* workerGetListReviewBrand(action: IGetListReviewBrandRequest) {
  try {
    const { query } = action.payload;

    const payload: DataResponse<FullReviewBrand[]> = yield call(
      ReviewHttp.getListBrand,
      query,
    );

    yield put(getListReviewBrandSuccess(payload));
  } catch (error) {
    yield put(getListReviewBrandFailure());
    yield put(alertError(error));
  }
}
function* watchGetListReviewBrand() {
  yield takeLatest(GET_LIST_REVIEW_BRAND_REQUEST, workerGetListReviewBrand);
}

function* workerGetListReviewUser(action: IGetListReviewUserRequest) {
  try {
    const { query } = action.payload;

    const payload: DataResponse<FullReviewUser[]> = yield call(
      ReviewHttp.getListUser,
      query,
    );

    yield put(getListReviewUserSuccess(payload));
  } catch (error) {
    yield put(getListReviewUserFailure());
    yield put(alertError(error));
  }
}
function* watchGetListReviewUser() {
  yield takeLatest(GET_LIST_REVIEW_USER_REQUEST, workerGetListReviewUser);
}

function* workerGetListUser(action: IGetListUserRequest) {
  try {
    const { query } = action.payload;
    const payload: DataResponse<FullUser[]> = yield call(
      UserHttp.getListUser,
      query,
    );

    yield put(getListUserSuccess(payload));
  } catch (error) {
    yield put(getListUserFailure());
    yield put(alertError(error));
  }
}
function* watchGetListUser() {
  yield takeLatest(GET_LIST_USER_REQUEST, workerGetListUser);
}

function* workerCreateReviewBrand(action: ICreateReviewBrandRequest) {
  try {
    const data = action.payload;

    yield call(ReviewHttp.createBrand, data);

    yield put(createReviewBrandSuccess());
    yield put(getListReviewBrandRequest());
    yield put(hideModal());
  } catch (error) {
    yield put(createReviewBrandFailure());
    yield put(alertError(error));
  }
}
function* watchCreateReviewBrand() {
  yield takeLatest(CREATE_REVIEW_BRAND_REQUEST, workerCreateReviewBrand);
}

function* workerCreateReviewUser(action: ICreateReviewUserRequest) {
  try {
    const data = action.payload;

    yield call(ReviewHttp.createUser, data);

    yield put(createReviewUserSuccess());
    yield put(getListReviewUserRequest());
    yield put(hideModal());
  } catch (error) {
    yield put(createReviewUserFailure());
    yield put(alertError(error));
  }
}
function* watchCreateReviewUser() {
  yield takeLatest(CREATE_REVIEW_USER_REQUEST, workerCreateReviewUser);
}

function* workerDeleteReviewBrand(action: IDeleteReviewBrandRequest) {
  try {
    const { id } = action.payload;

    yield call(ReviewHttp.deleteReviewBrand, id);

    yield put(deleteReviewBrandSuccess(id));
  } catch (error) {
    yield put(deleteReviewBrandFailure());
    yield put(alertError(error));
  }
}
function* watchDeleteReviewBrand() {
  yield takeLatest(DELETE_REVIEW_BRAND_REQUEST, workerDeleteReviewBrand);
}

function* workerDeleteReviewUser(action: IDeleteReviewUserRequest) {
  try {
    const { id } = action.payload;

    yield call(ReviewHttp.deleteReviewUser, id);

    yield put(deleteReviewUserSuccess(id));
  } catch (error) {
    yield put(deleteReviewUserFailure());
    yield put(alertError(error));
  }
}
function* watchDeleteReviewUser() {
  yield takeLatest(DELETE_REVIEW_USER_REQUEST, workerDeleteReviewUser);
}

function* workerUpdateReviewBrand(action: IUpdateReviewBrandRequest) {
  try {
    const { id, ...body } = action.payload;

    const payload: FullReviewBrand = yield call(
      ReviewHttp.updateBrand,
      id,
      body,
    );

    yield put(updateReviewBrandSuccess(payload));
    yield put(alertSuccess('Review successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateReviewBrandFailure());
    yield put(alertError(error));
  }
}
function* watchUpdateReviewBrand() {
  yield takeLatest(UPDATE_REVIEW_BRAND_REQUEST, workerUpdateReviewBrand);
}

function* workerUpdateReviewUser(action: IUpdateReviewUserRequest) {
  try {
    const { id, ...body } = action.payload;

    const payload: FullReviewUser = yield call(ReviewHttp.updateUser, id, body);

    yield put(updateReviewUserSuccess(payload));
    yield put(alertSuccess('Review successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateReviewUserFailure());
    yield put(alertError(error));
  }
}
function* watchUpdateReviewUser() {
  yield takeLatest(UPDATE_REVIEW_USER_REQUEST, workerUpdateReviewUser);
}

export const reviewWatchers: ForkEffect[] = [
  fork(watchGetListUser),
  fork(watchCreateReviewBrand),
  fork(watchGetListReviewBrand),
  fork(watchDeleteReviewBrand),
  fork(watchUpdateReviewBrand),
  fork(watchCreateReviewUser),
  fork(watchGetListReviewUser),
  fork(watchDeleteReviewUser),
  fork(watchUpdateReviewUser),
];
