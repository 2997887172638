/* eslint-disable indent */
import { useEffect } from 'react';

import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UserProps } from '@entities/User';
import { LayoutContent } from '@layouts';
import { RootState, useAppDispatch } from '@store/index';
import { getOneOrderProductRequest, getOneOrderRequest } from '@store/Order';
import { UIHeader, UIOverview, UITable } from '@ui';
import { ProcessStatus, Role } from '@utils';

import { columns, infoAside, infoList } from './constant';
import OrderDetailEdit from './Edit';
import './style.scss';

const OrderDetail = () => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams<'orderId'>();

  const { order, products, loading } = useSelector(
    (state: RootState) => state.order,
  );

  useEffect(() => {
    if (orderId) {
      dispatch(getOneOrderRequest(+orderId));
      dispatch(getOneOrderProductRequest(+orderId));
    }
  }, [dispatch, orderId]);

  // const editHandler = () => {
  //   if (orderId) {
  //     dispatch(
  //       showModal({
  //         modalType: ModalType.ORDER_EDIT,
  //         modalProps: { id: +orderId },
  //       }),
  //     );
  //   }
  // };

  // const onCancelClickHandler = () => {
  //   if (orderId) {
  //     dispatch(
  //       showModal({
  //         modalType: ModalType.CONFIRM,
  //         modalProps: {
  //           content: <p>Are you sure you want to cancel your order?</p>,
  //           action: () => {
  //             dispatch(
  //               updateOrderRequest({
  //                 id: +orderId,
  //                 status: ProcessStatus.CANCELED,
  //               }),
  //             );
  //           },
  //         },
  //       }),
  //     );
  //   }
  // };

  // const renderHeaderButton = () => {
  //   if (order?.status === ProcessStatus.SUCCESS) {
  //     return (
  //       <Space>
  //         <UIButton
  //           type={Button.ANT_BUTTON}
  //           handler={onCancelClickHandler}
  //           typeAntBtn="default"
  //         >
  //           Cancel a order
  //         </UIButton>
  //       </Space>
  //     );
  //   }

  //   return null;
  // };

  // const renderEditButton = () => {
  //   if (order?.status === ProcessStatus.SUCCESS) {
  //     return (
  //       <UIButton
  //         type={Button.ANT_BUTTON}
  //         typeAntBtn="text"
  //         icon={<EditIcon />}
  //         handler={editHandler}
  //       >
  //         Edit order
  //       </UIButton>
  //     );
  //   }

  //   return null;
  // };

  const users = (): UserProps[] => {
    if (!order) {
      return [];
    }

    return [
      order.driver && {
        id: order.driver.id,
        role: Role.DRIVERS,
        firstName: order.driver.profile?.firstName,
        lastName: order.driver.profile?.lastName,
        avatar: order.driver.avatar?.path,
        phone: order.driver.phone,
      },
      order.user && {
        id: order.user.id,
        role: Role.USER,
        firstName: order.user.profile?.firstName,
        lastName: order.user.profile?.lastName,
        avatar: order.user.avatar?.path,
        phone: order.user.phone,
      },
    ].filter((i) => i);
  };

  const tabs = [
    {
      title: 'Products',
      content: order && (
        <UITable
          dataSource={products as any}
          loading={loading}
          columns={columns()}
          adaptive={false}
        />
      ),
    },
    {
      title: 'Info',
      content: order && (
        <Row className="orderAllInfo">
          <Col span={12}>
            <UIOverview
              title=""
              infoList={infoList(order).filter((_, index) => index % 2 === 0)}
            />
          </Col>
          <Col span={12}>
            <UIOverview
              title=""
              infoList={infoList(order).filter((_, index) => index % 2 === 1)}
            />
          </Col>
          {/* <Col span={4}>
            <UIOverview
              title=""
              infoList={infoList(order).filter((_, index) => index % 6 === 2)}
            />
          </Col>
          <Col span={4}>
            <UIOverview
              title=""
              infoList={infoList(order).filter((_, index) => index % 6 === 3)}
            />
          </Col>
          <Col span={4}>
            <UIOverview
              title=""
              infoList={infoList(order).filter((_, index) => index % 6 === 4)}
            />
          </Col>
          <Col span={4}>
            <UIOverview
              title=""
              infoList={infoList(order).filter((_, index) => index % 6 === 5)}
            />
          </Col> */}
        </Row>
      ),
    },
    ...(order?.status !== ProcessStatus.CANCELED &&
    order?.status !== ProcessStatus.COMPLETED
      ? [
          {
            title: 'Edit',
            content: order && (
              <OrderDetailEdit order={order} loading={loading} />
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <UIHeader title="Order" isBack />
      <LayoutContent
        content={
          <UIHeader tabs={tabs} />
          // <UIComment messageAttribute={{ orderId }} filter={{ orderId }} />
        }
        aside={
          <UIOverview
            users={users()}
            date={order?.updatedAt}
            infoList={infoAside(order)}
            imagePath={order?.driverLicense?.path}
            imageTitle="Image license"
          />
        }
      />
    </>
  );
};

export default OrderDetail;
