import { ALERT_CLEAR, ALERT_ERROR, ALERT_SUCCESS } from './Alert.constant';
import { AlertActionsTypes } from './Alert.type';

export function alertSuccess(
  message: string,
  description = '',
  style?: React.CSSProperties,
): AlertActionsTypes {
  return {
    type: ALERT_SUCCESS,
    payload: {
      message,
      description,
      style,
    },
  };
}

export function alertError(
  message: any,
  description = '',
  style?: React.CSSProperties,
): AlertActionsTypes {
  return {
    type: ALERT_ERROR,
    payload: {
      message,
      description,
      style,
    },
  };
}

export function alertClear(): AlertActionsTypes {
  return {
    type: ALERT_CLEAR,
  };
}
