/* eslint-disable indent */
import dayjs from 'dayjs';

import { FORMAT_DATE } from '@utils';

import { UIAntDatePicker } from '../Ant';
import { Props } from './index.type';

const { RangePicker } = UIAntDatePicker;

const UICalendar = (props: Props) => {
  const { onChange, placeholder, value, status } = props;

  return (
    <RangePicker
      className="ui-calendar"
      placeholder={placeholder}
      onChange={onChange}
      size="large"
      status={status}
      {...(value &&
        value.length && {
          value: [dayjs(value[0], FORMAT_DATE), dayjs(value[1], FORMAT_DATE)],
        })}
    />
  );
};

export default UICalendar;
