import { ColumnsType } from 'antd/lib/table';

import { ChatUser, FullChat, Message } from '@entities/Chat';
import { FilterOption } from '@entities/Filter';
import { Order } from '@entities/Order';
import { SortOption } from '@entities/Sort';
import { UICellLink, UICellTooltip } from '@ui';
import {
  COLUMN_WIDTH_FULL_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_LONG_TEXT,
  COLUMN_WIDTH_NAME,
  DateHelper,
  FORMAT_DATE,
  FORMAT_DATE_TIME_WITH_DOT,
  LINK_CHAT,
  LINK_ORDER,
  LINK_USERS,
} from '@utils';

export const infoList = (order: (Order & Partial<Id>) | null) => [
  {
    name: 'Order',
    value: UICellLink({
      value: `Open order: ${order?.id}`,
      link: `/${LINK_ORDER}/${order?.id}`,
      className: 'p-0',
    }),
  },
];

export const columns = (): ColumnsType<FullChat> => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    render: (value: number) =>
      UICellLink({ value, link: `/${LINK_CHAT}/${value}` }),
    width: COLUMN_WIDTH_ID,
    fixed: 'left',
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (value: ChatUser) =>
      UICellLink({
        value: value?.profile?.fullName || 'no name',
        link: `/${LINK_USERS}/${value?.id}`,
      }),
    width: COLUMN_WIDTH_NAME,
  },
  {
    title: 'Driver',
    dataIndex: 'driver',
    key: 'driver',
    render: (value: ChatUser) =>
      UICellLink({
        value: value?.profile?.fullName || 'no name',
        link: `/${LINK_USERS}/${value?.id}`,
      }),
    width: COLUMN_WIDTH_NAME,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value: string) =>
      DateHelper.formateDateToString(value, FORMAT_DATE_TIME_WITH_DOT),
    width: COLUMN_WIDTH_FULL_DATE,
  },
  {
    title: 'Text',
    dataIndex: 'message',
    width: COLUMN_WIDTH_LONG_TEXT,
    key: 'message',
    ellipsis: true,
    render: (message: Message) => UICellTooltip({ text: message?.text || '' }),
  },
];

// const clientSort: SortOption[] = [
//   {
//     label: 'Client name (A-Z)',
//     name: 'user_full_name',
//     direction: 'asc',
//   },
//   {
//     label: 'Client name (Z-A)',
//     name: 'user_full_name',
//     direction: 'desc',
//   },
// ];

// const driverSort: SortOption[] = [
//   {
//     label: 'Expert name (A-Z)',
//     name: 'driver_full_name',
//     direction: 'asc',
//   },
//   {
//     label: 'Expert name (Z-A)',
//     name: 'driver_full_name',
//     direction: 'desc',
//   },
// ];

// const renderSort = (role?: Role) => {
//   if (role === Role.DRIVERS) {
//     return clientSort;
//   }

//   if (role === Role.USER) {
//     return driverSort;
//   }

//   return [...clientSort, ...driverSort];
// };

// export const sortOption = ({ role }: { role?: Role }): SortOption[] => [
export const sortOption = (): SortOption[] => [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  // ...renderSort(role),
  {
    label: 'Date (oldest first)',
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: 'Date (newest first)',
    name: 'created_at',
    direction: 'desc',
  },
  {
    label: 'Message date (oldest first)',
    name: 'message_created_at',
    direction: 'asc',
  },
  {
    label: 'Message date (newest first)',
    name: 'message_created_at',
    direction: 'desc',
  },
];

export const filterOptions: FilterOption[] = [
  {
    name: 'created_at',
    label: 'Date',
    type: 'radio-range',
    options: [
      {
        label: 'Today',
        value: {
          min: DateHelper.getStartDayFormatString({ format: FORMAT_DATE }),
          max: DateHelper.getEndDayFormatString({ format: FORMAT_DATE }),
        },
      },
      {
        label: 'Last 7 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            format: FORMAT_DATE,
            amountDays: -7,
          }),
          max: DateHelper.getEndDayFormatString({ format: FORMAT_DATE }),
        },
      },
      {
        label: 'Last 30 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            format: FORMAT_DATE,
            amountDays: -30,
          }),
          max: DateHelper.getEndDayFormatString({ format: FORMAT_DATE }),
        },
      },
    ],
  },
];
