import { Route, Routes } from 'react-router-dom';

import { PromoCodeDetail, PromoCodeList } from '@features/PromoCode';

const ViewPromoCode = () => {
  return (
    <Routes>
      <Route path=":promoCodeId" element={<PromoCodeDetail />} />
      <Route path="/" element={<PromoCodeList />} />
    </Routes>
  );
};

export default ViewPromoCode;
