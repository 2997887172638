import { useEffect, useState } from 'react';

import Title from 'antd/lib/typography/Title';

import { useEndLessScroll } from '@hooks';
import { useAppDispatch } from '@store/index';
import { clearSupportMessage, getListMessageRequest } from '@store/Support';
import { ActionStatus, LIMIT_DEFAULT } from '@utils';

import { SupportUiSeparator } from '../../../ui';
import SupportDetailMessageItem from '../Item';
import { Props } from './index.type';

const SupportDetailMessageList = ({ messages, chat, meta, status }: Props) => {
  const [page, setPage] = useState(1);
  const [oldScroll, setOldScroll] = useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearSupportMessage());
  }, [dispatch]);

  useEffect(() => {
    if (chat && page > 0) {
      dispatch(
        getListMessageRequest({
          id: chat.id,
          query: `?limit=${meta?.limit || LIMIT_DEFAULT}&page=${page}`,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, chat]);

  const [, containerRef, onScrollBottom] = useEndLessScroll({
    onIntersection: () => {
      if (meta.currentPage < meta.totalPages) {
        setPage(page + 1);
      }
    },
  });

  useEffect(() => {
    const refScroll = (containerRef as React.MutableRefObject<null>)
      ?.current as unknown as Element;

    if (
      (containerRef && messages.length <= meta?.limit) ||
      status === ActionStatus.UPDATED
    ) {
      refScroll.scrollTo({ top: refScroll.scrollHeight });
      setOldScroll(refScroll.scrollHeight);
    } else {
      refScroll.scrollTo({ top: refScroll.scrollHeight - oldScroll });
      setOldScroll(refScroll.scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, messages]);

  const renderList = () =>
    messages.length && chat?.user ? (
      messages.map((message, key) => {
        return (
          <div key={`message_${key}`}>
            <SupportUiSeparator
              step={key}
              currentDate={message.createdAt}
              prevDate={messages[key - 1]?.createdAt || message.createdAt}
            />
            <SupportDetailMessageItem chat={chat} message={message} />
          </div>
        );
      })
    ) : (
      <Title level={5}>Еhere are no messages yet</Title>
    );

  return (
    <div className="message-list chat-detail">
      <div
        ref={containerRef}
        className="massage-items"
        onScroll={onScrollBottom as any}
      >
        {renderList()}
      </div>
    </div>
  );
};

export default SupportDetailMessageList;
