import { FullChat } from '@entities/Chat';
import { http } from '@utils';

const basePath = '/api/chats';

export const getList = async (
  query?: string,
): Promise<DataResponse<FullChat[]>> => {
  const { data } = await http.get<DataResponse<FullChat[]>>(
    `${basePath}${query || ''}`,
  );

  return data;
};

export const getListMessage = async (props: {
  id: number;
  query?: string;
}): Promise<DataResponse<FullChat[]>> => {
  const { id, query } = props;

  const { data } = await http.get<DataResponse<FullChat[]>>(
    `${basePath}/${id}/messages${query || ''}`,
  );

  return data;
};

export const getOne = async (id: FullChat['id']): Promise<FullChat> => {
  const { data } = await http.get<DataResponse<FullChat>>(`${basePath}/${id}`);

  return data.data;
};
