// Page
export const LINK_ORDER = 'order';
export const LINK_PROMO_CODE = 'promoCode';
export const LINK_PRODUCT = 'product';
export const LINK_BRAND = 'brand';
export const LINK_EFFECT = 'effect';
export const LINK_CATEGORY = 'category';
export const LINK_DASHBOARD = 'dashboard';
export const LINK_LOGIN = 'login';
export const LINK_PAYOUT = 'payouts';
export const LINK_SETTING = 'settings';
export const LINK_TRANSACTION = 'transactions';
export const LINK_USERS = 'users';
export const LINK_REVIEW = 'reviews';
export const LINK_SHOP = 'shop';
export const LINK_CHAT = 'chats';
export const LINK_SUPPORT = 'support';
export const LINK_CSV = 'csv';
export const LINK_PAYMENT = 'payment';

// Socket
export const SOCKET_PATH = '/socket';
export const SOCKET_CHAT_NAMESPACE = 'chat';
export const SOCKET_SUPPORT_NAMESPACE = 'support';
export const SOCKET_ORDER_NAMESPACE = 'order';
export const SOCKET_CSV_NAMESPACE = 'csv';
export const SOCKET_NOTIFICATION_NAMESPACE = 'notification';
