/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Form, Input, Radio } from 'antd';
import PlacesAutocomplete from 'react-google-autocomplete';

import { IAddressComponents, IAddressesRequest } from '@entities/Address';
import { en } from '@i18n';
import {
  DateHelper,
  FORMAT_SHORT_DATE,
  Role,
  VALIDATE_MAX_PHONE,
  VALIDATE_MAX_USERNAME,
  getInfoAboutPlace,
} from '@utils';

import { WidgetModalUIImage } from '../../ui';
import { INITIAL_VALUES } from './constant';
import { FormUser, Props } from './index.type';

const WidgetModalUserForm = ({
  user,
  avatar,
  onFormSubmit,
  form,
  onUploadImage,
  onRemoveImage,
}: Props) => {
  // const dispatch = useAppDispatch();

  const [addresses, setAddresses] = useState(user?.addresses);
  const [newAddress, setNewAddress] = useState<IAddressesRequest>({});
  // const {
  //   shopList,
  //   loading: loadingShop,
  //   meta: metaShop,
  // } = useSelector((state: RootState) => state.shop);

  const role = Form.useWatch('role', form);
  // const storeId = Form.useWatch(['store', 'id'], form);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        ...user,
        profile: {
          ...user.profile,
        },
        ...(user?.product && {
          product: user.product.map((item) => item.id),
        }),
      });
      if (user?.addresses) {
        setAddresses(user.addresses);
      }
    }
  }, [form, user]);

  const handlerFinish = (values: FormUser) => {
    const addressesAdd =
      newAddress.formattedAddress &&
      !addresses
        ?.map((i) => i.formattedAddress)
        .includes(newAddress.formattedAddress)
        ? [
            { ...newAddress, isDefault: true },
            ...(addresses?.length
              ? addresses.map((obj) => ({ ...obj, isDefault: false }))
              : []),
          ]
        : addresses;

    onFormSubmit({
      ...values,
      avatar,
      ...(role !== Role.ADMIN &&
        role !== Role.MANAGER &&
        addressesAdd && { addresses: addressesAdd || [] }),
      profile: {
        ...values.profile,
      },
    });
  };

  const handleAddCity = (obj: google.maps.places.PlaceResult) => {
    setNewAddress({
      ...newAddress,
      ...getInfoAboutPlace(obj?.address_components as IAddressComponents[]),
      formattedAddress: obj.formatted_address || '',
      location: {
        lat: obj?.geometry?.location?.lat(),
        lng: obj?.geometry?.location?.lng(),
      },
    });
  };

  const handleDeleteCity = (id: number) => {
    setAddresses(addresses?.filter((item) => item.id !== id));
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 18 }}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      className="modal-create"
      autoComplete="off"
      validateMessages={en.validation}
    >
      <WidgetModalUIImage
        view="avatar"
        path={avatar?.path}
        onUploadImage={onUploadImage}
        onRemoveImage={onRemoveImage}
      />
      {role === Role.USER ? (
        <>
          <Form.Item label="New Address">
            <PlacesAutocomplete
              className="ant-input"
              onPlaceSelected={(places) => handleAddCity(places)}
              apiKey="AIzaSyCaSLe663mSesOXEO5HXgF_tNE_osZNKl4"
              options={{ types: ['geocode', 'establishment'] }}
            />
          </Form.Item>
        </>
      ) : null}
      <Form.Item
        label="First Name"
        name={['profile', 'firstName']}
        required
        rules={[
          { required: true, whitespace: true },
          { max: VALIDATE_MAX_USERNAME, type: 'string' },
        ]}
      >
        <Input
          showCount
          placeholder="First Name"
          maxLength={VALIDATE_MAX_USERNAME}
        />
      </Form.Item>
      <Form.Item
        label="Last name"
        name={['profile', 'lastName']}
        required
        rules={[
          { required: true, whitespace: true },
          { max: VALIDATE_MAX_USERNAME, type: 'string' },
        ]}
      >
        <Input
          showCount
          placeholder="Last name"
          maxLength={VALIDATE_MAX_USERNAME}
        />
      </Form.Item>
      {role === Role.DRIVERS ? (
        <>
          {/* <UISelectFormItem
            label="Store"
            name={['store', 'id']}
            options={shopList}
            getOption={(id) =>
              id &&
              dispatch(
                getOneShopRequest({
                  id: +id,
                  toList: true,
                }),
              )
            }
            valueId={storeId}
            getOptions={(query) => dispatch(getListShopRequest(query))}
            clearOptions={() => dispatch(clearListShop())}
            meta={metaShop}
            loading={loadingShop}
          /> */}
          <Form.Item
            name={['car', 'brandName']}
            label="Brand car"
            required
            rules={[{ required: true, whitespace: true }]}
          >
            <Input
              showCount
              placeholder="Brand car"
              maxLength={VALIDATE_MAX_USERNAME}
            />
          </Form.Item>
          <Form.Item name={['car', 'color']} label="Color car">
            <Input
              showCount
              placeholder="Color car"
              maxLength={VALIDATE_MAX_USERNAME}
            />
          </Form.Item>
          <Form.Item
            name={['car', 'licensePlate']}
            label="License plate"
            required
            rules={[{ required: true, whitespace: true }]}
          >
            <Input
              showCount
              placeholder="License plate"
              maxLength={VALIDATE_MAX_USERNAME}
            />
          </Form.Item>
          <Form.Item
            name={['car', 'modelName']}
            label="Model car"
            required
            rules={[{ required: true, whitespace: true }]}
          >
            <Input
              showCount
              placeholder="Model car"
              maxLength={VALIDATE_MAX_USERNAME}
            />
          </Form.Item>
          <Form.Item name={['car', 'year']} label="Year car">
            <Input
              showCount
              placeholder="Year car"
              maxLength={VALIDATE_MAX_USERNAME}
            />
          </Form.Item>
        </>
      ) : null}
      <Form.Item label="Role" name="role">
        <Radio.Group size="large">
          <Radio.Button value={Role.USER}>Customer</Radio.Button>
          <Radio.Button value={Role.DRIVERS}>Driver</Radio.Button>
          <Radio.Button value={Role.ADMIN}>Admin</Radio.Button>
          <Radio.Button value={Role.MANAGER}>Manager</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {addresses?.length ? (
        <Form.Item label="Addresses">
          <Radio.Group value={addresses?.find((i) => i.isDefault)?.id}>
            {addresses?.map((item) => (
              <Radio.Button
                onClick={() =>
                  setAddresses(
                    addresses?.map((i) => ({
                      ...i,
                      isDefault: i.id === item.id,
                    })),
                  )
                }
                key={item.id}
                value={item.id}
              >
                <CloseOutlined
                  className="close"
                  onClick={() => handleDeleteCity(item.id)}
                />
                {item.formattedAddress}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      ) : null}
      <Form.Item
        label="Email"
        name="email"
        required
        rules={[
          {
            required: true,
            whitespace: true,
            type: 'email',
          },
        ]}
      >
        <Input placeholder="Email" autoComplete="off" />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone"
        rules={[{ max: VALIDATE_MAX_PHONE }]}
      >
        <Input placeholder="Phone" />
      </Form.Item>
      {/* {(role === Role.ADMIN || role === Role.MANAGER) && (
        <Form.Item label="Password" name="password">
          <Input.Password placeholder="Password" autoComplete="off" />
        </Form.Item>
      )} */}
      {user?.createdAt && (
        <Form.Item label="Reg Date">
          <Input
            value={DateHelper.formateDateToString(
              user.createdAt,
              FORMAT_SHORT_DATE,
            )}
            disabled
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default WidgetModalUserForm;
