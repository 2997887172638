import cn from 'classnames';

import { Button, LINK_USERS, Role } from '@utils';

import UIAvatar from '../Avatar';
import UIButton from '../Button';
import { Props } from './index.type';

import './style.scss';

const UIUserCard = (props: Props) => {
  const { id, firstName, lastName, avatar, className, role, phone } = props;
  const isExpert = role === Role.DRIVERS;
  const link = `/${LINK_USERS}/${id}`;

  return (
    <div className={cn('ui-user-card', className)}>
      <div className="ui-user-card-role">
        {isExpert ? 'Driver' : 'Customer'}
      </div>
      <UIAvatar className="ui-user-card-avatar" path={avatar} />
      <UIButton type={Button.LINK} link={link} className="ui-user-card-name">
        {firstName} {lastName}
      </UIButton>
      {phone && <div className="ui-user-card-role">{phone}</div>}
    </div>
  );
};

export default UIUserCard;
