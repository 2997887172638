import { useCallback, useEffect } from 'react';

import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { FullProduct } from '@entities/Product';
import { useQuery } from '@hooks';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import {
  clearListProduct,
  deleteProductAllRequest,
  getListProductRequest,
} from '@store/Product';
import { UIHeader, UITable } from '@ui';
import { LIMIT_DEFAULT } from '@utils';

import { columns, filterOptions, orderOption } from './constants';

const ProductList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { query, updateQuery } = useQuery();
  const { productList, loading, meta } = useSelector(
    (state: RootState) => state.product,
  );

  const getRequest = useCallback(() => {
    dispatch(clearListProduct());
    dispatch(getListProductRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    if (search === '') {
      updateQuery({ page: 1, limit: LIMIT_DEFAULT });
    } else {
      getRequest();
    }

    return () => {
      dispatch(clearListProduct());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest]);

  useEffect(() => {
    LocalStorage.set('isBack', pathname + query);
  }, [pathname, query]);

  const onCreateProduct = useCallback(() => {
    dispatch(
      showModal({
        modalType: ModalType.CREATE_PRODUCTS,
        modalProps: { type: 'create' },
      }),
    );
  }, [dispatch]);

  const onCreateEndDay = useCallback(() => {
    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: <p>Are you sure? </p>,
          action: () => dispatch(deleteProductAllRequest()),
        },
      }),
    );
  }, [dispatch]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  const headerButtons = (
    <>
      <Button size="large" type="primary" onClick={onCreateProduct}>
        Add product
      </Button>

      <Button size="large" type="primary" onClick={onCreateEndDay}>
        End of day
      </Button>
    </>
  );

  return (
    <>
      <UIHeader
        title="Product"
        isSearch={true}
        buttons={headerButtons}
        sortOption={orderOption}
        filterOptions={filterOptions(dispatch)}
        searchTitle="Filter product"
      />
      <Row>
        <UITable
          dataSource={productList || []}
          loading={loading}
          meta={meta}
          columns={columns({
            path: pathname,
            dispatch,
          })}
          onRow={(item: FullProduct) => navigate(`${pathname}/${item?.id}`)}
          onPaginationChange={onPaginationChange}
          // isScrollDefault
        />
      </Row>
    </>
  );
};

export default ProductList;
