import { useEffect, useState } from 'react';

import { Form, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { FullMedia } from '@entities/Media';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import {
  clearProduct,
  createProductRequest,
  getOneProductRequest,
  updateProductRequest,
} from '@store/Product';
import { UIButton, UIModal } from '@ui';
import { Button, FileName, UploadFile } from '@utils';

import WidgetModalProductForm from './Form';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';
import './style.scss';

const WidgetModalProduct = (props: Props) => {
  const { productId } = props;

  const {
    loading: loadingImage,
    file: fileStore,
    name: nameFile,
  } = useSelector((state: RootState) => state.media);
  const { product, loading: loadingProduct } = useSelector(
    (state: RootState) => state.product,
  );
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();
  const [imageFile, setImageFile] = useState<FullMedia | null>(null);
  const [promotionImageFile, setPromotionImageFile] =
    useState<FullMedia | null>(null);

  useEffect(() => {
    if (fileStore) {
      switch (nameFile) {
        case 'image': {
          setImageFile(fileStore);
          break;
        }
        case 'promotionImage': {
          setPromotionImageFile(fileStore);
          break;
        }
        default:
      }
    }
  }, [dispatch, fileStore, nameFile]);

  useEffect(() => {
    if (productId) {
      dispatch(getOneProductRequest(+productId));
    }

    return () => {
      dispatch(clearProduct());
    };
  }, [dispatch, productId]);

  const isCreate = props.type === 'create';

  const onFormSubmitHandler = (values: FormValues) => {
    if (isCreate) {
      dispatch(clearProduct());
      dispatch(createProductRequest(values));
    } else {
      dispatch(updateProductRequest({ ...values, id: +productId }));
    }
  };

  const onUploadImageHandler = (
    file: UploadFile['file'],
    name?: string | null,
  ) => {
    dispatch(
      uploadMediaRequest({
        file,
        fileName: FileName.IMAGE,
        ...(name && { name }),
      }),
    );
  };

  useEffect(() => {
    if (product) {
      if (product.image) {
        setImageFile(product.image);
      }
      if (product?.promotionImage) {
        setPromotionImageFile(product?.promotionImage);
      }
    }
  }, [product]);

  const onRemoveImageHandler = (name?: string) => {
    dispatch(clearStore());
    switch (name) {
      case 'image': {
        setImageFile(null);
        break;
      }
      case 'promotionImage': {
        setPromotionImageFile(null);
        break;
      }
      default:
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, [dispatch]);

  const footer = [
    <UIButton
      key="Save changes"
      typeAntBtn="primary"
      handler={() => form.submit()}
      type={Button.ANT_BUTTON}
      size="large"
      className="edit-form__save-button"
    >
      {isCreate ? 'Add product' : 'Save'}
    </UIButton>,
  ];

  return (
    <UIModal
      className="edit-form"
      title={isCreate ? 'Add product' : 'Edit product'}
      footer={footer}
      visible
      children={
        <Spin spinning={loadingProduct || loadingImage}>
          <WidgetModalProductForm
            product={product}
            image={imageFile}
            promotionImage={promotionImageFile}
            form={form}
            isCreate={isCreate}
            onFormSubmit={onFormSubmitHandler}
            onUploadImage={onUploadImageHandler}
            onRemoveImage={onRemoveImageHandler}
          />
        </Spin>
      }
    />
  );
};

export default WidgetModalProduct;
