/* eslint-disable indent */
import React, { useEffect, useState } from 'react';

import { Button, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FullUser, ProductListToDriver } from '@entities/User';
import { useQuery } from '@hooks';
import { LayoutContent } from '@layouts';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import {
  clearListProduct,
  clearProductDrivers,
  getListProductRequest,
  getUserProductsRequest,
} from '@store/Product';
import {
  clearUserOneStore,
  clearUserProduct,
  createUserDriverRequest,
  deleteUserRequest,
  getOneUserRequest,
  updateUserRequest,
} from '@store/User';
import { UIHeader } from '@ui';
import { LIMIT_DEFAULT, LINK_USERS, Role } from '@utils';

import UserEdit from './Edit';
import ProductAllToDriver from './ProductAllToDriver';
import ProductToDriver from './ProductToDriver';
import ProductToOrder from './ProductToOrder';
import { filterOptions, sortOption } from './ProductToOrder/constant';
import UserDetailProfile from './Profile';

import './style.scss';

const UserDetail = () => {
  const dispatch = useAppDispatch();
  const { updateQuery } = useQuery();
  const { userId } = useParams<'userId'>();
  const { user, loadingUser, productList } = useSelector(
    (state: RootState) => state.user,
  );
  const tabsActive = LocalStorage.get('tabs');
  const [userInfo, setUserInfo] = useState<FullUser | null>(null);

  useEffect(() => {
    if (userId) {
      dispatch(getOneUserRequest({ id: +userId }));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (user && userId && +user.id === +userId) {
      setUserInfo(user);
    }
  }, [dispatch, userId, user]);

  useEffect(() => {
    return () => {
      LocalStorage.clear('tabs');
      LocalStorage.clear('isBack');
      dispatch(clearUserOneStore());
      // dispatch(clearSession());
    };
  }, [dispatch]);

  const onChangeStatus = () => {
    if (user) {
      dispatch(
        showModal({
          modalType: ModalType.CONFIRM,
          modalProps: {
            content: (
              <p>
                Are you sure you want to{' '}
                {!user.isVerified ? 'verify' : 'unverify'} the user
                <b> {user?.profile?.fullName || ''}</b>?
              </p>
            ),
            action: () =>
              dispatch(
                updateUserRequest({
                  id: user.id,
                  body: { isVerified: !user.isVerified },
                }),
              ),
          },
        }),
      );
    }
  };

  if (!userId) {
    return null;
  }

  const onDeleteUserHandler = () => {
    if (userInfo) {
      dispatch(
        showModal({
          modalType: ModalType.CONFIRM,
          modalProps: {
            content: (
              <p>
                Are you sure you want to delete the User
                <b> {userInfo?.profile?.fullName || ''}</b>?
              </p>
            ),
            action: () => dispatch(deleteUserRequest({ id: userInfo.id })),
          },
        }),
      );
    }
  };

  const aside = [
    userInfo && <UserDetailProfile user={userInfo} />,
    user && user.role === Role.DRIVERS && (
      <Button
        type="primary"
        className="user-card__delete-button"
        danger={user?.isVerified}
        onClick={onChangeStatus}
      >
        {!user?.isVerified ? 'Verify' : 'Unverify'} the user
      </Button>
    ),
    <Button
      type="primary"
      className="user-card__delete-button"
      danger
      onClick={onDeleteUserHandler}
    >
      Delete the user
    </Button>,
  ];

  const tabs = [
    {
      title: 'Edit',
      content: userInfo && (
        <UserEdit user={userInfo} loadingUser={loadingUser} />
      ),
    },
    ...(userInfo?.role === Role.DRIVERS
      ? [
          {
            title: 'Products',
            content: userInfo && <ProductToDriver id={+userInfo.id} />,
          },
          {
            title: 'Inventory tracking',
            content: userInfo && (
              <ProductAllToDriver
                tabsActive={tabsActive as string}
                id={+userInfo.id}
              />
            ),
          },
        ]
      : []),
    ...(userInfo?.role === Role.USER || userInfo?.role === Role.DRIVERS
      ? [
          {
            title: 'Orders history',
            content: userInfo && (
              <ProductToOrder role={userInfo.role} id={+userInfo.id} />
            ),
          },
        ]
      : []),
  ];

  const content = [
    <div className="userDetails__header">
      <UIHeader
        onClickTabs={() => updateQuery({ page: 1, limit: LIMIT_DEFAULT })}
        tabs={tabs}
        buttons={
          <div style={{ marginBottom: '2px' }}>
            {tabsActive === 'Orders history' ? (
              <UIHeader
                {...(userInfo?.role === Role.USER && {
                  sortOption: sortOption(),
                  filterOptions: filterOptions(),
                })}
              />
            ) : null}
            {tabsActive === 'Products' ? (
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  if (user) {
                    dispatch(
                      showModal({
                        modalType: ModalType.PRODUCT_TO_DRIVER,
                        modalProps: {
                          clear: () => dispatch(clearListProduct()),
                          get: (query: string) =>
                            dispatch(
                              getListProductRequest(
                                `${query}&filter[isActive]=true`,
                              ),
                            ),
                          listActive: productList,
                          update: (payload: ProductListToDriver[]) => {
                            dispatch(
                              createUserDriverRequest({
                                body: payload,
                                id: user.id,
                              }),
                            );
                            dispatch(clearProductDrivers());
                            dispatch(clearUserProduct());
                            dispatch(
                              getUserProductsRequest({
                                id: user.id,
                                query: '',
                              }),
                            );
                          },
                        },
                      }),
                    );
                  }
                }}
              >
                Add products to this driver
              </Button>
            ) : null}
          </div>
        }
        defaultTitle="Edit"
      />
    </div>,
  ];

  return (
    <Space direction="vertical">
      <UIHeader
        isBack
        backLink={LocalStorage.get('isBack') || `/${userInfo && LINK_USERS}`}
        title={userInfo?.profile?.fullName || ''}
      />
      <LayoutContent
        loadingContent={loadingUser}
        content={content}
        loadingAside={loadingUser}
        aside={aside}
      />
    </Space>
  );
};

export default React.memo(UserDetail);
