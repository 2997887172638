import axios, { CancelTokenSource } from 'axios';

import { FormPromoCode, FullPromoCode } from '@entities/PromoCode';
import { http } from '@utils';

const basePath = '/api/coupons';
let axiosSource: CancelTokenSource | null = null;

export const getList = async (
  query?: Query['query'],
  isCancel = true,
): Promise<{
  data: FullPromoCode[];
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<FullPromoCode[]>>(
    `${basePath}${query || ''}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};

export const create = async (body: FormPromoCode) => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullPromoCode>>(`${basePath}`, body);

  return data;
};

export const remove = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const getOne = async (
  id: FullPromoCode['id'],
): Promise<FullPromoCode> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullPromoCode>>(`${basePath}/${id}`);

  return data;
};

export const update = async (
  id: number,
  body: FormPromoCode,
): Promise<FullPromoCode> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullPromoCode>>(`${basePath}/${id}`, body);

  return data;
};
