import { useEffect } from 'react';

import { Button, Col, Form, Input, InputNumber, Row, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { en } from '@i18n';
import { UIImageUpload } from '@ui';

import { INITIAL_VALUES } from './constant';
import { FormValues, Props } from './index.type';

const BrandEditForm = ({
  onFormSubmit,
  form,
  brand,
  onUploadImage,
  logo,
  background,
  onRemoveImage,
}: Props) => {
  useEffect(() => {
    if (brand) {
      form.setFieldsValue(brand);
    }
  }, [brand, form]);

  const handlerFinish = (values: FormValues) => {
    onFormSubmit({
      ...values,
      logo,
      background,
    });
  };

  const imageWrap = {
    labelCol: { span: 0, xxl: 7 },
    wrapperCol: { span: 24, xxl: 17 },
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ xxl: 3, span: 5 }}
      wrapperCol={{ xxl: 18, span: 19 }}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      validateMessages={en.validation}
    >
      <Row justify="center">
        <Col span={12}>
          <Form.Item {...imageWrap} name="logo" label="Logo">
            <UIImageUpload
              view="image"
              name="logo"
              label="Logo"
              path={logo?.path || 'error'}
              onUploadImage={onUploadImage}
              onRemoveImage={onRemoveImage}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...imageWrap} name="background" label="Background">
            <UIImageUpload
              view="image"
              label="Background"
              name="background"
              path={background?.path || 'error'}
              onUploadImage={onUploadImage}
              onRemoveImage={onRemoveImage}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="name"
        label="Name of the brand"
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input placeholder="Enter the brand" />
      </Form.Item>
      <Form.Item label="Order" name="order">
        <InputNumber min={0} type="number" placeholder="Order" />
      </Form.Item>
      <Form.Item label="Visible" name="isActive" required>
        <Switch defaultChecked={brand?.isActive} />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <TextArea showCount rows={4} placeholder="Enter the description" />
      </Form.Item>
      <div className="brand-edit__save-button">
        <Button
          key="Save changes"
          type="primary"
          onClick={() => form.submit()}
          size="large"
          className="edit-form__save-button"
        >
          Save changes
        </Button>
      </div>
    </Form>
  );
};

export default BrandEditForm;
