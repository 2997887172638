import { useEffect } from 'react';

import { Form, Spin } from 'antd';

import { useAppDispatch } from '@store/index';
import { clearStore } from '@store/Media';
import { updateRewardRequest } from '@store/Setting';

import RewardEditForm from './Form';
import { FormReward } from './Form/index.type';
import { Props } from './index.type';

const RewardEdit = ({ reward, loading }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormReward>();

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, [dispatch]);

  const onFormSubmitHandler = (values: FormReward) => {
    dispatch(
      updateRewardRequest({
        ...values,
        id: reward.id,
      }),
    );
  };

  return (
    <div className="reward-profile reward-edit">
      <Spin spinning={loading}>
        <RewardEditForm
          reward={reward}
          form={form}
          onFormSubmit={onFormSubmitHandler}
        />
      </Spin>
    </div>
  );
};

export default RewardEdit;
