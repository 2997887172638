import { useCallback, useEffect } from 'react';

import { Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { FullChat } from '@entities/Chat';
import { useQuery } from '@hooks';
import { RootState, useAppDispatch } from '@store/index';
import { getListSupportRequest } from '@store/Support';
import { UIHeader, UITable } from '@ui';
import { LIMIT_DEFAULT, LINK_SUPPORT } from '@utils';

import { columns, filterOptions, sortOption } from '../constant';

const SupportList = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { query, updateQuery } = useQuery();
  const { chatList, meta, loading } = useSelector(
    (state: RootState) => state.support,
  );
  const navigate = useNavigate();

  const getRequest = useCallback(() => {
    dispatch(getListSupportRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    if (search === '') {
      updateQuery({ page: 1, limit: LIMIT_DEFAULT });
    } else {
      getRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest]);

  const onPaginationChange = (page: number, limit = meta.limit) => {
    updateQuery({ page, limit });
  };

  return (
    <>
      <UIHeader
        title="Supports"
        sortOption={sortOption()}
        filterOptions={filterOptions}
      />
      <Row>
        <UITable
          dataSource={chatList || []}
          loading={loading}
          columns={columns()}
          meta={meta}
          onPaginationChange={onPaginationChange}
          onRow={(item: FullChat) => navigate(`/${LINK_SUPPORT}/${item.id}`)}
          // isScrollDefault
          adaptive={false}
        />
      </Row>
    </>
  );
};

export default SupportList;
