import { TopInfo } from '@entities/Statistic';

import { GroupOption } from './index.type';

export const groupOptions: GroupOption[] = [
  {
    name: 'Day',
    value: 'day',
  },
  {
    name: 'Week',
    value: 'week',
  },
  {
    name: 'Month',
    value: 'month',
  },
  {
    name: 'Quarter',
    value: 'quarter',
  },
  {
    name: 'Year',
    value: 'year',
  },
];

export const infoTop: {
  dataIndex: keyof TopInfo;
  title: string;
}[] = [
  {
    title: 'Total price',
    dataIndex: 'totalPrice',
  },
  {
    title: 'Number of packages sold',
    dataIndex: 'totalSoldPacks',
  },
  {
    title: 'Number of products sold',
    dataIndex: 'totalSoldProducts',
  },
  {
    title: 'Total weight',
    dataIndex: 'totalWeight',
  },
];
