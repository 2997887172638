import { Route, Routes } from 'react-router-dom';

import { ProductDetail, ProductList } from '@features/Product';

const ViewProduct = () => {
  return (
    <Routes>
      <Route path=":productId" element={<ProductDetail />} />
      <Route path="/" element={<ProductList />} />
    </Routes>
  );
};

export default ViewProduct;
