import { FullCategory } from '@entities/Category';

import {
  CLEAR_CATEGORY,
  CLEAR_IMAGE_TO_CATEGORY,
  CLEAR_LIST_CATEGORY,
  CREATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  GET_LIST_CATEGORY_FAILURE,
  GET_LIST_CATEGORY_REQUEST,
  GET_LIST_CATEGORY_SUCCESS,
  GET_ONE_CATEGORY_FAILURE,
  GET_ONE_CATEGORY_REQUEST,
  GET_ONE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
} from './Category.constant';
import { CategoryActionsTypes } from './Category.type';

export function getListCategoryRequest(
  query?: Query['query'],
): CategoryActionsTypes {
  return {
    type: GET_LIST_CATEGORY_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListCategorySuccess(
  payload: DataResponse<FullCategory[]>,
): CategoryActionsTypes {
  return {
    type: GET_LIST_CATEGORY_SUCCESS,
    payload,
  };
}

export function getListCategoryFailure(): CategoryActionsTypes {
  return {
    type: GET_LIST_CATEGORY_FAILURE,
  };
}

export function createCategoryRequest(
  payload: Omit<FullCategory, 'id' | 'isActive'>,
): CategoryActionsTypes {
  return {
    type: CREATE_CATEGORY_REQUEST,
    payload,
  };
}

export function createCategorySuccess(
  payload: FullCategory,
): CategoryActionsTypes {
  return {
    type: CREATE_CATEGORY_SUCCESS,
    payload,
  };
}

export function createCategoryFailure(): CategoryActionsTypes {
  return {
    type: CREATE_CATEGORY_FAILURE,
  };
}

export function deleteCategoryRequest(
  id: FullCategory['id'],
): CategoryActionsTypes {
  return {
    type: DELETE_CATEGORY_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteCategorySuccess(
  payload: FullCategory['id'],
): CategoryActionsTypes {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload,
  };
}

export function deleteCategoryFailure(): CategoryActionsTypes {
  return {
    type: DELETE_CATEGORY_FAILURE,
  };
}

export function getOneCategoryRequest({
  id,
  toList,
}: {
  id: number;
  toList?: boolean;
}): CategoryActionsTypes {
  return {
    type: GET_ONE_CATEGORY_REQUEST,
    payload: {
      id,
      toList,
    },
  };
}

export function getOneCategorySuccess({
  data,
  toList,
}: {
  data: FullCategory;
  toList?: boolean;
}): CategoryActionsTypes {
  return {
    type: GET_ONE_CATEGORY_SUCCESS,
    payload: {
      data,
      toList,
    },
  };
}

export function getOneCategoryFailure(): CategoryActionsTypes {
  return {
    type: GET_ONE_CATEGORY_FAILURE,
  };
}

export function updateCategoryRequest(
  payload: Pick<FullCategory, 'id'> & Partial<FullCategory>,
): CategoryActionsTypes {
  return {
    type: UPDATE_CATEGORY_REQUEST,
    payload,
  };
}

export function updateCategorySuccess(
  payload: FullCategory,
): CategoryActionsTypes {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload,
  };
}

export function updateCategoryFailure(): CategoryActionsTypes {
  return {
    type: UPDATE_CATEGORY_FAILURE,
  };
}

export function clearCategory(): CategoryActionsTypes {
  return {
    type: CLEAR_CATEGORY,
  };
}

export function clearListCategory(): CategoryActionsTypes {
  return {
    type: CLEAR_LIST_CATEGORY,
  };
}

export function clearImageToCategory(): CategoryActionsTypes {
  return {
    type: CLEAR_IMAGE_TO_CATEGORY,
  };
}
