import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { PrivateRoute } from '@routes';
import { RootState } from '@store/index';
import {
  LINK_BRAND,
  LINK_CATEGORY,
  LINK_CHAT,
  LINK_CSV,
  LINK_EFFECT,
  LINK_LOGIN,
  LINK_ORDER,
  LINK_PAYMENT,
  LINK_PRODUCT,
  LINK_PROMO_CODE,
  LINK_REVIEW,
  LINK_SETTING,
  LINK_SUPPORT,
  LINK_USERS,
  Role,
} from '@utils';

import { ViewAuthLogin } from './Auth';
import ViewBrand from './Brand';
import ViewCategory from './Category';
import ViewChat from './Chat';
import ViewCsv from './Csv';
import ViewDashboard from './Dashboard';
import ViewEffect from './Effect';
import ViewOrder from './Order';
import ViewPayment from './Payment';
import ViewProduct from './Product';
import ViewPromoCode from './PromoCode';
import ViewReview from './Review';
import ViewSettings from './Settings';
import ViewSupport from './Support';
import ViewUsers from './Users';

const View = () => {
  // const role = CookieStorage.parseToken()?.role;
  const { profile } = useSelector((state: RootState) => state.profile);

  return (
    <Routes>
      <Route path={LINK_LOGIN} element={<ViewAuthLogin />} />
      <Route path={LINK_PAYMENT} element={<ViewPayment />} />
      {profile?.role === Role.ADMIN && (
        <>
          <Route
            path={`${LINK_PROMO_CODE}/*`}
            element={
              <PrivateRoute>
                <ViewPromoCode />
              </PrivateRoute>
            }
          />
          <Route
            path={`${LINK_BRAND}/*`}
            element={
              <PrivateRoute>
                <ViewBrand />
              </PrivateRoute>
            }
          />
          <Route
            path={`${LINK_EFFECT}/*`}
            element={
              <PrivateRoute>
                <ViewEffect />
              </PrivateRoute>
            }
          />
          <Route
            path={`${LINK_CATEGORY}/*`}
            element={
              <PrivateRoute>
                <ViewCategory />
              </PrivateRoute>
            }
          />
          {/* <Route
            path={`${LINK_SHOP}/*`}
            element={
              <PrivateRoute>
                <ViewShop />
              </PrivateRoute>
            }
          /> */}
          <Route
            path={`${LINK_REVIEW}/*`}
            element={
              <PrivateRoute>
                <ViewReview />
              </PrivateRoute>
            }
          />
          <Route
            path={`${LINK_CSV}/*`}
            element={
              <PrivateRoute>
                <ViewCsv />
              </PrivateRoute>
            }
          />
        </>
      )}
      <Route
        path={`${LINK_CHAT}/*`}
        element={
          <PrivateRoute>
            <ViewChat />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_SUPPORT}/*`}
        element={
          <PrivateRoute>
            <ViewSupport />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_USERS}/*`}
        element={
          <PrivateRoute>
            <ViewUsers />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_PRODUCT}/*`}
        element={
          <PrivateRoute>
            <ViewProduct />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_ORDER}/*`}
        element={
          <PrivateRoute>
            <ViewOrder />
          </PrivateRoute>
        }
      />
      <Route
        path={`${LINK_SETTING}/*`}
        element={
          <PrivateRoute>
            <ViewSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <ViewDashboard />
          </PrivateRoute>
        }
      />
      {/* <Route
        path={`${LINK_PAYOUT}/*`}
        element={
          <PrivateRoute>
            <ViewPayout />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        path="transactions/*"
        element={
          <PrivateRoute>
            <ViewTransaction />
          </PrivateRoute>
        }
      /> */}
    </Routes>
  );
};

export default View;
