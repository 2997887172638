import { useEffect } from 'react';

import { Button, Form, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '@store/index';
import { clearStore } from '@store/Media';
import { hideModal } from '@store/Modal';
import {
  clearReward,
  createRewardRequest,
  getOneRewardRequest,
  updateRewardRequest,
} from '@store/Setting';

import WidgetModalRewardForm from './Form';
import { FormReward } from './Form/index.type';
import { Props } from './index.type';

const WidgetModalReward = ({ rewardId, type }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormReward>();

  const { reward, loading } = useSelector((state: RootState) => state.setting);

  useEffect(() => {
    if (rewardId) {
      dispatch(getOneRewardRequest({ id: rewardId }));
    }
  }, [dispatch, rewardId]);

  useEffect(() => {
    return () => {
      dispatch(clearStore());
      dispatch(clearReward());
    };
  }, [dispatch]);

  const closeModal = () => {
    form.resetFields();
    dispatch(hideModal());
  };

  const saveProfile = () => {
    form.submit();
  };

  const onFormSubmitHandler = (values: FormReward) => {
    if (rewardId) {
      dispatch(updateRewardRequest({ ...values, id: rewardId }));
    } else {
      dispatch(createRewardRequest(values));
    }
  };

  return (
    <Modal
      visible
      title={`${type === 'create' ? 'Create' : 'Edit'} reward`}
      onOk={saveProfile}
      onCancel={closeModal}
      footer={[
        <Button
          key="save_reward"
          type="primary"
          onClick={saveProfile}
          size="large"
          className="edit-form__save-button"
        >
          {!rewardId ? 'Create' : 'Save changes'}
        </Button>,
      ]}
      className="edit-form"
    >
      <Spin spinning={loading}>
        <WidgetModalRewardForm
          reward={rewardId ? reward : null}
          form={form}
          isCreate={type === 'create'}
          onFormSubmit={onFormSubmitHandler}
        />
      </Spin>
    </Modal>
  );
};

export default WidgetModalReward;
