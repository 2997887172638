import { Route, Routes } from 'react-router-dom';

import { EffectDetail, EffectList } from '@features/Effect';

const ViewEffect = () => {
  return (
    <Routes>
      <Route path=":effectId" element={<EffectDetail />} />
      <Route path="/" element={<EffectList />} />
    </Routes>
  );
};

export default ViewEffect;
