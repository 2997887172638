import { Layout, Row } from 'antd';

import Payment from '@features/Payment';
import { WidgetHeader } from '@widgets';

import './styles.scss';

const ViewPayment = () => {
  return (
    <>
      <WidgetHeader />
      <Layout>
        <Layout.Content>
          <Row align="middle" justify="center" className="payment-layout">
            <Payment />
          </Row>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default ViewPayment;
