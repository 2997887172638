/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Form, InputNumber, Spin } from 'antd';
import { useSelector } from 'react-redux';

import { ProductDriverForm } from '@entities/User';
import { en } from '@i18n';
import { LocalStorage } from '@services/storage';
import { RootState } from '@store/index';
import { UIButton, UIModal, UISelectFormItem } from '@ui';
import { Button, OptionSelect } from '@utils';

import { Props } from './index.type';

const WidgetProductToDriver = (props: Props) => {
  const { update, get, clear, listActive } = props;

  const [form] = Form.useForm();
  const productListToDriver: ProductDriverForm[] | null = LocalStorage.get(
    'productListToDriver',
  );
  const [items, setItems] = useState<ProductDriverForm[] | null | undefined>(
    productListToDriver,
  );
  const [colDefault, setColDefault] = useState<{ [key: string]: number }>({});

  const {
    productList,
    loading: loadingProduct,
    meta: metaProduct,
  } = useSelector((state: RootState) => state.product);

  const setValueAll = (val: ProductDriverForm[]) => {
    LocalStorage.set('productListToDriver', val);
    setItems(val);
  };

  useEffect(() => {
    if (listActive) {
      setValueAll(
        listActive.map((item) => {
          if (item?.quantity) {
            setColDefault((prev) => ({
              ...prev,
              [`${item.productId}`]: item.quantity,
            }));
          }

          return {
            quantity: item.quantity,
            id: item?.productId,
            allQuantities: item?.product?.packsInStock || 1,
            name:
              item?.product?.name || `No name product, id - ${item?.productId}`,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, listActive]);

  const onFormSubmitHandler = () => {
    const newItems = [...(items || [])];

    listActive?.forEach((item) => {
      const asd = items?.find((item2) => item2.id === item?.productId);

      if (!asd) {
        newItems.push({
          quantity: 0,
          id: item?.productId,
          allQuantities: item?.product?.packsInStock || 1,
          name:
            item?.product?.name || `No name product, id - ${item?.productId}`,
        });
      }
    });

    if (newItems?.length) {
      update(
        newItems.map((item) => ({
          quantity: +item.quantity,
          productId: item.id,
        })),
      );
    }
  };

  const footer = [
    <UIButton
      key="Save changes"
      typeAntBtn="primary"
      handler={() => form.submit()}
      type={Button.ANT_BUTTON}
      disabled={
        !!items
          ?.map(
            (item) =>
              item?.quantity &&
              item?.quantity <= item.allQuantities + (colDefault[item.id] || 0),
          )
          ?.includes(false)
      }
      size="large"
      className="edit-form__save-button"
    >
      Save
    </UIButton>,
  ];

  return (
    <UIModal
      className="edit-form"
      title={'Add driver'}
      footer={footer}
      visible
      children={
        <Spin spinning={false}>
          <Form
            form={form}
            requiredMark={false}
            size="large"
            onFinish={onFormSubmitHandler}
            autoComplete="off"
            validateMessages={en.validation}
          >
            <UISelectFormItem
              label="Products"
              value={items?.map((i) => ({
                label: i.name,
                value: i.id,
                allQuantities: i.allQuantities,
              }))}
              loading={loadingProduct}
              mode="multiple"
              onChange={(ids, values) => {
                const newValue = (ids as number[])?.map((id) =>
                  [
                    ...(values as OptionSelect[]),
                    ...((items || []).map((i) => ({
                      label: i.name,
                      value: i.id,
                    })) || []),
                  ].find((e) => +e.value === +id),
                );

                const data = (newValue as OptionSelect[]).map((e) => {
                  const itemOld = items?.find((item) => e.value === item.id);

                  if (itemOld) {
                    return itemOld;
                  }

                  const newAllQuantities = productList?.find(
                    (item) => e.value === item.id,
                  )?.packsInStock;

                  return {
                    quantity: 1,
                    id: e.value,
                    name: e.label,
                    allQuantities: newAllQuantities,
                  } as ProductDriverForm;
                });

                setValueAll(data);
              }}
              options={(productList || [])?.map((item) => ({
                id: item.id,
                name: item?.name || `No name product, id - ${item.id}`,
              }))}
              getOptions={get}
              clearOptions={clear}
              meta={metaProduct}
            />
            {items?.map((item) => (
              <Form.Item
                className="long_titles"
                label={`${
                  item?.name || `No name product, id - ${item.id}`
                } (Units in stock - ${
                  item.allQuantities -
                  (item.quantity - (colDefault[item.id] || 0))
                }) - quantity`}
              >
                <InputNumber
                  value={+item.quantity}
                  min={1}
                  max={item.allQuantities + (colDefault[item.id] || 0)}
                  type="number"
                  placeholder="Quantity"
                  onChange={(e) => {
                    const data = [
                      ...items.map((item2) => {
                        if (item.id === item2.id) {
                          return {
                            ...item2,
                            quantity: e || 0,
                          };
                        }

                        return item2;
                      }),
                    ];

                    setValueAll(data);
                  }}
                />
              </Form.Item>
            ))}
          </Form>
        </Spin>
      }
    />
  );
};

export default WidgetProductToDriver;
