import { ColumnsType } from 'antd/lib/table';

import { FullCategory } from '@entities/Category';
import { FilterOption } from '@entities/Filter';
import { SortOption } from '@entities/Sort';
import type { AppDispatch } from '@store/index';
import { UICellFullName, UICellLink, UICellStatus, UICellTooltip } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_LONG_TEXT,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_TAG,
  DateHelper,
  FORMAT_DATE,
} from '@utils';

import { CategoryUIActionList } from '../ui';

export const columns = ({
  dispatch,
  path,
}: {
  dispatch: AppDispatch;
  path: string;
}): ColumnsType<FullCategory> => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    render: (value, record) =>
      UICellLink({ value, link: `${path}/${record.id}` }),
    width: COLUMN_WIDTH_ID,
  },
  {
    title: 'Category name',
    dataIndex: 'name',
    key: 'name',
    width: COLUMN_WIDTH_NAME,
    render: (value, { name, id, image }) =>
      UICellFullName({
        firstName: name,
        src: image?.path,
        link: `${path}/${id}`,
      }),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: {
      showTitle: false,
    },
    width: COLUMN_WIDTH_LONG_TEXT,
    render: (text) => UICellTooltip({ text }),
  },
  {
    title: 'Visible',
    dataIndex: 'isActive',
    key: 'isActive',
    width: COLUMN_WIDTH_TAG,
    render: (value) =>
      UICellStatus({
        value,
        text: value ? 'Yes' : 'No',
      }),
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    key: 'id',
    width: COLUMN_WIDTH_ACTIONS,
    render: (id) => CategoryUIActionList({ id, dispatch }),
    align: 'right',
  },
];

export const orderOption: SortOption[] = [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  {
    label: 'Name (A-Z)',
    name: 'name',
    direction: 'asc',
  },
  {
    label: 'Name (Z-A)',
    name: 'name',
    direction: 'desc',
  },
  {
    label: 'Create date (oldest first)',
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: 'Create date (newest first)',
    name: 'created_at',
    direction: 'desc',
  },
];

const today = new Date();

export const filterOptions: FilterOption[] = [
  {
    name: 'created_at',
    label: 'Create date',
    type: 'radio-range',
    options: [
      {
        label: 'Today',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 7 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -7,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
      {
        label: 'Last 30 days',
        value: {
          min: DateHelper.getStartDayFormatString({
            date: today,
            format: FORMAT_DATE,
            amountDays: -30,
          }),
          max: DateHelper.getEndDayFormatString({
            date: today,
            format: FORMAT_DATE,
          }),
        },
      },
    ],
  },
  {
    name: 'isActive',
    label: 'Visible',
    type: 'radio',
    options: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
  },
];
