import { Tabs } from 'antd';

import { LocalStorage } from '@services/storage';

import { Props } from './index.type';

const UITabContent = (props: Props) => {
  const { tabs, defaultTitle, onTabClick, buttons, activeTab } = props;

  const renderTabsHTML = () =>
    tabs.map(({ title, key, content }) => (
      <Tabs.TabPane tab={title} key={`tabs_${key || title}`}>
        {content}
      </Tabs.TabPane>
    ));

  const onChangeTabHandler = (
    activeKey?: string,
    e?: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>,
  ) => {
    if (activeKey) {
      const keyEl = activeKey.split('_');

      if (keyEl[0] === 'tabs') {
        LocalStorage.set('tabs', keyEl[1]);
      }
    }
    if (onTabClick) {
      onTabClick(activeKey, e);
    }
  };

  return (
    <Tabs
      onTabClick={onChangeTabHandler}
      tabBarExtraContent={buttons}
      defaultActiveKey={defaultTitle}
      activeKey={activeTab}
    >
      {renderTabsHTML()}
    </Tabs>
  );
};

export default UITabContent;
