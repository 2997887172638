import { Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { UIImage } from '@ui';

import { Props } from './index.type';

import './style.scss';

const CategoryDetailDiscription = ({ loading, category }: Props) => {
  return (
    <Spin size="large" spinning={loading}>
      <div className="card-categories-info-category">
        <div className="card-categories-info-category-header">
          <div>
            <Title level={5}>Image</Title>
            <UIImage path={category?.image?.path || 'error'} />
          </div>
          {/* <div>
            <Link onClick={onEditClickHandler}>Edit</Link>
          </div> */}
        </div>
        <div className="card-categories-info-category-line"></div>
        <div className="card-categories-info-category-content">
          <Title level={5}>Name of the categories</Title>
          <Text>{category?.name}</Text>
        </div>
        <div className="card-categories-info-category-line"></div>
        <div className="card-categories-info-category-content">
          <Title level={5}>Description</Title>
          <Text>{category?.description}</Text>
        </div>
      </div>
    </Spin>
  );
};

export default CategoryDetailDiscription;
