import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CLEAR_CHAT,
  CLEAR_MESSAGE,
  GET_LIST_CHAT_FAILURE,
  GET_LIST_CHAT_REQUEST,
  GET_LIST_CHAT_SUCCESS,
  GET_LIST_MESSAGE_FAILURE,
  GET_LIST_MESSAGE_REQUEST,
  GET_LIST_MESSAGE_SUCCESS,
  GET_ONE_CHAT_FAILURE,
  GET_ONE_CHAT_REQUEST,
  GET_ONE_CHAT_SUCCESS,
  SET_DIALOG_ID,
} from './Chat.constant';
import { ChatActionsTypes, IChatState } from './Chat.type';

const initialState: IChatState = {
  status: ActionStatus.NONE,
  chatList: null,
  messageList: null,
  chat: null,
  meta: META_DEFAULT,
  loaded: false,
  loading: false,
  dialogId: null,
  dialog: null,
};

export default function chatReducer(
  state = initialState,
  action: ChatActionsTypes,
): IChatState {
  switch (action.type) {
    case SET_DIALOG_ID: {
      return {
        ...state,
        dialogId: action.payload.id,
        dialog: null,
      };
    }
    case GET_LIST_CHAT_REQUEST:
    case GET_LIST_MESSAGE_REQUEST:
    case GET_ONE_CHAT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_MESSAGE_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.LOADED,
        messageList: [...(state.messageList || []), ...action.payload.data],
        meta: action.payload.meta,
        loaded: true,
        loading: false,
      };
    }
    case GET_LIST_CHAT_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.LOADED,
        chatList: action.payload.data,
        meta: action.payload.meta,
        loaded: true,
        loading: false,
      };
    }
    case GET_ONE_CHAT_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.LOADED,
        // messageList: action.payload,
        chat: action.payload,
        loading: false,
      };
    }
    case GET_ONE_CHAT_FAILURE:
    case GET_LIST_CHAT_FAILURE:
    case GET_LIST_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        messageList: null,
      };
    case CLEAR_CHAT:
      return initialState;
    default:
      return state;
  }
}
