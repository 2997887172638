import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullEffect } from '@entities/Effect';
import { EffectHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';

import {
  createEffectFailure,
  createEffectSuccess,
  deleteEffectFailure,
  deleteEffectSuccess,
  getListEffectFailure,
  getListEffectSuccess,
  getOneEffectFailure,
  getOneEffectSuccess,
  updateEffectFailure,
  updateEffectSuccess,
} from './Effect.action';
import {
  CREATE_EFFECT_REQUEST,
  DELETE_EFFECT_REQUEST,
  GET_LIST_EFFECT_REQUEST,
  GET_ONE_EFFECT_REQUEST,
  UPDATE_EFFECT_REQUEST,
} from './Effect.constant';
import {
  ICreateEffectRequest,
  IDeleteEffectRequest,
  IGetListEffectRequest,
  IGetOneEffectRequest,
  IUpdateEffectRequest,
} from './Effect.type';

function* workerGetListEffect(action: IGetListEffectRequest) {
  try {
    const { query } = action.payload;
    const data: DataResponse<FullEffect[]> = yield call(
      EffectHttp.getList,
      query,
    );

    yield put(getListEffectSuccess(data));
  } catch (error) {
    yield put(getListEffectFailure());
    yield put(alertError(error));
  }
}

function* workerCreateEffect(action: ICreateEffectRequest) {
  try {
    const data: FullEffect = yield call(EffectHttp.create, action.payload);

    yield put(createEffectSuccess(data));
    yield put(alertSuccess('Effect added successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(createEffectFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteEffect(action: IDeleteEffectRequest) {
  try {
    const { id } = action.payload;

    yield call(EffectHttp.remove, id);

    yield put(deleteEffectSuccess(id));
    yield put(alertSuccess('Effect successfully deleted!'));
  } catch (error) {
    yield put(deleteEffectFailure());
    yield put(alertError(error));
  }
}

function* workerGetOneEffect(action: IGetOneEffectRequest) {
  try {
    const { id } = action.payload;
    const payload: FullEffect = yield call(EffectHttp.getOne, id);

    yield put(getOneEffectSuccess(payload));
  } catch (error) {
    yield put(getOneEffectFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateEffect(action: IUpdateEffectRequest) {
  try {
    const { id, ...body } = action.payload;

    const data: FullEffect = yield call(EffectHttp.update, id, body);

    yield put(updateEffectSuccess(data));
    yield put(alertSuccess('Effect successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateEffectFailure());
    yield put(alertError(error));
  }
}

function* watchUpdateEffect() {
  yield takeLatest(UPDATE_EFFECT_REQUEST, workerUpdateEffect);
}

function* watchGetOneEffect() {
  yield takeLatest(GET_ONE_EFFECT_REQUEST, workerGetOneEffect);
}

function* watchDeleteEffect() {
  yield takeLatest(DELETE_EFFECT_REQUEST, workerDeleteEffect);
}

function* watchCreateEffect() {
  yield takeLatest(CREATE_EFFECT_REQUEST, workerCreateEffect);
}

function* watchGetListEffect() {
  yield takeLatest(GET_LIST_EFFECT_REQUEST, workerGetListEffect);
}

export const effectWatchers: ForkEffect[] = [
  fork(watchGetListEffect),
  fork(watchCreateEffect),
  fork(watchDeleteEffect),
  fork(watchGetOneEffect),
  fork(watchUpdateEffect),
];
