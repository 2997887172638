import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullPromoCode } from '@entities/PromoCode';
import { PromoCodeHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';

import {
  createPromoCodeFailure,
  createPromoCodeSuccess,
  deletePromoCodeFailure,
  deletePromoCodeSuccess,
  getListPromoCodeFailure,
  getListPromoCodeSuccess,
  getOnePromoCodeFailure,
  getOnePromoCodeSuccess,
  updatePromoCodeFailure,
  updatePromoCodeSuccess,
} from './PromoCode.action';
import {
  CREATE_PROMO_CODE_REQUEST,
  DELETE_PROMO_CODE_REQUEST,
  GET_LIST_PROMO_CODE_REQUEST,
  GET_ONE_PROMO_CODE_REQUEST,
  UPDATE_PROMO_CODE_REQUEST,
} from './PromoCode.constant';
import {
  ICreatePromoCodeRequest,
  IDeletePromoCodeRequest,
  IGetListPromoCodeRequest,
  IGetOnePromoCodeRequest,
  IUpdatePromoCodeRequest,
} from './PromoCode.type';

function* workerGetListPromoCode(action: IGetListPromoCodeRequest) {
  try {
    const { query } = action.payload;
    const data: DataResponse<FullPromoCode[]> = yield call(
      PromoCodeHttp.getList,
      query,
    );

    yield put(getListPromoCodeSuccess(data));
  } catch (error) {
    yield put(getListPromoCodeFailure());
    yield put(alertError(error));
  }
}

function* workerCreatePromoCode(action: ICreatePromoCodeRequest) {
  try {
    const data: FullPromoCode = yield call(
      PromoCodeHttp.create,
      action.payload,
    );

    yield put(createPromoCodeSuccess(data));
    yield put(alertSuccess('PromoCode added successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(createPromoCodeFailure());
    yield put(alertError(error));
  }
}

function* workerDeletePromoCode(action: IDeletePromoCodeRequest) {
  try {
    const { id } = action.payload;

    yield call(PromoCodeHttp.remove, id);

    yield put(deletePromoCodeSuccess(id));
    yield put(alertSuccess('PromoCode successfully deleted!'));
  } catch (error) {
    yield put(deletePromoCodeFailure());
    yield put(alertError(error));
  }
}

function* workerGetOnePromoCode(action: IGetOnePromoCodeRequest) {
  try {
    const { id, toList } = action.payload;
    const payload: FullPromoCode = yield call(PromoCodeHttp.getOne, id);

    yield put(getOnePromoCodeSuccess({ data: payload, toList }));
  } catch (error) {
    yield put(getOnePromoCodeFailure());
    yield put(alertError(error));
  }
}

function* workerUpdatePromoCode(action: IUpdatePromoCodeRequest) {
  try {
    const { id, ...body } = action.payload;

    const data: FullPromoCode = yield call(PromoCodeHttp.update, id, body);

    yield put(updatePromoCodeSuccess(data));
    yield put(alertSuccess('PromoCode successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updatePromoCodeFailure());
    yield put(alertError(error));
  }
}

function* watchUpdatePromoCode() {
  yield takeLatest(UPDATE_PROMO_CODE_REQUEST, workerUpdatePromoCode);
}

function* watchGetOnePromoCode() {
  yield takeLatest(GET_ONE_PROMO_CODE_REQUEST, workerGetOnePromoCode);
}

function* watchDeletePromoCode() {
  yield takeLatest(DELETE_PROMO_CODE_REQUEST, workerDeletePromoCode);
}

function* watchCreatePromoCode() {
  yield takeLatest(CREATE_PROMO_CODE_REQUEST, workerCreatePromoCode);
}

function* watchGetListPromoCode() {
  yield takeLatest(GET_LIST_PROMO_CODE_REQUEST, workerGetListPromoCode);
}

export const promoCodeWatchers: ForkEffect[] = [
  fork(watchGetListPromoCode),
  fork(watchCreatePromoCode),
  fork(watchDeletePromoCode),
  fork(watchGetOnePromoCode),
  fork(watchUpdatePromoCode),
];
