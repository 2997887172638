export const CLEAR_LIST_REVIEW = '@review/CLEAR_LIST_REVIEW';
export const CLEAR_LIST_USER = '@review/CLEAR_LIST_USER';

export const GET_LIST_USER_REQUEST = '@review/GET_LIST_USER_REQUEST';
export const GET_LIST_USER_SUCCESS = '@review/GET_LIST_USER_SUCCESS';
export const GET_LIST_USER_FAILURE = '@review/GET_LIST_USER_FAILURE';

export const GET_LIST_REVIEW_BRAND_REQUEST =
  '@review/GET_LIST_REVIEW_BRAND_REQUEST';
export const GET_LIST_REVIEW_BRAND_SUCCESS =
  '@review/GET_LIST_REVIEW_BRAND_SUCCESS';
export const GET_LIST_REVIEW_BRAND_FAILURE =
  '@review/GET_LIST_REVIEW_BRAND_FAILURE';

export const CREATE_REVIEW_BRAND_REQUEST =
  '@review/CREATE_REVIEW_BRAND_REQUEST';
export const CREATE_REVIEW_BRAND_SUCCESS =
  '@review/CREATE_REVIEW_BRAND_SUCCESS';
export const CREATE_REVIEW_BRAND_FAILURE =
  '@review/CREATE_REVIEW_BRAND_FAILURE';

export const DELETE_REVIEW_BRAND_REQUEST =
  '@review/DELETE_REVIEW_BRAND_REQUEST';
export const DELETE_REVIEW_BRAND_SUCCESS =
  '@review/DELETE_REVIEW_BRAND_SUCCESS';
export const DELETE_REVIEW_BRAND_FAILURE =
  '@review/DELETE_REVIEW_BRAND_FAILURE';

export const UPDATE_REVIEW_BRAND_REQUEST =
  '@review/UPDATE_REVIEW_BRAND_REQUEST';
export const UPDATE_REVIEW_BRAND_SUCCESS =
  '@review/UPDATE_REVIEW_BRAND_SUCCESS';
export const UPDATE_REVIEW_BRAND_FAILURE =
  '@review/UPDATE_REVIEW_BRAND_FAILURE';

export const GET_LIST_REVIEW_USER_REQUEST =
  '@review/GET_LIST_REVIEW_USER_REQUEST';
export const GET_LIST_REVIEW_USER_SUCCESS =
  '@review/GET_LIST_REVIEW_USER_SUCCESS';
export const GET_LIST_REVIEW_USER_FAILURE =
  '@review/GET_LIST_REVIEW_USER_FAILURE';

export const CREATE_REVIEW_USER_REQUEST = '@review/CREATE_REVIEW_USER_REQUEST';
export const CREATE_REVIEW_USER_SUCCESS = '@review/CREATE_REVIEW_USER_SUCCESS';
export const CREATE_REVIEW_USER_FAILURE = '@review/CREATE_REVIEW_USER_FAILURE';

export const DELETE_REVIEW_USER_REQUEST = '@review/DELETE_REVIEW_USER_REQUEST';
export const DELETE_REVIEW_USER_SUCCESS = '@review/DELETE_REVIEW_USER_SUCCESS';
export const DELETE_REVIEW_USER_FAILURE = '@review/DELETE_REVIEW_USER_FAILURE';

export const UPDATE_REVIEW_USER_REQUEST = '@review/UPDATE_REVIEW_USER_REQUEST';
export const UPDATE_REVIEW_USER_SUCCESS = '@review/UPDATE_REVIEW_USER_SUCCESS';
export const UPDATE_REVIEW_USER_FAILURE = '@review/UPDATE_REVIEW_USER_FAILURE';
