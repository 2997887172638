import { Statistic } from '@entities/Statistic';

import {
  CLEAR_LIST_STATISTICS,
  GET_LIST_STATISTIC_FAILURE,
  GET_LIST_STATISTIC_REQUEST,
  GET_LIST_STATISTIC_SUCCESS,
} from './Statistic.constant';
import { StatisticActionsTypes } from './Statistic.type';

export function getListStatisticRequest(
  query?: Query['query'],
): StatisticActionsTypes {
  return {
    type: GET_LIST_STATISTIC_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListStatisticSuccess(
  payload: DataResponse<Statistic>,
): StatisticActionsTypes {
  return {
    type: GET_LIST_STATISTIC_SUCCESS,
    payload,
  };
}

export function getListStatisticFailure(): StatisticActionsTypes {
  return {
    type: GET_LIST_STATISTIC_FAILURE,
  };
}

export function clearListStatistics(): StatisticActionsTypes {
  return {
    type: CLEAR_LIST_STATISTICS,
  };
}
