/* eslint-disable indent */
import React, { useEffect } from 'react';

import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LayoutContent } from '@layouts';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import {
  clearReward,
  deleteRewardRequest,
  getOneRewardRequest,
} from '@store/Setting';
import { UIButton, UIHeader } from '@ui';
import { Button, LINK_SETTING } from '@utils';

import RewardDetailDescription from './Description';
import RewardEdit from './Edit';
import './style.scss';

const SettingsRewardDetail = () => {
  const dispatch = useAppDispatch();
  const { settingsId } = useParams<'settingsId'>();
  const { reward, loading } = useSelector((state: RootState) => state.setting);

  useEffect(() => {
    if (settingsId) {
      dispatch(getOneRewardRequest({ id: +settingsId }));
    }
  }, [dispatch, settingsId]);

  useEffect(() => {
    return () => {
      LocalStorage.clear('isBack');
      dispatch(clearReward());
    };
  }, [dispatch]);

  // const onChangeStatus = () => {
  //   if (reward) {
  //     dispatch(
  //       showModal({
  //         modalType: ModalType.CONFIRM,
  //         modalProps: {
  //           content: (
  //             <p>
  //               Are you sure you want to{' '}
  //               {!reward.isActive ? 'verify' : 'unverify'} the reward
  //               <b> {reward?.name || ''}</b>?
  //             </p>
  //           ),
  //           action: () =>
  //             dispatch(
  //               updateRewardRequest({
  //                 id: reward.id,
  //                 isActive: !reward.isActive,
  //               }),
  //             ),
  //         },
  //       }),
  //     );
  //   }
  // };

  if (!settingsId) {
    return null;
  }

  const onDeleteRewardHandler = () => {
    if (reward) {
      dispatch(
        showModal({
          modalType: ModalType.CONFIRM,
          modalProps: {
            content: (
              <p>
                Are you sure you want to delete the Reward
                <b> {reward?.name || ''}</b>?
              </p>
            ),
            action: () => dispatch(deleteRewardRequest(reward.id)),
          },
        }),
      );
    }
  };

  const aside = [
    <RewardDetailDescription reward={reward} loading={loading} />,
    // reward && (
    //   <UIButton
    //     className="delete-brand-button"
    //     children={`${!reward?.isActive ? 'Verify' : 'Unverify'} the reward`}
    //     size="middle"
    //     type={Button.ANT_BUTTON}
    //     prop={{ danger: reward?.isActive }}
    //     handler={onChangeStatus}
    //   />
    // ),
    <UIButton
      className="delete-brand-button"
      children="Delete the reward"
      size="middle"
      type={Button.ANT_BUTTON}
      prop={{ danger: true }}
      handler={onDeleteRewardHandler}
    />,
  ];

  const content = [reward && <RewardEdit reward={reward} loading={loading} />];

  return (
    <Space direction="vertical">
      <UIHeader
        isBack
        backLink={LocalStorage.get('isBack') || `/${reward && LINK_SETTING}`}
        title={reward?.name || ''}
      />
      <LayoutContent
        loadingContent={loading}
        content={content}
        loadingAside={loading}
        aside={aside}
      />
    </Space>
  );
};

export default React.memo(SettingsRewardDetail);
