export const GET_LIST_PROMO_CODE_REQUEST =
  '@promoCode/GET_LIST_PROMO_CODE_REQUEST';
export const GET_LIST_PROMO_CODE_SUCCESS =
  '@promoCode/GET_LIST_PROMO_CODE_SUCCESS';
export const GET_LIST_PROMO_CODE_FAILURE =
  '@promoCode/GET_LIST_PROMO_CODE_FAILURE';

export const CREATE_PROMO_CODE_REQUEST = '@promoCode/CREATE_PROMO_CODE_REQUEST';
export const CREATE_PROMO_CODE_SUCCESS = '@promoCode/CREATE_PROMO_CODE_SUCCESS';
export const CREATE_PROMO_CODE_FAILURE = '@promoCode/CREATE_PROMO_CODE_FAILURE';

export const GET_ONE_PROMO_CODE_REQUEST =
  '@promoCode/GET_ONE_PROMO_CODE_REQUEST';
export const GET_ONE_PROMO_CODE_SUCCESS =
  '@promoCode/GET_ONE_PROMO_CODE_SUCCESS';
export const GET_ONE_PROMO_CODE_FAILURE =
  '@promoCode/GET_ONE_PROMO_CODE_FAILURE';

export const DELETE_PROMO_CODE_REQUEST = '@promoCode/DELETE_PROMO_CODE_REQUEST';
export const DELETE_PROMO_CODE_SUCCESS = '@promoCode/DELETE_PROMO_CODE_SUCCESS';
export const DELETE_PROMO_CODE_FAILURE = '@promoCode/DELETE_PROMO_CODE_FAILURE';

export const UPDATE_PROMO_CODE_REQUEST = '@promoCode/UPDATE_PROMO_CODE_REQUEST';
export const UPDATE_PROMO_CODE_SUCCESS = '@promoCode/UPDATE_PROMO_CODE_SUCCESS';
export const UPDATE_PROMO_CODE_FAILURE = '@promoCode/UPDATE_PROMO_CODE_FAILURE';

export const CLEAR_PROMO_CODE = '@promoCode/CLEAR_PROMO_CODE';
export const CLEAR_LIST_PROMO_CODE = '@promoCode/CLEAR_LIST_PROMO_CODE';
