import { LayoutMenu } from '@layouts';
import { ModalType, showModal } from '@store/Modal';
import { deleteTaxesRequest } from '@store/Setting';
import { UICellAction } from '@ui';

import { Props } from './index.type';

const TaxesUIActions = ({ id, taxes, query, dispatch }: Props) => {
  const onEditClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.TAXES,
        modalProps: { taxesId: id, type: 'edit', query },
      }),
    );
  };

  const onDeleteTaxesHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(
      showModal({
        modalType: ModalType.CONFIRM,
        modalProps: {
          content: (
            <p>
              Are you sure you want to delete the taxes
              <b> {taxes?.city || ''}</b>?
            </p>
          ),
          action: () => dispatch(deleteTaxesRequest(id)),
        },
      }),
    );
  };

  // const onDeactivateTaxesHandler = (e: React.MouseEvent<HTMLElement>) => {
  //   e.stopPropagation();

  //   dispatch(
  //     updateTaxesRequest({
  //       id,
  //       body: { isActive: !taxes.isActive },
  //     }),
  //   );
  // };

  return UICellAction({
    dropdownMenu: LayoutMenu({
      buttons: [
        {
          title: 'Delete the taxes',
          onClick: (e) => onDeleteTaxesHandler(e),
        },
        // {
        //   title: `${taxes?.isActive ? 'Deactivate' : 'Activate'} the taxes`,
        //   onClick: (e) => onDeactivateTaxesHandler(e),
        // },
      ],
    }),
    buttons: [
      {
        title: 'Edit',
        onClick: (e: React.MouseEvent<HTMLElement>) => onEditClickHandler(e),
      },
    ],
  });
};

export default TaxesUIActions;
