import { useEffect } from 'react';

import { Button, Form, Spin } from 'antd';

import { Referral } from '@entities/Referral';
import { useAppDispatch } from '@store/index';
import { updateReferralRequest } from '@store/Setting';

import ReferralEditForm from './Form';
import { Props } from './index.type';

const ReferralEdit = ({ referral, loading }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<Referral>();

  useEffect(() => {
    if (referral) {
      form.setFieldsValue(referral);
    }
  }, [form, referral]);

  const onFormSubmitHandler = (values: Partial<Referral>) => {
    dispatch(updateReferralRequest(values));
  };

  return (
    <>
      <div className="referral-profile referral-edit">
        <Spin spinning={loading}>
          <ReferralEditForm
            title="For invitee"
            form={form}
            nameKey="ForInvited"
            onFormSubmit={onFormSubmitHandler}
          />
        </Spin>
      </div>
      <div className="referral-profile referral-edit">
        <Spin spinning={loading}>
          <ReferralEditForm
            title="For who invites"
            nameKey="ForInviter"
            form={form}
            onFormSubmit={onFormSubmitHandler}
          />
        </Spin>
      </div>

      <div className="promoCode-edit__save-button">
        <Button
          key="Save changes"
          type="primary"
          onClick={() => form.submit()}
          size="large"
          className="edit-form__save-button"
        >
          Save changes
        </Button>
      </div>
    </>
  );
};

export default ReferralEdit;
