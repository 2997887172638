import { useEffect } from 'react';

import { Button, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './style.scss';

import { DriverProduct } from '@entities/Shop';
import { useQuery } from '@hooks';
import { LayoutContent } from '@layouts';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import {
  changeProductDriversRequest,
  clearProduct,
  clearProductDrivers,
  deleteProductRequest,
  getDriversProductRequest,
  getOneProductRequest,
} from '@store/Product';
import { clearUserListStore, getListUserRequest } from '@store/User';
import { UIButton, UIHeader } from '@ui';
import { ActionStatus, Button as ButtonType, LINK_PRODUCT } from '@utils';

import ProductDetailDiscription from './Discription';
import DriversTablet from './DriversTablet';
import ProductEdit from './Edit';

const ProductDetail = () => {
  const dispatch = useAppDispatch();
  const {
    // storesList,
    driversList,
    status,
    product,
    loading,
  } = useSelector((state: RootState) => state.product);
  const { productId } = useParams<'productId'>();
  const { updateQuery } = useQuery();
  const tabsActive = LocalStorage.get('tabs');

  useEffect(() => {
    if (productId) {
      dispatch(getOneProductRequest(+productId));
    }
  }, [productId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearProduct());
      LocalStorage.clear('isBack');
      LocalStorage.clear('tabs');
    };
  }, [dispatch]);

  useEffect(() => {
    if (status === ActionStatus.DELETED) {
      window.history.back();
    }
  }, [status]);

  // const onChangeVisible = () => {
  //   if (productId) {
  //     dispatch(
  //       updateProductRequest({
  //         id: +productId,
  //         isActive: !product?.isActive,
  //       }),
  //     );
  //   }
  // };

  const tabs = [
    {
      title: 'Edit',
      content: product && (
        <ProductEdit product={product} loadingProduct={loading} />
      ),
    },
    // {
    //   title: 'Stores',
    //   content: product && (
    //     <ShopsTablet tabsActive={tabsActive === 'Stores'} id={+product.id} />
    //   ),
    // },
    {
      title: 'Drivers',
      content: product && (
        <DriversTablet
          tabsActive={tabsActive === 'Drivers'}
          id={+product.id}
          packsInStock={+product.packsInStock}
        />
      ),
    },
  ];

  const addStore = (
    <Button
      size="large"
      type="primary"
      onClick={() => {
        // if (product) {
        //   dispatch(
        //     showModal({
        //       modalType: ModalType.EDIT_QUANTITIES,
        //       modalProps: {
        //         clear: () => dispatch(clearListShop()),
        //         get: (query: string) =>
        //           dispatch(
        //             getListShopRequest(`${query}&filter[only_driver]=true`),
        //           ),
        //         listActive: storesList,
        //         type: 'store',
        //         allQuantities: product.packsInStock,
        //         update: (payload: ShopProduct[]) => {
        //           dispatch(
        //             changeProductStoresRequest({
        //               body: payload,
        //               id: product.id,
        //             }),
        //           );
        //           dispatch(clearProductStores());
        //           dispatch(getStoresProductRequest(product.id, '', false));
        //         },
        //       },
        //     }),
        //   );
        // }
      }}
    >
      Add a store for this product
    </Button>
  );
  const addDrivers = (
    <Button
      size="large"
      type="primary"
      onClick={() => {
        if (product) {
          dispatch(
            showModal({
              modalType: ModalType.EDIT_QUANTITIES,
              modalProps: {
                clear: () => dispatch(clearUserListStore()),
                get: (query: string) =>
                  dispatch(
                    getListUserRequest(`${query}&filter[only_driver]=true`),
                  ),
                listActive: driversList,
                type: 'driver',
                allQuantities: product.packsInStock,
                update: (payload: DriverProduct[]) => {
                  dispatch(
                    changeProductDriversRequest({
                      body: payload,
                      id: product.id,
                    }),
                  );
                  dispatch(clearProductDrivers());
                  dispatch(getDriversProductRequest(product.id, '', false));
                },
              },
            }),
          );
        }
      }}
    >
      Add this product to drivers
    </Button>
  );

  const buttonAdd =
    tabsActive === 'Stores' ? addStore : tabsActive === 'Drivers' && addDrivers;

  const aside = [
    // <Row justify="space-between" align="middle" className="visible-wrapper">
    //   <Text strong>Visible</Text>
    //   <Switch checked={product?.isActive} onChange={onChangeVisible} />
    // </Row>,
    <ProductDetailDiscription product={product} loading={loading} />,
    <UIButton
      className="delete-product-button"
      children="Delete Product"
      size="middle"
      type={ButtonType.ANT_BUTTON}
      prop={{ danger: true }}
      handler={() => {
        if (productId) {
          dispatch(
            showModal({
              modalType: ModalType.CONFIRM,
              modalProps: {
                content: <p>Are you sure you want to delete the Product</p>,
                action: () => dispatch(deleteProductRequest(+productId)),
              },
            }),
          );
        }
      }}
    />,
  ];

  const content = [
    <UIHeader
      onClickTabs={() => updateQuery({ page: 1, limit: 100 })}
      tabs={tabs}
      buttons={buttonAdd}
      defaultTitle="Edit"
    />,
  ];

  return (
    <Space direction="vertical">
      <UIHeader
        isBack
        backLink={LocalStorage.get('isBack') || `/${product && LINK_PRODUCT}`}
        title={product?.name}
      />
      <LayoutContent content={content} loadingAside={loading} aside={aside} />
    </Space>
  );
};

export default ProductDetail;
