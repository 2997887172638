import { Dispatch } from 'redux';
import { Socket } from 'socket.io-client';

import { FullOrder, OrderEvent } from '@entities/Order';
import {
  clearListOrder,
  getListOrderRequest,
  getOneOrderProductRequest,
  getOneOrderRequest,
  socketOrder,
} from '@store/Order';
import { addNotification } from '@store/Profile';
import { LINK_ORDER, NotificationType } from '@utils';

export const initSubscribe = (params: {
  dispatch: Dispatch;
  pathname: string;
  query: string;
  socket: Socket;
}) => {
  const { socket, dispatch, pathname, query } = params;

  socket.on(
    OrderEvent.ORDER_SUBSCRIBE,
    ({ data }: { data: DataResponse<FullOrder> }) => {
      if (pathname === `/${LINK_ORDER}/${data.order?.id}` && data.order) {
        dispatch(socketOrder(data));
        dispatch(getOneOrderRequest(data.order.id));
        dispatch(getOneOrderProductRequest(+data.order.id));
      } else if (pathname === `/${LINK_ORDER}`) {
        dispatch(clearListOrder());
        dispatch(getListOrderRequest(query));
      } else {
        dispatch(socketOrder(data));
      }

      console.log(data);

      dispatch(
        addNotification({
          createdAt: data?.order?.createdAt || '',
          id: data?.order?.id,
          text: `New order(${data?.order?.id}) user - ${data?.order?.user?.profile?.fullName}`,
          type: NotificationType.ORDER,
        }),
      );
    },
  );
};
