import { useCallback, useEffect } from 'react';

import { Alert } from 'antd';
import { useSelector } from 'react-redux';

import { alertClear } from '@store/Alert';
import { RootState, useAppDispatch } from '@store/index';

import style from './style.module.scss';

const WidgetAlert = () => {
  const dispatch = useAppDispatch();
  const {
    message,
    type,
    description,
    style: styleProps,
  } = useSelector((state: RootState) => state.alert);

  const handlerClose = useCallback(() => {
    dispatch(alertClear());
  }, [dispatch]);

  useEffect(() => {
    if (type && message) {
      setTimeout(() => handlerClose(), 6000);
    }
  }, [type, message, handlerClose]);

  return type && message ? (
    <div className={style.container}>
      <Alert
        message={message}
        description={description}
        type={type}
        {...(styleProps && { style: styleProps })}
        showIcon
      />
    </div>
  ) : null;
};

export default WidgetAlert;
