import {
  CreateReviewBrandRequest,
  CreateReviewUserRequest,
  FullReviewBrand,
  FullReviewUser,
} from '@entities/Review';
import { http } from '@utils';

const basePathUser = '/api/users-reviews';
const basePathBrand = '/api/brands-reviews';

export const getListBrand = async (
  query: string,
): Promise<DataResponse<FullReviewBrand[]>> => {
  const { data } = await http.get<DataResponse<FullReviewBrand[]>>(
    `${basePathBrand}${query}`,
  );

  return data;
};

export const createBrand = async (
  data: CreateReviewBrandRequest,
): Promise<void> => {
  await http.post(basePathBrand, data);
};

export const deleteReviewBrand = async (id: number): Promise<void> => {
  await http.delete(`${basePathBrand}/${id}`);
};

export const updateBrand = async (
  id: number,
  body: Pick<CreateReviewBrandRequest, 'rating' | 'comment'>,
): Promise<FullReviewBrand> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullReviewBrand>>(
    `${basePathBrand}/${id}`,
    body,
  );

  return data;
};

export const getListUser = async (
  query: string,
): Promise<DataResponse<FullReviewUser[]>> => {
  const { data } = await http.get<DataResponse<FullReviewUser[]>>(
    `${basePathUser}${query}`,
  );

  return data;
};

export const createUser = async (
  data: CreateReviewUserRequest,
): Promise<void> => {
  await http.post(basePathUser, data);
};

export const deleteReviewUser = async (id: number): Promise<void> => {
  await http.delete(`${basePathUser}/${id}`);
};

export const updateUser = async (
  id: number,
  body: Pick<CreateReviewUserRequest, 'rating' | 'comment'>,
): Promise<FullReviewUser> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullReviewUser>>(
    `${basePathUser}/${id}`,
    body,
  );

  return data;
};
