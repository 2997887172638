import { Col, Row } from 'antd';
import cn from 'classnames';

import { Props } from './index.type';

const UIOverviewInfo = (props: Props) => {
  const { name, value, className } = props;

  return (
    <Row className={cn('overview-data', className)} justify="space-between">
      <Col>{name}</Col>
      <Col className="overview-data-info">{value}</Col>
    </Row>
  );
};

export default UIOverviewInfo;
