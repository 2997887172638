import { Button } from 'antd';
import { NavLink } from 'react-router-dom';

import { Props } from './index.type';
import './style.scss';

const UICellLink = ({ value, link, className, target }: Props) => {
  if (target) {
    return (
      <a
        className={className}
        target={target || '_blank'}
        onClick={(event) => event.stopPropagation()}
        href={link}
      >
        {value}
      </a>
    );
  }

  return (
    <NavLink onClick={(event) => event.stopPropagation()} to={link}>
      <Button className={className} id="ant-button-cell-link" type="link">
        {value}
      </Button>
    </NavLink>
  );
};

export default UICellLink;
