import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { en } from '@i18n';
import { alertError } from '@store/Alert';
import { ValidationHelper } from '@utils';

type ValidationType = {
  label?: string;
  maxSize?: number;
  minSize?: number;
  value?: string;
};

const useValidation = ({ value, maxSize = 50 }: ValidationType) => {
  const dispatch = useDispatch();
  const [trimValue, setTrimValue] = useState<string>('');
  const [isError, setIsError] = useState(false);

  const length = value?.length;

  const isValid = ValidationHelper.isValidSize(maxSize, length || 0);

  useEffect(() => {
    if (isError) {
      dispatch(alertError(en.error.max));
    }
  }, [dispatch, isError]);

  useEffect(() => {
    if (isValid) {
      setIsError(false);
    } else {
      const trimStr = ValidationHelper.trimString(maxSize, value || '');

      setIsError(true);
      setTrimValue(trimStr);
    }

    return () => {
      setTrimValue('');
    };
  }, [isValid, isError, value, maxSize]);

  return { isError, trimValue: trimValue || value };
};

export default useValidation;
