import { useCallback, useEffect, useState } from 'react';

import { Form } from 'antd';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '@store/index';
import {
  clearListOrder,
  getListOrderRequest,
  getOneOrderRequest,
} from '@store/Order';
import {
  createReviewUserRequest,
  updateReviewUserRequest,
} from '@store/Review';
import {
  UIButton,
  UICellRate,
  UIModal,
  UISelectFormItem,
  UITextArea,
} from '@ui';
import { Button, DateHelper, FORMAT_DATE_TIME_WITH_DOT } from '@utils';

import { Props } from './index.type';

const WidgetModalReviewUserEdit = (props: Props) => {
  const { review } = props;

  const dispatch = useAppDispatch();

  const {
    orderList,
    loading: loadingBrand,
    meta: metaBrand,
  } = useSelector((state: RootState) => state.order);
  const [comment, setComment] = useState<string>('');
  const [rating, setRating] = useState<number>(0);
  const [orderId, setOrderId] = useState<number | null>(null);

  useEffect(() => {
    if (review) {
      setRating(review.rating);
      setComment(review.comment);
    }
  }, [review]);

  const onAcceptHandler = useCallback(() => {
    if (review) {
      dispatch(
        updateReviewUserRequest({
          id: (review?.id || orderId) as number,
          rating,
          comment,
        }),
      );
    } else {
      dispatch(
        createReviewUserRequest({
          orderId: orderId as number,
          rating,
          comment,
        }),
      );
    }
  }, [dispatch, rating, review, orderId, comment]);

  const footer = [
    <UIButton
      key="Save changes"
      typeAntBtn="primary"
      handler={() => onAcceptHandler()}
      type={Button.ANT_BUTTON}
      size="large"
      className="edit-form__save-button"
    >
      {review ? 'Save' : 'Add'} review
    </UIButton>,
  ];

  return (
    <UIModal
      footer={footer}
      title="Edit review"
      visible
      onAccept={onAcceptHandler}
    >
      <Form.Item label="Rating">
        <UICellRate
          onChangedHandler={setRating}
          rating={rating}
          count={5}
          allowHalf={false}
        />
      </Form.Item>
      {!review ? (
        <UISelectFormItem
          label="Order"
          rules={[{ required: true }]}
          required
          options={
            orderList?.map((item) => ({
              name: `${
                item.user.profile.fullName
              } / ${DateHelper.formateDateToString(
                item?.createdAt,
                FORMAT_DATE_TIME_WITH_DOT,
              )}`,
              id: item.id,
            })) || []
          }
          getOption={(id) => id && dispatch(getOneOrderRequest(+id))}
          valueId={orderId}
          getOptions={(query) => dispatch(getListOrderRequest(query))}
          onChange={(id) => setOrderId(id as unknown as number)}
          clearOptions={() => dispatch(clearListOrder())}
          meta={metaBrand}
          loading={loadingBrand}
        />
      ) : (
        <></>
      )}
      <Form.Item label="Text">
        <UITextArea rows={4} onChangeHandler={setComment} value={comment} />
      </Form.Item>
    </UIModal>
  );
};

export default WidgetModalReviewUserEdit;
