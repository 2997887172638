import { DiscountUnit } from '@utils';

import { FormValues } from './index.type';

export const INITIAL_VALUES: FormValues = {
  code: '',
  discountUnit: DiscountUnit.PERCENT,
  discountValue: null,
  userId: null,
  validFrom: new Date().toString(),
  validUntil: '',
  isActive: true,
  maxDiscountAmount: null,
  maxOrderValue: null,
  minOrderValue: null,
  usageLimit: null,
};
