import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { FORMAT_DATE, FORMAT_DATE_TIME } from '../constants';

dayjs.extend(utc);

export const endOfDayToISOString = (date?: dayjs.ConfigType) => {
  return dayjs(date).endOf('day').utc().toISOString();
};

export const startOfDayToISOString = (date?: dayjs.ConfigType) => {
  return dayjs(date).startOf('day').utc().toISOString();
};

export const dateIsBefore = (
  startDate: Date | string,
  endDate: Date | string,
): boolean => {
  return dayjs(startDate).isBefore(endDate);
};

export const formateDateToString = (
  date?: dayjs.ConfigType,
  format: string = FORMAT_DATE_TIME,
) => {
  return dayjs(date).format(format);
};

export const getDateFormat = (
  date?: string | Date | null,
  format = FORMAT_DATE,
) => {
  const m = dayjs(date || new Date());

  if (m.isValid()) {
    return m.format(format);
  }

  return date;
};

export const getDurationTime = ({
  startDate,
  endDate,
}: {
  endDate: string | Date;
  startDate: string | Date;
}) => {
  const seconds = dayjs(endDate).diff(startDate, 'second');

  return `${Math.floor(seconds / 60)}:${seconds % 60}`;
};

export const getEndDayFormatString = ({
  date,
  amountDays = 0,
  format,
}: {
  amountDays?: number;
  date?: dayjs.ConfigType;
  format?: string;
}) => {
  return dayjs(date).add(amountDays, 'days').endOf('day').format(format);
};

export const addDate = (date: dayjs.ConfigType, amount = 0) => {
  return dayjs(date).add(amount, 'days').toDate();
};

export const getEndOfMonthISO = (date?: dayjs.ConfigType) => {
  return dayjs(date).utc().endOf('month').toISOString();
};

export const getMonthFromNumber = (month: number, date?: dayjs.ConfigType) => {
  return dayjs(date).month(month).format('MMMM');
};

export const getNumberOfMonth = (date?: dayjs.ConfigType) => {
  return dayjs(date).month();
};

export const getStartDayFormatString = ({
  date,
  amountDays = 0,
  format,
}: {
  amountDays?: number;
  date?: dayjs.ConfigType;
  format?: string;
}) => {
  return dayjs(date).add(amountDays, 'days').startOf('day').format(format);
};

export const getStartOfMonthISO = (date?: dayjs.ConfigType) => {
  return dayjs(date).utc().startOf('month').toISOString();
};

export const getTimeFromMinutes = (val?: number) => {
  if (!val) {
    return '00:00:00';
  }

  const minutes = val % 60;

  const hours = (val - minutes) / 60;

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:00`;
};

export const setNumberOfMonth = (date: dayjs.ConfigType, month: number) => {
  return dayjs(date).month(month);
};

export const stringToDayjs = (date?: Date | string | null): dayjs.Dayjs =>
  dayjs(date);
