export const SET_CHAT = '@support/SET_CHAT';
export const SET_DIALOG_ID = '@support/SET_DIALOG_ID';
export const SET_MESSAGE_RECEIVE = '@support/SET_MESSAGE_RECEIVE';

export const CREATE_MESSAGE_REQUEST = '@support/CREATE_MESSAGE_REQUEST';

export const GET_LIST_CHAT_REQUEST = '@support/GET_LIST_CHAT_REQUEST';
export const GET_LIST_CHAT_SUCCESS = '@support/GET_LIST_CHAT_SUCCESS';
export const GET_LIST_CHAT_FAILURE = '@support/GET_LIST_CHAT_FAILURE';

export const GET_LIST_MESSAGE_REQUEST = '@support/GET_LIST_MESSAGE_REQUEST';
export const GET_LIST_MESSAGE_SUCCESS = '@support/GET_LIST_MESSAGE_SUCCESS';
export const GET_LIST_MESSAGE_FAILURE = '@support/GET_LIST_MESSAGE_FAILURE';

export const GET_ONE_CHAT_REQUEST = '@support/GET_ONE_CHAT_REQUEST';
export const GET_ONE_CHAT_SUCCESS = '@support/GET_ONE_CHAT_SUCCESS';
export const GET_ONE_CHAT_FAILURE = '@support/GET_ONE_CHAT_FAILURE';

export const CLEAR_CHAT = '@support/CLEAR_CHAT';
export const CLEAR_MESSAGE = '@support/CLEAR_MESSAGE';
