import { useEffect } from 'react';

import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './style.scss';

import { LayoutContent } from '@layouts';
import {
  clearCategory,
  deleteCategoryRequest,
  getOneCategoryRequest,
} from '@store/Category';
import { RootState, useAppDispatch } from '@store/index';
import { ModalType, showModal } from '@store/Modal';
import { UIButton, UIHeader } from '@ui';
import { ActionStatus, Button as ButtonType } from '@utils';

import CategoryDetailDiscription from './Discription';
import CategoryEdit from './Edit';

const CategoryDetail = () => {
  const dispatch = useAppDispatch();
  const { status, category, loading } = useSelector(
    (state: RootState) => state.category,
  );
  const { categoryId } = useParams<'categoryId'>();

  useEffect(() => {
    if (categoryId) {
      dispatch(getOneCategoryRequest({ id: +categoryId }));
    }
  }, [categoryId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearCategory());
    };
  }, [dispatch]);

  useEffect(() => {
    if (status === ActionStatus.DELETED) {
      window.history.back();
    }
  }, [status]);

  // const onChangeVisible = () => {
  //   if (categoryId) {
  //     dispatch(
  //       updateCategoryRequest({
  //         id: +categoryId,
  //         isActive: !category?.isActive,
  //       }),
  //     );
  //   }
  // };

  const aside = [
    // <Row justify="space-between" align="middle" className="visible-wrapper">
    //   <Text strong>Visible</Text>
    //   <Switch checked={category?.isActive} onChange={onChangeVisible} />
    // </Row>,
    <CategoryDetailDiscription category={category} loading={loading} />,
    <UIButton
      className="delete-category-button"
      children="Delete Category"
      size="middle"
      type={ButtonType.ANT_BUTTON}
      prop={{ danger: true }}
      handler={() => {
        if (categoryId) {
          dispatch(
            showModal({
              modalType: ModalType.CONFIRM,
              modalProps: {
                content: <p>Are you sure you want to delete the Category</p>,
                action: () => dispatch(deleteCategoryRequest(+categoryId)),
              },
            }),
          );
        }
      }}
    />,
  ];

  const content = [
    category && <CategoryEdit category={category} loadingCategory={loading} />,
  ];

  return (
    <Space direction="vertical">
      <UIHeader isBack title={category?.name} />
      <LayoutContent content={content} loadingAside={loading} aside={aside} />
    </Space>
  );
};

export default CategoryDetail;
