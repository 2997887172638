import { useEffect, useRef, useState } from 'react';

import { Spin, Table } from 'antd';
import cn from 'classnames';

import { Props } from './index.type';

import './style.scss';

const UITable = <T extends Id>({
  dataSource,
  columns,
  loading = false,
  meta,
  onPaginationChange,
  scroll,
  isScrollDefault,
  adaptive = true,
  onRow,
  pageSizeOptions,
}: Props<T>) => {
  const isPagination = meta && dataSource.length > 0;
  const refWrap: React.LegacyRef<HTMLDivElement> = useRef(null);
  const [scrollAdaptive, setScroll] = useState(false);

  useEffect(() => {
    if (refWrap?.current?.clientWidth && refWrap?.current?.clientWidth < 1400) {
      setScroll(true);
    }
  }, [refWrap]);

  return (
    <div ref={refWrap} className={cn('ui-table', { onRow: !!onRow })}>
      <Spin size="large" spinning={loading}>
        <Table
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          {...(onRow && {
            onRow: (record: any, rowIndex) => ({
              onClick: () => onRow(record, rowIndex),
            }),
          })}
          pagination={
            (isPagination && {
              pageSize: meta.limit,
              pageSizeOptions,
              current: meta.currentPage,
              total: meta.totalItems,
              onChange: onPaginationChange,
            }) ||
            false
          }
          scroll={
            isScrollDefault || (adaptive && scrollAdaptive)
              ? { x: 1588 }
              : scroll
          }
        />
      </Spin>
    </div>
  );
};

export default UITable;
