import { useEffect } from 'react';

import { Form, Input, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { en } from '@i18n';

import { WidgetModalUIImage } from '../../ui';
import { INITIAL_VALUES } from './constant';
import { FormValues, Props } from './index.type';

const WidgetModalCategoryForm = ({
  onFormSubmit,
  form,
  category,
  onUploadImage,
  image,
  onRemoveImage,
}: Props) => {
  useEffect(() => {
    if (category) {
      form.setFieldsValue(category);
    }
  }, [category, form]);

  const handlerFinish = (values: FormValues) => {
    onFormSubmit({
      ...values,
      image,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      validateMessages={en.validation}
    >
      <WidgetModalUIImage
        name="image"
        view="image"
        path={image?.path || 'error'}
        onUploadImage={onUploadImage}
        onRemoveImage={onRemoveImage}
      />
      <Form.Item
        name="name"
        label="Name of the category"
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input placeholder="Enter the category" />
      </Form.Item>
      {category ? (
        <Form.Item label="Visible" name="isActive" required>
          <Switch defaultChecked={category?.isActive} />
        </Form.Item>
      ) : null}
      <Form.Item label="Description" name="description">
        <TextArea showCount rows={4} placeholder="Enter the description" />
      </Form.Item>
    </Form>
  );
};

export default WidgetModalCategoryForm;
