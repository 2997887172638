import axios, { CancelTokenSource } from 'axios';

import { FullBrand } from '@entities/Brand';
import { http } from '@utils';

const basePath = '/api/brands';
let axiosSource: CancelTokenSource | null = null;

export const getList = async (
  query?: Query['query'],
  isCancel = true,
): Promise<{
  data: FullBrand[];
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<FullBrand[]>>(
    `${basePath}${query || ''}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};

export const create = async (body: Pick<FullBrand, 'name' | 'description'>) => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullBrand>>(`${basePath}`, body);

  return data;
};

export const remove = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const getOne = async (id: FullBrand['id']): Promise<FullBrand> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullBrand>>(`${basePath}/${id}`);

  return data;
};

export const update = async (
  id: number,
  body: Partial<FullBrand>,
): Promise<FullBrand> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullBrand>>(`${basePath}/${id}`, body);

  return data;
};
