import { Button as AntButton } from 'antd';
import cn from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';

import { Button } from '@utils';

import { Props } from './index.type';

const UIButton = ({
  link,
  handler,
  children,
  className = '',
  type = Button.BUTTON,
  disabled = false,
  defaultClassName = 'btn',
  size = 'large',
  icon,
  typeAntBtn = 'primary',
  prop,
}: Props) => {
  const navigate = useNavigate();

  const renderLink = () => {
    if (link) {
      return (
        <NavLink
          end
          to={link}
          onClick={(event) => event.stopPropagation()}
          className={({ isActive }) =>
            cn(className) + (isActive ? ' active current' : '')
          }
        >
          {children}
        </NavLink>
      );
    }

    return null;
  };

  const renderButton = () => {
    if (type === Button.BUTTON || type === Button.SUBMIT) {
      return (
        <button
          type={type}
          className={cn(defaultClassName, className)}
          disabled={disabled}
          onClick={link ? () => navigate(link) : handler}
        >
          {children}
        </button>
      );
    }

    return null;
  };

  const renderAntButton = () => {
    if (type === Button.ANT_BUTTON) {
      return (
        <AntButton
          size={size}
          type={typeAntBtn}
          disabled={disabled}
          icon={icon}
          className={cn(className)}
          onClick={handler}
          {...prop}
        >
          {children}
        </AntButton>
      );
    }

    return null;
  };

  switch (type) {
    case Button.LINK:
      return renderLink();
    case Button.ANT_BUTTON:
      return renderAntButton();
    default:
      return renderButton();
  }
};

export default UIButton;
