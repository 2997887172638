import { useEffect, useState } from 'react';

import { Col, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { PaymentToken } from '@entities/Order';
import { useScript } from '@hooks';
import { RootState } from '@store/index';
import { decode } from '@utils';

import './style.scss';
import PaymentAeroPay from './Aeropay';

const Payment = () => {
  useScript('https://sdk.aeropay.com/ap/aeropay.js');
  const [searchParams] = useSearchParams();
  const paymentToken = searchParams.get('paymentToken');
  const { loading: loadingOrder } = useSelector(
    (state: RootState) => state.order,
  );

  const paymentInfo: PaymentToken | null = paymentToken
    ? decode(paymentToken)
    : null;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Col className="payment-form">
      <Spin spinning={loading || loadingOrder}>
        {!loading && paymentToken && paymentInfo?.orderId ? (
          <PaymentAeroPay
            paymentToken={paymentToken}
            totalPrice={paymentInfo.totalPrice}
          />
        ) : null}
      </Spin>
    </Col>
  );
};

export default Payment;
