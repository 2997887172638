/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Col, Form, Input, InputNumber, Row, Switch } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useSelector } from 'react-redux';

import { en } from '@i18n';
import { RootState, useAppDispatch } from '@store/index';
import {
  clearUserListStore,
  getListUserRequest,
  getOneUserRequest,
} from '@store/User';
import { UIButton, UICalendar, UISelectFormItem } from '@ui';
import { Button, DiscountUnit, META_DEFAULT, Role, StringHelper } from '@utils';

import { INITIAL_VALUES } from './constant';
import { FormValues, Props } from './index.type';

dayjs.extend(customParseFormat);

const WidgetModalPromoCodeForm = ({
  onFormSubmit,
  form,
  promoCode,
  isCreate,
}: Props) => {
  const discountValue = Form.useWatch('discountValue', form);
  const discountUnitValue = Form.useWatch('discountUnit', form);
  const userId = Form.useWatch('userId', form);
  const [timeActive, setTimeActive] = useState<[string, string] | []>([]);
  const [btnActive, setBtnActive] = useState(false);
  const dispatch = useAppDispatch();
  const isPercent = discountUnitValue === DiscountUnit.PERCENT;

  const {
    userList,
    meta,
    loading: loadingUser,
  } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (promoCode) {
      form.setFieldsValue(promoCode);
      if (promoCode?.validFrom && promoCode?.validUntil) {
        setTimeActive([promoCode.validFrom, promoCode.validUntil]);
      }
    }
  }, [promoCode, form]);

  const handlerFinish = ({ userId, ...val }: FormValues) => {
    onFormSubmit({
      ...val,
      ...(userId && { userId }),
      ...(timeActive.length && {
        validFrom: timeActive[0],
        validUntil: timeActive[1],
      }),
    });
  };

  const formCheckbox = {
    labelCol: { span: 'auto' },
    wrapperCol: { span: 'auto' },
  };

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      validateMessages={en.validation}
    >
      <Form.Item
        name="code"
        label="Name of the promoCode"
        required
        rules={[{ required: true }]}
      >
        <Input placeholder="Enter the promoCode" />
      </Form.Item>
      <UIButton
        typeAntBtn="primary"
        handler={() =>
          form.setFieldValue('code', StringHelper.make16SystemString(6))
        }
        type={Button.ANT_BUTTON}
        size="large"
      >
        Generate a promo code
      </UIButton>
      <UISelectFormItem
        label="User"
        name="userId"
        options={
          (userList || [])
            .map((item) => {
              if (item.profile?.fullName) {
                return {
                  id: item.id,
                  name: item.profile?.fullName,
                };
              }

              return null;
            })
            .filter((i) => i) as {
            id: number | string;
            name: string;
          }[]
        }
        getOptions={(query) => dispatch(getListUserRequest(query, false))}
        getOption={(id) =>
          id && dispatch(getOneUserRequest({ id: +id, role: Role.USER }))
        }
        valueId={userId}
        clearOptions={() => dispatch(clearUserListStore())}
        queryString={`filter[role]=${Role.USER}&filter[isConfirmed21Age]=true`}
        meta={meta || META_DEFAULT}
        loading={loadingUser}
      />
      <Row justify="center">
        <Col xxl={14} span={14}>
          {isPercent ? (
            <Form.Item
              label="Percent"
              name="discountValue"
              required={isPercent}
              rules={[
                {
                  required: isPercent,
                  min: 1,
                  max: 100,
                  type: 'number',
                },
              ]}
            >
              <InputNumber
                min={1}
                step={1}
                type="number"
                {...(discountValue &&
                  (+discountValue < 1 || +discountValue > 100) && {
                    status: 'error',
                  })}
                placeholder="Enter value"
              />
            </Form.Item>
          ) : (
            <Form.Item
              label="Discount"
              name="discountValue"
              required={!isPercent}
              rules={[{ required: !isPercent, min: 0.01, type: 'number' }]}
            >
              <InputNumber
                min={0}
                type="number"
                {...(discountValue &&
                  +discountValue < 0.01 && { status: 'error' })}
                placeholder="Price"
              />
            </Form.Item>
          )}
        </Col>
        <Col offset={4} xxl={6} span={6}>
          <Form.Item {...formCheckbox} label="Is percent" name="discountUnit">
            <Switch
              onChange={(item) => {
                form.setFieldValue(
                  'discountUnit',
                  item ? DiscountUnit.PERCENT : DiscountUnit.CURRENCY,
                );
                form.setFieldValue('discountValue', null);
              }}
              checked={isPercent}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Col xxl={14} span={14}>
          <Form.Item label="Promo code lifetime">
            <UICalendar
              value={timeActive}
              {...(!timeActive.length &&
                btnActive && {
                  status: 'error',
                })}
              onChange={(_, i) => setTimeActive(i)}
            />
          </Form.Item>
        </Col>
        <Col offset={4} xxl={6} span={6}>
          {promoCode ? (
            <Form.Item {...formCheckbox} label="Is active" name="isActive">
              <Switch defaultChecked={promoCode?.isActive} />
            </Form.Item>
          ) : null}
        </Col>
      </Row>
      <UIButton
        key="Save changes"
        typeAntBtn="primary"
        handler={() => {
          form.submit();
          setBtnActive(true);
        }}
        type={Button.ANT_BUTTON}
        size="large"
        className="edit-form__save-button"
      >
        {isCreate ? 'Add promoCode' : 'Save'}
      </UIButton>
    </Form>
  );
};

export default WidgetModalPromoCodeForm;
