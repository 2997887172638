/* eslint-disable indent */
import { ColumnsType } from 'antd/lib/table';

import { FilterOption } from '@entities/Filter';
import { FullProduct } from '@entities/Product';
import { SortOption } from '@entities/Sort';
import { clearListBrands, getListBrandRequest } from '@store/Brand';
import { clearListCategory, getListCategoryRequest } from '@store/Category';
import type { AppDispatch } from '@store/index';
import { clearUserListStore, getListUserRequest } from '@store/User';
import { UICellFullName, UICellLink, UICellStatus, UICellTooltip } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_LONG_TEXT,
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_PRISE,
  COLUMN_WIDTH_TAG,
  DateHelper,
  FORMAT_DATE,
  FORMAT_SHORT_DATE,
  LINK_BRAND,
  LINK_CATEGORY,
  Role,
  StringHelper,
} from '@utils';

import { ProductUIActionList } from '../ui';

export const columns = ({
  dispatch,
  path,
}: {
  dispatch: AppDispatch;
  path: string;
}): ColumnsType<FullProduct> => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    render: (value, record) =>
      UICellLink({ value, link: `${path}/${record.id}` }),
    width: COLUMN_WIDTH_ID,
  },
  {
    title: 'Product name',
    dataIndex: 'name',
    key: 'name',
    width: COLUMN_WIDTH_NAME,
    render: (_, { name, id, image }) =>
      UICellFullName({
        firstName: name,
        src: image?.path,
        link: `${path}/${id}`,
      }),
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    key: 'brand',
    ellipsis: true,
    width: COLUMN_WIDTH_ACTIONS,

    render: (_, record) =>
      UICellLink({
        value: record?.brand?.name || '',
        link: `/${LINK_BRAND}/${record?.brand?.id}`,
      }),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    ellipsis: true,
    width: COLUMN_WIDTH_ACTIONS,

    render: (_, record) =>
      UICellLink({
        value: record?.category?.name || '',
        link: `/${LINK_CATEGORY}/${record?.category?.id}`,
      }),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    ellipsis: {
      showTitle: false,
    },
    width: COLUMN_WIDTH_PRISE,
    render: (value: number) => StringHelper.toMoney(value, 2),
  },
  {
    title: 'Discount price',
    dataIndex: 'discountPrice',
    key: 'discountPrice',
    ellipsis: {
      showTitle: false,
    },
    width: 100,
    render: (value: number) => StringHelper.toMoney(value, 2),
  },
  {
    title: 'Discount from / to',
    width: 140,
    render: (_, { discount }) =>
      discount?.validFrom && discount?.validUntil
        ? `${DateHelper.formateDateToString(
            discount?.validFrom,
            FORMAT_SHORT_DATE,
          )} / ${DateHelper.formateDateToString(
            discount?.validUntil,
            FORMAT_SHORT_DATE,
          )}`
        : 'There is no discount period',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: {
      showTitle: false,
    },
    width: COLUMN_WIDTH_LONG_TEXT,
    render: (text) => UICellTooltip({ text }),
  },
  {
    title: 'Visible',
    dataIndex: 'isActive',
    key: 'isActive',
    width: COLUMN_WIDTH_TAG,
    render: (value) =>
      UICellStatus({
        value,
        text: value ? 'Yes' : 'No',
      }),
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    key: 'id',
    width: COLUMN_WIDTH_ACTIONS,
    render: (id) => ProductUIActionList({ id, dispatch }),
    align: 'right',
  },
];

export const orderOption: SortOption[] = [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  {
    label: 'Name (A-Z)',
    name: 'name',
    direction: 'asc',
  },
  {
    label: 'Name (Z-A)',
    name: 'name',
    direction: 'desc',
  },
  {
    label: 'Price (ascending)',
    name: 'price',
    direction: 'asc',
  },
  {
    label: 'Price (descending)',
    name: 'price',
    direction: 'desc',
  },
  {
    label: 'Create date (oldest first)',
    name: 'created_at',
    direction: 'asc',
  },
  {
    label: 'Create date (newest first)',
    name: 'created_at',
    direction: 'desc',
  },
];

const today = new Date();

export const filterOptions = (dispatch: AppDispatch) =>
  [
    {
      name: 'brandIds',
      label: 'Brands',
      type: 'radio-select',
      className: 'scroll-y h200px w100',
      load: {
        get: (query: string) => dispatch(getListBrandRequest(query)),
        clear: () => dispatch(clearListBrands()),
        store: 'brand',
        listName: ['brandList'],
      },
    },
    {
      name: 'categoryIds',
      label: 'Category',
      type: 'radio-select',
      className: 'scroll-y h200px w100',
      load: {
        get: (query: string) => dispatch(getListCategoryRequest(query)),
        clear: () => dispatch(clearListCategory()),
        store: 'category',
        listName: ['categoryList'],
      },
    },
    // {
    //   name: 'storeIds',
    //   label: 'Store',
    //   type: 'radio-select',
    //   typeName: 'shop',
    //   className: 'scroll-y h200px w100',
    //   load: {
    //     get: (query: string) => dispatch(getListShopRequest(query)),
    //     clear: () => dispatch(clearListShop()),
    //   },
    // },
    {
      name: 'driverIds',
      label: 'Drivers',
      type: 'radio-select',
      className: 'scroll-y h200px w100',
      load: {
        get: (query: string) =>
          dispatch(getListUserRequest(`${query}&filter[role]=${Role.DRIVERS}`)),
        clear: () => dispatch(clearUserListStore(Role.DRIVERS)),
        store: 'user',
        listName: ['userDriver', 'list'],
      },
    },
    {
      name: 'is_most_popular',
      label: 'Is most popular',
      type: 'radio',
      options: [
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      name: 'is_promotion',
      label: 'Is promotion',
      type: 'radio',
      options: [
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      name: 'is_fastest',
      label: 'Is fastest',
      type: 'radio',
      options: [
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      name: 'is_today_deals',
      label: 'Is today deals',
      type: 'radio',
      options: [
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      name: 'created_at',
      label: 'Create date',
      type: 'radio-range',
      options: [
        {
          label: 'Today',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
        {
          label: 'Last 7 days',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
              amountDays: -7,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
        {
          label: 'Last 30 days',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
              amountDays: -30,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
      ],
    },
    {
      name: 'isActive',
      label: 'Visible',
      type: 'radio',
      options: [
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      name: 'price',
      label: 'Filter by product price ',
      type: 'number-range',
      step: 1,
      min: 0,
    },
  ] as FilterOption[];
