import { Dispatch } from 'redux';
import { Socket } from 'socket.io-client';

import { alertError, alertSuccess } from '@store/Alert';
import { updateListMetrc } from '@store/Csv';
import { addNotification } from '@store/Profile';
import { NotificationEvent, NotificationType } from '@utils';

export const initSubscribe = (params: {
  dispatch: Dispatch;
  pathname: string;
  query: string;
  socket: Socket;
}) => {
  const { socket, dispatch } = params;

  socket.on(
    NotificationEvent.NOTIFICATION_SUBSCRIBE,
    (data: DataResponse<{ message: string; type: string }>) => {
      console.log('NOTIFICATION_SUBSCRIBE', data);
      if (data.data.type === 'success') {
        dispatch(
          alertSuccess(data.data.message, '', {
            width: '40vw',
            height: '20vh',
            fontSize: '22px',
          }),
        );
        dispatch(
          addNotification({
            createdAt: new Date().toString(),
            text: data.data.message,
            type: NotificationType.NOTIFICATION,
          }),
        );
        dispatch(updateListMetrc(true));
      } else {
        dispatch(alertError(data.data.message));
      }
    },
  );
};
