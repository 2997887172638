import { FilterCheckboxOption, FilterRange } from '@entities/Filter';

import { FilterOption, FilterValues } from '../model';

export const filterValueTypeToString = (
  option: FilterOption,
  filterValue: FilterValues,
): string => {
  switch (option.type) {
    case 'number': {
      const { min, max } = filterValue as FilterRange;

      return `${option.label}: ${min || ''}${min && max ? ' - ' : ''}${
        max || ''
      }`;
    }

    case 'radio': {
      const currentOption = option.options.find(
        (i) => `${i.value}` === `${filterValue}`,
      );

      return `${option.label}: ${currentOption?.label}`;
    }
    case 'radio-range': {
      const { min, max } = filterValue as FilterRange;

      return `${option.label}: ${min || ''}${min && max ? ' - ' : ''}${
        max || ''
      }`;
    }
    case 'number-range': {
      const { min, max } = filterValue as FilterRange;

      return `${option.label}: ${min || ''}${min && max ? ' - ' : ''}${
        max || ''
      }`;
    }

    case 'checkbox': {
      return `${option.label}: ${(filterValue as FilterCheckboxOption[])?.join(
        ', ',
      )}`;
    }

    case 'radio-select': {
      return `Filter ${option.label}`;
    }

    default:
      return '';
  }
};
