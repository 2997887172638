import { combineReducers } from 'redux';

import { AlertReducer } from './Alert';
import { AuthReducer } from './Auth';
import { BrandReducer } from './Brand';
import { CategoryReducer } from './Category';
import { ChatReducer } from './Chat';
import { CommentReducer } from './Comment';
import { csvReducer } from './Csv';
import { EffectReducer } from './Effect';
import { MediaReducer } from './Media';
import { ModalReducer } from './Modal';
import { OrderReducer } from './Order';
import { ProductReducer } from './Product';
import { ProfileReducer } from './Profile';
import { PromoCodeReducer } from './PromoCode';
import { ReviewReducer } from './Review';
import { ScheduleReducer } from './Schedule';
import { SettingReducer } from './Setting';
import { ShopReducer } from './Shop';
import { StatisticReducer } from './Statistic';
import { SupportReducer } from './Support';
import { TransactionReducer } from './Transaction';
import { UserReducer } from './User';

export const rootReducer = combineReducers({
  alert: AlertReducer,
  auth: AuthReducer,
  chat: ChatReducer,
  support: SupportReducer,
  profile: ProfileReducer,
  user: UserReducer,
  modal: ModalReducer,
  order: OrderReducer,
  transaction: TransactionReducer,
  comment: CommentReducer,
  media: MediaReducer,
  product: ProductReducer,
  brand: BrandReducer,
  promoCode: PromoCodeReducer,
  shop: ShopReducer,
  effect: EffectReducer,
  category: CategoryReducer,
  setting: SettingReducer,
  schedule: ScheduleReducer,
  review: ReviewReducer,
  csv: csvReducer,
  statistic: StatisticReducer,
});
