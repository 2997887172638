import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullOrder, OrderBasket } from '@entities/Order';
import { OrderProducts } from '@entities/Product';
import { OrderHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';

import {
  createOrderFailure,
  createOrderSuccess,
  getListOrderFailure,
  getListOrderSuccess,
  getOneOrderFailure,
  getOneOrderProductFailure,
  getOneOrderProductSuccess,
  getOneOrderRequest,
  getOneOrderSuccess,
  // getOrderPaymentFailure,
  // getOrderPaymentSuccess,
  orderBasketFailure,
  orderBasketSuccess,
  orderPaymentFailure,
  orderPaymentSuccess,
  updateOrderFailure,
  updateOrderSuccess,
} from './Order.action';
import {
  CREATE_ORDER_REQUEST,
  GET_LIST_ORDER_REQUEST,
  GET_ONE_ORDER_PRODUCT_REQUEST,
  GET_ONE_ORDER_REQUEST,
  // GET_ORDER_PAYMENT_REQUEST,
  ORDER_BASKET_REQUEST,
  ORDER_PAYMENT_REQUEST,
  UPDATE_ORDER_REQUEST,
} from './Order.constant';
import {
  ICreateOrderRequest,
  IGetListOrderRequest,
  IGetOneOrderProductRequest,
  IGetOneOrderRequest,
  // IGetOrderPaymentRequest,
  IOrderPaymentRequest,
  IUpdateOrderRequest,
} from './Order.type';

function* workerGetListOrder(action: IGetListOrderRequest) {
  try {
    const { query } = action.payload;

    const payload: DataResponse<FullOrder[]> = yield call(
      OrderHttp.getList,
      query,
    );

    yield put(getListOrderSuccess(payload));
  } catch (error) {
    yield put(getListOrderFailure());
    yield put(alertError(error));
  }
}

function* watchGetListOrder() {
  yield takeLatest(GET_LIST_ORDER_REQUEST, workerGetListOrder);
}

function* workerGetOneOrder(action: IGetOneOrderRequest) {
  try {
    const { orderId } = action.payload;

    const payload: DataResponse<Omit<FullOrder, 'products'>> = yield call(
      OrderHttp.getOne,
      orderId,
    );

    yield put(getOneOrderSuccess(payload));
  } catch (error) {
    yield put(getOneOrderFailure());
    yield put(alertError(error));
  }
}

function* watchGetOneOrder() {
  yield takeLatest(GET_ONE_ORDER_REQUEST, workerGetOneOrder);
}

function* workerGetOneOrderProduct(action: IGetOneOrderProductRequest) {
  try {
    const { orderId } = action.payload;

    const payload: DataResponse<OrderProducts[]> = yield call(
      OrderHttp.getOneProduct,
      orderId,
    );

    yield put(getOneOrderProductSuccess(payload));
  } catch (error) {
    yield put(getOneOrderProductFailure());
    yield put(alertError(error));
  }
}

// function* workerGetOrderPayment(action: IGetOrderPaymentRequest) {
//   try {
//     const payload: DataResponse<OrderPayment> = yield call(
//       OrderHttp.getPayment,
//       action.payload,
//     );

//     yield put(getOrderPaymentSuccess(payload));
//   } catch (error) {
//     yield put(getOrderPaymentFailure());
//     yield put(alertError(error));
//   }
// }

function* workerOrderPayment(action: IOrderPaymentRequest) {
  try {
    yield call(OrderHttp.successPayment, action.payload);

    yield put(orderPaymentSuccess());
  } catch (error) {
    yield put(orderPaymentFailure());
    yield put(alertError(error));
  }
}

function* watchGetOneOrderProduct() {
  yield takeLatest(GET_ONE_ORDER_PRODUCT_REQUEST, workerGetOneOrderProduct);
}

function* workerUpdateOrder(action: IUpdateOrderRequest) {
  try {
    const { id, ...body } = action.payload;

    yield call(OrderHttp.update, +id, body);

    yield put(updateOrderSuccess());
    yield put(getOneOrderRequest(id));
    yield put(alertSuccess('Order successfully update!'));
  } catch (error) {
    yield put(updateOrderFailure());
    yield put(alertError(error));
  }
}

function* watchUpdateOrder() {
  yield takeLatest(UPDATE_ORDER_REQUEST, workerUpdateOrder);
}

function* workerCreateOrder(action: ICreateOrderRequest) {
  try {
    const payload: FullOrder = yield call(OrderHttp.create, action.payload);

    yield put(createOrderSuccess(payload));
    yield put(alertSuccess('Order successfully update!'));
  } catch (error) {
    yield put(createOrderFailure());
    yield put(alertError(error));
  }
}

function* workerOrderBasket(action: ICreateOrderRequest) {
  try {
    const payload: OrderBasket = yield call(OrderHttp.basket, action.payload);

    yield put(orderBasketSuccess(payload));
    yield put(alertSuccess('Order successfully calculation!'));
  } catch (error) {
    yield put(orderBasketFailure());
    yield put(alertError(error));
  }
}

function* watchCreateOrder() {
  yield takeLatest(CREATE_ORDER_REQUEST, workerCreateOrder);
}

function* watchOrderBasket() {
  yield takeLatest(ORDER_BASKET_REQUEST, workerOrderBasket);
}

// function* watchGetOrderPayment() {
//   yield takeLatest(GET_ORDER_PAYMENT_REQUEST, workerGetOrderPayment);
// }

function* watchOrderPayment() {
  yield takeLatest(ORDER_PAYMENT_REQUEST, workerOrderPayment);
}

export const orderWatchers: ForkEffect[] = [
  fork(watchGetListOrder),
  fork(watchGetOneOrder),
  fork(watchUpdateOrder),
  fork(watchCreateOrder),
  fork(watchOrderBasket),
  // fork(watchGetOrderPayment),
  fork(watchOrderPayment),
  fork(watchGetOneOrderProduct),
];
