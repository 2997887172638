import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CLEAR_LIST_ORDER,
  CLEAR_ORDER,
  CLEAR_ORDER_BASKET,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  GET_LIST_ORDER_FAILURE,
  GET_LIST_ORDER_REQUEST,
  GET_LIST_ORDER_SUCCESS,
  GET_ONE_ORDER_FAILURE,
  GET_ONE_ORDER_PRODUCT_FAILURE,
  GET_ONE_ORDER_PRODUCT_REQUEST,
  GET_ONE_ORDER_PRODUCT_SUCCESS,
  GET_ONE_ORDER_REQUEST,
  GET_ONE_ORDER_SUCCESS,
  // GET_ORDER_PAYMENT_FAILURE,
  // GET_ORDER_PAYMENT_REQUEST,
  // GET_ORDER_PAYMENT_SUCCESS,
  ORDER_BASKET_FAILURE,
  ORDER_BASKET_REQUEST,
  ORDER_BASKET_SUCCESS,
  ORDER_PAYMENT_FAILURE,
  ORDER_PAYMENT_REQUEST,
  ORDER_PAYMENT_SUCCESS,
  SOCKET_ORDER,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
} from './Order.constant';
import { IOrderState, OrderActionsTypes } from './Order.type';

const initialState: IOrderState = {
  orderList: null,
  meta: META_DEFAULT,
  loading: false,
  loaded: false,
  status: ActionStatus.NONE,
  order: null,
  notification: 0,
  products: null,
  basket: null,
};

export default function orderReducer(
  state = initialState,
  action: OrderActionsTypes,
): IOrderState {
  switch (action.type) {
    // case GET_ORDER_PAYMENT_REQUEST:
    case GET_LIST_ORDER_REQUEST:
    case GET_ONE_ORDER_REQUEST:
    case GET_ONE_ORDER_PRODUCT_REQUEST:
    case UPDATE_ORDER_REQUEST:
    case CREATE_ORDER_REQUEST:
    case ORDER_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        status: ActionStatus.NONE,
      };
    case ORDER_BASKET_REQUEST:
      return {
        ...state,
        loading: true,
        basket: null,
      };
    case ORDER_BASKET_SUCCESS:
      return {
        ...state,
        loading: false,
        basket: action.payload,
      };
    case ORDER_BASKET_FAILURE:
    case CLEAR_ORDER_BASKET:
      return {
        ...state,
        loading: false,
        basket: null,
      };
    case GET_LIST_ORDER_SUCCESS: {
      return {
        ...state,
        orderList: [...(state.orderList || []), ...action.payload.data],
        meta: action.payload.meta,
        loaded: true,
        loading: false,
        status: ActionStatus.LOADED,
        notification: 0,
      };
    }
    case GET_ONE_ORDER_PRODUCT_SUCCESS: {
      return {
        ...state,
        products: action.payload.data,
        meta: action.payload.meta,
      };
    }
    // case GET_ORDER_PAYMENT_SUCCESS: {
    //   return {
    //     ...state,
    //     orderPayment: action.payload.data,
    //     loading: false,
    //   };
    // }
    case SOCKET_ORDER:
      return {
        ...state,
        notification: state.notification + 1,
      };
    case GET_ONE_ORDER_SUCCESS: {
      const order = action.payload.data;
      const isAddOrder = (state.orderList || [])
        .map((i) => +i.id)
        .includes(+action.payload.data.id);

      return {
        ...state,
        orderList: isAddOrder
          ? (state.orderList || []).map((b) => (b.id === order.id ? order : b))
          : [action.payload.data, ...(state.orderList || [])],
        order,
        loading: false,
        status: ActionStatus.LOADED,
        notification: 0,
      };
    }
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: ActionStatus.UPDATED,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: ActionStatus.UPDATED,
        orderList: [action.payload, ...(state.orderList || [])],
      };
    // case GET_ORDER_PAYMENT_FAILURE:
    case GET_LIST_ORDER_FAILURE:
    case UPDATE_ORDER_FAILURE:
    case CREATE_ORDER_FAILURE:
    case GET_ONE_ORDER_FAILURE:
    case ORDER_PAYMENT_FAILURE:
    case ORDER_PAYMENT_SUCCESS:
    case GET_ONE_ORDER_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_LIST_ORDER:
      return {
        ...state,
        orderList: null,
        meta: META_DEFAULT,
      };
    case CLEAR_ORDER: {
      return {
        ...state,
        status: ActionStatus.NONE,
        order: null,
        products: null,
      };
    }
    default:
      return state;
  }
}
