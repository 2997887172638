import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullCategory } from '@entities/Category';
import { CategoryHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';

import {
  createCategoryFailure,
  createCategorySuccess,
  deleteCategoryFailure,
  deleteCategorySuccess,
  getListCategoryFailure,
  getListCategorySuccess,
  getOneCategoryFailure,
  getOneCategorySuccess,
  updateCategoryFailure,
  updateCategorySuccess,
} from './Category.action';
import {
  CREATE_CATEGORY_REQUEST,
  DELETE_CATEGORY_REQUEST,
  GET_LIST_CATEGORY_REQUEST,
  GET_ONE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_REQUEST,
} from './Category.constant';
import {
  ICreateCategoryRequest,
  IDeleteCategoryRequest,
  IGetListCategoryRequest,
  IGetOneCategoryRequest,
  IUpdateCategoryRequest,
} from './Category.type';

function* workerGetListCategory(action: IGetListCategoryRequest) {
  try {
    const { query } = action.payload;
    const data: DataResponse<FullCategory[]> = yield call(
      CategoryHttp.getList,
      query,
    );

    yield put(getListCategorySuccess(data));
  } catch (error) {
    yield put(getListCategoryFailure());
    yield put(alertError(error));
  }
}

function* workerCreateCategory(action: ICreateCategoryRequest) {
  try {
    const data: FullCategory = yield call(CategoryHttp.create, action.payload);

    yield put(createCategorySuccess(data));
    yield put(alertSuccess('Category added successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(createCategoryFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteCategory(action: IDeleteCategoryRequest) {
  try {
    const { id } = action.payload;

    yield call(CategoryHttp.remove, id);

    yield put(deleteCategorySuccess(id));
    yield put(alertSuccess('Category successfully deleted!'));
  } catch (error) {
    yield put(deleteCategoryFailure());
    yield put(alertError(error));
  }
}

function* workerGetOneCategory(action: IGetOneCategoryRequest) {
  try {
    const { id, toList } = action.payload;
    const payload: FullCategory = yield call(CategoryHttp.getOne, id);

    yield put(getOneCategorySuccess({ data: payload, toList }));
  } catch (error) {
    yield put(getOneCategoryFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateCategory(action: IUpdateCategoryRequest) {
  try {
    const { id, ...body } = action.payload;

    const data: FullCategory = yield call(CategoryHttp.update, id, body);

    yield put(updateCategorySuccess(data));
    yield put(alertSuccess('Category successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateCategoryFailure());
    yield put(alertError(error));
  }
}

function* watchUpdateCategory() {
  yield takeLatest(UPDATE_CATEGORY_REQUEST, workerUpdateCategory);
}

function* watchGetOneCategory() {
  yield takeLatest(GET_ONE_CATEGORY_REQUEST, workerGetOneCategory);
}

function* watchDeleteCategory() {
  yield takeLatest(DELETE_CATEGORY_REQUEST, workerDeleteCategory);
}

function* watchCreateCategory() {
  yield takeLatest(CREATE_CATEGORY_REQUEST, workerCreateCategory);
}

function* watchGetListCategory() {
  yield takeLatest(GET_LIST_CATEGORY_REQUEST, workerGetListCategory);
}

export const categoryWatchers: ForkEffect[] = [
  fork(watchGetListCategory),
  fork(watchCreateCategory),
  fork(watchDeleteCategory),
  fork(watchGetOneCategory),
  fork(watchUpdateCategory),
];
