import { useEffect, useState } from 'react';

import { Form, Spin } from 'antd';
import { useSelector } from 'react-redux';
import './style.scss';

import { FullMedia } from '@entities/Media';
import { updateEffectRequest } from '@store/Effect';
import { RootState, useAppDispatch } from '@store/index';
import { clearStore, uploadMediaRequest } from '@store/Media';
import { FileName, UploadFile } from '@utils';

import EffectEditForm from './Form';
import { FormValues } from './Form/index.type';
import { Props } from './index.type';

const EffectEdit = ({ effect, loadingEffect }: Props) => {
  const [imageFile, setImageFile] = useState<FullMedia | null>(null);
  const { loading: loadingImage, file: fileStore } = useSelector(
    (state: RootState) => state.media,
  );
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  const onFormSubmitHandler = (values: FormValues) => {
    dispatch(updateEffectRequest({ ...values, id: effect?.id }));
  };

  const onUploadImageHandler = (file: UploadFile['file']) => {
    dispatch(uploadMediaRequest({ file, fileName: FileName.IMAGE }));
  };

  const onRemoveImageHandler = () => {
    dispatch(clearStore());
    setImageFile(null);
  };

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fileStore) {
      setImageFile(fileStore);
    }
  }, [dispatch, fileStore]);

  useEffect(() => {
    if (effect?.image) {
      setImageFile(effect?.image);
    }
  }, [effect?.image]);

  return (
    <div className="effect-edit card-effects-info-effect">
      <Spin spinning={loadingEffect || loadingImage}>
        <EffectEditForm
          effect={effect}
          image={imageFile}
          form={form}
          onFormSubmit={onFormSubmitHandler}
          onUploadImage={onUploadImageHandler}
          onRemoveImage={onRemoveImageHandler}
        />
      </Spin>
    </div>
  );
};

export default EffectEdit;
