/* eslint-disable @typescript-eslint/no-shadow */
import { Fragment, useCallback, useEffect, useState } from 'react';

import { Col, Row, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { FilterRange } from '@entities/Filter';
import { FullUser } from '@entities/User';
import { useQuery } from '@hooks';
import { LayoutContainerWhite } from '@layouts';
import { RootState, useAppDispatch } from '@store/index';
import { getOneProductRequest } from '@store/Product';
import { clearListStatistics, getListStatisticRequest } from '@store/Statistic';
import {
  clearUserListStore,
  getListUserRequest,
  getOneUserRequest,
} from '@store/User';
import { UICalendar, UIDashboardInfo, UIHeader, UISelectFormItem } from '@ui';
import {
  DateHelper,
  FORMAT_DATE,
  LINK_PRODUCT,
  LINK_USERS,
  OptionSelect,
  RangeValue,
  Role,
} from '@utils';

import { infoTop } from './constants';
import { DashboardDetailChartArea } from './Detail';

import './style.scss';

const Dashboard = () => {
  const [period, setPeriod] = useState<FilterRange | null>(null);

  const [driverOption, setDriverOption] = useState<OptionSelect | null>(null);
  const [driver, setDriver] = useState<FullUser | null>(null);

  const [userOption, setUserOption] = useState<OptionSelect | null>(null);

  const dispatch = useAppDispatch();
  const { query, updateQuery } = useQuery();
  const {
    user,
    userDriver,
    userCustomer,
    loading: loadingUser,
  } = useSelector((state: RootState) => state.user);
  const { statistic, loading } = useSelector(
    (state: RootState) => state.statistic,
  );
  const { product, loading: loadingProduct } = useSelector(
    (state: RootState) => state.product,
  );

  useEffect(() => {
    if (!driver && user && statistic?.topDriver?.driverId === user.id) {
      setDriver({ ...user });
    }
  }, [driver, user, statistic]);

  useEffect(() => {
    if (statistic?.topDriver?.driverId) {
      dispatch(getOneUserRequest({ id: statistic?.topDriver?.driverId }));
    }
  }, [statistic, dispatch]);

  const getRequest = useCallback(() => {
    dispatch(clearListStatistics());
    dispatch(getListStatisticRequest(query));
  }, [query, dispatch]);

  useEffect(() => {
    getRequest();

    return () => {
      dispatch(clearListStatistics());
    };
  }, [dispatch, getRequest]);

  useEffect(() => {
    if ((period?.min && period?.max) || userOption || driverOption) {
      updateQuery({
        filter: {
          ...(period && { date: period }),
          ...(userOption && { userId: userOption.value }),
          ...(driverOption && { driverId: driverOption.value }),
        },
        pagination: false,
      });
    } else {
      updateQuery({ pagination: false, filter: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, userOption, driverOption]);

  const onChangeHandler = (values: RangeValue<Dayjs>) => {
    if (!values) {
      setPeriod(null);

      return;
    }

    setPeriod({
      min: DateHelper.getStartDayFormatString({
        date: values[0],
        format: FORMAT_DATE,
      }),
      max: DateHelper.getEndDayFormatString({
        date: values[1],
        format: FORMAT_DATE,
      }),
    });
  };

  const buttons = [
    <UISelectFormItem
      label="Driver"
      className="dashboardSelect"
      options={
        userDriver.list?.map((item) => ({
          name: item?.profile?.fullName || 'no name',
          id: item.id,
        })) || []
      }
      style={{ minWidth: '200px' }}
      getOption={(id) => id && dispatch(getOneUserRequest({ id }))}
      valueId={driverOption?.value}
      {...(driverOption && { value: driverOption })}
      getOptions={(query) =>
        dispatch(
          getListUserRequest(`${query}&filter[role]=${Role.DRIVERS}`, false),
        )
      }
      onChange={(id, e) => {
        setUserOption(null);
        setDriverOption(e as OptionSelect);
        setDriver(null);
      }}
      clearOptions={() => dispatch(clearUserListStore())}
      meta={userDriver?.meta}
      loading={loadingUser}
    />,
    <UISelectFormItem
      label="User"
      className="dashboardSelect"
      options={
        userCustomer?.list?.map((item) => ({
          name: item?.profile?.fullName || 'no name',
          id: item.id,
        })) || []
      }
      style={{ minWidth: '200px' }}
      getOption={(id) => id && dispatch(getOneUserRequest({ id }))}
      valueId={userOption?.value}
      {...(userOption && { value: userOption })}
      getOptions={(query) =>
        dispatch(
          getListUserRequest(`${query}&filter[role]=${Role.USER}`, false),
        )
      }
      onChange={(id, e) => {
        setDriverOption(null);
        setUserOption(e as OptionSelect);
        setDriver(null);
      }}
      clearOptions={() => dispatch(clearUserListStore())}
      meta={userCustomer?.meta}
      loading={loadingUser}
    />,
    <Fragment key={1}>
      <Text>Date range:</Text>
      <UICalendar
        placeholder={['Start date min', 'Start date max']}
        onChange={onChangeHandler}
      />
    </Fragment>,
  ];

  useEffect(() => {
    if (statistic) {
      setDriver(null);
      if (statistic?.topProduct?.productId && !product) {
        dispatch(getOneProductRequest(statistic?.topProduct?.productId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistic]);

  const renderInfoTopHTML = (
    title: string,
    key: 'topProduct' | 'topDriver',
  ) => {
    if (!statistic?.[key]) {
      return null;
    }

    return (
      <>
        <Col span={24}>
          <Title level={5}>{title}</Title>
        </Col>
        <LayoutContainerWhite title="Name" span={6} className="dashboard-info">
          <Space direction="vertical">
            {(() => {
              switch (key) {
                case 'topProduct':
                  return (
                    <Link to={`/${LINK_PRODUCT}/${product?.id}`}>
                      <Text strong className="dashboard-info__value">
                        {product?.name}
                      </Text>
                    </Link>
                  );

                case 'topDriver':
                  return (
                    <Link to={`/${LINK_USERS}/${driver?.id}`}>
                      <Text strong className="dashboard-info__value">
                        {driver?.profile?.fullName}
                      </Text>
                    </Link>
                  );

                default:
                  return null;
              }
            })()}
          </Space>
        </LayoutContainerWhite>
        {infoTop.map(
          (item) =>
            statistic?.[key] && (
              <UIDashboardInfo
                key={`info_${item.title}`}
                title={item.title}
                last={false}
                value={statistic?.[key]?.[item.dataIndex] as number}
              />
            ),
        )}
      </>
    );
  };

  return (
    <div className="dashboard">
      <Spin size="large" spinning={loading || loadingUser || loadingProduct}>
        <UIHeader title="Dashboard" buttons={buttons} />
        <Row gutter={[24, 24]} className="dashboard__wrap">
          {renderInfoTopHTML('Highest selling product', 'topProduct')}
          {renderInfoTopHTML('Best driver', 'topDriver')}

          {statistic ? (
            <DashboardDetailChartArea
              title="Average order price"
              data={statistic.salesByDay.map((item) => ({
                amount: item.avgPrice,
                date: item.date,
              }))}
              dataKey="amount"
              theme="purple"
              XAxisDataKey="date"
              span="24"
              fullInterval
              syncId="syncAllArea_1"
              tooltipValue="Price"
              tooltipLabelType="date"
            />
          ) : null}
          {statistic ? (
            <DashboardDetailChartArea
              title="Average order weight"
              data={statistic.salesByDay.map((item) => ({
                amount: item.avgWeight,
                date: item.date,
              }))}
              dataKey="amount"
              theme="purple"
              XAxisDataKey="date"
              span="24"
              fullInterval
              syncId="syncAllArea_2"
              tooltipValue="Grams"
              tooltipLabelType="date"
            />
          ) : null}
          {statistic ? (
            <DashboardDetailChartArea
              title="Total Sales"
              data={statistic.salesByDay.map((item) => ({
                amount: item.totalPrice,
                date: item.date,
              }))}
              dataKey="amount"
              theme="purple"
              XAxisDataKey="date"
              span="24"
              fullInterval
              syncId="syncAllArea_3"
              tooltipValue="Price"
              tooltipLabelType="date"
            />
          ) : null}
          {statistic ? (
            <DashboardDetailChartArea
              title="Total sold products"
              data={statistic.salesByDay.map((item) => ({
                amount: item.totalSoldProducts,
                date: item.date,
              }))}
              dataKey="amount"
              theme="purple"
              XAxisDataKey="date"
              span="24"
              fullInterval
              syncId="syncAllArea_4"
              tooltipValue="quantity"
              tooltipLabelType="date"
            />
          ) : null}
          {statistic ? (
            <DashboardDetailChartArea
              title="Total weight"
              data={statistic.salesByDay.map((item) => ({
                amount: item.totalWeight,
                date: item.date,
              }))}
              dataKey="amount"
              theme="purple"
              XAxisDataKey="date"
              span="24"
              fullInterval
              syncId="syncAllArea_5"
              tooltipValue="Grams"
              tooltipLabelType="date"
            />
          ) : null}
          {statistic ? (
            <DashboardDetailChartArea
              title="Average delivery time"
              data={statistic.salesByDay.map((item) => ({
                amount: item.avgDeliveryTime,
                date: item.date,
              }))}
              dataKey="amount"
              theme="purple"
              XAxisDataKey="date"
              span="24"
              fullInterval
              syncId="syncAllArea_6"
              tooltipValue="Minutes"
              tooltipLabelType="date"
            />
          ) : null}
          {statistic ? (
            <DashboardDetailChartArea
              title="Average delivery distance"
              data={statistic.salesByDay.map((item) => ({
                amount: item.avgDeliveryDistance,
                date: item.date,
              }))}
              dataKey="amount"
              theme="purple"
              XAxisDataKey="date"
              span="24"
              fullInterval
              syncId="syncAllArea_7"
              tooltipValue="Km"
              tooltipLabelType="date"
            />
          ) : null}
        </Row>
      </Spin>
    </div>
  );
};

export default Dashboard;
