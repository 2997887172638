/* eslint-disable indent */
import { ColumnsType } from 'antd/lib/table';

import type {
  FilterOption,
  FullReviewBrand,
  FullReviewUser,
  Profile,
  SortOption,
} from '@entities/index';
import { clearListBrands, getListBrandRequest } from '@store/Brand';
import type { AppDispatch } from '@store/index';
import { clearUserListStore, getListUserRequest } from '@store/User';
import { UICellFullName, UICellRate, UICellTooltip } from '@ui';
import {
  COLUMN_WIDTH_ACTIONS,
  COLUMN_WIDTH_FULL_DATE,
  COLUMN_WIDTH_ID,
  COLUMN_WIDTH_NAME,
  DateHelper,
  FORMAT_DATE,
  FORMAT_DATE_TIME_WITH_DOT,
  LINK_USERS,
} from '@utils';

import { ReviewBrandUIActions, ReviewUserUIActions } from './ui';

const renderActionColumnBrand = (
  dispatch?: AppDispatch,
): ColumnsType<FullReviewBrand> => {
  if (dispatch) {
    return [
      {
        title: 'Actions',
        key: 'action',
        width: COLUMN_WIDTH_ACTIONS * 2,
        render: (_value: FullReviewBrand, review: FullReviewBrand) =>
          ReviewBrandUIActions({
            review,
            dispatch,
          }),
        align: 'right',
      },
    ];
  }

  return [];
};

const renderActionColumnUser = (
  dispatch?: AppDispatch,
): ColumnsType<FullReviewUser> => {
  if (dispatch) {
    return [
      {
        title: 'Actions',
        key: 'action',
        width: COLUMN_WIDTH_ACTIONS * 2,
        render: (_value: FullReviewUser, review: FullReviewUser) =>
          ReviewUserUIActions({
            review,
            dispatch,
          }),
        align: 'right',
      },
    ];
  }

  return [];
};

export const columnsBrand = ({
  dispatch,
}: {
  dispatch?: AppDispatch;
}): ColumnsType<FullReviewBrand> => {
  return [
    {
      title: 'Id',
      key: 'id',
      width: COLUMN_WIDTH_ID,
      render: (value: FullReviewBrand) => value.id,
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: COLUMN_WIDTH_NAME,
      render: (value: { id: Id; profile: Profile }) =>
        UICellFullName({
          lastName: value?.profile?.lastName,
          firstName: value?.profile?.firstName,
          link: `/${LINK_USERS}/${value?.id}`,
        }),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: COLUMN_WIDTH_FULL_DATE,
      render: (value: string) =>
        DateHelper.formateDateToString(value, FORMAT_DATE_TIME_WITH_DOT),
    },
    {
      title: 'Text',
      dataIndex: 'comment',
      key: 'comment',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => UICellTooltip({ text }),
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      width: COLUMN_WIDTH_FULL_DATE,
      render: (value) =>
        UICellRate({ rating: value, count: 5, disabled: true }),
    },
    ...renderActionColumnBrand(dispatch),
  ];
};

export const columnsUser = ({
  dispatch,
}: {
  dispatch?: AppDispatch;
}): ColumnsType<FullReviewUser> => {
  return [
    {
      title: 'Id',
      key: 'id',
      width: COLUMN_WIDTH_ID,
      render: (value: FullReviewUser) => value.id,
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      width: COLUMN_WIDTH_NAME,
      render: (value: { id: Id; profile: Profile }) =>
        UICellFullName({
          lastName: value?.profile?.lastName,
          firstName: value?.profile?.firstName,
          link: `/${LINK_USERS}/${value?.id}`,
        }),
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: COLUMN_WIDTH_NAME,
      render: (value: { id: Id; profile: Profile }) =>
        UICellFullName({
          lastName: value?.profile?.lastName,
          firstName: value?.profile?.firstName,
          link: `/${LINK_USERS}/${value?.id}`,
        }),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: COLUMN_WIDTH_FULL_DATE,
      render: (value: string) =>
        DateHelper.formateDateToString(value, FORMAT_DATE_TIME_WITH_DOT),
    },
    {
      title: 'Text',
      dataIndex: 'comment',
      key: 'comment',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => UICellTooltip({ text }),
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      width: COLUMN_WIDTH_FULL_DATE,
      render: (value) =>
        UICellRate({ rating: value, count: 5, disabled: true }),
    },
    ...renderActionColumnUser(dispatch),
  ];
};

export const sortOption = (): SortOption[] => [
  {
    label: 'Id (ascending)',
    name: 'id',
    direction: 'asc',
  },
  {
    label: 'Id (descending)',
    name: 'id',
    direction: 'desc',
  },
  {
    label: 'Date (oldest first)',
    name: 'createdAt',
    direction: 'asc',
  },
  {
    label: 'Date (newest first)',
    name: 'createdAt',
    direction: 'desc',
  },
  {
    label: 'Rating (ascending)',
    name: 'rating',
    direction: 'asc',
  },
  {
    label: 'Rating (descending)',
    name: 'rating',
    direction: 'desc',
  },
];

const today = new Date();

export const filterOptions = ({
  dispatch,
  tabsActive,
}: {
  dispatch: AppDispatch;
  tabsActive: string | null;
}) =>
  [
    {
      name: 'createdAt',
      label: 'Date',
      type: 'radio-range',
      options: [
        {
          label: 'Today',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
        {
          label: 'Last 7 days',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
              amountDays: -7,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
        {
          label: 'Last 30 days',
          value: {
            min: DateHelper.getStartDayFormatString({
              date: today,
              format: FORMAT_DATE,
              amountDays: -30,
            }),
            max: DateHelper.getEndDayFormatString({
              date: today,
              format: FORMAT_DATE,
            }),
          },
        },
      ],
    },
    {
      name: 'rating',
      label: 'Rating',
      type: 'number-range',
      step: 1,
      min: 0,
      max: 5,
    },
    ...(tabsActive === 'Brand'
      ? [
          {
            name: 'brandIds',
            label: 'Brands',
            type: 'radio-select',
            className: 'scroll-y h200px w100',
            load: {
              get: (query: string) => dispatch(getListBrandRequest(query)),
              clear: () => dispatch(clearListBrands()),
              store: 'brand',
              listName: ['brandList'],
            },
          },
        ]
      : [
          {
            name: 'userIds',
            label: 'User',
            type: 'radio-select',
            className: 'scroll-y h200px w100',
            load: {
              get: (query: string) => dispatch(getListUserRequest(query)),
              clear: () => dispatch(clearUserListStore()),
              store: 'user',
              listName: ['userList'],
            },
          },
        ]),
  ] as FilterOption[];
