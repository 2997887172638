/* eslint-disable no-param-reassign */
import { IAddressComponents, IAddressesRequest } from '@entities/Address';

export const getInfoCity = (components: IAddressComponents[]) => {
  return components.reduce((acc, curr) => {
    curr.types.forEach((type) => {
      switch (type) {
        case 'locality':
          acc = { ...acc, city: curr.long_name };
          break;
        case 'country':
          acc = {
            ...acc,
            country: curr.long_name,
            countryISO: curr.short_name,
          };
          break;
        case 'postal_code':
          acc = { ...acc, postalCode: curr.long_name };
          break;
        default:
          return acc;
      }

      return acc;
    });

    return acc;
  }, {} as IAddressesRequest);
};

export const getInfoAboutPlace = (components: IAddressComponents[]) => {
  return components.reduce((acc, curr) => {
    curr.types.forEach((type) => {
      switch (type) {
        case 'street_number':
          acc = { ...acc, streetNumber: +curr.short_name };
          break;
        case 'route':
          acc = { ...acc, streetName: curr.long_name };
          break;
        case 'locality':
          acc = { ...acc, city: curr.long_name };
          break;
        case 'administrative_area_level_1':
          acc = {
            ...acc,
            state: curr.long_name,
            stateISO: curr.short_name,
          };
          break;
        case 'administrative_area_level_2':
          acc = {
            ...acc,
            county: curr.long_name.replace('County', '').trim(),
          };
          break;
        case 'country':
          acc = {
            ...acc,
            country: curr.long_name,
            countryISO: curr.short_name,
          };
          break;
        case 'postal_code':
          acc = { ...acc, postalCode: curr.long_name };
          break;
        default:
          return acc;
      }

      return acc;
    });

    return acc;
  }, {} as IAddressesRequest);
};
