import { useEffect, useMemo, useState } from 'react';

import { Tabs } from 'antd';

import { useQuery } from '@hooks';
import { LocalStorage } from '@services/storage';

import { Props } from './index.type';

import './style.scss';

const UITabFilter = ({ defaultTitle, tab }: Props) => {
  const { setQuery } = useQuery();
  const [activeItem, setActiveItem] = useState(defaultTitle || 'tab-All');

  useEffect(() => {
    const localActive: string | null | undefined = LocalStorage.get('tab');

    if (localActive) {
      setActiveItem(`tab-${localActive}`);
    }
  }, []);

  const renderTabs = useMemo(
    () =>
      tab &&
      tab.map(({ label }) => <Tabs.TabPane tab={label} key={`tab-${label}`} />),
    [tab],
  );

  const onChangeTabHandler = (key: string) => {
    const keyEl = key.split('-');

    if (keyEl[0] === 'tab' && tab) {
      setActiveItem(`tab-${keyEl[1]}`);
      const item = tab.find(({ label }) => label === keyEl[1]);

      if (item) {
        setQuery(item.options);
        LocalStorage.set('tab', keyEl[1]);
      }
    }
  };

  return (
    <Tabs
      activeKey={activeItem}
      onTabClick={onChangeTabHandler}
      className="header-tab"
    >
      {renderTabs}
    </Tabs>
  );
};

export default UITabFilter;
