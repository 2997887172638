import { ForkEffect, call, fork, put, takeLatest } from 'redux-saga/effects';

import { FullBrand } from '@entities/Brand';
import { BrandHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';

import {
  createBrandFailure,
  createBrandSuccess,
  deleteBrandFailure,
  deleteBrandSuccess,
  getListBrandFailure,
  getListBrandSuccess,
  getOneBrandFailure,
  getOneBrandSuccess,
  updateBrandFailure,
  updateBrandSuccess,
} from './Brand.action';
import {
  CREATE_BRAND_REQUEST,
  DELETE_BRAND_REQUEST,
  GET_LIST_BRAND_REQUEST,
  GET_ONE_BRAND_REQUEST,
  UPDATE_BRAND_REQUEST,
} from './Brand.constant';
import {
  ICreateBrandRequest,
  IDeleteBrandRequest,
  IGetListBrandRequest,
  IGetOneBrandRequest,
  IUpdateBrandRequest,
} from './Brand.type';

function* workerGetListBrand(action: IGetListBrandRequest) {
  try {
    const { query } = action.payload;
    const data: DataResponse<FullBrand[]> = yield call(
      BrandHttp.getList,
      query,
    );

    yield put(getListBrandSuccess(data));
  } catch (error) {
    yield put(getListBrandFailure());
    yield put(alertError(error));
  }
}

function* workerCreateBrand(action: ICreateBrandRequest) {
  try {
    const data: FullBrand = yield call(BrandHttp.create, action.payload);

    yield put(createBrandSuccess(data));
    yield put(alertSuccess('Brand added successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(createBrandFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteBrand(action: IDeleteBrandRequest) {
  try {
    const { id } = action.payload;

    yield call(BrandHttp.remove, id);

    yield put(deleteBrandSuccess(id));
    yield put(alertSuccess('Brand successfully deleted!'));
  } catch (error) {
    yield put(deleteBrandFailure());
    yield put(alertError(error));
  }
}

function* workerGetOneBrand(action: IGetOneBrandRequest) {
  try {
    const { id, toList } = action.payload;
    const payload: FullBrand = yield call(BrandHttp.getOne, id);

    yield put(getOneBrandSuccess({ data: payload, toList }));
  } catch (error) {
    yield put(getOneBrandFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateBrand(action: IUpdateBrandRequest) {
  try {
    const { id, ...body } = action.payload;

    const data: FullBrand = yield call(BrandHttp.update, id, body);

    yield put(updateBrandSuccess(data));
    yield put(alertSuccess('Brand successfully update!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateBrandFailure());
    yield put(alertError(error));
  }
}

function* watchUpdateBrand() {
  yield takeLatest(UPDATE_BRAND_REQUEST, workerUpdateBrand);
}

function* watchGetOneBrand() {
  yield takeLatest(GET_ONE_BRAND_REQUEST, workerGetOneBrand);
}

function* watchDeleteBrand() {
  yield takeLatest(DELETE_BRAND_REQUEST, workerDeleteBrand);
}

function* watchCreateBrand() {
  yield takeLatest(CREATE_BRAND_REQUEST, workerCreateBrand);
}

function* watchGetListBrand() {
  yield takeLatest(GET_LIST_BRAND_REQUEST, workerGetListBrand);
}

export const brandWatchers: ForkEffect[] = [
  fork(watchGetListBrand),
  fork(watchCreateBrand),
  fork(watchDeleteBrand),
  fork(watchGetOneBrand),
  fork(watchUpdateBrand),
];
