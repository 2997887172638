import { Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { UICellFullName, UICellStatus } from '@ui';
import {
  DateHelper,
  DiscountUnit,
  FORMAT_SHORT_DATE,
  LINK_USERS,
} from '@utils';

import { Props } from './index.type';

import './style.scss';

const PromoCodeDetailDiscription = ({ loading, promoCode }: Props) => {
  return (
    <Spin size="large" spinning={loading}>
      <div className="card-promoCode-info-promoCode">
        <div className="card-promoCode-info-promoCode-line"></div>
        <div className="card-promoCode-info-promoCode-content">
          <Title level={5}>Code</Title>
          <Text>{promoCode?.code}</Text>
        </div>
        <div className="card-promoCode-info-promoCode-content">
          <Title level={5}>Discount unit</Title>
          <Text>{promoCode?.discountUnit}</Text>
        </div>
        <div className="card-promoCode-info-promoCode-content">
          <Title level={5}>Value</Title>
          <Text>{`${promoCode?.discountValue}${
            promoCode?.discountUnit === DiscountUnit.PERCENT ? '%' : '$'
          }`}</Text>
        </div>
        <div className="card-promoCode-info-promoCode-content">
          <Title level={5}>User</Title>
          <Text>
            {UICellFullName({
              lastName: promoCode?.user?.profile?.lastName,
              firstName: promoCode?.user?.profile?.firstName || '',
              src: promoCode?.user?.avatar?.path,
              link: `${LINK_USERS}/${promoCode?.user?.id}`,
            })}
          </Text>
        </div>
        <div className="card-promoCode-info-promoCode-content">
          <Title level={5}>Created by</Title>
          <Text>
            {UICellFullName({
              lastName: promoCode?.createdBy?.profile?.lastName,
              firstName: promoCode?.createdBy?.profile?.firstName || '',
              src: promoCode?.createdBy?.avatar?.path,
              link: `${LINK_USERS}/${promoCode?.createdBy?.id}`,
            })}
          </Text>
        </div>
        <div className="card-promoCode-info-promoCode-content">
          <Title level={5}>Valid from / to</Title>
          <Text>{`${DateHelper.formateDateToString(
            promoCode?.validFrom,
            FORMAT_SHORT_DATE,
          )} / ${DateHelper.formateDateToString(
            promoCode?.validUntil,
            FORMAT_SHORT_DATE,
          )}`}</Text>
        </div>
        <div className="card-promoCode-info-promoCode-content">
          <Title level={5}>Is active</Title>
          <Text>
            {UICellStatus({
              value: !!promoCode?.isActive,
              text: promoCode?.isActive ? 'Yes' : 'No',
            })}
          </Text>
        </div>
      </div>
    </Spin>
  );
};

export default PromoCodeDetailDiscription;
