import { FullChat, Message } from '@entities/Chat';

import {
  CLEAR_CHAT,
  CLEAR_MESSAGE,
  GET_LIST_CHAT_FAILURE,
  GET_LIST_CHAT_REQUEST,
  GET_LIST_CHAT_SUCCESS,
  GET_LIST_MESSAGE_FAILURE,
  GET_LIST_MESSAGE_REQUEST,
  GET_LIST_MESSAGE_SUCCESS,
  GET_ONE_CHAT_FAILURE,
  GET_ONE_CHAT_REQUEST,
  GET_ONE_CHAT_SUCCESS,
  SET_DIALOG_ID,
} from './Chat.constant';
import { ChatActionsTypes } from './Chat.type';

export function setDialogId(id: number | null): ChatActionsTypes {
  return {
    type: SET_DIALOG_ID,
    payload: {
      id,
    },
  };
}

export function getListChatRequest(query?: Query['query']): ChatActionsTypes {
  return {
    type: GET_LIST_CHAT_REQUEST,
    payload: {
      query,
    },
  };
}

export function getListChatSuccess(
  payload: DataResponse<FullChat[]>,
): ChatActionsTypes {
  return {
    type: GET_LIST_CHAT_SUCCESS,
    payload,
  };
}

export function getListChatFailure(): ChatActionsTypes {
  return {
    type: GET_LIST_CHAT_FAILURE,
  };
}

export function getListMessageRequest(payload: {
  id: number;
  query?: Query['query'];
}): ChatActionsTypes {
  return {
    type: GET_LIST_MESSAGE_REQUEST,
    payload,
  };
}

export function getListMessageSuccess(
  payload: DataResponse<Message[]>,
): ChatActionsTypes {
  return {
    type: GET_LIST_MESSAGE_SUCCESS,
    payload,
  };
}

export function getListMessageFailure(): ChatActionsTypes {
  return {
    type: GET_LIST_MESSAGE_FAILURE,
  };
}

export function getOneChatRequest(id: FullChat['id']): ChatActionsTypes {
  return {
    type: GET_ONE_CHAT_REQUEST,
    payload: {
      id,
    },
  };
}

export function getOneChatSuccess(payload: FullChat): ChatActionsTypes {
  return {
    type: GET_ONE_CHAT_SUCCESS,
    payload,
  };
}

export function getOneChatFailure(): ChatActionsTypes {
  return {
    type: GET_ONE_CHAT_FAILURE,
  };
}

export function clearChat(): ChatActionsTypes {
  return {
    type: CLEAR_CHAT,
  };
}

export function clearMessage(): ChatActionsTypes {
  return {
    type: CLEAR_MESSAGE,
  };
}
